import React from 'react';
import { Box, Typography, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/system';

const ProgressWrapper = styled(Box)(() => ({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const ProgressLabel = styled(Box)(() => ({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const CommonCircularProgress = ({
    value = 0,
    size = 100,
    color = "",
    label = "",
    subLabel = ""
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'center' : 'flex-start',
            gap: 2,
            backgroundColor: '#131314',
            borderRadius: "26px",
            p: 3,
            width: '100%',
        }}>
            <ProgressWrapper>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={size}
                    sx={{
                        color: alpha(color, 0.06),
                        position: 'absolute',
                    }}
                />
                <CircularProgress
                    variant="determinate"
                    value={value}
                    size={size}
                    sx={{
                        color: alpha(color, 0.6),
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'butt',
                        },
                    }}
                />
                <ProgressLabel>
                    <Box textAlign={"center"}>
                        <Typography
                            sx={{ fontWeight: '600', fontSize: '24px', }}
                        >
                            {value}
                        </Typography>
                        <Typography
                            sx={{ fontWeight: '300', color: alpha(color, 0.6), fontSize: "12px" }}
                        >
                            {subLabel}
                        </Typography>
                    </Box>
                </ProgressLabel>
            </ProgressWrapper>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'flex-start',
                marginTop: isMobile ? 0 : 3
            }}>
                <Typography
                    color="white"
                    sx={{
                        fontWeight: '700',
                        fontSize: '24px',
                        lineHeight: 1.2,
                        textAlign: isMobile ? 'center' : 'left'
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: 'grey.500',
                        fontSize: '14px',
                        fontWeight: '300',
                        textAlign: isMobile ? 'center' : 'left'
                    }}
                >
                    Total
                </Typography>
            </Box>
        </Box>
    );
};

export default CommonCircularProgress;