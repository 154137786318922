import { BrowserRouter, Route, Routes } from "react-router-dom";
import User from "./Pages/User/Experience";
import { ThemeProvider } from "@emotion/react";
import { darkTheme } from "./theme";
import Keywords from "./Pages/User/keywords";
import Layouts from "./Components/Layouts";
import Review from "./Pages/User/Review";
import Feedback from "./Pages/User/Feedback";
import FeedbackForm from "./Pages/User/FeedbackForm";
import Success from "./Pages/User/Success";
import Dashboard from "./Pages/Admin/Dashboard";
import MyAccount from "./Pages/Admin/MyAccount";
import Subscription from "./Pages/Admin/Subscription";
import MyBusiness from "./Pages/Admin/MyBusiness";
import Reviews from "./Pages/Admin/Reviews";
import Complains from "./Pages/Admin/Complains";
import PrivacyPolicy from "./Pages/Admin/PrivacyPolicy";
import HelpDesk from "./Pages/Admin/HelpDesk";
import Login from "./Pages/Login";
import { ProtectedRoutes } from "./Routes/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Pages/Loader/Loader";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import OtpVerification from "./Pages/ForgotPassword/OtpVerification";
import SetnewPassword from "./Pages/ForgotPassword/SetnewPassword";
import PasswordReset from "./Pages/ForgotPassword/PasswordReset";
import AllRestaurant from "./Pages/SuperAdmin/AllRestaurant";
import MangeSubscription from "./Pages/Admin/MangeSubscription";
import PaymentDetails from "./Pages/PaymentDetails";
import KeywordsAndTags from "./Pages/Admin/KeywordsAndTags"
import PaymentPage from "./Pages/PaymentDetails/PaymentPage";
import PageNotFound from "./Components/Common/pageNotFound";
import Admin from "./Pages/Admin/AdminList";
import TermsandConditions from "./Pages/Admin/TermsandConditions";
import SignUp from "./Pages/SignUp";

function App() {
  return (
    <BrowserRouter>
      <Loader />
      <ToastContainer />
      <ThemeProvider theme={darkTheme}>
        <Layouts>
          <Routes>
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/otp-verification" element={<OtpVerification />} />
            <Route exact path="/setnew-password" element={<SetnewPassword />} />
            <Route exact path="/password-reset" element={<PasswordReset />} />
            <Route exact path="/user/experience" element={<User />} />
            <Route exact path="/user/keyword" element={<Keywords />} />
            <Route exact path="/user/review" element={<Review />} />
            <Route exact path="/user/feedback" element={<Feedback />} />
            <Route exact path="/user/feedbackform" element={<FeedbackForm />} />
            <Route exact path="/user/success" element={<Success />} />
            <Route element={<ProtectedRoutes />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/myAccount" element={<MyAccount />} />
              <Route exact path="/admin" element={<Admin />} />
              <Route exact path="/mangesubscription" element={<MangeSubscription />} />
              <Route exact path="/subscription" element={<Subscription />} />
              <Route exact path="/myBusiness" element={<MyBusiness />} />
              <Route exact path="/reviews" element={<Reviews />} />
              <Route exact path="/complains" element={<Complains />} />
              <Route exact path="/TermsandConditions" element={<TermsandConditions />} />
              <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route exact path="/helpDesk" element={<HelpDesk />} />
              <Route exact path="/allBusiness" element={<AllRestaurant />} />
              <Route exact path="/paymentdetails" element={<PaymentDetails />} />
              <Route exact path="/keywords-and-tags" element={<KeywordsAndTags />} />
              <Route exact path="/payment-page" element={<PaymentPage />} />
              <Route exact path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Layouts>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
