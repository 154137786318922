import React, { useState } from 'react';
import CommonBarChart from '../BarChart/CommonBarChart';

const AgeBarChart = ({ data, options }) => {
    // Static data for the age groups


    const gradientIds = ['ageGradient1'];
    const barNumbers = 1;
    const title = 'Review by Age';
    const [selectedValue, setSelectedValue] = useState('Monthly');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <CommonBarChart
            data={data}
            gradientIds={gradientIds}
            barNumbers={barNumbers}
            title={title}
            showMonthlyOption={true}
            selectedValue={selectedValue}
            handleChange={handleChange}
            showGrid={false}
            options={options}
            height={250}
            color1={'#F4A606 '}
        />
    );
};

export default AgeBarChart;

