import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CurrentRatingChip from '../../Components/Common/Charts/Chips/CurrentRatingChip';
import InitialRatingChip from '../../Components/Common/Charts/Chips/InitialRatingChip';
import TotalReviewsChip from '../../Components/Common/Charts/Chips/TotalReviewsChip';
import ComplaintsChip from '../../Components/Common/Charts/Chips/ComplaintsChip';
import LineChartComponent from '../../Components/Common/Charts/LineChart';
import QRCodeScansChip from '../../Components/Common/Charts/Chips/QRCodeScansChip';
import FiveStarReviewChip from '../../Components/Common/Charts/Chips/FiveStarReviewChip';
import PositiveResponsesChip from '../../Components/Common/Charts/Chips/PositiveResponsesChip';
import MostDislikedCategoryChip from '../../Components/Common/Charts/Chips/MostDislikedCategoryChip';
import RatingReviewPieChart from '../../Components/Common/Charts/RatingReviewPieChart';
import RepliesPieChart from '../../Components/Common/Charts/RepliesPieChart';
import AreaChartComponent from '../../Components/Common/Charts/AreaChartComponent';
import MonthlyReportsLineChart from '../../Components/Common/Charts/MonthlyReportsLineChart';
import ReviewBarChart from '../../Components/Common/Charts/ReviewBarChart';
import ReviewList from '../../Components/Common/Charts/ReviewList';
import TabTable from '../../Components/Common/Charts/TabTable';
import UserJourneyChart from '../../Components/Common/Charts/UserJourneyChart';
import CustomBarChart from '../../Components/Common/Charts/SuperAdmin/CustomBarChart';
import DonutChart from '../../Components/Common/Charts/SuperAdmin/DonutChart';
import TopKeywords from '../../Components/Common/Charts/SuperAdmin/TopKeywords';
import AgeBarChart from '../../Components/Common/Charts/SuperAdmin/AgeBarChart';
import LocationBarChart from '../../Components/Common/Charts/SuperAdmin/LocationBarChart';
import UserDataPieChart from '../../Components/Common/Charts/SuperAdmin/UserDataPieChart';
import BusinessTypeBarChart from '../../Components/Common/Charts/SuperAdmin/BusinessTypeBarChart';
import SitePerformanceDatePicker from '../../Components/Common/SitePerformanceDatePicker';
import ReviewByAgeBarChart from '../../Components/Common/Charts/ReviewByAgeBarChart';
import sessionStoreUtil from '../../ApiSetup/Localstorage';
import { businessData, dataByBusinessType, subscriptionPercentage, topUseKeywords } from '../../Redux/Actions/action';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import ErrorHandler from '../../Utils/handler';

const options = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Yearly', label: 'Yearly' },
];

const LineChartData = [
  { month: 'Jan', value: 15 },
  { month: 'Feb', value: 25 },
  { month: 'Mar', value: 35 },
  { month: 'Apr', value: 45 },
  { month: 'May', value: 45 },
  { month: 'Jun', value: 55 },
  { month: 'Jul', value: 60 },
  { month: 'Aug', value: 50 },
  { month: 'Sep', value: 15 },
  { month: 'Oct', value: 35 },
  { month: 'Nov', value: 60 },
  { month: 'Dec', value: 40 },
];

// const RatingReviewdata = [
//   { name: 'Five Star', value: 50, color: 'url(#gradient)' },
//   { name: 'Four Star', value: 20, color: '#D0CCC4' },
//   { name: 'Three Star', value: 10, color: '#43413E' },
//   { name: 'Two Star', value: 10, color: '#43413E' },
//   { name: 'One Star', value: 10, color: '#43413E' },
// ];

const RatingReviewdata = [
  { name: 'Five Star', value: 50, },
  { name: 'Four Star', value: 20, },
  { name: 'Three Star', value: 10, },
  { name: 'Two Star', value: 10, },
  { name: 'One Star', value: 10, },
];

const AreaLineChartdata = [
  { name: 'Jan 21', review: 320, complain: 100 },
  { name: 'Feb 21', review: 280, complain: 130 },
  { name: 'Mar 21', review: 300, complain: 90 },
  { name: 'Apr 21', review: 290, complain: 110 },
];

const AreaLineChart2data = [
  { name: 'Jan 21', review: 120, complain: 200 },
  { name: 'Feb 21', review: 180, complain: 120 },
  { name: 'Mar 21', review: 250, complain: 150 },
  { name: 'Apr 21', review: 300, complain: 180 },
];

const MonthlyReportsLineChartdata = [
  { name: 'Jan 21', rating: 2, average: 1 },
  { name: 'Feb 21', rating: 2.5, average: 1.2 },
  { name: 'Mar 21', rating: 2.1, average: 1.3 },
  { name: 'Apr 21', rating: 2.7, average: 1.4 },
  { name: 'May 21', rating: 3.2, average: 1.5 }
];

const ReviewBarChartdata = [
  { month: '15-20', Data1: 25 },
  { month: '21-25', Data1: 50 },
  { month: '26-30', Data1: 75 },
  { month: '31-35', Data1: 85 },
  { month: '36-40', Data1: 100 },
  { month: '41-45', Data1: 90 },
  { month: '46-50', Data1: 75 },
  { month: '51-55', Data1: 40 },
];

const ReviewByAgeBarChartData = [
  { month: '15-20', Data1: 25 },
  { month: '21-25', Data1: 50 },
  { month: '26-30', Data1: 75 },
  { month: '31-35', Data1: 85 },
  { month: '36-40', Data1: 100 },
  { month: '41-45', Data1: 90 },
  { month: '46-50', Data1: 75 },
  { month: '51-55', Data1: 40 },
];

const ReviewsData = [
  {
    id: 1,
    username: 'John Doe',
    avatar: '/api/placeholder/40/40',
    rating: 5,
    review: 'Been dreaming about delicious pizza? Amazing Italian cuisine awaits you in this gem. The pizza, with its crispy crust and rich toppings, hits the spot.',
    postedTime: '2 hours ago'
  },
  {
    id: 2,
    username: 'John Doe',
    avatar: '/api/placeholder/40/40',
    rating: 5,
    review: 'Been dreaming about delicious pizza? Amazing Italian cuisine awaits you in this gem. The pizza, with its crispy crust and rich toppings, hits the spot.',
    postedTime: '2 hours ago'
  },
  {
    id: 3,
    username: 'John Doe',
    avatar: '/api/placeholder/40/40',
    rating: 5,
    review: 'Been dreaming about delicious pizza? Amazing Italian cuisine awaits you in this gem. The pizza, with its crispy crust and rich toppings, hits the spot.',
    postedTime: '2 hours ago'
  },
  {
    id: 4,
    username: 'John Doe',
    avatar: '/api/placeholder/40/40',
    rating: 5,
    review: 'Been dreaming about delicious pizza? Amazing Italian cuisine awaits you in this gem. The pizza, with its crispy crust and rich toppings, hits the spot.',
    postedTime: '2 hours ago'
  },
  {
    id: 5,
    username: 'John Doe',
    avatar: '/api/placeholder/40/40',
    rating: 5,
    review: 'Been dreaming about delicious pizza? Amazing Italian cuisine awaits you in this gem. The pizza, with its crispy crust and rich toppings, hits the spot.',
    postedTime: '2 hours ago'
  },
];

const TabTable1Data = [
  { no: '01', keyword: 'Pasta', count: 120 },
  { no: '02', keyword: 'Maggie', count: 80 },
  { no: '03', keyword: 'Pizza', count: 65 },
  { no: '04', keyword: 'Dosa', count: 52 },
  { no: '05', keyword: 'Cakes', count: 40 },
];

// const UserJourneyChartdata = [
//   { name: 'Landing page', value: 45000, color: '#FD63C5' },
//   { name: 'Keyword page', value: 35000, color: '#F4A606' },
//   { name: 'Review page', value: 25000, color: '#E39F93' },
//   { name: 'Rating page', value: 18000, color: '#FA7D00' },
//   { name: 'Complain', value: 12000, color: '#FFEFAF' },
// ];

const UserJourneyChartdata = [
  { name: 'Landing page', value: 45000, },
  { name: 'Keyword page', value: 35000, },
  { name: 'Review page', value: 25000, },
  { name: 'Rating page', value: 18000, },
  { name: 'Complain', value: 12000, },
];

const CustomBardata = [
  { month: 'Jan', Data1: 100, Data2: 50 },
  { month: 'Feb', Data1: 200, Data2: 80 },
  { month: 'Mar', Data1: 150, Data2: 60 },
  { month: 'Apr', Data1: 120, Data2: 40 },
  { month: 'May', Data1: 180, Data2: 70 },
  { month: 'Jun', Data1: 220, Data2: 90 },
];

// const subscriptionData = [
//   { label: 'Monthly', percentage: 80 },
//   { label: 'Quarterly', percentage: 10 },
//   { label: 'Yearly', percentage: 10 },
// ];

// const keywords = [
//   { title: '🥗Healthy', count: 5, rating: 5 },
//   { title: '😋Delicious', count: 4, rating: 4 },
//   { title: '🍽 Farm-to-table', count: 3, rating: 3 },
//   { title: '🍽 Food tasty food', count: 2, rating: 2 },
//   { title: '😋Yummy Pizza', count: 1, rating: 1 },
// ];

const AgeBardata = [
  { month: '15-20', Data1: 10, },
  { month: '21-25', Data1: 20, },
  { month: '26-30', Data1: 30, },
  { month: '31-35', Data1: 50, },
  { month: '36-40', Data1: 70, },
  { month: '41-45', Data1: 40, },
  { month: '46-50', Data1: 20, },
  { month: '51-55', Data1: 5, },
];

const LocationBardata = [
  { month: 'Jan 21', Data1: 3 },
  { month: 'Feb 21', Data1: 2 },
  { month: 'Mar 21', Data1: 1 },
  { month: 'Apr 21', Data1: 2 },
  { month: 'May 21', Data1: 3 },
  { month: 'June 12', Data1: 1 },
  { month: 'July 25', Data1: 2 },
];

const countries = [
  { value: 'UK', label: 'UK' },
  { value: 'India', label: 'India' },
  { value: 'USA', label: 'USA' },
];

const UserPieChartdata = [
  { name: 'Active user', value: 1200 },
  { name: 'Hold user number', value: 250 },
  { name: 'Deleted user number', value: 123 },
];

const BuisinessTypeData = [
  { month: 'Dairy & Cheese', Data1: 200 },
  { month: 'Food Manufacturers', Data1: 316 },
  { month: 'Specialty Food', Data1: 412 },
  { month: 'Farm to Table', Data1: 120 },
  { month: 'Meal Delivery', Data1: 305 },
  { month: 'Bars & Pubs', Data1: 400 },
  { month: 'Street Food', Data1: 302 },
  { month: 'Confectioneries', Data1: 296 },
  { month: 'Frozen Dessert', Data1: 295 },
  { month: 'Butcher Shops', Data1: 90 },
];

const RepliesPieData = [
  { name: 'Replies', value: 34249 },
  { name: 'Breakdown', value: 20000 },
];

const chipData = [
  {
    value: 4.33,
    label: 'Average rating',
    trend: '12.90%'
  },
  {
    value: 210,
    label: 'Total Review',
    trend: '12.90%'
  },
  {
    value: 2630,
    label: 'Total Request',
    trend: '12.90%'
  },
  {
    value: '21.03%',
    label: 'Response rate',
    trend: '13.90%'
  }
];

const InitialRatingChipdata = {
  title: 'Initial Rating',
  value: '1.2',
  change: '5.56%',
};

const CurrentRatingChipdata = {
  title: 'Current Rating',
  value: '4.6',
  change: '5.60%',
};

const TotalReviewsChipdata = {
  title: 'Total number of review',
  value: '1123',
  change: '102.20',
};

const ComplaintsChipdata = {
  title: 'Complaints',
  value: '10',
  change: '4',
};

const QRCodeScansChipdata = {
  title: 'QR Code Scans',
  value: 'N/A',
};

const FiveStarReviewChipdata = {
  title: '5 Star Review Till 4.6',
  value: '200',
};

const PositiveResponsesChipdata = {
  title: 'Positive Responses',
  value: '36.2',
};

const MostDislikedCategoryChipdata = {
  title: 'Most Disliked Category',
  value: 'noise - 96',
};

function Dashboard() {

  const dispatch = useDispatch()
  const userData = sessionStoreUtil?.getLSItem("userData");
  const loginData = userData ? JSON.parse(userData) : null;

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [dataByBusiness, setDataByBusiness] = useState([]);
  const [topKeywords, setTopKeywords] = useState([]);
  const [topTags, setTopTags] = useState([]);
  const [userChartData, setUserChartData] = useState([]);


  const _getSubscriptionPercentage = async () => {
    dispatch(showLoader(true));
    let body = { planType: 1 };
    try {
      const result = await dispatch(subscriptionPercentage(body))
      const error = ErrorHandler(result)
      if (error) {
        setSubscriptionData(result?.payload?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(showLoader(false));
    }
  }

  const _getDataByBusinessType = async () => {
    dispatch(showLoader(true));
    let body = { planType: 1 };
    try {
      const result = await dispatch(dataByBusinessType(body))
      const error = ErrorHandler(result)
      if (error) {
        setDataByBusiness(result?.payload?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(showLoader(false));
    }
  }

  const _getTopUseKeywords = async () => {
    dispatch(showLoader(true));
    let body = { planType: 1 };
    try {
      const result = await dispatch(topUseKeywords(body))
      const error = ErrorHandler(result)
      if (error) {
        setTopKeywords(result?.payload?.data?.keywords)
        setTopTags(result?.payload?.data?.tags);
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(showLoader(false));
    }
  }

  const _getBusinessData = async () => {
    dispatch(showLoader(true));
    let body = { planType: 1 };
    try {
      const result = await dispatch(businessData(body))
      const error = ErrorHandler(result)
      if (error) {
        setUserChartData(result?.payload?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(showLoader(false));
    }
  }

  useEffect(() => {
    if (loginData?.userType === 0) {
      _getSubscriptionPercentage();
      _getBusinessData();
      _getTopUseKeywords();
      _getDataByBusinessType();
    }
  }, [])


  return (

    <>
      {
        (loginData?.userType === 1 || loginData?.userType === 2) &&
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CurrentRatingChip data={CurrentRatingChipdata} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <InitialRatingChip data={InitialRatingChipdata} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TotalReviewsChip data={TotalReviewsChipdata} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ComplaintsChip data={ComplaintsChipdata} />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: "20px !important", paddingBottom: "4px !important" }}>
            <SitePerformanceDatePicker />
          </Grid>

          <Grid item xs={12} >
            <LineChartComponent data={LineChartData} chipData={chipData} />
          </Grid>

          <Grid item container xs={12} spacing={2} sx={{ paddingTop: "20px !important", paddingBottom: "4px !important" }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <QRCodeScansChip data={QRCodeScansChipdata} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FiveStarReviewChip data={FiveStarReviewChipdata} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <PositiveResponsesChip data={PositiveResponsesChipdata} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MostDislikedCategoryChip data={MostDislikedCategoryChipdata} />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} lg={6}>
              <RatingReviewPieChart data={RatingReviewdata} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RepliesPieChart data={RepliesPieData} />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <ReviewBarChart data={ReviewBarChartdata} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ReviewByAgeBarChart data={ReviewByAgeBarChartData} />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
              <AreaChartComponent data={AreaLineChartdata} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MonthlyReportsLineChart data={MonthlyReportsLineChartdata} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <AreaChartComponent data={AreaLineChart2data} />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} lg={8}>
              <ReviewList data={ReviewsData} />
            </Grid>
            <Grid container item xs={12} lg={4} spacing={2} direction={{ xs: "row", lg: "column" }}>
              <Grid item xs={12} sm={6}>
                <TabTable data={TabTable1Data} isLike={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TabTable data={TabTable1Data} isLike={false} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} >
            <UserJourneyChart data={UserJourneyChartdata} options={options} />
          </Grid>
        </Grid>
      }
      {
        loginData?.userType === 0 &&

        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomBarChart data={CustomBardata} options={options} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DonutChart subscriptionData={subscriptionData?.subscriptionCounts} options={options} />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopKeywords keywords={topKeywords} options={options} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TopKeywords keywords={topTags} options={options} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <AgeBarChart data={AgeBardata} options={options} />
            </Grid>
          </Grid>


          <Grid container item spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <LocationBarChart data={LocationBardata} options={options} countries={countries} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <UserDataPieChart data={userChartData} options={options} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BusinessTypeBarChart data={dataByBusiness} options={options} />
          </Grid>
        </Grid>
      }
    </>

  )
}

export default Dashboard
