import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiDelete, ApiGet, ApiPost, ApiPostNoAuth, ApiPut } from "../../ApiSetup/Api";
import { api } from "../../ApiSetup/Axios";

// USER ACTIONS

//GetBusinessDetails
export const getBusinessDetails = createAsyncThunk(
  "getBusinessDetails",
  async (body) => {
    return ApiPostNoAuth(api?.getBusinessForUser, body)
      .then((response) => response?.data)
      .catch((error) => error);
  }
);

//Generate Review
export const generateReview = createAsyncThunk(
  "generateReview",
  async (body) => {
    return ApiPostNoAuth(api?.generateReview, body)
      .then((response) => response?.data)
      .catch((error) => error);
  }
);

//create Complain
export const createComplain = createAsyncThunk(
  "createComplain",
  async (body) => {
    return ApiPostNoAuth(api?.createComplain, body)
      .then((response) => response?.data)
      .catch((error) => error);
  }
);

// ADMIN ACTIONS

// ADMIN LOGIN ACTIONS
export const login = createAsyncThunk("login", async (body) => {
  return ApiPostNoAuth(api?.login, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Admin forget & change password
export const forgetPassword = createAsyncThunk("forgetPassword", async (body) => {
  return ApiPost(api?.forgetPassword, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const otpVerification = createAsyncThunk("otpVerification", async (body) => {
  return ApiPost(api?.otp_verification, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const changePassword = createAsyncThunk("changePassword", async (body) => {
  return ApiPost(api?.change_password, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// upload image
export const uploadimg = createAsyncThunk("uploadimg", async (body) => {
  return ApiPost(api?.uploadImage, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// ----------------------->>>>>>>>>>>>  Complains
export const getComplain = createAsyncThunk("getComplain", async (body) => {
  return ApiPost(api?.getComplain, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const postReplyToComplain = createAsyncThunk("postReplyToComplain", async (body) => {
  return ApiPost(api?.replyToComplain, body)
    .then((response) => response?.data)
    .catch((error) => error);
});



// ----------------------->>>>>>>>>>>>  Business Complains
export const getBusinessComplains = createAsyncThunk("getBusinessComplains", async (body) => {
  return ApiPost(api?.getBusinessComplain, body)
    .then((response) => response?.data)
    .catch((error) => error);
}
);
export const addBusinessComplains = createAsyncThunk("addBusinessComplains", async (body) => {
  return ApiPost(api?.addBusinessComplains, body)
    .then((response) => response?.data)
    .catch((error) => error);
}
);
export const updateComplainStatus = createAsyncThunk("updateComplainStatus", async (body) => {
  return ApiPut(api?.addBusinessComplains, body)
    .then((response) => response?.data)
    .catch((error) => error);
}
);

// ----------------------->>>>>>>>>>>> My Business
// => Get country
export const getCountries = createAsyncThunk("getCountries", async (body) => {
  return ApiGet(api?.getCountries, body)
    .then((response) => response?.data)
    .catch((error) => error);
});
// => Get State
export const getStates = createAsyncThunk("getStates", async (body) => {
  return ApiPost(api?.getStates, body)
    .then((response) => response?.data)
    .catch((error) => error);
});
// => Get City
export const getCities = createAsyncThunk("getCities", async (body) => {
  return ApiPost(api?.getCities, body)
    .then((response) => response?.data)
    .catch((error) => error);
});
// Add Genral
export const AddMybusinessGenral = createAsyncThunk("AddMybusinessGenral", async (body) => {
  return ApiPost(api?.getBusiness, body)
    .then((response) => response?.data)
    .catch((error) => error);
});
export const editMybusinessGenral = createAsyncThunk("AddMybusinessGenral", async (body) => {
  return ApiPut(api?.editBusiness, body)
    .then((response) => response?.data)
    .catch((error) => error);
});
// get Eidt Genral
export const getSingleData = createAsyncThunk("getSingleData", async (body) => {
  return ApiGet(`${api?.getsingleData}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});
export const deleteSingleBusiness = createAsyncThunk("deleteSingleBusiness", async (body) => {
  return ApiDelete(`${api?.deleteBusiness}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});

//Get MyAccount
export const getMyAccount = createAsyncThunk("getMyAccount", async (body) => {
  return ApiPost(api?.getMyBusiness, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const getSingleBusiness = createAsyncThunk("getSingleBusiness", async (body) => {
  return ApiGet(`${api?.getBusiness}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const getMyBusinessWithAdmin = createAsyncThunk("getMyBusinessWithAdmin", async (body) => {
  return ApiPost(api?.getMyBusinessUsingAdmin, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// =========================>>>>>>>>>>>>  My Business
// ------------------------>>>> Generate
// Keyword generate
export const keywordGenerate = createAsyncThunk("keywordGenerate", async (body) => {
  return ApiPost(api?.keywordGenerate, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Keyword get
export const getBusinessKeywords = createAsyncThunk("getBusinessKeywords", async (body) => {
  return ApiPost(api?.getBusinessKeywords, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Keyword add
export const addBusinessKeywords = createAsyncThunk("addBusinessKeywords", async (body) => {
  return ApiPost(api?.addBusinessKeywords, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Keyword edit
export const editBusinessKeywords = createAsyncThunk("editBusinessKeywords", async (body) => {
  return ApiPut(api?.editBusinessKeywords, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Keywords delete  
export const deleteBusinessKeywords = createAsyncThunk("deleteBusinessKeywords", async (body) => {
  return ApiDelete(api?.deleteBusinessKeywords, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Keyword Single delete  
export const deleteBusinessKeyword = createAsyncThunk("deleteBusinessKeyword", async (body) => {
  return ApiDelete(api?.deleteBusinessKeyword, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// ------------------------>>>> Tag
// Tag generate
export const tagGenerate = createAsyncThunk("tagGenerate", async (body) => {
  return ApiPost(api?.tagGenerate, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Tag get generate
export const getGenerate = createAsyncThunk("getGenerate", async (body) => {
  return ApiPost(api?.getGenerate, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Tag add generate
export const addGenerate = createAsyncThunk("addGenerate", async (body) => {
  return ApiPost(api?.addGenerate, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Tag delete generate
export const deleteGenerate = createAsyncThunk("deleteGenerate", async (body) => {
  return ApiDelete(api?.deleteGenerate, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// ----------------------->>>>>>>>>>>> Reviews
// => Get Reviews
export const getGoogleReview = createAsyncThunk("getGoogleReview", async (body) => {
  return ApiPost(api?.getGoogleReview, body)
    .then((response) => response?.data)
    .catch((error) => error);
});


// ----------------------->>>>>>>>>>>> All business 

export const createAdmin = createAsyncThunk("createAdmin", async (body) => {
  return ApiPost(api?.adminCreate, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const addNotes = createAsyncThunk("addNotes", async (body) => {
  return ApiPost(api?.addPrivateNotes, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// ----------------------->>>>>>>>>>>> Subscfiption

export const getSubscription = createAsyncThunk("getSubscription", async (body) => {
  return ApiGet(api?.getSubscriptionPlan, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const getSingleSubscriptionDetail = createAsyncThunk("getSingleSubscriptionDetail", async (body) => {
  return ApiGet(`${api?.subscriptionPlan}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const buySubscriptionPlan = createAsyncThunk("buySubscriptionPlan", async (body) => {
  return ApiPost(api?.buyPlan, body)
    .then((response) => response?.data)

    .catch((error) => error);
});

export const secondTimeBuySubscriptionPlan = createAsyncThunk("secondTimeBuySubscriptionPlan", async (body) => {
  return ApiPost(api?.secondTimeBuySubscriptionPlan, body)
    .then((response) => response?.data)

    .catch((error) => error);
});

export const businessPaymentHistory = createAsyncThunk("businessPaymentHistory", async (body) => {
  return ApiPost(api?.subscriptionHistroy, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Get admin List
export const getAdminList = createAsyncThunk("getAdminList", async (body) => {
  return ApiPost(api?.adminGet, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Get Business List
export const businessListGet = createAsyncThunk("businessListGet", async (body) => {
  return ApiGet(api?.businessListGet, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Update Admin Detail  
export const updateAdminDetail = createAsyncThunk("updateAdminDetail", async (body) => {
  return ApiPost(api?.updateAdminDetail, body)
    .then((response) => response?.data)
    .catch((error) => error);
});


// get Eidt Genral
export const _getgenerateFeedbackAiSuggestion = createAsyncThunk("_getgenerateFeedbackAiSuggestion", async (body) => {
  return ApiGet(`${api?.generateFeedbackAiSuggestion}/${body?.feedbackId}`)
    .then((response) => response?.data)
    .catch((error) => error);
});

// get Eidt Genral
export const feedbackAutoResponse = createAsyncThunk("feedbackAutoResponse", async (body) => {
  return ApiGet(`${api?.feedbackAutoResponse}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});


// get bussiness Plan Details
export const bussinessPlanDetails = createAsyncThunk("bussinessPlanDetails", async (body) => {
  return ApiGet(`${api?.bussinessPlanDetails}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Dahsboard Chart data 

// Super admin
export const subscriptionPercentage = createAsyncThunk("subscriptionPercentage", async (body) => {
  return ApiPost(api?.subscriptionPercentage, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const dataByBusinessType = createAsyncThunk("dataByBusinessType", async (body) => {
  return ApiPost(api?.dataByBusinessType, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const topUseKeywords = createAsyncThunk("topUseKeywords", async (body) => {
  return ApiPost(api?.topUseKeywords, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

export const businessData = createAsyncThunk("businessData", async (body) => {
  return ApiPost(api?.businessData, body)
    .then((response) => response?.data)
    .catch((error) => error);
});

// Delete Admin
export const deleteAdmin = createAsyncThunk("deleteAdmin", async (body) => {
  return ApiDelete(`${api?.adminDelete}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});


// activate Trial
export const activateTrial = createAsyncThunk("activateTrial", async (body) => {
  return ApiGet(`${api?.activateTrial}/${body}`)
    .then((response) => response?.data)
    .catch((error) => error);
});

// activate Trial
export const addComplainsNotes = createAsyncThunk("addComplainsNotes", async (body) => {
  return ApiPost(api?.addComplainsNotes, body)
    .then((response) => response?.data)
    .catch((error) => error);
});