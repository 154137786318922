import { configureStore } from '@reduxjs/toolkit'
import loaderSlice from './Slices/loaderSlice'
import keywordsReducer from './Slices/keyWordSlice'
import journeyReducer from './Slices/journeySlice'
import businessReducer from './Slices/businessId'
import adminReducer from './Slices/adminId'

export const store = configureStore({
  reducer: {
    loader: loaderSlice,
    keywords: keywordsReducer,
    journey: journeyReducer,
    business: businessReducer,
    admin: adminReducer,
  },
})