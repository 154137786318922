import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import CommonTextField from "../../Components/Common/Fields/TextField";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sessionStoreUtil from "../../ApiSetup/Localstorage";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/Actions/action";
import ErrorHandler from "../../Utils/handler";
import { showLoader } from "../../Redux/Slices/loaderSlice";

const Login = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value.trimStart() });
  };

  const validation = () => {
    const errors = {};
    let valid = true;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!data.email) {
      errors.email = "Please enter email id.";
      valid = false;
    } else if (!emailRegex.test(data.email)) {
      errors.emailValid = "Please enter a valid email id";
      valid = false;
    }

    if (!data.password) {
      errors.password = "Please enter password";
      valid = false;
    }

    setError(errors);
    return valid;
  };

  const onSubmit = async () => {
    if (validation()) {
      dispatch(showLoader(true));
      let body = {
        email: data.email,
        password: data.password,
      };

      try {
        const result = await dispatch(login(body));
        const error = ErrorHandler(result);
        if (error) {
          toast.success(result?.payload?.message);
          sessionStoreUtil.setLSItem(
            "auth_token",
            result?.payload?.data?.token
          );
          sessionStoreUtil.setLSItem(
            "userData",
            JSON.stringify({
              name: result?.payload?.data?.name,
              userType: result?.payload?.data?.userType,
              _id: result?.payload?.data?._id,
            })
          );
          setData({});
          navigate(result?.payload?.data?.userType === 0 ? "/" : "/myAccount");
        }
      } catch (error) {
        // toast.error(error?.message || "An unknown error occurred");
        toast.error(error?.message || "An unknown error occurred");
      } finally {
        dispatch(showLoader(false));
        console.log("finally👍");
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        minWidth={"30vh"}
        sx={{
          background:
            "radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 100%)",
          backgroundImage: "url(/images/backgroundLinigImg.svg)", // Your grid background image here
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#FFFFFF",
        }}
      >
        <Grid
          container
          spacing={isMobile ? 1 : 2}
          maxWidth={isMobile ? "80%" : "620px"}
          maxHeight={isMobile ? "100%" : "500px"}
          padding={isMobile ? "25px 20px 25px 20px" : "35px 40px"}
          border="1px solid #3E3E3E"
          borderRadius="20px"
          sx={{
            backgroundColor: "#121212",
            boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.25)",
          }}
        >
          {/* Logo */}
          <Grid
            item
            xs={12}
            marginBottom={isMobile ? "10px" : "10px"}
            padding="0px !important"
          >
            <Box display="flex" alignItems="center" gap="10px">
              <img
                src="/images/logo.svg"
                alt="logo"
                height="28px"
                width="28px"
              />
              <Typography fontSize="14px" fontWeight={600} color="#FFFFFF">
                The AI Reviews
              </Typography>
            </Box>
          </Grid>

          {/* Title */}
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={isMobile ? "25px" : "40px"}
          >
            <Typography
              fontSize={isMobile ? "28px" : "30px"}
              fontWeight={700}
              lineHeight={isMobile ? "30px" : "38.19px"}
              letterSpacing="-1px"
              sx={{
                background:
                  "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Log in
            </Typography>
          </Grid>

          {/* Form Fields */}
          <Grid
            item
            container
            xs={12}
            padding={isMobile ? "0px 10px" : "0px 30px !important"}
          >
            <Grid item xs={12} mb={{ xs: "15px", md: "20px" }}>
              <CommonTextField
                text="Email"
                placeholder="Email"
                type={"text"}
                name={"email"}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <Typography variant="caption" color={"error"}>
                {data?.email ? "" : error?.email}
              </Typography>
              <Typography variant="caption" color={"error"}>
                {data?.email ? error?.emailValid : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} mb="8px">
              <CommonTextField
                text="Password"
                placeholder="Password"
                type="password"
                name={"password"}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
                showPasswordToggle
              />
              <Typography variant="caption" color={"error"}>
                {data?.password ? "" : error?.password}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              // display="flex"
              // alignItems="center"
              // justifyContent="end"
              mb="25px"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize="12px"
                  lineHeight="20px"
                  color="#F4A606"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(showLoader(true));
                    setTimeout(() => {
                      navigate("/signup");
                      dispatch(showLoader(false));
                    }, 100);
                  }}
                >
                  Sign up for Admin?
                </Typography>
                <Typography
                  fontSize="12px"
                  lineHeight="20px"
                  color="#F4A606"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(showLoader(true));
                    setTimeout(() => {
                      navigate("/forgot-password");
                      dispatch(showLoader(false));
                    }, 100);
                  }}
                >

                  Forgot Password?
                </Typography>
              </Box>
            </Grid>


            {/* Login Button */}
            <Grid item xs={12}>
              <CommonButton
                onClick={onSubmit}
                text="Login"
                className="primary"
                fullWidth={isMobile}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default Login;
