import React from 'react';
import { Box, Typography, Avatar, Rating, Card, CardContent, Stack, useMediaQuery } from '@mui/material';
import CommonButtonDashboard from '../ButtonDashboard/CommonButtonDashboard';
import { useNavigate } from 'react-router-dom';

const ReviewList = ({ data }) => {

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 600px)')

    const handleViewAllClick = () => {
        navigate('/reviews');
    }

    return (
        <Box sx={{ bgcolor: '#141414', p: 3, borderRadius: 5 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: '#CCC9C9', fontSize: '16px', fontWeight: '700', letterSpacing: '-2%' }}>
                    Recent Review
                </Typography>
                <CommonButtonDashboard
                    label="View all"
                    onClick={handleViewAllClick}
                />
            </Stack>
            <Typography sx={{ color: '#696969', fontSize: '16px', fontWeight: '400', }} mb={4}>
                Latest Customer Feedback and Insights
            </Typography>
            <Box sx={{ maxHeight: 483, overflow: 'auto' }}>
                {data?.map((review) => (
                    <Card key={review?.id} sx={{ color: 'white', mb: 2, borderRadius: 2 }}>
                        <CardContent sx={{ backgroundColor: '#141414', borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }}>
                            <Stack direction="row" spacing={2} alignItems="center" mb={1}>
                                <Avatar width='50px' height='50px' src={review?.avatar} alt={review?.username} />
                                <Box>
                                    <Rating value={review?.rating} readOnly size="small" />
                                    <Stack direction={isMobile ? 'column' : 'row'} spacing={1} alignItems="center">
                                        <Typography sx={{ color: '#B9B2B2', fontSize: '16px', fontWeight: '500' }}>
                                            Recent Review
                                        </Typography>
                                        <Typography sx={{ color: '#696969', fontSize: '12px', fontWeight: '400' }}>
                                            {review?.postedTime}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Stack>
                            <Typography sx={{ color: '#696969', fontSize: '14px', fontWeight: '400' }}>{review?.review}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default ReviewList;