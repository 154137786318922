import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';
import CommonBackgroundContainer from './CommonBackgroundContainer';


const ContentContainer = styled.div`
  display: flex;
  flex-direction: column; /* Default to column for mobile */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  padding: 30px;

  @media (min-width: 768px) {
    flex-direction: row; /* Change to row for tablets and larger */
    align-items: center; /* Align items to the start */
  }
`;

const PieChartContainer = styled.div`
  width: 180px;
  height: 180px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;


const StatsContainer = styled.div`
  display: flex;
  align-items: center;  // Center vertically
  justify-content: center; 
  gap: 20px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    justify-content: flex-start; // Align to the start for larger screens
  }
`;


const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  // margin-Top: 40px;
  align-items: flex-start;
  justify-content: center; 

  // @media (max-width: 768px) {
  //   margin-Top: -20px;
  // }
`;

const StatValue = styled.span`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;

   @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const StatLabel = styled.span`
  font-size: 16px;
  color: #888;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const BreakdownDot = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: linear-gradient(90deg, white, #F4A606, #FD63C5);
  margin-right: 8px;
`;

const RepliesPieChart = ({ data }) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const textColor = index === 0 ? '#090805' : '#fff';

    return (
      <g>
        <text x={x} y={y} fill={textColor} textAnchor="middle" dominantBaseline="central" fontSize="16px" fontWeight="bold">
          {data[index]?.value.toLocaleString()}
        </text>
        <text x={x} y={y + 15} fill={textColor} textAnchor="middle" dominantBaseline="central" fontSize="12px">
          {data[index]?.name}
        </text>
      </g>
    );
  };

  return (
    <CommonBackgroundContainer
      title="Replies and breakdown"
      subtitle="Analyzing Customer Interactions and Feedback Details"
      titleFontSize='20px'
      titleFontWeight='700'
      subtitleFontSize='14px'
      subTitleFontWeight='400'
    >
      <ContentContainer>
        <PieChartContainer>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <defs>
                <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.3761 129.988) rotate(-10.3026) scale(172.196 326.584)">
                  <stop stopColor="white" />
                  <stop offset="0.79" stopColor="#F4A606" />
                  <stop offset="1" stopColor="#FD63C5" />
                </radialGradient>
                <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(201.124 112.12) rotate(169.697) scale(172.196 326.584)">
                  <stop stopColor="white" />
                  <stop offset="0.79" stopColor="#F4A606" />
                  <stop offset="1" stopColor="#FD63C5" />
                </radialGradient>
              </defs>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                dataKey="value"
                startAngle={-40}
                endAngle={320}
                stroke="none"
              >
                <Cell key={`cell-0`} fill="url(#paint0_radial)" />
                <Cell key={`cell-1`} fill="url(#paint1_radial)" opacity={0.2} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </PieChartContainer>
        <StatsContainer>
          <StatItem>
            <StatValue>{data[0]?.value?.toLocaleString()}</StatValue>
            <StatLabel><BreakdownDot />Replies</StatLabel>
          </StatItem>
          <StatItem>
            <StatValue>{data[1]?.value?.toLocaleString()} </StatValue>
            <StatLabel> <BreakdownDot />Breakdown</StatLabel>
          </StatItem>
        </StatsContainer>
      </ContentContainer>
    </CommonBackgroundContainer>
  );
};

export default RepliesPieChart;
