import React, { useMemo, useState } from 'react';
import { Box, Typography, CircularProgress, styled, useMediaQuery } from '@mui/material';
import CommonSelect from '../../CommonSelect/CommonSelect';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    position: 'relative',
    '& .MuiCircularProgress-circle': {
        strokeLinecap: 'round',
    },
}));


const getGradientId = (name) => {
    // Return the corresponding gradient ID based on the label
    return `${name?.toLowerCase()}Gradient`;
};

const DonutChart = ({ subscriptionData, options }) => {
    const [selectedValue, setSelectedValue] = useState('Monthly');

    // Calculate percentages from the count data
    const processedData = useMemo(() => {
        if (!subscriptionData?.length) return [];

        // Calculate total count
        const totalCount = subscriptionData.reduce((sum, item) => sum + item.count, 0);

        // Calculate percentage for each item
        return subscriptionData.map(item => ({
            ...item,
            percentage: totalCount ? Math.round((item.count / totalCount) * 100) : 0
        }));
    }, [subscriptionData]);

    const handleChange = (event) => {
        setSelectedValue(event?.target?.value);
    };

    // Use MUI's useMediaQuery hook to check if the screen width is below 600px
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box sx={{ backgroundColor: 'rgba(217, 217, 217, 0.06)', padding: '20px', borderRadius: '20px', height: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography sx={{ color: '#CCC9C9', fontSize: '16px', fontWeight: '700', }}>Subscription</Typography>
                </Box>
                <Box display="flex" alignItems="center" marginLeft="auto">
                    <CommonSelect value={selectedValue} onChange={handleChange} options={options} />
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                justifyContent={isMobile ? "center" : "space-around"}
                alignItems={isMobile ? "center" : "flex-start"}
                marginTop="60px"
            >
                {processedData?.map((data, index) => (
                    <Box key={index} display="flex" flexDirection="column" alignItems="center" position="relative" mb={isMobile ? 4 : 0}>
                        <CircularProgress
                            variant="determinate"
                            value={100}
                            size={130}
                            thickness={2.5}
                            sx={{
                                color: '#F1FFF333',
                                position: 'absolute',
                            }}
                        />
                        <StyledCircularProgress
                            variant="determinate"
                            value={data?.count}
                            size={130}
                            thickness={2.5}
                            sx={{
                                color: 'transparent',
                                '& .MuiCircularProgress-circle': {
                                    stroke: `url(#${getGradientId(data?.name)})`,
                                },
                                // marginBottom: '10px',
                            }}
                        />
                        <svg width="0" height="0">
                            <defs>
                                <radialGradient id="monthlyGradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(11.6481 106.564) rotate(-57.1381) scale(97.4299 160.153)">
                                    <stop offset="0" stopColor="white" />
                                    <stop offset="0.79" stopColor="#F4A606" />
                                    <stop offset="1" stopColor="#FD63C5" />
                                </radialGradient>
                                <radialGradient id="quarterlyGradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(11.6481 106.564) rotate(-57.1381) scale(97.4299 160.153)">
                                    <stop offset="0" stopColor="white" />
                                    <stop offset="0.79" stopColor="#F4A606" />
                                    <stop offset="1" stopColor="#FD63C5" />
                                </radialGradient>
                                <radialGradient id="yearlyGradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(11.6481 106.564) rotate(-57.1381) scale(97.4299 160.153)">
                                    <stop offset="0" stopColor="white" />
                                    <stop offset="0.79" stopColor="#F4A606" />
                                    <stop offset="1" stopColor="#FD63C5" />
                                </radialGradient>
                            </defs>
                        </svg>
                        <Box display="flex" flexDirection="column" alignItems="center" position="absolute" top="25%" left="33%">
                            <Typography variant="h6" sx={{ color: '#FFFFFF' }}>{data?.percentage}%</Typography>
                            <Typography sx={{ color: '#FFFFFF33', fontSize: "12px" }}>User</Typography>
                        </Box>
                        <Typography variant="body2" sx={{ color: '#FFFFFF', marginTop: "20px" }}>{data?.name}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default DonutChart;
