export const setAdmin = (admin) => ({
    type: "SET_ADMIN",
    payload: admin,
});

// reducer.js
const initialState = {
    admin: null,
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ADMIN":
            return { ...state, admin: action.payload };
        default:
            return state;
    }
};

export default adminReducer;