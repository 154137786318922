import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import CommonTextField from "../../Components/Common/Fields/TextField";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changePassword } from "../../Redux/Actions/action";
import ErrorHandler from "../../Utils/handler";
import { showLoader } from "../../Redux/Slices/loaderSlice";
import WestIcon from '@mui/icons-material/West';


const SetnewPassword = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { otp } = location.state || {}

    const [data, setData] = useState({
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState({});

    // useEffect(() => {
    //     if (!otp) {
    //         navigate("/login");
    //     }
    // }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value.trimStart() });
    };

    const validation = () => {
        const errors = {};
        let valid = true;
        if (!data.password) {
            errors.email = "Please enter password.";
            valid = false;
        }
        if (!data.confirmPassword) {
            errors.confirmPassword = "Please enter confirm password";
            valid = false;
        }
        if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
            valid = false;
        }
        setError(errors);
        return valid;
    };

    const onSubmit = async () => {
        if (validation()) {
            dispatch(showLoader(true));
            let body = {
                otp: +otp,
                password: data.password,
            };
            try {
                const result = await dispatch(changePassword(body));
                const error = ErrorHandler(result);
                if (error) {
                    toast.success(result?.payload?.message);
                    navigate("/password-reset");
                }
            } catch (error) {
                toast.error(error?.message || "An unknown error occurred");
            } finally {
                dispatch(showLoader(false));
            }
        }
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                minWidth={"30vh"}
                sx={{
                    background:
                        "radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 100%)",
                    backgroundImage: "url(/images/backgroundLinigImg.svg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#FFFFFF",
                }}
            >
                <Grid
                    container
                    spacing={isMobile ? 1 : 2}
                    maxWidth={isMobile ? "80%" : "620px"}
                    maxHeight={isMobile ? "100%" : "500px"}
                    padding={isMobile ? "25px 20px 25px 20px" : "35px 40px"}
                    border="1px solid #3E3E3E"
                    borderRadius="20px"
                    sx={{
                        backgroundColor: "#121212",
                        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.25)",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        padding="0px !important"
                    >
                        <Box display="flex" alignItems="center" gap="10px">
                            <img
                                src="/images/logo.svg"
                                alt="logo"
                                height="28px"
                                width="28px"
                            />
                            <Typography fontSize="14px" fontWeight={600} color="#FFFFFF">
                                The AI Reviews
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={{ xs: 1, sm: 0 }}
                    >
                        <Typography
                            fontSize={isMobile ? "28px" : "30px"}
                            fontWeight={700}
                            lineHeight={isMobile ? "30px" : "38.19px"}
                            letterSpacing="-1px"
                            textAlign={"center"}
                            sx={{
                                background:
                                    "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Set a New Password
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ paddingTop: "10px !important" }}
                    >
                        <Typography
                            fontSize={"14px"}
                            fontWeight={400}
                            lineHeight={"20px"}
                            color={"#696969"}
                            display={"flex"}
                            alignItems={"center"}
                            textAlign={"center"}
                            maxWidth={"250px"}
                            gap={1}
                        >
                            Your new password must be different to previous used password.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        padding={isMobile ? "0px 10px" : "0px 30px !important"}
                        mt={{ xs: 2, sm: 0 }}
                    >
                        <Grid item xs={12} mb={{ xs: "15px", sm: "15px" }}>
                            <CommonTextField
                                text="Password"
                                placeholder="Enter password"
                                type={"password"}
                                name={"password"}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onSubmit();
                                    }
                                }}
                                showPasswordToggle
                            />
                            {data?.email
                                ?
                                <Typography variant="caption" color={"error"}>
                                    {error?.email}
                                </Typography>
                                :
                                null
                            }
                        </Grid>
                        <Grid item xs={12} mb="4px">
                            <CommonTextField
                                text="Confirm password"
                                placeholder="Enter Confirm password"
                                type="password"
                                name={"confirmPassword"}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        onSubmit();
                                    }
                                }}
                                showPasswordToggle
                            />
                            {data?.confirmPassword
                                ?
                                <Typography variant="caption" color={"error"}>
                                    {error?.confirmPassword}
                                </Typography>
                                :
                                null
                            }
                        </Grid>
                        <Grid item xs={12} mt={{ xs: 3, sm: 3 }}>
                            <CommonButton
                                onClick={onSubmit}
                                text="Reset Password"
                                className="primary"
                                fullWidth={isMobile}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt={isMobile ? "30px" : "30px"}
                        >
                            {/* <Typography
                             sx={{ cursor: "pointer" }}
                                fontSize={"13px"}
                                fontWeight={400}
                                lineHeight={"20px"}
                                color={"#696969"}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                                onClick={() => {
                                    dispatch(showLoader(true));
                                    setTimeout(() => {
                                        navigate("/login");
                                        dispatch(showLoader(false));
                                    }, 100);
                                }}
                            >
                                <WestIcon sx={{ fontSize: "20px" }} />
                                {`Back to login`}
                            </Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box >
        </>
    )
}

export default SetnewPassword
