import React from 'react';
import { Select, MenuItem, styled } from '@mui/material';

// Custom SVG icon component
const CustomIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="5" viewBox="0 0 8 5" fill="none" {...props}>
        <path d="M0.435443 1.45117C0.189316 1.21424 0.165883 0.828201 0.381546 0.563244C0.626964 0.26173 1.07911 0.23815 1.35456 0.512498L3.12569 2.27655C3.51925 2.66853 4.15685 2.66466 4.54562 2.26792L6.279 0.499018C6.54351 0.229093 6.98128 0.23957 7.23257 0.52184C7.46325 0.780967 7.45087 1.17529 7.20439 1.41944L4.54575 4.05284C4.15996 4.43496 3.53972 4.43939 3.14851 4.06281L0.435443 1.45117Z" fill="url(#paint0_radial_1601_10088)" />
        <defs>
            <radialGradient id="paint0_radial_1601_10088" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1.63994 0.504255) rotate(36.438) scale(6.09852 10.9348)">
                <stop stopColor="white" />
                <stop offset="0.79" stopColor="#F4A606" />
                <stop offset="1" stopColor="#FD63C5" />
            </radialGradient>
        </defs>
    </svg>
);

// Styled Select component
const CustomSelect = styled(Select)({
    backgroundColor: 'rgba(255, 255, 255, 0.10)',
    borderRadius: '30px',
    padding: '4px 8px',
    '& .MuiSelect-icon': {
        top: 'calc(50% - 5px)',
        right: '10px',
        color: 'transparent',
        width: '15px',
        height: '10px',
        position: 'absolute',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-select': {
        padding: '4px 20px 4px 8px',
        fontSize: '15px',
        background: 'var(--Style, radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%))',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
});

const GradientMenuItem = styled(MenuItem)({
    '& .MuiMenuItem-root': {
        background: 'var(--Style, radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%))',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    background: 'var(--Style, radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%))',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

const StyledSelect = ({ value, onChange, options = [] }) => {
    return (
        <CustomSelect
            value={value}
            onChange={onChange}
            variant="outlined"
            IconComponent={CustomIcon}
            sx={{
                marginRight: '10px',
            }}
        >
            {options.map((option) => (
                <GradientMenuItem key={option.value} value={option.value}>
                    {option.label}
                </GradientMenuItem>
            ))}
        </CustomSelect>
    );
};

export default StyledSelect;
