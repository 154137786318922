import React from 'react'
import { useSelector } from 'react-redux'



function Loader() {
    const loader = useSelector((state) => state?.loader?.value)

    return (
        <>
            {loader ?
                (
                    <div class="loading">
                        <div class="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <h2>Loading</h2>
                    </div>

                )
                :
                null}
        </>
    )
}

export default Loader