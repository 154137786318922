// src/redux/slices/keywordsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedKeywords: [],
};

const keywordsSlice = createSlice({
    name: 'keywords',
    initialState,
    reducers: {
        setSelectedKeywords: (state, action) => {
            state.selectedKeywords = action?.payload;
        },
        clearSelectedKeywords: (state) => {
            state.selectedKeywords = [];
        }
    }
});

export const { setSelectedKeywords, clearSelectedKeywords } = keywordsSlice.actions;
export default keywordsSlice.reducer;
