import React, { useEffect, useState } from 'react'
import { showLoader } from '../../Redux/Slices/loaderSlice';
import { useDispatch } from 'react-redux';
import { createAdmin, deleteAdmin, getAdminList } from '../../Redux/Actions/action';
import ErrorHandler from '../../Utils/handler';
import { Box, Grid, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CustomPagination from '../../Components/Common/CustomPagination';
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { makeStyles } from 'tss-react/mui';
import { BucketURL } from '../../ApiSetup/Api';
import CommonSearch from '../../Components/Common/CommonSearch';
import CommonButton from '../../Components/Common/Button/CommonButton';
import CommonModal from '../../Components/Common/CommonModel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import { Regex } from '../../Utils/regex';
import { toast } from 'react-toastify';
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonConformationModal from '../../Components/Common/CommonConformationModal';
import { useNavigate } from 'react-router-dom';

// Custom Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 400,
        fontSize: 16,
        color: "rgba(255, 255, 255, 1)",
        fontFamily: "Wanted Sans",
        whiteSpace: "nowrap",
        padding: "28px 20px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Wanted Sans",
        fontWeight: 400,
        padding: "18px 20px",
        backgroundColor: "#0e0e0e",
        color: "rgba(163, 158, 158, 1)",
        // borderBottom: "none",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#0e0e0e",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => ({
    avatar: {
        height: "60px",
        width: "60px",
        objectFit: "cover",
        borderRadius: "50%",
    },
}));

const Admin = () => {
    // ----- Hook -------
    const dispatch = useDispatch()
    const { classes } = useStyles();
    const navigate = useNavigate();

    // --- state ------
    const [adminData, setAdminData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0)
    const [serch, setSerach] = useState("")
    const [timer, setTimer] = useState(0);
    const [open, setOpen] = useState(false);
    const [businesdata, setBusinesdata] = useState({
        firstName: "",
        LastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState({});
    const [conformationModelOpenKeyword, setConformationModalOpenKeyword] = useState(false)
    const [deleteAdminId, setDeleteAdminId] = useState(null);

    const handleConformationModelForKeyword = () => {
        setConformationModalOpenKeyword(false);
        setDeleteAdminId(null)
    }

    const handleSearch = (term) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setPage(1)
                setSerach(term);
            }, 500)
        );
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(1);
    };

    const onchangeBusinesdata = (e) => {
        const { name, value } = e.target
        setBusinesdata({ ...businesdata, [name]: value });
    }

    const handleclose = () => {
        setOpen(false);
        setError({});
        setBusinesdata({})
    }

    const validation = () => {
        const error = {};
        let valid = true;
        if (!businesdata?.firstName) {
            error.firstName = "please enter your business name";
            valid = false;
        }
        // if (!businesdata?.mobileNo) {
        //     error.mobileNo = "please enter mobile number";
        //     valid = false;
        // } else if (!Regex?.mobileNumberRegex?.test(businesdata?.mobileNo)) {
        //     error.mobileValid = "Please enter your a valid mobile number";
        //     valid = false;
        // }

        // Mobile number is optional, only Validate if it typed in the Mobile TextField
        if (businesdata?.mobileNo && !Regex?.mobileNumberRegex?.test(businesdata?.mobileNo)) {
            error.mobileValid = "Please enter your a valid mobile number";
            valid = false;
        }
        if (!businesdata?.email) {
            error.email = "Please enter your email id.";
            valid = false;
        } else if (!Regex?.emailRegex?.test(businesdata?.email)) {
            error.emailValid = "Please enter your a valid email id";
            valid = false;
        }
        if (!businesdata?.password) {
            error.password = "please enter your password";
            valid = false;
        } else if (!businesdata?.password?.match(Regex?.passwordRegex)) {
            valid = false;
            error["invalidPassword"] = "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character";
        }
        if (!businesdata?.confirmPassword) {
            error.confirmPassword = "Please Enter Confirm Password";
            valid = false;
        }
        if (businesdata?.password !== businesdata?.confirmPassword) {
            error.passwordMatch = "passwords do not match";
            valid = false;
        }
        setError(error);
        return valid;
    }

    // ------ API ---------
    const _getAdminData = async () => {
        dispatch(showLoader(true))
        let body = {
            page: page,
            limit: rowsPerPage,
        }
        if (serch) {
            body.search = serch
        }
        try {
            const result = await dispatch(getAdminList(body));
            const error = ErrorHandler(result);
            if (error) {
                setAdminData(result?.payload?.data?.response)
                setCount(result?.payload?.data?.count)
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(showLoader(false))
        }
    };

    const _createAdmin = async () => {
        if (validation()) {
            dispatch(showLoader(true))
            let body = {
                name: businesdata?.firstName,
                email: businesdata?.email,
                // mobileNo: businesdata?.mobileNo,
                password: businesdata?.password
            };

            // Only add mobileNo to body if it's entered in the Mobile TextField
            if (businesdata?.mobileNo) {
                body.mobileNo = businesdata?.mobileNo;
            }

            try {
                const result = await dispatch(createAdmin(body));
                const error = ErrorHandler(result);
                if (error) {
                    toast.success('Admin created successfully!');
                    _getAdminData()
                    handleclose()
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(showLoader(false))
            }
        }
    };

    // ===> delete Admin data
    const _deleteKeyAdmin = async (id) => {
        if (deleteAdminId) {
            dispatch(showLoader(true));
            try {
                const result = await dispatch(deleteAdmin(deleteAdminId));
                const error = ErrorHandler(result);
                if (error) {
                    _getAdminData()
                    toast?.success(result?.payload?.message)
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(showLoader(false));
            }
        }
    }

    useEffect(() => {
        _getAdminData()
    }, [page, rowsPerPage, serch])

    return (
        <>
            <Grid container>


                <Grid container item spacing={1} xs={12} my={2} display={"flex"} justifyContent={{ xs: "flex-start", sm: "flex-end" }} gap={2}>
                    <CommonSearch
                        placeholder="Enter your admin"
                        handleSearch={handleSearch}
                    />
                    <CommonButton
                        maxWidth={"140px"}
                        fontSize={"14px"}
                        text={"+ Create Admin"}
                        className={"primary"}
                        onClick={() => setOpen(true)}
                    />
                </Grid>

                <Grid item xs={12} sx={{ borderRadius: "26px", overflow: "hidden", backgroundColor: "#13131399", borderColor: "#FFFFFF1A" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead
                                sx={{
                                    background: "#131313",
                                    color: "white",
                                }}
                            >
                                <TableRow>
                                    <StyledTableCell align="center">ID</StyledTableCell>
                                    <StyledTableCell align="center">Logo</StyledTableCell>
                                    <StyledTableCell align="center">Name</StyledTableCell>
                                    <StyledTableCell align="center">Email</StyledTableCell>
                                    <StyledTableCell align="center">Total Business Count</StyledTableCell>
                                    <StyledTableCell align="center">Mobile Number</StyledTableCell>
                                    <StyledTableCell align="center">Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {adminData?.length > 0 ? (
                                <>
                                    <TableBody>
                                        {adminData?.map((item, i) => (
                                            <StyledTableRow
                                                key={item?.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell align='center'>
                                                    {i + 1 + (page - 1) * rowsPerPage}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <img
                                                        src={`${BucketURL}${item?.avtar || ''}`}
                                                        alt="logo"
                                                        className={classes.avatar}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = '/images/avatar.png';
                                                        }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {item?.name}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {item?.email}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {item?.businessCount}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {item?.mobileNo}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <IconButton
                                                        sx={{ padding: 0, color: "#f9cf77" }}
                                                        onClick={(e) => { setConformationModalOpenKeyword(true); setDeleteAdminId(item?._id); }}
                                                    >
                                                        <img src="/images/deleteicon.svg" alt="delete icon" style={{ width: 16, height: 16 }} />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                width="100%"
                                            >
                                                <img src="/images/noDataFound.svg" alt="no data" />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    {adminData?.length > 0 && (
                        <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                    )}
                </Grid>


                {open && (
                    <CommonModal
                        open={open}
                        onClose={handleclose}
                        title={"Create Admin"}
                        maxWidth={"md"}
                        content={
                            <Grid
                                container
                                xs={12}
                                spacing={2}
                                padding={{ xs: "12px 16px", sm: "20px 20px" }}
                                sx={{ backgroundColor: "#222222" }}
                            >
                                <Grid item xs={12} sm={6}>
                                    <CommonTextField
                                        color={"#FFFFFF"}
                                        text={"Business Name"}
                                        // fontSize={"12px"}
                                        fontWeight={500}
                                        type="text"
                                        name={"firstName"}
                                        placeholder="Enter your business name"
                                        value={businesdata?.firstName}
                                        onChange={onchangeBusinesdata}
                                    />
                                    <TextLabel
                                        marginTop={1}
                                        fontSize="12px"
                                        color="error"
                                        title={!businesdata?.firstName ? error.firstName : ""}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CommonTextField
                                        color={"#FFFFFF"}
                                        fontWeight={500}
                                        text={"Mobile Number"}
                                        type="number"
                                        name={"mobileNo"}
                                        placeholder="Enter your mobile number"
                                        value={businesdata?.mobileNo}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (/^(?!0{2,})\d*$/.test(value) && value.length <= 10) {
                                                setBusinesdata((prevValue) => ({
                                                    ...prevValue,
                                                    mobileNo: value,
                                                }));
                                            }
                                        }}
                                    />
                                    <TextLabel
                                        marginTop={1}
                                        fontSize="12px"
                                        color="error"
                                        title={businesdata?.mobileNo ? error.mobileValid : ""}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CommonTextField
                                        color={"#FFFFFF"}
                                        fontWeight={500}
                                        text={"Email"}
                                        type="email"
                                        name={"email"}
                                        placeholder="Enter your email"
                                        value={businesdata?.email}
                                        onChange={onchangeBusinesdata}
                                    />
                                    <TextLabel
                                        marginTop={1}
                                        fontSize="12px"
                                        color="error"
                                        title={!businesdata?.email ? error.email : ""}
                                    />
                                    <TextLabel
                                        marginTop={1}
                                        fontSize="12px"
                                        color="error"
                                        title={businesdata?.email ? error?.emailValid : ""}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CommonTextField
                                        color={"#FFFFFF"}
                                        fontWeight={500}
                                        text={"Password"}
                                        type="password"
                                        name={"password"}
                                        placeholder="Enter your password"
                                        value={businesdata?.password}
                                        onChange={onchangeBusinesdata}
                                        showPasswordToggle
                                    />
                                    <TextLabel
                                        marginTop={1}
                                        fontSize="12px"
                                        color="error"
                                        title={!businesdata?.password ? error.password : ""}
                                    />
                                    {(error?.password || error?.invalidPassword || error?.passwordMatch) && (
                                        <TextLabel
                                            marginTop={1}
                                            fontSize="12px"
                                            color="error"
                                            title={
                                                businesdata?.password && !businesdata?.password?.match(Regex?.passwordRegex)
                                                    ? "Password must be at least 8 characters, with uppercase, lowercase, number, and special character"
                                                    : ""
                                            }
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CommonTextField
                                        color={"#FFFFFF"}
                                        fontWeight={500}
                                        text={"Confirm Password"}
                                        type="password"
                                        name={"confirmPassword"}
                                        placeholder="Enter your confirm password"
                                        value={businesdata?.confirmPassword}
                                        onChange={onchangeBusinesdata}
                                        showPasswordToggle
                                    />
                                    <TextLabel
                                        marginTop={1}
                                        fontSize="12px"
                                        color="error"
                                        title={error?.confirmPassword || error?.passwordMatch}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    mt={{ xs: 1, sm: 4 }}
                                    display={"flex"}
                                    justifyContent={"end"}
                                >
                                    <CommonButton
                                        maxWidth={"110px"}
                                        text={"Submit"}
                                        className={"primary"}
                                        onClick={_createAdmin}
                                    />
                                </Grid>
                            </Grid>
                        }
                    />
                )}

                {/* Model for delete Admin */}
                {conformationModelOpenKeyword &&
                    <CommonConformationModal
                        open={conformationModelOpenKeyword}
                        onClose={() => { handleConformationModelForKeyword() }}
                        onSubmit={() => { _deleteKeyAdmin(deleteAdminId); handleConformationModelForKeyword(); }}
                        maxWidth={"sm"}
                        question={"Are you sure you want to delete this Admin?"}
                        isDelete
                    />}

            </Grid >
        </>
    )
}

export default Admin
