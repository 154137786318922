import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CommonButton from './Button/CommonButton';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/');
    };

    return (
        
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#000', // Black background color
                color: '#fff',           // White text color
                textAlign: 'center',
                gap: '20px',
                padding: '20px',
            }}
        >
            <Box
                component="img"
                src="/images/PageNotFoundImg.svg"
                alt="404"
                sx={{
                    width: { xs: '250px', sm: '300px', md: '400px' }, // Adjust width for mobile, tablet, and desktop
                    marginBottom: '10px',
                }}
            />

            <Typography
                sx={{
                    maxWidth: '600px',
                    fontSize: { xs: '14px', sm: '16px' },
                    color: 'rgba(107, 103, 103, 1)',
                }}
            >
                A 404 page informs users that the page is missing and guides them back with helpful links, a search bar, or navigation options.
            </Typography>

            <CommonButton
                padding={"12px 10px"}
                className="primary"
                borderRadius={"30px"}
                text={"Go Back"}
                maxWidth={"144px"}
                onClick={handleRedirect}
            />
        </Box>
    );
};

export default PageNotFound;
