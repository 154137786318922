import React from 'react';
import { Box, TablePagination, Pagination, useMediaQuery } from "@mui/material";

const CustomPagination = ({ count, page, onPageChange, rowsPerPage, onRowsPerPageChange, rowsPerPageOptions }) => {
    // Check for mobile screens
    const isMobile = useMediaQuery('(max-width:400px)');

    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={isMobile ? 'column' : 'row'} // Change direction on mobile
            gap={isMobile ? 1 : 0} // Add spacing between items on mobile
            flexWrap={'wrap'}
        >
            <TablePagination
                component="div"
                rowsPerPageOptions={rowsPerPageOptions}
                count={count}
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                labelDisplayedRows={(from = page) => (``)}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                sx={{
                    color: '#404040',
                    '& .MuiTablePagination-selectLabel': {
                        color: '#404040',
                    },
                    '& .MuiSelect-select': {
                        color: '#404040',
                        borderColor: '#404040',
                    },
                    '& .MuiTablePagination-actions .MuiIconButton-root': {
                        color: '#404040',
                    },
                    '& .MuiTablePagination-displayedRows': {
                        color: '#404040',
                    },
                }}
            />
            <Pagination
                variant="outlined"
                count={count}
                page={page}
                onChange={onPageChange}
                size='small'
                color="primary"
                shape="rounded"
                sx={{
                    marginRight: '20px !important',
                    marginBottom: isMobile ? '20px !important' : '0px',
                    '& .MuiPaginationItem-root': {
                        border: '1px solid #625A5A',
                        color: '#625A5A',
                        borderRadius: "6px"
                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                        background: 'radial-gradient(141.22% 118.61% at 21.45% 10.64%, rgba(255, 255, 255, 0.2) 0%, rgba(244, 166, 6, 0.2) 79%, rgba(253, 99, 197, 0.2) 100%)',
                        background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        border: "1px solid #625A5A"
                    },
                    '& .MuiPaginationItem-root:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Hover effect
                    },
                }}
            />
        </Box>
    );
}

export default CustomPagination;
