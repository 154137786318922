const sessionStoreUtil = {
    setLSItem: (key, data) => {
    if (typeof data === "object") {
      sessionStorage.setItem(key, JSON.stringify(data));
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      sessionStorage.setItem(key, data);
      localStorage.setItem(key, data);
    }

    return true;
  },

  getLSItem: (key) => {
    // const item = sessionStorage.getItem(key);
    const item = localStorage.getItem(key);

    if (!item) return;
    return item;
  },

  removeLSItem: (key) => {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
    return true;
  },

  remove_all: () => {
    sessionStorage.clear();
    localStorage.clear();
    return true;
  },
};

export default sessionStoreUtil;
