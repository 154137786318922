import React from 'react';
import CommonChipDash from './CommonChipDash';

const ComplaintsChip = ({ data }) => {

    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            change={data?.change}
            isPositive={false}
            icon={<img src="/images/ChipIcon/ComplainsChipIcon.svg" alt="Star" width="24" height="24" />}
        />
    );
};

export default ComplaintsChip;
