import React from 'react'

const Subscription = ({ active }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" opacity={active ? "1" : "0.3"}  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.298 11.8058C13.3413 11.8058 12.562 10.9951 12.562 10C12.562 9.00485 13.3413 8.19417 14.298 8.19417H17V5.63107C17 4.73301 16.2953 4 15.432 4H4.568C3.70467 4 3 4.73301 3 5.63107V6.34466H13.024C13.2807 6.34466 13.4907 6.56311 13.4907 6.8301C13.4907 7.09709 13.2807 7.31553 13.024 7.31553H3V14.3689C3 15.267 3.70467 16 4.568 16H15.432C16.2953 16 17 15.267 17 14.3689V11.8058H14.298Z" fill="url(#paint0_radial_553_433)" />
            <path d="M14 10.0029C14 10.5536 14.2115 11 14.4724 11H17V9H14.4724C14.2115 9 14 9.44638 14 9.9971V10.0029Z" fill="url(#paint1_radial_553_433)" />
            <defs>
                <radialGradient id="paint0_radial_553_433" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.00355 5.27659) rotate(45.5819) scale(12.8389 24.0833)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
                <radialGradient id="paint1_radial_553_433" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.6436 9.21277) rotate(38.4404) scale(2.45837 4.49199)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export default Subscription
