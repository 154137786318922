import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import styled from 'styled-components';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const ChipContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1e1e1e;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #333;
  flex-wrap: wrap;
  cursor: pointer;
`;

const Chip = styled.div`
  background-color: ${props => props.isSelected ? '#302613' : '#1b1714'};
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  flex: 1 1 100%;
  padding: 20px 0;

  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 15px 0;
    font-size: 12px;
  }

  @media (min-width: 600px) {
    flex: 1;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #333;

    @media (max-width: 600px) {
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;  /* Full width below the Chip */
      height: 1px;   /* Horizontal line */
    }
  }
`;


const ChipValue = styled.span`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 4px;

  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 22px;
    margin-bottom: 2px;
  }
`;

const ChipInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ChipLabel = styled.span`
  color: #888;
  font-size: 14px;

  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 10px;
  }  

`;

const ChipTrend = styled.div`
  color: #4caf50;
  font-size: 10px;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 6px;
  border-radius: 20px;

  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 9px;
    gap: 1px;
  }

`;


const StyledResponsiveContainer = styled(ResponsiveContainer)`
  background-color: #1e1e1e;
`;

const ChartContainer = styled.div`
  background-color: #1e1e1e;
  border-radius: 12px;
  overflow: hidden;
`;
const ScrollableChartWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; // For smooth scrolling on iOS
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const MinWidthContainer = styled.div`
  min-width: 600px; // Minimum width to ensure chart is readable on mobile
  width: 100%;
`;


const LineChartComponent = ({ chipData, data }) => {

  const [selectedChips, setSelectedChips] = useState(Array(data?.length)?.fill(false).map((_, index) => index === 0));

  const handleChipClick = (index) => {
    const updatedChips = selectedChips?.map((chip, idx) => idx === index);
    setSelectedChips(updatedChips);
  };

  return (
    <ChartContainer>
      <ChipContainer>
        {chipData?.map((entry, index) => (
          <Chip key={index} isSelected={selectedChips[index]} onClick={() => handleChipClick(index)}>
            <ChipLabel>{entry?.label}</ChipLabel>
            <ChipInfoContainer>
              <ChipValue>{entry?.value}</ChipValue>
              {/* <ChipTrend>{entry.trend}</ChipTrend> */}
              <ChipTrend>
                <TrendingUpIcon sx={{ color: '#4CAF50', fontSize: '14px' }} />
                {entry?.trend}
              </ChipTrend>
            </ChipInfoContainer>
          </Chip>
        ))}
      </ChipContainer>
      <ScrollableChartWrapper>
        <MinWidthContainer>
          <StyledResponsiveContainer width="100%" height={300}>
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            >
              {data?.map((entry, index) => (
                <ReferenceLine
                  key={`vl-${index}`}
                  x={entry.month}
                  stroke="#333"
                  strokeDasharray="3 3"
                />
              ))}
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#888', fontSize: 12 }}
                scale="point"
                padding={{ left: 30, right: 30 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#888', fontSize: 12 }}
                domain={[0, 80]}
                ticks={[0, 20, 40, 60, 80]}
              />
              <defs>
                <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#FFFFFF" />
                  <stop offset="79%" stopColor="#F4A606" />
                  <stop offset="100%" stopColor="#FD63C5" />
                </linearGradient>
                <radialGradient id="paint0_radial_1601_9713" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(266.312 15.483) rotate(7.07435) scale(796.818 365.433)">
                  <stop stop-color="white" />
                  <stop offset="0.79" stop-color="#F4A606" />
                  <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
                <filter id="glow">
                  <feGaussianBlur stdDeviation="2" result="coloredBlur" />
                  <feMerge>
                    <feMergeNode in="coloredBlur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <Line
                type="linear"
                dataKey="value"
                stroke="url(#paint0_radial_1601_9713)"
                strokeWidth={2}
                dot={{ r: 2, fill: "#F4A606", stroke: "#F4A606" }}
                filter="url(#glow)"
              />
            </LineChart>
          </StyledResponsiveContainer>
        </MinWidthContainer>
      </ScrollableChartWrapper>
    </ChartContainer >
  );
};

export default LineChartComponent;
