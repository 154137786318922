import React, { useState } from 'react';
import CommonBarChart from '../BarChart/CommonBarChart';

const BusinessTypeBarChart = ({ data, options }) => {

    const [selectedValue, setSelectedValue] = useState('Monthly');

    const title = 'Data by Business Type';

    // Transform the data
    const businessTypeBarData = data
        // .filter(item => item?._id !== null) // Remove null entries
        .map(item => ({
            name: item?._id,
            Data1: item?.count,
            month: item?._id // Using _id as month for tooltip display
        }))
        // .sort((a, b) => b?.Data1 - a?.Data1); // Sort by count in descending order

    const handleChange = (event) => {
        setSelectedValue(event?.target?.value);
    };

    const barColors = ['#FF781E', '#F8C67D', '#F88661'];
    return (
        <CommonBarChart
            data={businessTypeBarData}
            gradientIds={['subscriptionGradient']}
            selectedValue={selectedValue}
            handleChange={handleChange}
            barNumbers={1}
            title={title}
            height={250}
            options={options}
            showLabels={false}
            showMonthlyOption={true}
            showCountrySelect={false}
            useGradient={false}
            color1={barColors}
        />
    );
};

export default BusinessTypeBarChart;    
