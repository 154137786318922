import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import CommonTextField from "../../Components/Common/Fields/TextField";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createAdmin } from "../../Redux/Actions/action";
import ErrorHandler from "../../Utils/handler";
import { showLoader } from "../../Redux/Slices/loaderSlice";
import { Regex } from "../../Utils/regex";
import { useNavigate } from "react-router-dom";
import TextLabel from "../../Components/Common/Fields/TextLabel";

const SignUp = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();

    const [adminData, setAdminData] = useState({
        firstName: "",
        mobileNo: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [error, setError] = useState({});

    const handleAdminChange = (e) => {
        const { name, value } = e.target;
        setAdminData({ ...adminData, [name]: value.trimStart() });
    };

    const validation = () => {
        const errors = {};
        let valid = true;

        if (!adminData.firstName) {
            errors.firstName = "Please enter your admin name";
            valid = false;
        }
        if (adminData.mobileNo && !Regex.mobileNumberRegex.test(adminData.mobileNo)) {
            errors.mobileValid = "Please enter a valid mobile number";
            valid = false;
        }
        if (adminData?.mobileNo && !Regex?.mobileNumberRegex?.test(adminData?.mobileNo)) {
            error.mobileValid = "Please enter your a valid mobile number";
            valid = false;
        }
        if (!adminData.email) {
            errors.emailAdmin = "Please enter your email id";
            valid = false;
        } else if (!Regex.emailRegex.test(adminData.email)) {
            errors.emailAdminValid = "Please enter a valid email id";
            valid = false;
        }
        if (!adminData.password) {
            errors.passwordAdmin = "Please enter your password";
            valid = false;
        } else if (!adminData.password.match(Regex.passwordRegex)) {
            errors.invalidPassword =
                "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character";
            valid = false;
        }
        if (!adminData.confirmPassword) {
            errors.confirmPassword = "Please enter Confirm Password ";
            valid = false;
        }
        if (adminData.password !== adminData.confirmPassword) {
            errors.passwordMatch = "Passwords do not match";
            valid = false;
        }


        setError(errors);
        return valid;
    };

    const _createAdmin = async () => {
        if (validation()) {
            dispatch(showLoader(true));
            const body = {
                name: adminData.firstName,
                email: adminData.email,
                password: adminData.password,
            };

            if (adminData?.mobileNo) {
                body.mobileNo = adminData?.mobileNo;
            }

            try {
                const result = await dispatch(createAdmin(body));
                const error = ErrorHandler(result);
                if (error) {
                    toast.success("Admin created successfully!");
                    setAdminData({
                        firstName: "",
                        mobileNo: "",
                        email: "",
                        password: "",
                        confirmPassword: "",
                    });
                    setError({});
                    navigate('/login');
                }
            } catch (error) {
                toast.error(error?.message || "An unknown error occurred");
            } finally {
                dispatch(showLoader(false));
            }
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            minWidth={"30vh"}
            sx={{
                background: "radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 100%)",
                backgroundImage: "url(/images/backgroundLinigImg.svg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "#FFFFFF",
            }}
        >
            <Grid
                container
                spacing={isMobile ? 1 : 2}
                maxWidth={isMobile ? "80%" : "620px"}
                maxHeight={isMobile ? "100%" : "100%"}
                padding={isMobile ? "25px 20px" : "30px 30px"}
                border="1px solid #3E3E3E"
                borderRadius="20px"
                sx={{
                    backgroundColor: "#121212",
                    boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.25)",
                }}
            >
                {/* Logo */}
                <Grid item xs={12} marginBottom={isMobile ? "10px" : "10px"}>
                    <Box display="flex" alignItems="center" gap="10px">
                        <img src="/images/logo.svg" alt="logo" height="28px" width="28px" />
                        <Typography fontSize="14px" fontWeight={600} color="#FFFFFF">
                            The AI Reviews
                        </Typography>
                    </Box>
                </Grid>

                {/* Title */}
                <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    // mb={isMobile ? "25px" : "25px"}
                    mb={"20px"}
                >
                    <Typography
                        fontSize={isMobile ? "28px" : "30px"}
                        fontWeight={700}
                        lineHeight={isMobile ? "30px" : "38.19px"}
                        letterSpacing="-1px"
                        sx={{
                            background:
                                "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        Create admin
                    </Typography>
                </Grid>

                {/* Form Fields */}
                <Grid item xs={12} sm={6} >
                    <CommonTextField
                        valid
                        text="Admin Name"
                        placeholder="Enter your admin name"
                        type="text"
                        name="firstName"
                        value={adminData.firstName}
                        onChange={handleAdminChange}
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={!adminData.firstName ? error?.firstName : ""}
                    />
                </Grid>

                {/* <Grid item xs={12} sm={6} >
                    <CommonTextField
                        text="Mobile Number"
                        placeholder="Enter your mobile number"
                        type="number"
                        name="mobileNo"
                        value={adminData.mobileNo}
                        // onChange={handleAdminChange}
                        onChange={(e) => {
                            const { value } = e.target;
                            if (/^(?!0{2,})\d*$/.test(value) && value.length <= 10) {
                                setAdminData((prevValue) => ({
                                    ...prevValue,
                                    mobileNo: value,
                                }));
                            }
                        }}
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={adminData?.mobileNo ? !Regex?.mobileNumberRegex?.test(adminData?.mobileNo) ? error.mobileValid : "" : ""}
                    />

                </Grid> */}

                <Grid item xs={12} sm={6} >
                    <CommonTextField
                        valid
                        text="Email"
                        subtitle="do not use your business email"
                        placeholder="Enter your email"
                        type="email"
                        name="email"
                        value={adminData.email}
                        onChange={handleAdminChange}
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={!adminData?.email ? error.emailAdmin : ""}
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={adminData.email ? !Regex.emailRegex.test(adminData.email) ? error.emailAdminValid : "" : ""}
                    />
                </Grid>

                <Grid item xs={12} sm={6} >
                    <CommonTextField
                        valid
                        text="Password"
                        placeholder="Enter your password"
                        type="password"
                        name="password"
                        value={adminData.password}
                        onChange={handleAdminChange}
                        showPasswordToggle
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={!adminData.password ? error?.passwordAdmin : ""}
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={adminData?.password ? !adminData.password.match(Regex.passwordRegex) ? error.invalidPassword : "" : ""}
                    />
                </Grid>

                <Grid item xs={12} sm={6} >
                    <CommonTextField
                        valid
                        text="Confirm Password"
                        placeholder="Confirm your password"
                        type="password"
                        name="confirmPassword"
                        value={adminData.confirmPassword}
                        onChange={handleAdminChange}
                        showPasswordToggle
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={!adminData.confirmPassword ? error.confirmPassword : ""}
                    />
                    <TextLabel
                        marginTop={1}
                        fontSize="12px"
                        color="error"
                        title={adminData.confirmPassword ? adminData.password !== adminData.confirmPassword ? error.passwordMatch : "" : ""}
                    />
                    {/* <Typography variant="caption" color={"error"}>
                        {adminData.confirmPassword ? adminData.password === adminData.confirmPassword ? "" : error.passwordMatch : ""}
                    </Typography> */}
                </Grid>

                {/* <Grid item xs={12} justifyContent={"center"} >
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1}>
                        <TextLabel
                            marginTop={1}
                            fontSize="12px"
                            title={"Note :"}
                            sx={{
                                background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, rgb(255, 255, 255) 0%, rgb(244, 166, 6) 33.88%, rgb(253, 99, 197) 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                display: "inline-block",
                            }}
                        />
                        <TextLabel
                            marginTop={1}
                            fontSize="12px"
                            color="#FFFFFF"
                            title={"do not use your business email"}

                        />
                    </Box>
                </Grid> */}

                {/* Submit Button */}
                <Grid item xs={12} paddingTop={"20px !important"}>
                    <CommonButton
                        text="Create Admin"
                        className="primary"
                        fullWidth={isMobile}
                        onClick={_createAdmin}
                    />
                    <Box display={"flex"} justifyContent={"flex-end"} mr={2}>
                        <Typography
                            mt={1}
                            fontSize="12px"
                            lineHeight="20px"
                            color="#F4A606"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                dispatch(showLoader(true));
                                setTimeout(() => {
                                    navigate("/login");
                                    dispatch(showLoader(false));
                                }, 100);
                            }}
                        >
                            login?
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
};

export default SignUp;
