import React from 'react';
import CommonBarChart from './BarChart/CommonBarChart';
import CommonBackgroundContainer from './CommonBackgroundContainer';



const ReviewBarChart = ({ data }) => {

  const gradientIds = ['ageGradient1'];
  const barNumbers = 1;
  const title = 'Review by Location';

  return (
    <CommonBackgroundContainer
      title={title}
      titleColor='#CCC9C9'
      titleFontSize='16px'
      titleFontWeight='700'
    >
      <CommonBarChart
        data={data}
        gradientIds={gradientIds}
        barNumbers={barNumbers}
        height={230}
        showGrid={false}
        color1={'#F4A606'}
      />
    </CommonBackgroundContainer>
  );
};

export default ReviewBarChart;
