export const api = {
  // USER ROUTES
  generateReview: "openaiGoogleReview",

  // => get Business+
  getBusinessForUser: "businessProfile",

  // => Create complain
  createComplain: "userFeedback",


  //ADMIN ROUTES
  login: "login",
  forgetPassword: "forgetPassword",
  otp_verification: "otp_verification",
  change_password: "change_password",

  // Upload Image
  uploadImage: "upload/image/attachment",

  // Complain
  getComplain: "userFeedbackGet",
  replyToComplain: "userFeedbackReplay",

  // Business Complains
  getBusinessComplain: "complainsGet",
  addBusinessComplains: "complains",

  //Country
  getCountries: "countries",
  getStates: "states",
  getCities: "cities",

  // => My Account
  getMyBusiness: "businessGet",
  getMyBusinessUsingAdmin: "businessList",

  // => My Bussiness
  getsingleData: "business",
  getBusiness: "business",
  editBusiness: "business",
  deleteBusiness: "business",
  keywordGenerate: "openaiBusinessKeywords",

  // Keyword
  getBusinessKeywords: "businessKeywordsGet",
  addBusinessKeywords: "businessKeywords",
  deleteBusinessKeywords: "businessAllKeywords",
  editBusinessKeywords: "businessKeywords",
  deleteBusinessKeyword: "businessKeyword",

  // Tag
  tagGenerate: "openaiBusinessTags",
  getGenerate: "businessTagsGet",
  addGenerate: "businessTags",
  deleteGenerate: "businessTags",



  // => Help-Desk
  getHelpDesk: "complains",
  addHelpDesk: "complains/", // by id
  deleteHelpDesk: "complains/", // by id

  // => Reviews
  getGoogleReview: "openaiGoogleReview",

  //Create Admin
  adminCreate: "adminCreate",
  addPrivateNotes: "addPrivateNotes",
  adminDelete: "admin",

  // Subscription

  getSubscriptionPlan: "subscrptionPlanGet",
  subscriptionPlan: "subscrptionPlan",
  buyPlan: "buySubscrptionPlan",
  secondTimeBuySubscriptionPlan: "manageSubscrptionPlan",
  subscriptionHistroy: "paymenthistoriesGet",

  // Super admin
  adminGet: "adminGet",
  businessListGet: "businessGet",

  //Help Desk Chart API
  helpdeskChartGet: "complainsSummary",

  // update admin
  updateAdminDetail: "updateAdminProfile",

  // Ai generate  Feedback Ai Suggestion
  generateFeedbackAiSuggestion: "generateFeedbackAiSuggestion",

  // feedback Auto Response
  feedbackAutoResponse: "feedbackAutoResponse",

  // bussiness Plan Details
  bussinessPlanDetails: "bussinessPlanDetails",

  //Dashboard Chart API

  // Superadmin
  subscriptionPercentage: "subscriptionPercentage",
  dataByBusinessType: "dataByBusinessType",
  topUseKeywords: "topUseKeywords",
  businessData: "businessData",

  // all business
  activateTrial: "activateTrial",

  // addComplainsNotes
  addComplainsNotes: "addUserFeedbackNotes"

};
