import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, Skeleton } from '@mui/material';
import CommonButton from '../../Components/Common/Button/CommonButton';
import { BucketURL } from '../../ApiSetup/Api';
import { secondTimeBuySubscriptionPlan, buySubscriptionPlan, getSingleBusiness, getSingleSubscriptionDetail } from '../../Redux/Actions/action';
import { useDispatch } from 'react-redux';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import ErrorHandler from '../../Utils/handler';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        highlightLink: {
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#939393",
            "&:hover": {
                color: "darkgray",
            },
        }
    }
});

const PaymentDetails = () => {
    const { classes } = useStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const subscriptionID = searchParams?.get('id');
    const businessID = searchParams?.get('businessId');
    const plan = searchParams?.get('plan');
    const [businessData, setBusinessData] = useState({})
    const [subscriptionData, setSubscriptionData] = useState({})

    const _getSingleBusiness = async () => {
        if (businessID) {
            dispatch(showLoader(true));
            try {
                const result = await dispatch(getSingleBusiness(businessID));
                const error = ErrorHandler(result);
                if (error) {
                    setBusinessData(result?.payload?.data?.[0])
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(showLoader(false));
            }
        }
    }

    const _getSingleSubscriptionDetail = async () => {
        if (subscriptionID) {
            dispatch(showLoader(true));
            try {
                const result = await dispatch(getSingleSubscriptionDetail(subscriptionID));
                const error = ErrorHandler(result);
                if (error) {
                    setSubscriptionData(result?.payload?.data)
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(showLoader(false));
            }
        }
    }

    const _buyPlan = async () => {
        if (businessID && subscriptionID) {
            dispatch(showLoader(true))
            let body = {
                businessId: businessID,
            };
            if (plan === "true") {
                body.newSubscrptionPlanId = subscriptionID
            } else {
                body.subscrptionPlanId = subscriptionID
            }
            try {
                const result = await dispatch(plan === "true" ? secondTimeBuySubscriptionPlan(body) : buySubscriptionPlan(body));
                const error = ErrorHandler(result)
                if (error) {
                    if (plan === "true") {
                        navigate(`/subscription`);
                    } else {
                        window.location.href = result?.payload?.data?.url
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(showLoader(false))
            }
        }
    };

    useEffect(() => {
        _getSingleBusiness()
        _getSingleSubscriptionDetail()
    }, [businessID])

    console.log(subscriptionData, "businessData")
    return (
        <Grid container padding={{ xs: "30px", sm: "40px" }} spacing={3}>
            <Grid item xs={12}>
                <Typography fontSize={"30px"} fontWeight={800} color={"#FFFFFF"}>
                    Payment detail
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6} lg={5} xl={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ padding: { xs: "30px 25px", sm: "36px 50px" }, backgroundColor: "#0F0F0F", borderRadius: "20px", flex: 1 }}>
                        <Typography color="#FFFFFF" fontSize="20px" fontWeight={700}>
                            Business Details
                        </Typography>
                        <Divider sx={{ backgroundColor: '#FFFFFF1A', my: "15px" }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Business name :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.name}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Business Type :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.businessType}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Country :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.countryDetail?.label}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    State :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.stateDetail?.label}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    City :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.cityDetail?.label}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Mobile number :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.mobileNo || "-"}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Email :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.email || "-"}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Address Line 1 :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.addressLine1 || "-"}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Address Line 2 :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.addressLine2 || "-"}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: "wrap", justifyContent: 'space-between' }}>
                                <Typography color="#989898" fontSize="16px" fontWeight={700} sx={{ flex: '1 0 45%' }}>
                                    Pincode :
                                </Typography>
                                <Typography color="#6B6767" fontSize="16px" fontWeight={500} sx={{ flex: '1 0 45%' }}>
                                    {businessData?.pincode || "-"}
                                </Typography>
                            </Box>
                            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: "15px", backgroundColor: "#161616", borderRadius: "20px", padding: "18px 20px", mt: "40px" }}>
                                <Box>
                                    {businessData?.qrcodeUrl ? (
                                        <Box
                                            component="img"
                                            src={`${BucketURL}${businessData?.qrcodeUrl}`}
                                            alt="QR Code"
                                            sx={{
                                                width: '109px',
                                                height: '109px',
                                                borderRadius: "10px",
                                            }}
                                        />
                                    ) : (
                                        <Skeleton variant="rectangular" width="109px" height="109px" sx={{ borderRadius: "10px" }} />
                                    )}
                                </Box>
                                <Box>
                                    <Typography fontSize="18px" color="#FFFFFF" fontWeight={700}>
                                        QR code
                                    </Typography>
                                    <Typography fontSize="12px" color="#939393">
                                        One-time barcodes are unique, single-use codes that are designed to be scanned only once. They are commonly used in various applications where security, authenticity.
                                    </Typography>
                                </Box>
                            </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' }, // Column for mobile, row for larger devices
                                    alignItems: 'center',
                                    gap: "15px",
                                    backgroundColor: "#161616",
                                    borderRadius: "20px",
                                    padding: "18px 20px",
                                    mt: "40px"
                                }}
                            >
                                <Box>
                                    {businessData?.qrcodeUrl ? (
                                        <Box
                                            component="img"
                                            src={`${BucketURL}${businessData?.qrcodeUrl}`}
                                            alt="QR Code"
                                            sx={{
                                                width: '109px',
                                                height: '109px',
                                                borderRadius: "10px",
                                            }}
                                        />
                                    ) : (
                                        <Skeleton variant="rectangular" width="109px" height="109px" sx={{ borderRadius: "10px" }} />
                                    )}
                                </Box>
                                <Box sx={{ mt: { xs: '10px', sm: 0 }, textAlign: { xs: 'center', sm: 'left' } }}>
                                    <Typography fontSize="18px" color="#FFFFFF" fontWeight={700}>
                                        QR code
                                    </Typography>
                                    <Typography fontSize="12px" color="#939393">
                                        One-time barcodes are unique, single-use codes that are designed to be scanned only once. They are commonly used in various applications where security, authenticity.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                </Grid>
                <Grid item xs={12} md={6} lg={5} xl={4} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ padding: { xs: "30px 25px", sm: "36px 50px" }, backgroundColor: "#0F0F0F", borderRadius: "20px", flex: 1 }}>
                        <Typography color="#FFFFFF" fontSize="20px" fontWeight={700}>
                            Order Details
                        </Typography>
                        <Divider sx={{ backgroundColor: '#FFFFFF1A', my: "14px" }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box display={"flex"} flexDirection={"column"} >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography color="#989898" fontSize="16px" fontWeight={700}>
                                        Annual subscription
                                    </Typography>
                                    <Typography color="#989898" fontSize="16px" fontWeight={700}>
                                        {`${(subscriptionData?.price?.toFixed(2))}$`}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography color="#6B6767" fontSize="14px" fontWeight={500}>
                                        VAT/GST/Sales taxes (16%)
                                    </Typography>
                                    <Typography color="#6B6767" fontSize="14px" fontWeight={500}>
                                        {`${(subscriptionData?.price * 0.16)?.toFixed(2)}$`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ backgroundColor: '#FFFFFF1A', my: "22px" }} />
                            <Box display={"flex"} flexDirection={"column"} >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography color="#989898" fontSize="16px" fontWeight={700}>
                                        Discount
                                    </Typography>
                                    <Typography color="#989898" fontSize="16px" fontWeight={700}>
                                        0%
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography color="#6B6767" fontSize="14px" fontWeight={500}>
                                        VAT/GST/Sales taxes (16%)
                                    </Typography>
                                    <Typography color="#6B6767" fontSize="14px" fontWeight={500}>
                                        0.00$
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ backgroundColor: '#FFFFFF1A', my: "22px" }} />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography color="#989898" fontSize="16px" fontWeight={700}>
                                Total
                            </Typography>
                            <Typography color="#989898" fontSize="16px" fontWeight={700}>
                                {`${(subscriptionData?.price?.toFixed(2))}$`}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: '121px' }}>
                            <CommonButton text="Confirm and pay" className="primary" maxWidth="318px" onClick={() => _buyPlan()} />
                            <CommonButton text="Back to subscription" className="secondary" maxWidth="318px" onClick={() => navigate("/mangesubscription")} />
                        </Box>

                        <Typography color="#939393" fontSize="14px" my="25px">
                            Your subscription will renew automatically every year as one payment of <strong>{`$${(subscriptionData?.price?.toFixed(2))}`}</strong>. Cancel it anytime from your subscription settings. By clicking "Confirm and pay" you agree to the
                            <a href="/TermsandConditions" className={classes.highlightLink} style={{ marginLeft: "5px" }}>
                                Terms and Conditions
                            </a>.
                        </Typography>
                    </Box>

                </Grid>
            </Grid>
        </Grid>
    );
};

export default PaymentDetails;
