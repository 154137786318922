import { InputLabel, Typography, Box, InputAdornment, IconButton, TextField, } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid } from "lucide-react";

const useStyles = makeStyles()((theme) => {
  return {
    main: {
      // background: "transparent",
      minWidth: "50px",
      ".MuiInputBase-input": {
        height: "12px",
        fontSize: '14px',
      },
      ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `#2D2D2D !important`,
      },
      ".MuiInputBase-formControl.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `#2D2D2D !important`,
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: `#2D2D2D !important`,
        // borderRadius: '30px',
      },
      ".Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `#2D2D2D !important`,
        borderWidth: "1px !important",
      },
      "& .MuiInputBase-formControl.Mui-disabled": {
        backgroundColor: "transparent",
        opacity: 0.8
      },
      '& input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 30px transparent inset !important',
        '-webkit-text-fill-color': '#ffffff !important',
        transition: 'background-color 5000s ease-in-out 0s',
      },
    },
    error: {
      border: "1px solid green",
    },
  };
});


const CommonTextField = ({ text, type, placeholder, height, width, valid, multiline, rows, name, value, onChange, onInput, inputProps, defaultValue, fontWeight, showPasswordToggle, className,
  bgcolor,
  onKeyDown,
  onPaste,
  onBlur,
  disabled,
  onDrag,
  border,
  background,
  fontSize,
  secondaryText,
  color,
  borderRadius,
  subtitle,
}) => {
  const { classes } = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {text && (
        <Box
          mb={0.5}
          display="flex"
          fontSize="12px"
          flexDirection={"row"}
          marginBottom={"8px"}
        >
          <InputLabel
            sx={{
              letterSpacing: "0.03em",
              marginRight: "2px",
              fontWeight: fontWeight,
              fontSize: fontSize || "14px",
              color: color || "rgba(255, 255, 255, 1)",
            }}
          >
            {text}
          </InputLabel>
          {valid && (
            <Typography marginRight={1} color="#cf2525" component={"caption"} variant={"body2"}>
              *
            </Typography>
          )}
          {subtitle && (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <Typography
                fontSize="10px"
                component={"caption"}
                variant={"body2"}
                sx={{
                  background:
                    "radial-gradient(184.42% 99.8% at 21.45% 10.64%, rgb(255, 255, 255) 0%, rgb(244, 166, 6) 33.88%, rgb(253, 99, 197) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: "inline-block",
                }}
              >
                Note:
              </Typography>
              <Typography
                fontSize="10px"
                color="#FFFFFF"
              >
                {subtitle}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <TextField
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type={
          type === "password"
            ? showPassword && showPasswordToggle
              ? "text"
              : type
            : type
        }
        placeholder={placeholder}
        sx={{
          height: height || 42,
          width: width,
          bgcolor: bgcolor,
          border: border,
          background: background || "transparent",
          '& .MuiOutlinedInput-root': {
            borderRadius: borderRadius || "30px", // Apply the border radius to the input root
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#5F616C',
            opacity: 1,
          },

        }}
        multiline={multiline}
        rows={rows}
        className={`${classes?.main}`}
        InputProps={{
          ...(showPasswordToggle && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: showPassword ? "#FFFFFF" : "#FFFFFF" }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }),
          inputProps: {
            onWheel: (e) => e.target.blur(),
            ...inputProps
          },
        }}
        disabled={disabled ? disabled : false}
        autoComplete={showPassword ? "new-password" : "off"}
      />

    </>
  );
};

export default CommonTextField;