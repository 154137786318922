// CurrentRatingChip.js
import React from 'react';
import CommonChipDash from './CommonChipDash';

const CurrentRatingChip = ({ data }) => {

    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            change={data?.change}
            isPositive={true}
            icon={<img src="/images/ChipIcon/StarChipIcon.svg" alt="Star" width="24" height="24" />}
        />
    );
};

export default CurrentRatingChip;
