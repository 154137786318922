import {
  Box, Grid, Paper, Typography, RadioGroup, FormControlLabel, Radio, Skeleton,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DocumentsUpload from "../../Components/Common/documentsUploadComponent";
import CommonTextField from "../../Components/Common/Fields/TextField";
import CommonButton from "../../Components/Common/Button/CommonButton";
import AutoCompleteSearch from "../../Components/Common/commonAutoComplete";
import { makeStyles } from "tss-react/mui";
import { styled } from "@mui/material/styles";
import CommonModal from "../../Components/Common/CommonModel";
import { useDispatch } from "react-redux";
import ErrorHandler from "../../Utils/handler";
import { Regex } from "../../Utils/regex";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import {
  AddMybusinessGenral, editMybusinessGenral, getCities, getCountries, getSingleData, getStates, uploadimg,
} from "../../Redux/Actions/action";
import { BucketURL } from "../../ApiSetup/Api";
import { toast } from "react-toastify";
import Switch from '@mui/material/Switch';
import { showLoader } from "../../Redux/Slices/loaderSlice";
import sessionStoreUtil from "../../ApiSetup/Localstorage";
import { toPng } from 'html-to-image';
import { useNavigate, useSearchParams } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopy";


const useStyles = makeStyles()((theme) => ({
  mainGridContainer: {
    position: "relative",
  },
  avatar: {
    height: "60px",
    width: "60px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  highlightLink: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: "#939393",
    "&:hover": {
      color: "darkgray",
    },
  }
}));

const BackgroundImage = styled('img')({
  position: 'absolute',
  bottom: '-30px',
  right: '-48px',
  zIndex: 0,
  borderBottomRightRadius: '26px',
});

const CustomSwitch = styled(Switch)(() => ({
  width: 58,
  height: 32,
  padding: 3,
  '& .MuiSwitch-switchBase': {
    margin: "2px 0px 0px 5px",
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(26px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#000',
      },
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(90deg, #FFDD00 0%, #FF3D88 100%)',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#000',
    width: 20,
    height: 20,
    marginTop: 4
  },
  '& .MuiSwitch-track': {
    borderRadius: "30px",
    background: '#FFFFFF33',
    opacity: 1,
  },
}));


const GradientCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.text.secondary,

  '&.Mui-checked': {
    color: 'transparent',
    '& svg': {
      fill: 'url(#gradient)',
    },
  },
}));

const GradientIcon = () => (
  <svg width="0" height="0">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: '#FFDD00', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#FF3D88', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  </svg>
);


const MyBusiness = () => {
  // ---------------------------- Hooks --------------------------------
  const bannerRef = useRef(null);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = sessionStoreUtil?.getLSItem("userData");
  const loginUser = userData ? JSON.parse(userData) : null;
  const [searchParams] = useSearchParams();
  const businessUpdateID = searchParams.get('param1');
  // ---------------------------- STATE --------------------------------
  const [generalData, setGeneralData] = useState({
    name: "",
    country: "",
    state: "",
    city: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    businessType: "",
    googleLocationUrl: "",
    googleReviewsUrl: "",
    mobilNo: "",
    email: "",
    password: "",
    confirmPassword: "",
    description: ""
  });
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [imageData, setImageData] = useState("");
  const [checked, setChecked] = useState(false);
  const [openBannerModal, setOpenBannerModal] = useState(false)
  const [bannerData, setBannerData] = useState({})
  const [bannerShape, setBannerShape] = useState('rectangle');
  const [qrUrl, setQrUrl] = useState()
  const [edit, SetEdit] = useState(false);
  const [editdata, SetEditdata] = useState();
  const [error, setError] = useState({})
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  // Name sort function
  const truncateName = (name) => {
    return name && name.length > 20 ? `${name.substring(0, 20)}...` : name;
  };

  const downloadImage = () => {
    if (bannerRef?.current === null) {
      return;
    }
    toPng(bannerRef?.current, { cacheBust: true })
      .then((dataUrl) => {
        console.log(dataUrl, "dataurlll");
        const link = document.createElement('a');
        link.download = 'card-image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('Failed to download image', err);
      });
  };

  const handleBannerChange = (event) => {
    setBannerShape(event.target.value);
  };

  const handleCloseForBannerModal = () => {
    setOpenBannerModal(false)
    setBannerData({})
    setError({})
  }

  const handleChangeCheckBox = (event) => {
    setIsChecked(event.target.checked);
  };

  // --------------------------------- Genral HandelChange --------------------------------

  const handleChangegenral = (e) => {
    const { name, value } = e.target;
    setGeneralData((preventvalue) => ({
      ...preventvalue,
      [name]: value,
    }));
  };

  const handleChangeForBannerModal = (e) => {
    const { name, value } = e.target;
    setBannerData((preventvalue) => ({
      ...preventvalue,
      [name]: value?.trimStart(),
    }));
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // Handle validation for my business
  const handleValidation = () => {
    let flag = true;
    let errors = {};

    if (!generalData?.name) {
      flag = false;
      errors["name"] = "Please enter your business name";
    }
    if (generalData?.mobilNo?.length < 0) {
      flag = false;
      errors["mobilNo"] = "Please enter your full phone number";
    } else if (
      generalData?.mobilNo &&
      !generalData?.mobilNo?.match(Regex?.mobileNumberRegex)
    ) {
      flag = false;
      errors["invalidMobile"] = "Please enter valid phone number";
    }
    if (!generalData?.country) {
      flag = false;
      errors["country"] = "Please select country";
    }
    if (!generalData?.state) {
      flag = false;
      errors["state"] = "Please select state";
    }
    if (!generalData?.city) {
      flag = false;
      errors["city"] = "Please select city";
    }
    if (!generalData?.addressLine1) {
      flag = false;
      errors["addressLine1"] = "Please enter your Address one";
    }
    if (!generalData?.addressLine2) {
      flag = false;
      errors["addressLine2"] = "Please enter your Business Address two";
    }
    if (!generalData?.pincode) {
      flag = false;
      errors["pincode"] = "Please enter your pincode";
    }
    if (!generalData?.businessType) {
      flag = false;
      errors["businessType"] = "Please enter your Business Type";
    }
    if (!generalData?.googleLocationUrl) {
      flag = false;
      errors["googleLocationUrl"] = "Please enter your Business google location";
    }
    else if (!generalData?.googleLocationUrl?.match(Regex?.googleLocationLink)) {
      flag = false;
      errors["invalidGoogleLocationUrl"] = "Please enter valid Google location URL";
    }

    if (!generalData?.googleReviewsUrl) {
      flag = false;
      errors["googleReviewsUrl"] = "Please enter your google reviews url";
    }
    else if (!generalData?.googleReviewsUrl?.match(Regex?.googleReviewsUrl)) {
      flag = false;
      errors["invalidGoogleReviewsUrl"] = "Please enter valid Google reviews URL";
    }
    if (!generalData?.email || generalData?.email === "") {
      flag = false;
      errors["email"] = "Please enter your email";
    } else if (!generalData?.email?.match(Regex?.emailRegex)) {
      flag = false;
      errors["invalidEmail"] = "Please enter your valid email";
    }

    // Only validate password fields if NOT in edit mode
    if (!edit) {
      if (!generalData?.password) {
        flag = false;
        errors["password"] = "Please enter your password";
      } else if (!generalData?.password?.match(Regex?.passwordRegex)) {
        flag = false;
        errors["invalidPassword"] = "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character";
      }

      if (!generalData?.confirmPassword) {
        flag = false;
        errors["confirmPassword"] = "Please enter your confirm password";
      }
      if (generalData?.password !== generalData?.confirmPassword) {
        flag = false;
        errors["confirmPasswordNotSame"] = "Password must match";
      }
    }
    if (!imageData) {
      flag = false;
      errors["imageData"] = "please upload image";
    }
    if (!isChecked) {
      flag = false;
      errors["isChecked"] = "please select Privacy Policy and Terms and Conditions";
    }

    setError(errors);
    return flag;
  };

  const downloadQRImage = (imageUrl) => {
    const proxyUrl = 'https://api.allorigins.win/get?url=';
    fetch(`${proxyUrl}${encodeURIComponent(BucketURL + imageUrl)}`)
      .then(response => response.json())
      .then(data => {
        const binaryString = atob(data.contents.split(',')[1]);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'downloaded_image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Image download failed:', error);
      });
  }

  const handleCopyUrl = () => {
    if (isDisabled) return;
    setIsDisabled(true);
    navigator.clipboard.writeText(generalData?.qrRedirectUrl).then(() => {
      toast?.success("URL copied to clipboard!")
    }).catch((err) => {
      console.error("Failed to copy URL: ", err);
    });
    setTimeout(() => {
      setIsDisabled(false);
    }, 4000);
  };

  //------------------->>>>>>>>>>>>>>>>>> functions for country, state and city
  const _getCountryList = async () => {
    dispatch(showLoader(true))
    let body = {};
    try {
      const result = await dispatch(getCountries(body));
      const error = ErrorHandler(result);
      if (error) {
        setCountryData(result?.payload?.data?.response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  const _getStateList = async () => {
    dispatch(showLoader(true))
    let body = {
      country_id: generalData?.country?.id,
    };
    try {
      const result = await dispatch(getStates(body));
      const error = ErrorHandler(result);
      if (error) {
        setStateData(result?.payload?.data?.response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  const _getCityList = async () => {
    dispatch(showLoader(true))
    let body = {
      country_id: generalData?.country?.id,
      state_id: generalData?.state?.id,
    };
    try {
      const result = await dispatch(getCities(body));
      const error = ErrorHandler(result);
      if (error) {
        setCityData(result?.payload?.data?.response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  const handleUpload = async (file, key) => {
    // Allowed file types
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    // Validate the file type
    if (!allowedImageTypes.includes(file.type)) {
      toast?.error("Invalid file type. Only JPEG, PNG, and JPG are allowed.");
      return;
    }

    // Validate the file size
    if (file.size > maxFileSize) {
      toast?.error("File size exceeds the 5 MB limit. Please upload a smaller file.");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    dispatch(showLoader(true));
    try {
      const result = await dispatch(uploadimg(formData));
      const error = ErrorHandler(result);
      if (error) {
        setImageData(result?.payload?.data?.image);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false));
    }
  };

  const redirectWithState = (path) => {
    sessionStoreUtil?.setLSItem("generalData", JSON.stringify(generalData));
    sessionStoreUtil?.setLSItem("imageData", imageData);
    navigate(path, { state: { generalData } });
  };


  // => Add Genral API
  const _addGenral = async () => {
    if (handleValidation()) {
      dispatch(showLoader(true))
      let body = {
        avtar: imageData,
        name: generalData?.name,
        email: generalData?.email,
        password: generalData?.password,
        googleLocationUrl: generalData?.googleLocationUrl,
        businessType: generalData?.businessType,
        googleReviewsUrl: generalData?.googleReviewsUrl,
        country: generalData?.country?.id,
        state: generalData?.state?.id,
        city: generalData?.city?.id,
        isShowGoogleComplianceBtn: checked,
        addressLine1: generalData?.addressLine1,
        addressLine2: generalData?.addressLine2,
        pincode: generalData?.pincode,
        mobileNo: generalData?.mobilNo || "",
        description: generalData?.description || "",
        isPolicyCheck: isChecked
      };
      if (!edit) {
        body.adminId = loginUser?._id
      }
      if (edit && generalData?._id) {
        body._id = generalData?._id
      }
      try {
        let fn = edit ? editMybusinessGenral(body) : AddMybusinessGenral(body)
        const result = await dispatch(fn);
        const error = ErrorHandler(result);
        if (error) {
          setGeneralData({
            name: "",
            country: "",
            state: "",
            city: "",
            address: "",
            businessType: "",
            googleLocationUrl: "",
            googleReviewsUrl: "",
            mobilNo: "",
            email: "",
            password: "",
            addressLine1: "",
            addressLine2: "",
            pincode: "",
            confirmPassword: "",
            description: ""
          });
          setImageData(null);
          setError({});
          navigate("/myAccount")
          setQrUrl(result?.payload?.data?.qrcodeUrl)
          toast?.success(result?.payload?.message)
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false))
      }
    }
    sessionStoreUtil?.removeLSItem("generalData");
    sessionStoreUtil?.removeLSItem("imageData");
  };

  const _getEditData = async () => {
    dispatch(showLoader(true))
    try {
      const result = await dispatch(getSingleData(businessUpdateID));
      const error = ErrorHandler(result);
      if (error) {
        SetEditdata(result?.payload?.data?.[0])
        SetEdit(true)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // --------------------------------------- useEffect ----------------------------------

  useEffect(() => {
    const savedFormData = sessionStoreUtil.getLSItem("generalData");
    const savedImageData = sessionStoreUtil.getLSItem("imageData");
    if (savedFormData) {
      setGeneralData(JSON.parse(savedFormData));
    }
    if (savedImageData) {
      setImageData(savedImageData);
    }
  }, []);

  useEffect(() => {
    _getCountryList();
  }, []);

  useEffect(() => {
    if (businessUpdateID) {
      _getEditData()
    }
  }, []);

  useEffect(() => {
    if (generalData?.country) {
      _getStateList();
    }
  }, [generalData?.country]);

  useEffect(() => {
    if (generalData?.state) {
      _getCityList();
    }
  }, [generalData?.state]);

  useEffect(() => {
    if (editdata) {
      setGeneralData({
        _id: editdata._id,
        name: editdata?.name,
        country: editdata?.countryDetail,
        state: editdata?.stateDetail,
        city: editdata?.cityDetail,
        businessType: editdata?.businessType,
        googleLocationUrl: editdata?.googleLocationUrl,
        googleReviewsUrl: editdata?.googleReviewsUrl,
        mobilNo: editdata?.mobileNo,
        email: editdata?.email,
        password: editdata?.password,
        addressLine1: editdata?.addressLine1,
        addressLine2: editdata?.addressLine2,
        pincode: editdata?.pincode,
        description: editdata?.description,
        qrRedirectUrl: editdata?.qrRedirectUrl
      })
      setImageData(editdata?.avtar)
      setQrUrl(editdata?.qrcodeUrl)
      setChecked(editdata?.isShowGoogleComplianceBtn)
      setIsChecked(editdata?.isPolicyCheck)
    }
  }, [editdata]);
  return (
    <>
      <Paper
        sx={{
          padding: { xs: "20px", sm: "32px" },
          backgroundColor: "rgba(19, 19, 19, 0.6)",
          backgroundImage: "none",
          borderRadius: "26px",
        }}
      >
        <Grid container xs={12} spacing={2} className={classes.mainGridContainer}>
          <Grid item container spacing={2} xs={12} sm={12} md={12} lg={7} xl={8}>
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={{ xs: "column", sm: "row" }} gap={1} >
                <Box width={"100%"} position={"relative"}>
                  <DocumentsUpload
                    valid
                    titleHead={"Business logo"}
                    title={"Choose file No chosen file"}
                    handleFileChange={(e) => {
                      const file = e.target.files[0];
                      handleUpload(file, "image");
                    }}
                  />
                </Box>
                <Box mt={{ xs: 1, sm: 3.5 }}>
                  {imageData && imageData !== null && (
                    <img
                      src={BucketURL + imageData}
                      alt="logo"
                      className={classes.avatar}
                    />
                  )}
                </Box>
              </Box>
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!imageData ? error?.imageData : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CommonTextField
                valid
                text={"Business name"}
                type={"text"}
                placeholder={"Enter your business name"}
                name={"name"}
                onChange={handleChangegenral}
                value={generalData?.name}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!generalData?.name ? error?.name : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                valid
                text={"Business Type"}
                type={"text"}
                placeholder={"Type your Business"}
                name={"businessType"}
                onChange={handleChangegenral}
                value={generalData?.businessType}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!generalData?.businessType ? error?.businessType : ""}
              />
            </Grid>

            <Grid item xs={12} mb={11}>
              <CommonTextField
                text={"Description"}
                type={"text"}
                multiline
                rows={4}
                placeholder={"Enter your business description"}
                name={"description"}
                onChange={(e) => {
                  const { name, value } = e.target;
                  setGeneralData((preventvalue) => ({
                    ...preventvalue,
                    [name]: value?.trimStart(),
                  }))
                }
                }
                value={generalData?.description}
                borderRadius={"20px"}
              />
            </Grid>
            <Grid container item xs={12} sm={12} spacing={2}>

              <Grid item xs={12} sm={6} >
                <CommonTextField
                  valid
                  text={"Address line 1"}
                  type={"text"}
                  multiline
                  rows={2}
                  placeholder={"Enter your Address line 1"}
                  name={"addressLine1"}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setGeneralData((preventvalue) => ({
                      ...preventvalue,
                      [name]: value?.trimStart(),
                    }))
                  }
                  }
                  value={generalData?.addressLine1}
                  borderRadius={"20px"}
                />
                <TextLabel
                  marginTop={6}
                  fontSize={"12px"}
                  color={"error"}
                  title={!generalData?.addressLine1 ? error?.addressLine1 : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6} >
                <CommonTextField
                  valid
                  text={"Address line 2"}
                  type={"text"}
                  multiline
                  rows={2}
                  placeholder={"Enter your Address line 2"}
                  name={"addressLine2"}
                  onChange={
                    (e) => {
                      const { name, value } = e.target;
                      setGeneralData((preventvalue) => ({
                        ...preventvalue,
                        [name]: value?.trimStart(),
                      }))
                    }
                  }
                  value={generalData?.addressLine2}
                  borderRadius={"20px"}
                />
                <TextLabel
                  marginTop={6}
                  fontSize={"12px"}
                  color={"error"}
                  title={!generalData?.addressLine2 ? error?.addressLine2 : ""}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <CommonTextField
                valid
                text={"Pincode"}
                type={"text"}
                placeholder={"Enter pincode"}
                name={"pincode"}
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 6) {
                    setGeneralData((prevValue) => ({
                      ...prevValue,
                      pincode: value,
                    }));
                  }
                }}
                value={generalData?.pincode}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={
                  !generalData?.pincode ? error?.pincode : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <AutoCompleteSearch
                valid
                text={"Country"}
                options={countryData}
                placeholder={"Enter your country"}
                handleChange={(e, newValue) => {
                  setGeneralData({
                    ...generalData,
                    country: newValue,
                    state: "",
                    city: "",
                  });
                }}
                fullWidth
                defaultValue={generalData?.country?.label}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!generalData?.country ? error?.country : ""}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <AutoCompleteSearch
                valid
                text={"State"}
                options={stateData}
                placeholder={"Enter your state"}
                handleChange={(e, newValue) => {
                  setGeneralData({
                    ...generalData,
                    state: newValue,
                    city: "",
                  });
                }}
                fullWidth
                disabled={!generalData?.country}
                defaultValue={generalData?.state?.label}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!generalData?.state ? error?.state : ""}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <AutoCompleteSearch
                valid
                text={"City"}
                options={cityData}
                placeholder={"Enter your city"}
                handleChange={(e, newValue) => {
                  setGeneralData({
                    ...generalData,
                    city: newValue,
                  });
                }}
                fullWidth
                disabled={!generalData?.state}
                defaultValue={generalData?.city?.label}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!generalData?.city ? error?.city : ""}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
              <CommonTextField
                valid
                text={"Business google location link"}
                type={"text"}
                placeholder={"Enter google location"}
                name={"googleLocationUrl"}
                onChange={handleChangegenral}
                value={generalData?.googleLocationUrl}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={
                  !generalData?.googleLocationUrl ? error?.googleLocationUrl : ""
                }
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={
                  generalData?.googleLocationUrl && !generalData?.googleLocationUrl?.match(Regex?.googleLocationLink)
                    ? error?.invalidGoogleLocationUrl
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                valid
                text={"Google Review URL"}
                type={"text"}
                placeholder={"Google Review URL"}
                name={"googleReviewsUrl"}
                onChange={handleChangegenral}
                value={generalData?.googleReviewsUrl}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={
                  !generalData?.googleReviewsUrl ? error?.googleReviewsUrl : ""
                }
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                // title={
                //   !generalData?.googleReviewsUrl ? error?.googleReviewsUrl : ""
                // }
                title={
                  generalData?.googleReviewsUrl && !generalData?.googleReviewsUrl?.match(Regex?.googleReviewsUrl)
                    ? error?.invalidGoogleReviewsUrl
                    : ""
                }
              />
              {/* <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={generalData?.googleReviewsUrl ?
                  !generalData?.googleReviewsUrl?.match(Regex?.googleReviewsUrl)
                    ? error?.invalidgoogleReviewsUrl
                    : "" : ""
                }
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CommonTextField
                text={"Mobile no"}
                type={"number"}
                placeholder={"Please enter business number"}
                name={"mobilNo"}
                onChange={(e) => {
                  const { value } = e.target;
                  if (/^(?!0{2,})\d*$/.test(value) && value.length <= 10) {
                    setGeneralData((prevValue) => ({
                      ...prevValue,
                      mobilNo: value,
                    }));
                  }
                }}
                value={generalData?.mobilNo}
                inputProps={{
                  maxLength: 10,
                }}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={generalData?.mobilNo ? error?.mobilNo : ""}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={
                  generalData?.mobilNo ? !generalData?.mobilNo?.match(Regex?.mobileNumberRegex)
                    ? error?.invalidMobile
                    : "" : ""
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CommonTextField
                disabled={editdata}
                valid
                text={"Email"}
                type={"text"}
                placeholder={"Enter your business email"}
                name={"email"}
                onChange={handleChangegenral}
                value={generalData?.email}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!generalData?.email ? error?.email : ""}
              />
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={
                  !generalData?.email?.match(Regex?.emailRegex)
                    ? error?.invalidEmail
                    : ""
                }
              />
            </Grid>
            {editdata ? <></> : (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <CommonTextField
                    valid
                    text={"Password"}
                    type={"password"}
                    placeholder={"Please enter strong password"}
                    name={"password"}
                    onChange={handleChangegenral}
                    value={generalData?.password}
                    showPasswordToggle
                  />
                  <TextLabel
                    marginTop={1}
                    fontSize={"12px"}
                    color={"error"}
                    // title={ 
                    //   generalData?.password !== generalData?.confirmPassword
                    //     ? error?.confirmPasswordNotSame
                    //     : ""
                    // }
                    title={generalData?.password ? "" : error?.password}
                  />
                  {(error?.password || error?.invalidPassword || error?.passwordMatch) && (
                    <TextLabel
                      marginTop={1}
                      fontSize="12px"
                      color="error"
                      title={
                        generalData?.password && !generalData?.password?.match(Regex?.passwordRegex)
                          ? "Password must be at least 8 characters, with uppercase, lowercase, number, and special character"
                          : ""
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <CommonTextField
                    valid
                    text={"Confirm Password"}
                    type={"password"}
                    placeholder={"Please enter confirm password"}
                    name={"confirmPassword"}
                    onChange={handleChangegenral}
                    value={generalData?.confirmPassword}
                    showPasswordToggle
                  />
                  <TextLabel
                    marginTop={1}
                    fontSize={"12px"}
                    color={"error"}
                    title={!generalData?.confirmPassword ? error?.confirmPassword : ""}
                  />
                  <TextLabel
                    marginTop={1}
                    fontSize={"12px"}
                    color={"error"}
                    title={(generalData?.password !== generalData?.confirmPassword) ? error?.confirmPasswordNotSame : ""}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} mt={"26PX"} display={"flex"} gap={1} flexWrap={"wrap"} alignItems={"flex-start"}>
              <Box sx={{ maxWidth: "950px", marginTop: "2px" }}>
                <TextLabel
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"16.71px"}
                  color={"#777777"}
                  title={
                    "Streamline your complaint management by customizing submission visibility, balancing transparency and control to meet your business objectives effectively."
                  } />
              </Box>
              <CustomSwitch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>

            {<Grid item xs={12} mt={"12PX"} display={"flex"} flexWrap={"wrap"} alignItems={"flex-start"}>
              <Box>
                <GradientIcon />
                <FormControlLabel
                  control={
                    <GradientCheckbox
                      checked={isChecked}
                      onChange={handleChangeCheckBox}
                      sx={{
                        color: "#777777",
                        "&.Mui-checked": {
                          color: "#777777",
                          fontWeight: 400,
                        },
                      }}
                    />
                  }
                  sx={{
                    color: "#777777",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16.71px",
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      lineHeight: "16.71px",
                      fontWeight: 400,
                    },
                  }}
                  label={
                    // <span> I agree
                    //   <a href="/privacyPolicy" className={classes.highlightLink} style={{ marginLeft: "5px" }}>
                    //     <strong> Privacy Policy  </strong>
                    //   </a>
                    //   and
                    //   <a href="/TermsandConditions" className={classes.highlightLink} style={{ marginLeft: "5px" }}>
                    //     <strong> Terms and Conditions</strong>
                    //   </a>.
                    // </span>
                    <span>
                      I agree
                      <a
                        // href="#"
                        className={classes.highlightLink}
                        style={{ marginLeft: "5px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          redirectWithState("/privacyPolicy");
                        }}
                      >
                        <strong> Privacy Policy </strong>
                      </a>
                      and
                      <a
                        // href="#"
                        className={classes.highlightLink}
                        style={{ marginLeft: "5px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          redirectWithState("/TermsandConditions");
                        }}
                      >
                        <strong> Terms and Conditions</strong>
                      </a>
                    </span>
                  }
                />
                <TextLabel
                  marginTop={{ xs: 1, sm: 0 }}
                  fontSize={"12px"}
                  color={"error"}
                  title={!isChecked ? error?.isChecked : ""}
                />
              </Box>
            </Grid>}

            <Grid item xs={12} sm={12} display={"flex"} justifyContent={"flex-end"} mt={{ xs: "10px", sm: "17px" }}>
              <CommonButton
                padding={"12px 10px"}
                className="primary"
                borderRadius={"30px"}
                text={edit ? "Update" : "Save"}
                maxWidth={"144px"}
                onClick={_addGenral}
              />
            </Grid>
          </Grid>
          <Grid item spacing={0} container xs={12} sm={12} md={12} lg={5} xl={4} justifyContent={"center"} maxHeight={"734px"}>
            <Grid padding={"0px !important"} item xs={12} sm={12} md={12} lg={12} xl={12} maxWidth={"290px !important"} justifyContent={"center"}
              sx={{
                backgroundColor: "#1F1F1F99",
                borderRadius: "15px",
                padding: { xs: "12px", sm: "25px", md: "30px", lg: "40px" },
                textAlign: "center",
                color: "#fff",
                zIndex: "1",
                margin: "0 auto",
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h5">
                  QR code
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"20px 0"} padding={"10px"}>
                {qrUrl ? <Box
                  component="img"
                  src={BucketURL + qrUrl}
                  alt="QR Code"
                  sx={{
                    width: { xs: '175px', sm: '200px' },
                    height: { xs: '175px', sm: '200px' },
                    borderRadius: "10px",
                  }}
                /> : <Skeleton variant="rectangular" width={"175px"} height={"175px"} />
                }
              </Grid>
              <Grid item xs={12} mb={"30px"}>
                <Typography variant="body2" gutterBottom>
                  One-time barcodes are unique, single-use codes that are designed to be scanned only once. They are commonly used in various applications where security, authenticity, and tracking are essential.
                </Typography>
              </Grid>
              {editdata && generalData?.qrRedirectUrl && <Grid item xs={12} mb={2}>
                {/* <Box
                  maxWidth="100%"
                  display="flex"
                  flexWrap="wrap"
                  
                > */}
                <Tooltip title="Copy URL">
                  <IconButton onClick={handleCopyUrl} size="small">
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <TextLabel
                  color="white"
                  fontSize="10px"
                  title={generalData?.qrRedirectUrl}
                  noWrap={true}
                />

                {/* </Box> */}
              </Grid>}
              <Grid item xs={12} mb={2}>
                <CommonButton disabled={!qrUrl} disabledWithColor={!qrUrl} text={"Downoad QR"} className={"primary"} onClick={() => downloadQRImage(qrUrl)} />
              </Grid>
              <Grid item xs={12}>
                <CommonButton disabled={!qrUrl} text={"Download Banner"} className={"secondary"} onClick={() => setOpenBannerModal(true)} />
              </Grid>
            </Grid>
          </Grid>
          <BackgroundImage
            src="/images/backgroundforcreatebusiness.svg"
            alt="company logo as a backgroud"
          />
        </Grid>
      </Paper>


      <CommonModal
        open={openBannerModal}
        onClose={() => handleCloseForBannerModal()}
        title={"Banner details"}
        maxWidth={'md'}
        content={
          <Grid
            container
            padding={{ xs: "12px 16px", sm: "29px 41px" }}
            sx={{ background: "#13131399" }}
            spacing={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={5} container spacing={2}>
              <Grid item xs={12}>
                <CommonTextField
                  text="Company Name"
                  color="rgba(171, 171, 171, 1)"
                  type="text"
                  name="CompanyName"
                  value={generalData?.name || "harsh jivani"}
                  disabled
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <CommonTextField
                  text="Offer details"
                  color="rgba(171, 171, 171, 1)"
                  type="text"
                  placeholder="Add your offer details"
                  name="description"
                  onChange={handleChangeForBannerModal}
                  value={bannerData?.description}
                  multiline
                  inputProps={{ maxLength: 20 }}
                  rows={5}
                  borderRadius={"10px"}
                />
              </Grid>
              <Grid item xs={12} mb={"36px"} >
                <Typography variant="body1">
                  Select a Banner
                </Typography>
                <RadioGroup value={bannerShape} onChange={handleBannerChange} row>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                    <FormControlLabel value="rectangle" control={<Radio
                      sx={{
                        color: "#D9D9D91A",
                        '&.Mui-checked': {
                          color: "#434343",
                        },
                      }}
                    />} label="Rectangle"
                      sx={{
                        color: "#FFFFFF33"
                      }} />
                    <img src="/images/rectengleBanner.svg" alt="Rectangle banner" style={{ maxWidth: "36px", height: "59px" }} />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                    <FormControlLabel value="round" control={<Radio
                      sx={{
                        color: "#D9D9D91A",
                        '&.Mui-checked': {
                          color: "#434343",
                        },
                      }}
                    />} label="Round"
                      sx={{
                        color: "#FFFFFF33"
                      }} />
                    <img src="/images/roundBanner.svg" alt="Round banner" style={{ maxWidth: "61px", height: "61px" }} />
                  </Box>
                </RadioGroup>
              </Grid>
              <Grid item xs={12} display={"flex"}
                sx={{
                  justifyContent: { xs: "center", sm: "center", md: "start", lg: "start" },
                  marginBottom: { xs: "20px", sm: "20px", md: "0px", lg: "0px" }
                }}>
                <CommonButton
                  text="Download banner"
                  maxWidth="226px"
                  className="primary"
                  padding="12px 16px"
                  onClick={downloadImage}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={9} md={7} display={"flex"} alignItems={"center"} justifyContent={"center"}>
              {bannerShape === 'rectangle' ? (
                <Box
                  ref={bannerRef} // Attach the ref here
                  sx={{
                    position: "relative",
                    maxWidth: "350px", // 378px
                    borderRadius: "26px",
                    padding: "4px",
                    height: "550px",
                    background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                  }}
                >
                  <Box
                    sx={{
                      background: "#1F1F1F",
                      borderRadius: "inherit",
                      padding: { xs: "40px 16px", md: "40px 40px 80px" },
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"0px"}>
                        <Box display="flex" alignItems="center" gap={1}>
                          {/* <img src={imageData ? BucketURL + imageData : "/images/logo.svg"} alt="Logo" style={{ width: "40px", height: "40px", borderRadius: "50%" }} /> */}
                          <Typography fontSize={"21.52px"} lineHeight={"29.59px"} fontWeight={700} color={"#FFFFFF"}>{truncateName(generalData?.name?.toUpperCase()) || "Company Name"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"15px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"18px"} lineHeight={"22px"} color={"#FFFFFF80"}>
                          Tap Your Smart Phone or Scan here
                        </Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"10px"}>
                        <Box component="img" src="images/leftWifi.svg" alt="Wifi icon" width={"56px"} height={"36px"} />
                        <Box component="img" src={qrUrl ? BucketURL + qrUrl : "images/qrCode.svg"} alt="QR Code" width={"100px"} height={"100px"} />
                        <Box component="img" src="images/rightWifi.svg" alt="Wifi icon" width={"56px"} height={"36px"} />
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"15px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"16.91px"} lineHeight={"26.57px"} color={"#FFFFFF80"}>Review By Google</Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" flexDirection={"column"} gap={"10px"} mb={"30px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"20px"} lineHeight={"22px"} fontWeight={700} color={"#FFFFFF"}>{bannerData?.description || ""}</Typography>
                        <Box display="flex" gap={1}>
                          {Array(5).fill(0).map((_, index) => (
                            <Box key={index} component="img" src="images/starIconForBanner.svg" alt="Star" width={"24px"} height={"24px"} />
                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" flexDirection={"column"} gap={"2px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"14px"} lineHeight={"22px"} color={"#FFFFFF80"}>Present by</Typography>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"22px"} lineHeight={"22px"} color={"#FFFFFF"}>The AI Review</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Box
                  ref={bannerRef} // Attach the ref here
                  sx={{
                    position: "relative",
                    borderRadius: "50%",
                    padding: "4px",
                    background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                    maxWidth: "593px",
                    maxHeight: "593px"
                  }}
                >
                  <Box
                    sx={{
                      background: "#1F1F1F",
                      borderRadius: "inherit",
                      padding: { xs: "40px 16px", md: "50px" },
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"5px"}>
                        <Box display="flex" alignItems="center" gap={1}>
                          {/* <img src={imageData ? BucketURL + imageData : "/images/logo.svg"} alt="Logo" style={{ maxWidth: "40px", height: "40px", borderRadius: "50%" }} /> */}
                          <Typography fontSize={"18px"} lineHeight={"29.59px"} fontWeight={600}>{truncateName(generalData?.name?.toUpperCase()) || "Company Name"}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"5px"}>
                        <Typography maxWidth="200px" textAlign="center" fontSize={"16px"} lineHeight={"20px"} color={"#FFFFFF80"}>
                          Tap Your Smart Phone or Scan here
                        </Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"5px"}>
                        <Box component="img" src="images/leftWifi.svg" alt="Wifi icon" width={"56px"} height={"36px"} />
                        <Box component="img" src={qrUrl ? BucketURL + qrUrl : "images/qrCode.svg"} alt="QR Code" width={"90px"} height={"90px"} />
                        <Box component="img" src="images/rightWifi.svg" alt="Wifi icon" width={"56px"} height={"36px"} />
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" mb={"0px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"14.91px"} lineHeight={"23.57px"} color={"#FFFFFF80"}>Review By Google</Typography>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" flexDirection={"column"} gap={"10px"} mb={"5px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"18px"} lineHeight={"20px"} fontWeight={600} color={"#FFFFFF"}>{bannerData?.description || ""}</Typography>
                        <Box display="flex" gap={1}>
                          {Array(5)?.fill(0)?.map((_, index) => (
                            <Box key={index} component="img" src="images/starIconForBanner.svg" alt="Star" width={"24px"} height={"24px"} />
                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" flexDirection={"column"} gap={"2px"}>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"14px"} lineHeight={"22px"} color={"#FFFFFF80"}>Present by</Typography>
                        <Typography maxWidth="180px" textAlign="center" fontSize={"18px"} lineHeight={"20px"} color={"#FFFFFF"}>The AI Review</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        }
      />
    </ >
  );
};

export default MyBusiness;