import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Typography, Button, Popover } from '@mui/material';
import { Calendar } from 'lucide-react';
import format from 'date-fns/format';


// Styled components
const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#27272A',
    borderRadius: '30px',
    padding: '8px 16px',
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#3F3F46',
    },
}));

const SitePerformanceDatePicker = () => {
    const [startDate, setStartDate] = useState(new Date(2024, 7, 12));
    const [endDate, setEndDate] = useState(new Date(2024, 7, 13));
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const formatDateRange = () => {
        return `${format(startDate, 'd MMM yyyy')} - ${format(endDate, 'd MMM yyyy')}`;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, }}>
                <Box>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#CCC9C9',
                            fontSize: '16px',
                            fontWeight: 500
                        }}
                    >
                        Site performance
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: '#5F616C',
                            opacity: 0.6,
                            fontSize: '12px'
                        }}
                    >
                        Today vs 7 days ago
                    </Typography>
                </Box>

                <StyledButton
                    onClick={handleClick}
                    startIcon={<img src='/images/Calender.svg' />}
                >
                    {formatDateRange()}
                </StyledButton>

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: '#27272A',
                            mt: 1,
                            '& .MuiPickersLayout-root': {
                                bgcolor: '#27272A',
                                color: 'white',
                            },
                            '& .MuiPickersCalendarHeader-label': {
                                color: 'white',
                            },
                            '& .MuiIconButton-root': {
                                color: 'white',
                            },
                            '& .MuiDayCalendar-weekDayLabel': {
                                color: 'white',
                            },
                            '& .MuiPickersDay-root': {
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#3F3F46',
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#FBBF24',
                                    color: '#000000',
                                    '&:hover': {
                                        backgroundColor: '#F59E0B',
                                    },
                                },
                            },
                            '& .MuiPickersDay-today': {
                                border: '1px solid #FBBF24',
                            },
                        }
                    }}
                >
                    <Box sx={{ p: 2, display: 'flex', gap: 2 }}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            sx={{
                                '& .MuiInputLabel-root': { color: 'white' },
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': { borderColor: 'white' },
                                },
                            }}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            sx={{
                                '& .MuiInputLabel-root': { color: 'white' },
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': { borderColor: 'white' },
                                },
                            }}
                        />
                    </Box>
                </Popover>
            </Box>
        </LocalizationProvider>
    );
};

export default SitePerformanceDatePicker;