import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import CommonSelect from '../../CommonSelect/CommonSelect';

const CommonBarChart = ({
    data,
    barNumbers,
    title,
    selectedValue,
    handleChange,
    options,
    countries,
    selectedCountry,
    handleCountryChange,
    height,
    showLabels = false,
    showCountrySelect = false,
    showMonthlyOption = false,
    useGradient = true,
    labels = {},
    showGrid = true,
    color1 = [],
    color2 = [],
    gradientIds = ['gradient1', 'gradient2']
}) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const containerRef = useRef(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:600px) and (max-width:1000px)');

    // Responsive calculations
    // const calculateFontSize = (width) => (width < 400 ? 8 : width < 600 ? 10 : 12);
    const calculateFontSize = (width) => {
        if (isTablet) return 8;
        return width < 400 ? 8 : width < 600 ? 10 : 12;
    };
    // const calculateWrapLength = (width) => (width < 400 ? 8 : width < 600 ? 12 : 15);
    const calculateWrapLength = (width) => {
        if (isTablet) return 10;
        return width < 400 ? 8 : width < 600 ? 12 : 15;
    };

    const updateDimensions = useCallback(() => {
        if (containerRef?.current) {
            setContainerWidth(containerRef?.current?.offsetWidth);
        }
    }, []);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    const wrapText = (text, containerWidth) => {
        if (isTablet) {
            const words = text.split(' ');
            if (words.length > 1) {
                return [words[0], words.slice(1).join(' ')];
            }
            return [text];
        }

        const wrapLength = calculateWrapLength(containerWidth);
        if (text.length > wrapLength) {
            const breakPoint = text.lastIndexOf(' ', wrapLength);
            if (breakPoint > 0) {
                return [text.substring(0, breakPoint), text.substring(breakPoint + 1)];
            }
        }
        return [text];
    };

    const barColors1 = Array.isArray(color1) ? color1 : [color1];
    const barColors2 = Array.isArray(color2) ? color2 : [color2];

    const getColor = (colors, index, dataKey) => {
        if (barNumbers === 2) return dataKey === 'Data1' ? barColors1[0] : barColors2[0];
        if (Array.isArray(colors)) return colors[index % colors.length];
        return colors;
    };

    const processedData = React.useMemo(() => {
        return data?.map((item, index) => ({
            ...item,
            bar1Color: getColor(barColors1, index, 'Data1'),
            bar2Color: getColor(barColors2, index, 'Data2')
        }));
    }, [data, barColors1, barColors2, barNumbers]);

    const CustomBar = (props) => {
        const { x, y, width, height: barHeight, dataKey, index } = props;
        const colorKey = barNumbers === 2 && dataKey === 'Data2'
            ? 'bar2Color'
            : 'bar1Color';
        const currentColor = processedData[index][colorKey];
        const gradientId = `${dataKey === 'Data1' ? gradientIds[0] : gradientIds[1]}-${index}`;

        return (
            <g>
                <defs>
                    <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor={currentColor} stopOpacity={1} />
                        <stop offset="100%" stopColor={currentColor} stopOpacity={0} />
                    </linearGradient>
                </defs>
                <path
                    d={`M ${x},${y + 10} Q ${x},${y} ${x + 10},${y} L ${x + width - 10},${y} Q ${x + width},${y} ${x + width},${y + 10} L ${x + width},${y + barHeight} L ${x},${y + barHeight} Z`}
                    fill={useGradient ? `url(#${gradientId})` : currentColor}
                />
            </g>
        );
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            return (
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '8px',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px'
                }}>
                    <Typography
                        sx={{
                            color: '#141414',
                            fontSize: '12px'
                        }}
                    >
                        {payload[0]?.payload?.month}
                    </Typography>
                    {payload?.map((entry, index) => (
                        <Typography
                            key={index}
                            sx={{
                                color: '#141414',
                                fontSize: '12px'
                            }}
                        >
                            {entry?.value}
                        </Typography>
                    ))}
                </Box>
            );
        }
        return null;
    };

    return (
        <Box sx={{
            backgroundColor: '#141414',
            padding: '15px',
            borderRadius: '20px',
            width: '100%',
        }}>
            <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
                <Typography sx={{ color: '#CCC9C9', fontSize: '16px', fontWeight: 700, marginRight: '10px' }}>{title}</Typography>
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : 'flex-end'}>
                    {showMonthlyOption && (
                        <CommonSelect value={selectedValue} onChange={handleChange} options={options} />
                    )}
                    {showCountrySelect && (
                        <Box ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
                            <CommonSelect value={selectedCountry} onChange={handleCountryChange} options={countries} />
                        </Box>
                    )}
                </Box>
                {/* <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection='row' ml='auto'>
                    {!isMobile && showLabels && (
                        <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center" mr={1}>
                                <span style={{
                                    width: '10.939px',
                                    height: '10px',
                                    flexShrink: 0,
                                    background: 'linear-gradient(180deg, #F4A606 0%, #131313 100%)',
                                    marginRight: '5px'
                                }} />
                                <Typography variant="body2" sx={{ color: '#FFFFFF', fontSize: '14px' }}>{labels?.label1}</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <span style={{
                                    width: '10.939px',
                                    height: '10px',
                                    flexShrink: 0,
                                    background: 'linear-gradient(180deg, #FD63C5 0%, #131313 100%)',
                                    marginRight: '5px'
                                }} />
                                <Typography variant="body2" sx={{ color: '#FFFFFF', fontSize: '14px' }}>{labels?.label2}</Typography>
                            </Box>
                        </Box>
                    )}
                </Box> */}
            </Box>

            <Box
                ref={containerRef}
                sx={{
                    overflowX: isMobile ? 'auto' : 'hidden',
                }}
            >

                <Box width={isMobile ? '800px' : '100%'}>
                    <ResponsiveContainer width="100%" height={height}>
                        <BarChart
                            data={processedData}
                            margin={{
                                top: 15,
                                right: 30,
                                bottom: containerWidth < 600 ? -35 : -20
                            }}
                            sx={{ color: '#FFFFFF' }}
                        >
                            {showGrid && <CartesianGrid vertical={false} strokeDasharray="3 3" opacity={0.2} />}
                            <XAxis
                                dataKey="month"
                                height={containerWidth < 600 ? 80 : 60}
                                tick={(props) => {
                                    const { x, y, payload } = props;
                                    const lines = wrapText(payload.value, containerWidth);
                                    const fontSize = calculateFontSize(containerWidth);
                                    const lineHeight = fontSize * 1.5;
                                    return (
                                        <g transform={`translate(${x},${y})`}>
                                            {lines.map((line, i) => (
                                                <text key={i} x={0} y={10} dy={i * lineHeight} textAnchor="middle" fill="#666" fontSize={fontSize}>
                                                    {line}
                                                </text>
                                            ))}
                                        </g>
                                    );
                                }}
                                interval={0}
                            />
                            <YAxis sx={{ color: '#FFFFFF' }} tick={{ fontSize: calculateFontSize(containerWidth) }} />
                            {/* <Tooltip cursor={false} contentStyle={{ color: '#141414' }} /> */}
                            <Tooltip
                                cursor={false}
                                content={<CustomTooltip />}
                                contentStyle={{ color: '#141414' }}
                            />
                            {barNumbers >= 1 && (
                                <Bar
                                    dataKey="Data1"
                                    shape={<CustomBar />}
                                    barSize={20}
                                    isAnimationActive={false}
                                />
                            )}
                            {barNumbers === 2 && (
                                <Bar
                                    dataKey="Data2"
                                    shape={<CustomBar />}
                                    barSize={20}
                                    isAnimationActive={false}
                                />
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
            {showLabels && (
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <Box display="flex" alignItems="center" mr={2}>
                        <span style={{
                            width: '10.939px',
                            height: '10px',
                            flexShrink: 0,
                            background: 'linear-gradient(180deg, #F4A606 0%, #131313 100%)',
                            marginRight: '5px'
                        }} />
                        <Typography variant="body2" sx={{ color: '#FFFFFF' }}>{labels?.label1}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {/* <span style={{
                                backgroundColor: barColors2[0],
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                marginRight: '5px'
                            }}></span> */}
                        <span style={{
                            width: '10.939px',
                            height: '10px',
                            flexShrink: 0,
                            background: 'linear-gradient(180deg, #FD63C5 0%, #131313 100%)',
                            marginRight: '5px'
                        }} />
                        <Typography variant="body2" sx={{ color: '#FFFFFF' }}>{labels?.label2}</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CommonBarChart;