import { Box, Button, Dialog, DialogActions, DialogTitle, styled, Typography } from '@mui/material';
import React from 'react'
import { makeStyles } from 'tss-react/mui';
import CommonButton from './Button/CommonButton';


const useStyles = makeStyles()((theme) => {
    return {
        dialog_Main: {
            '& .MuiDialog-paper.MuiPaper-rounded': {
                borderRadius: '26px',
                overflow: 'hidden',
                padding: "48px 14px !important",
                backgroundImage: "none"
            },
            "& .MuiDialogTitle-root": {
                backgroundColor: "#13131399",
                fontSize: "20px",
                [theme.breakpoints.down('sm')]: {
                    padding: "12px 16px !important"
                }
            }
        }

    };
});


const CommonConformationModal = ({ onClose, open, maxWidth, question, isDelete, onSubmit }) => {
    const { classes } = useStyles();
    return (
        <Dialog
            fullWidth
            onClose={onClose}
            open={open}
            className={classes.dialog_Main}
            maxWidth={maxWidth}
            sx={{ padding: "10px" }}
        >
            <Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} marginBottom={"23px"}>
                    {isDelete ? <img src='/images/DeleteIconForModal.svg' alt='delete icon' /> : <img src='/images/questionMarkIconForModal.svg' alt='questionmark icon' />}
                </Box>
                <Typography
                    fontSize={"22px"}
                    fontWeight={700}
                    lineHeight={"20px"}
                    textAlign="center"
                    color={"#FFFFFF"}
                    mb={"16px"}
                >
                    {isDelete ? "Delete Permanently?" : "Are you sure you want to proceed?"}
                </Typography>
                <Box display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}>
                    <Typography
                        fontSize={"14px"}
                        fontWeight={400}
                        lineHeight={"18px"}
                        textAlign="center"
                        color={"#FFFFFF66"}
                        mb={"34px"}
                        maxWidth={"346px"}
                    >
                        {question || "Once confirmed, this action will be permanent. Are you certain you want to proceed?"}
                    </Typography>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <CommonButton onClick={onClose} text={"Cancel"} className={"secondary"} maxWidth={"105px"} />
                    <CommonButton onClick={onSubmit} text={isDelete ? "Delete" : "Confirm"} className={"primary"} maxWidth={"105px"} fontWeight={700}/>
                </DialogActions>
            </Box>
        </Dialog >
    )
}

export default CommonConformationModal