export const setBusiness = (business) => ({
  type: "SET_BUSINESS",
  payload: business,
});

// reducer.js
const initialState = {
  business: null,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BUSINESS":
      return { ...state, business: action.payload };
    default:
      return state;
  }
};

export default businessReducer;