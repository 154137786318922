import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const getColorStyles = (label) => {
    const commonStyles = {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16.71px",
        border: "none",
        height: "37px",
        maxWidth: "116px",
        padding: "10px"
    };

    if (label === "Resolve") {
        return {
            ...commonStyles,
            bgcolor: "#05EF630F",
            color: "#05EF6399",
            borderColor: "#05EF630F",
        };
    } else if (label === "Pending") {
        return {
            ...commonStyles,
            bgcolor: "#ECF15E0F",
            color: "#F4A60699",
            borderColor: "#ECF15E0F",
        };
    } else if (label === "In Progress") {
        return {
            ...commonStyles,
            bgcolor: "#2F251566",
            color: "#FF620099",
            borderColor: "#2F251566",
        };
    }
    else if (label === "Cancelled") {
        return {
            ...commonStyles,
            bgcolor: "#FF2E000F",
            color: "#FF000099",
            borderColor: "#FF2E000F",
        };
    }

    return commonStyles;
};


const CommonChep = ({ label }) => {
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Chip
                    label={label}
                    variant="outlined"
                    sx={getColorStyles(label)}
                />
            </Stack>
        </>
    )
}

export default CommonChep
