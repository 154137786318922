import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import CommonTextField from '../../../Components/Common/Fields/TextField';
import CommonButton from '../../../Components/Common/Button/CommonButton';
import { useLocation, useNavigate } from 'react-router-dom';
import TextLabel from '../../../Components/Common/Fields/TextLabel';
import { Regex } from '../../../Utils/regex';
import { createComplain, getBusinessDetails } from '../../../Redux/Actions/action';
import { useDispatch } from 'react-redux';
import ErrorHandler from '../../../Utils/handler';
import { showLoader } from '../../../Redux/Slices/loaderSlice';

const useStyles = makeStyles()((theme) => ({
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginBottom: "20px",
    },
    formContainer: {
        marginBottom: "46px",
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",

    },
    imageContainer: {
        marginBottom: "13px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const FeedbackForm = () => {
    const { classes } = useStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const id = new URLSearchParams(useLocation().search).get('id');


    const [data, setData] = useState({})
    const [error, setError] = useState({})

    const handleChange = (event) => {
        const { name, value } = event.target
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handleValidation = () => {
        let flag = true;
        let errors = {};

        if (!data?.feedback || data?.feedback === "") {
            flag = false;
            errors["feedback"] = "This field is required!";
        }
        if (!data?.name || data?.name === "") {
            flag = false;
            errors["name"] = "This field is required!";
        } else if (!data?.name?.match(Regex?.fullNameRegex)) {
            flag = false;
            errors["invalidName"] = "Please enter valid name"
        }
        if (data?.mobileNo && !data?.mobileNo?.match(Regex?.mobileNumberRegex)) {
            flag = false;
            errors["invalidMobile"] = "Please enter valid phone number"
        }
        if (!data?.email || data?.email === "") {
            flag = false;
            errors["email"] = "This field is required!";
        } else if (!data?.email?.match(Regex?.emailRegex)) {
            flag = false;
            errors["invalidEmail"] = "Invalid email address";
        }
        setError(errors)
        return flag;
    }

    const _getBusinessProfile = async () => {
        dispatch(showLoader(true))
        let body = {
            _id: id,
            pageName:"feedback"
        };
        try {
            const result = await dispatch(getBusinessDetails(body))
            const error = ErrorHandler(result)
            if (error) {
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false))
        }
    }

    const onSubmit = async () => {
        if (handleValidation() && id) {
            dispatch(showLoader(true));
            let body = {
                businessId: id,
                name: data?.name,
                feedback: data?.feedback,
                email: data?.email,
            };
            if (data?.mobileNo) {
                body.mobileNo = data?.mobileNo
            }
            try {
                const result = await dispatch(createComplain(body))
                const error = ErrorHandler(result)
                if (error) {
                    navigate("/user/success")
                }
            } catch (error) {
                console.log(error)
            } finally {
                dispatch(showLoader(false));
            }
        }
    }
    useEffect(() => {
        _getBusinessProfile()
    }, [])

    return (
        <>
            <Grid item container>
                <Grid item className={classes?.descriptionContainer}>
                    <Typography fontSize={"26px"} fontWeight={700} color='#FFFFFF' letterSpacing={"-1px"} lineHeight={"31.03px"}
                        sx={{
                            background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}>We’re here to help</Typography>
                    <Typography fontSize={"16px"} fontWeight={400} color='#6B6B6B' lineHeight={"22px"}>Thank you for resolving this privately, which helps us improve. Please provide your contact details if you're comfortable, so we can address any concerns directly.</Typography>
                </Grid>
                <Grid item className={classes?.imageContainer} width={"100%"}>
                    <img src="/images/user/feedbackFormImage.svg" alt="Company branding" maxWidth={"228px"} height={"228px"} />
                </Grid>
                <Grid item container spacing={"19px"} className={classes?.formContainer}>
                    <Grid item xs={12}>
                        <CommonTextField
                            text={"Enter your feedback"}
                            fontSize={"16px"}
                            rows={4}
                            onChange={handleChange}
                            name={"feedback"}
                            value={data?.feedback}
                            type={"text"}
                            multiline
                            valid
                        />
                        <TextLabel
                            marginTop={"90px"}
                            fontSize={"12px"}
                            color={"error"}
                            title={!data?.feedback ? error?.feedback : ""}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonTextField
                            text={"Name"}
                            fontSize={"16px"}
                            onChange={handleChange}
                            name={"name"}
                            type={"text"}
                            value={data?.name}
                            valid
                        />
                        <TextLabel
                            marginTop={1}
                            fontSize={"12px"}
                            color={"error"}
                            title={!data?.name ? error?.name : ""}
                        />
                        <TextLabel
                            marginTop={1}
                            fontSize={"12px"}
                            color={"error"}
                            title={!data?.name?.match(Regex?.fullNameRegex) ? error?.invalidName : ""}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonTextField
                            text={"Phone (optional)"}
                            fontSize={"16px"}
                            onChange={(e) => {
                                const { value } = e.target;
                                if (/^(?!0{2,})\d*$/.test(value) && value.length <= 10) {
                                    setData((prevValue) => ({
                                        ...data,
                                        mobileNo: value,
                                    }));
                                }
                            }}
                            name={"mobileNo"}
                            type={"number"}
                            value={data?.mobileNo}
                        />
                        <TextLabel
                            marginTop={1}
                            fontSize={"12px"}
                            color={"error"}
                            title={!data?.mobileNo?.match(Regex?.mobileNumberRegex) ? error?.invalidMobile : ""}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonTextField
                            text={"Email"}
                            fontSize={"16px"}
                            onChange={handleChange}
                            name={"email"}
                            value={data?.email}
                            valid
                        />
                        <TextLabel
                            marginTop={1}
                            fontSize={"12px"}
                            color={"error"}
                            title={!data?.email ? error?.email : ""}
                        />
                        <TextLabel
                            fontSize={"12px"}
                            color={"error"}
                            title={!data?.email?.match(Regex.emailRegex) ? error.invalidEmail : ""}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes?.buttonContainer}>
                    <CommonButton variant='contained' className='primary' text={"Submit"} fullWidth onClick={onSubmit} />
                </Grid>
            </Grid>
        </>
    )
}

export default FeedbackForm