import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Cell } from 'recharts';
import { styled } from '@mui/material/styles';
import CommonSelect from '../CommonSelect/CommonSelect';
import useMediaQuery from '@mui/material/useMediaQuery';

//------------ Styled components------------

const StyledCard = styled(Box)(({ theme }) => ({
    backgroundColor: '#151515',
    padding: '24px',
    borderRadius: '20px',
    [theme.breakpoints.down('sm')]: {
        padding: '16px',
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    borderRadius: '12px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        overflowX: 'auto', // Enable horizontal scrolling on small screens
        overflowY: 'hidden',
    },
}));

const StyledTypography = styled(Typography)({
    color: '#CCC9C9',
    fontSize: "16px",
    fontWeight: "700",
});

const UserJourneyChart = ({ data, options }) => {
    const [selectedValue, setSelectedValue] = useState('Monthly');
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleChange = (event) => {
        setSelectedValue(event?.target?.value);
    };

    const colors = [
        '#FD63C5',
        '#F4A606',
        '#E39F93',
        '#FA7D00',
        '#FFEFAF',
    ];

    return (
        <StyledCard>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <StyledTypography>
                    User Journey (Last 30 days)
                </StyledTypography>

                <CommonSelect value={selectedValue} onChange={handleChange} options={options} />
            </Box>

            <StyledBox>
                <ResponsiveContainer width={isMobile ? '200%' : '100%'} height={300}>
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid
                            horizontal={false}
                            stroke="#fff"
                            strokeOpacity={0.1}
                        />
                        <XAxis
                            type="number"
                            tick={{ fill: '#B9B2B2' }}
                            axisLine={{ stroke: '#444' }}
                            tickFormatter={(value) => `${value / 1000}K`}
                            style={{
                                color: '#B9B2B2',
                                fontFamily: '"Wanted Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                letterSpacing: '-0.32px'
                            }}
                        />
                        <YAxis
                            type="category"
                            dataKey="name"
                            tick={{ fill: '#B9B2B2' }}
                            axisLine={{ stroke: '#444' }}
                            width={95}
                            style={{
                                color: '#B9B2B2',
                                textAlign: 'right',
                                fontFamily: '"Wanted Sans"',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                letterSpacing: '-0.32px'
                            }}
                        />
                        <Tooltip
                            cursor={false}
                            contentStyle={{
                                backgroundColor: '#141414',
                                borderRadius: '8px',
                                borderColor: '#444',
                            }}
                            labelStyle={{ color: '#f5b942' }}
                            itemStyle={{ color: '#f5b942' }}
                        />
                        <Bar dataKey="value" barSize={20} radius={[0, 10, 10, 0]} >
                            {data?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </StyledBox>
        </StyledCard>
    );
};

export default UserJourneyChart;
