import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, IconButton, Rating, Typography, } from '@mui/material';
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useState } from 'react';
import ErrorHandler from '../../../Utils/handler';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader } from '../../../Redux/Slices/loaderSlice';
import { activateTrial, addNotes, createAdmin, getCities, getCountries, getMyAccount, getStates } from '../../../Redux/Actions/action';
import { BucketURL } from '../../../ApiSetup/Api';
import { makeStyles } from 'tss-react/mui';
import FreeTrial from '../../../Components/Common/icons/freeTrial';
import GuestLogin from '../../../Components/Common/icons/guestLogin';
import CommonModal from '../../../Components/Common/CommonModel';
import CommonTextField from '../../../Components/Common/Fields/TextField';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TextLabel from '../../../Components/Common/Fields/TextLabel';
import CommonButton from '../../../Components/Common/Button/CommonButton';
import { Regex } from '../../../Utils/regex';
import CustomPagination from '../../../Components/Common/CustomPagination';
import { useEffect } from 'react';
import Note from '../../../Components/Common/icons/note';
import CommonTextEditor from '../../../Components/Common/CommonTextEditor';
import { toast } from 'react-toastify';
import CommonSearch from '../../../Components/Common/CommonSearch';
import AutoCompleteSearch from '../../../Components/Common/commonAutoComplete';
import { ratings } from '../../../Utils/enum';

// Custom Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 400,
        fontSize: 16,
        color: "rgba(255, 255, 255, 1)",
        fontFamily: "Wanted Sans",
        whiteSpace: "nowrap",
        padding: "18px 18px", // 28 20,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Wanted Sans",
        fontWeight: 400,
        padding: "15px 20px",
        color: "rgba(163, 158, 158, 1)",
        borderBottom: "1px solid #FFFFFF1A",
        backgroundColor: "#0e0e0e",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const useStyles = makeStyles()((theme) => ({
    // mainGridContainer: {
    //     position: "relative",
    // },
    avatar: {
        height: "60px",
        width: "60px",
        objectFit: "cover",
        borderRadius: "50%",
    },
}));


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `none`,
    '&:not(:last-child)': { borderBottom: 0, },
    '&::before': { display: 'none', },
    // padding: "2px 3px",
    backgroundColor: "#1e1e1e",
    marginBottom: "3px"
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "'0.5rem'" }} />}    {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? "#121212" : "#79797914",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0),
    },
    padding: "0px 10px",
    // borderRadius: "10px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0.5),
    borderTop: '0px solid rgba(0, 0, 0, .125)',
}));

const AllRestaurant = () => {
    const dispatch = useDispatch();
    const selectedAdmin = useSelector((state) => state.admin.admin)

    const { classes } = useStyles();
    const [count, setCount] = useState(0)  // set count direct in get data
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [data, setdata] = useState([]);
    const [expanded, setExpanded] = React.useState('panel1');
    const [modelOpenForNote, setModelOpenForNote] = useState(false)
    const [noteId, setNoteId] = useState(null)
    const [dataNote, setDataNote] = React.useState({ topicDescription: "" });
    const [error, setError] = useState({});
    const [serch, setSerach] = useState("")
    const [timer, setTimer] = useState(0);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]); const [cityData, setCityData] = useState([]);
    const [filterData, setFilterData] = useState({
        rating: "",
        country: "",
        city: "",
    })

    // Helper funtion to remove duplicates from the city and country data
    // const removeDuplicates = (data, key) => {
    //     const seen = new Set();
    //     return data.filter((item) => {
    //         const uniqueKey = item[key];
    //         if (seen.has(uniqueKey)) {
    //             return false;
    //         }
    //         seen.add(uniqueKey);
    //         return true;
    //     });
    // };

    const handleContentChange = (content) => {
        setDataNote(prevState => ({
            ...prevState,
            topicDescription: content
        }));
    };

    const removeParagraphTags = (content) => {
        if (content.startsWith('<p>') && content?.endsWith('</p>')) {
            return content?.slice(3, -4);
        }
        return content;
    };

    const handleSearch = (term) => {
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                setPage(1)
                setSerach(term);
            }, 500)
        );
    };

    const getFirstTwo = (arr) => arr?.slice(0, 3)?.join(" , ");


    const handleAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        _getMyAccount(newPage)
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(1);
        // _getMyAccount(1)
    };

    // const _getMyAccount = async (p) => {
    //     dispatch(showLoader(true));
    //     const pageToFetch = p || 1;
    //     let body = {
    //         page: pageToFetch,
    //         limit: rowsPerPage,
    //     };
    //     if (filterData?.rating) {
    //         body.rating = filterData?.rating?.stars;
    //     }
    //     if (filterData?.country?.id) {
    //         body.country_id = filterData?.country?.id;
    //     }
    //     if (filterData?.city?.id) {
    //         body.city_id = filterData?.city?.id;
    //     }
    //     if (selectedAdmin?._id) {
    //         body.adminId = selectedAdmin?._id;
    //     }
    //     if (serch) {
    //         body.search = serch;
    //     }
    //     if (p) {
    //         setPage(p);
    //     }
    //     try {
    //         const result = await dispatch(getMyAccount(body));
    //         const error = ErrorHandler(result);
    //         if (error) {
    //             setdata(result?.payload?.data?.response);
    //             setCount(result?.payload?.data?.count);
    //             console.log(result?.payload?.data?.response, "Result DATA");
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         dispatch(showLoader(false));
    //     }
    // };

    const _getMyAccount = async (p) => {
        dispatch(showLoader(true));
        const pageToFetch = p || 1;
        let body = {
            page: pageToFetch,
            limit: rowsPerPage,
        };

        // Include selected filter IDs in the payload
        if (filterData?.country?.id) {
            body.country = filterData.country.id;
        }
        if (filterData?.state?.id) {
            body.state = filterData.state.id;
        }
        if (serch) {
            body.search = serch;
        }
        if (p) {
            setPage(p);
        }

        try {
            const result = await dispatch(getMyAccount(body));
            const error = ErrorHandler(result);
            if (error) {
                setdata(result?.payload?.data?.response);
                setCount(result?.payload?.data?.count);
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(showLoader(false));
        }
    };
    
    // console.log(countryData, "Country data");

    // funtion to fetch country and city data
    // const fetchCountryAndCityData = async () => {
    //     try {
    //         const result = await _getMyAccount(); // Assuming _getMyAccount fetches data
    //         const error = ErrorHandler(result);
    //         if (error) {
    //             const data = result?.payload?.data?.response || [];

    //             console.log("Raw API Data:", data);

    //             // Extract country and city details
    //             const countries = data.map((item) => item.countryDetail).filter(Boolean);
    //             const cities = data.map((item) => item.cityDetail).filter(Boolean);

    //             console.log("Extracted Countries:", countries);
    //             console.log("Extracted Cities:", cities);

    //             // Remove duplicates
    //             const uniqueCountries = removeDuplicates(countries, "id");
    //             const uniqueCities = removeDuplicates(cities, "id");

    //             console.log("Unique Countries:", uniqueCountries);
    //             console.log("Unique Cities:", uniqueCities);

    //             // Set state
    //             setCountryData(uniqueCountries);
    //             setCityData(uniqueCities);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching country and city data:", error);
    //     }
    // };

    const _getCountryList = async () => {
        dispatch(showLoader(true));
        try {
            const result = await dispatch(getCountries({}));
            const error = ErrorHandler(result);
            if (error) {
                setCountryData(result?.payload?.data?.response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(showLoader(false));
        }
    };

    const _getStateList = async () => {
        dispatch(showLoader(true))
        let body = {
            country_id: filterData?.country?.id,
        };
        try {
            const result = await dispatch(getStates(body));
            const error = ErrorHandler(result);
            if (error) {
                setStateData(result?.payload?.data?.response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(showLoader(false))
        }
    };

    const _getCityList = async () => {
        dispatch(showLoader(true));
        const body = {
            country_id: filterData?.country?.id,
            state_id: filterData?.state?.id,
        };
        try {
            const result = await dispatch(getCities(body));
            const error = ErrorHandler(result);
            if (error) {
                setCityData(result?.payload?.data?.response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(showLoader(false));
        }
    };

    const _addNote = async () => {
        dispatch(showLoader(true))
        let body = {
            businessId: noteId,
            privateNotes: removeParagraphTags(dataNote?.topicDescription)
        };
        try {
            const result = await dispatch(addNotes(body));
            const error = ErrorHandler(result);
            if (error) {
                _getMyAccount(1)
                setNoteId(null)
                setModelOpenForNote(false)
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(showLoader(false))
        }
    };

    const _addFreeTrial = async (id) => {
        if (id) {
            dispatch(showLoader(true))
            try {
                const result = await dispatch(activateTrial(id));
                const error = ErrorHandler(result);
                if (error) {
                    _getMyAccount(1)
                }
            } catch (error) {
                console.log(error);
            } finally {
                dispatch(showLoader(false))
            }
        }
    };

    // useEffect(() => {
    //     fetchCountryAndCityData();
    // }, []);


    // useEffect(() => {
    //     _getMyAccount();
    // }, [selectedAdmin?._id, page, rowsPerPage]);

    // useEffect(() => {
    //     if (page == 1 ? 1 : page) {
    //         _getMyAccount();
    //     }
    // }, [selectedAdmin?._id, page, rowsPerPage]);

    useEffect(() => {
        _getCountryList()
    }, []);

    useEffect(() => {
        if (filterData?.country?.id) {
            _getStateList()
        }
    }, [filterData?.country?.id]);

    useEffect(() => {
        if (selectedAdmin?._id) {
            setPage(1);
            _getMyAccount(1);
        }
    }, [selectedAdmin?._id, rowsPerPage]);

    useEffect(() => {
        if (!selectedAdmin?._id) {
            _getMyAccount(page);
        }
    }, [selectedAdmin?._id, page, rowsPerPage, serch]);

    useEffect(() => {
        _getMyAccount(1);
    }, [filterData]);

    return (
        <>
            <Grid container xs={12} spacing={0} alignItems={"center"}>
                {/* <Grid item xs={12} sm={3} md={2}>
                    <AutoCompleteSearch
                        options={ratings}
                        renderOption={(props, option) => (
                            <Box {...props} display="flex" alignItems="center" sx={{ backgroundColor: "#070707" }}>
                                <Typography variant="body2" style={{ marginRight: '8px', color: "#6a6a6a" }}>
                                    {option?.label}
                                </Typography>
                                <Rating
                                    name="read-only"
                                    value={option?.stars}
                                    readOnly
                                    size="small"
                                />
                            </Box>
                        )}
                        placeholder={"Rating"}
                        name={"rating"}
                        handleChange={(e, newValue) => {
                            setFilterData({
                                ...filterData,
                                rating: newValue,
                            });
                        }}
                        fullWidth
                        defaultValue={filterData?.rating}
                        getOptionLabel={(option) => option?.stars || ""}
                    />
                </Grid> */}

                {/* <Grid item xs={12} sm={3} md={2}>
                    <AutoCompleteSearch
                        options={cityData}
                        placeholder={"City"}
                        handleChange={(e, newValue) => {
                            setFilterData({
                                ...filterData,
                                city: newValue,
                            });
                        }}
                        fullWidth
                        value={filterData?.city?.label}
                    /> 
                </Grid> */}

                <Grid container spacing={2} item xs={12} sm={6} my={2} alignItems={"center"} gap={{ xs: 2, sm: 0 }}  >
                    <Grid item xs={12} sm={5} paddingTop={"0px !important"}>
                        <AutoCompleteSearch
                            options={countryData}
                            placeholder={"Country"}
                            handleChange={(e, newValue) => {
                                setFilterData({
                                    ...filterData,
                                    country: newValue,
                                    state: ""
                                });
                            }}
                            fullWidth
                            defaultValue={filterData?.country?.label}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} paddingTop={"0px !important"}>
                        <AutoCompleteSearch
                            options={stateData}
                            placeholder={"State"}
                            handleChange={(e, newValue) => {
                                setFilterData({
                                    ...filterData,
                                    state: newValue,
                                });
                            }}
                            fullWidth
                            defaultValue={filterData?.state?.label}
                            disabled={!filterData?.country}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6} my={2} display={"flex"} justifyContent={{ xs: "flex-start", sm: "flex-end" }}  >
                    <CommonSearch
                        placeholder="Enter your admin"
                        handleSearch={handleSearch}
                    />
                </Grid>

                <Grid item xs={12} spacing={0} mt={"0px"}>
                    <Box sx={{ borderRadius: "26px", overflow: 'hidden', backgroundColor: "#13131399" }}>
                        <TableContainer
                            sx={{
                                // maxHeight: { xs: '400px', md: 'none' },  
                                overflowY: 'auto',
                            }}
                        >
                            <>
                                <Table stickyHeader sx={{ minWidth: 800, background: "#131313", }} aria-label="business table">
                                    <TableHead>
                                        <StyledTableCell align='center'>ID</StyledTableCell>
                                        <StyledTableCell align='center'>Logo</StyledTableCell>
                                        <StyledTableCell align='center'>Business Name</StyledTableCell>
                                        <StyledTableCell align='center'>Business Type</StyledTableCell>
                                        {/* <StyledTable align='center'Cell>Contact Name</StyledTable> */}
                                        <StyledTableCell align='center'>Email</StyledTableCell>
                                        <StyledTableCell align='center'>City</StyledTableCell>
                                        <StyledTableCell align='center'>State</StyledTableCell>
                                        <StyledTableCell align='center'>Country</StyledTableCell>
                                        <StyledTableCell align='center'>Rating</StyledTableCell>
                                        <StyledTableCell align='center'>Note</StyledTableCell>
                                        {/* <StyledTableCell align='center'>Guest Login</StyledTableCell> */}
                                        <StyledTableCell align='center'>Free Trial</StyledTableCell>
                                    </TableHead>
                                    <TableBody>
                                        {data?.length > 0 ? data?.map((dataItem, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align='center'>
                                                    {index + 1 + (page - 1) * rowsPerPage}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <img
                                                        src={`${BucketURL}${dataItem?.avtar || ''}`}
                                                        alt="logo"
                                                        className={classes.avatar}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = '/images/avatar.png';
                                                        }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>{dataItem?.name || "-"}</StyledTableCell>
                                                <StyledTableCell align='center'>{dataItem?.businessType || "-"}</StyledTableCell>
                                                {/* <StyledTableCell>{dataItem?.contactName || "-"}</StyledTableCell> */}
                                                <StyledTableCell align='center'>{dataItem?.email || "-"}</StyledTableCell>
                                                <StyledTableCell align='center'>{dataItem?.cityDetail?.label || "-"}</StyledTableCell>
                                                <StyledTableCell align='center'>{dataItem?.stateDetail?.label || "-"}</StyledTableCell>
                                                <StyledTableCell align='center'>{dataItem?.countryDetail?.label || "-"}</StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Rating name="read" defaultValue={dataItem?.rating || 1} readOnly size="small" />
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <IconButton onClick={() => {
                                                        setModelOpenForNote(true);
                                                        setNoteId(dataItem?._id);
                                                        setDataNote({ topicDescription: dataItem?.privateNotes });
                                                    }}>
                                                        <Note />
                                                    </IconButton>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align='center'>
                                                    <IconButton>
                                                        <GuestLogin />
                                                    </IconButton>
                                                </StyledTableCell> */}
                                                <StyledTableCell align='center'>
                                                    <IconButton
                                                        disabled={dataItem?.isSubscribe}
                                                        onClick={() => _addFreeTrial(dataItem?._id)} sx={{ cursor: "pointer" }} >
                                                        <FreeTrial stroke={dataItem?.isSubscribe ? "white" : dataItem?.isActiveTrial ? "green" : "red"} />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )) : (
                                            <TableRow>
                                                <TableCell colSpan={11}>
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    // width="100%"
                                                    >
                                                        <img src="/images/noDataFound.svg" alt="no data" />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </>

                        </TableContainer>
                        {data?.length > 0 && (
                            <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
                                <CustomPagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={Math.ceil(count / rowsPerPage)}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid >

            {modelOpenForNote && (
                <CommonModal
                    open={modelOpenForNote}
                    onClose={() => {
                        setModelOpenForNote(false);
                        setError({});
                        setDataNote({ topicDescription: "" });
                        setNoteId(null);
                    }}
                    title={"Notes"}
                    maxWidth={"md"}
                    content={
                        <Grid
                            container
                            xs={12}
                            padding={{ xs: "12px 16px", sm: "20px 20px" }}
                            sx={{ backgroundColor: "#222222" }}
                        >
                            <Grid item xs={12}>
                                <CommonTextEditor
                                    text="Topic Description"
                                    handleContentChange={handleContentChange}
                                    value={dataNote?.topicDescription}
                                    valid
                                />
                                <TextLabel
                                    marginTop={"5px"}
                                    fontSize={"12px"}
                                    color={"error"}
                                    title={!dataNote?.topicDescription ? error?.topicDescription : ""}
                                />
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={1}>
                                <CommonButton
                                    maxWidth={"90px"}
                                    text={"Submit"}
                                    height={"40px"}
                                    className={"primary"}
                                    onClick={() => {
                                        if (!dataNote?.topicDescription) {
                                            setError({ ...error, topicDescription: "please enter note first" })
                                        } else {
                                            _addNote()
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                />
            )}
        </>
    )
}

export default AllRestaurant