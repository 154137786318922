import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CommonSelect from '../../CommonSelect/CommonSelect';

const UserDataPieChart = ({ data, options }) => {
    const [selectedValue, setSelectedValue] = useState('Monthly');
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    // Transform API data into pie chart format
    const pieChartData = [
        { name: 'Active Business', value: data?.activeBusiness || 0, },
        { name: 'Subscription Active', value: data?.subscrptionActive || 0 },
        { name: 'Deleted Business', value: data?.deletedBusiness || 0 }
    ];

    const totalBusiness = data?.totalBusiness || 0;

    console.log(pieChartData, "pieChartData");


    // Define the colors directly in the component
    const COLORS = ['#F8C67D', '#FF781E', '#F88661'];

    // Determine PieChart dimensions based on device size
    const chartWidth = 250;
    const chartHeight = isMobile ? 200 : 250;
    const cx = isMobile ? '50%' : 120;
    const cy = isMobile ? '50%' : 120;
    const outerRadius = isMobile ? 80 : 90;

    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor: '#141414',
                padding: '15px',
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? '10px' : '0',
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Typography sx={{ color: '#CCC9C9', fontSize: '16px', fontWeight: '700' }}>
                            Business Data
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft='auto'>
                        <CommonSelect value={selectedValue} onChange={handleChange} options={options} />
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <PieChart width={chartWidth} height={chartHeight}>
                    <Pie
                        data={pieChartData}
                        cx={cx}
                        cy={cy}
                        labelLine={!isMobile}
                        label={!isMobile ? (entry) => entry.value : false}
                        outerRadius={outerRadius}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {pieChartData?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: isMobile ? '10px' : '100px',
                        alignItems: 'flex-start',
                        color: '#FFFFFF',
                        marginLeft: isMobile ? '0' : '20px',
                    }}
                >
                    {pieChartData?.map((entry, index) => (
                        <Typography
                            key={index}
                            sx={{
                                color: '#CCC9C9',
                                fontSize: '12px',
                                fontWeight: '400',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '9px',
                                    height: '9px',
                                    backgroundColor: COLORS[index % COLORS.length],
                                    marginRight: '10px',
                                    borderRadius: '50%',
                                }}
                            />
                            {entry?.name}: {entry?.value}
                        </Typography>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default UserDataPieChart;
