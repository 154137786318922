import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from 'tss-react/mui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, InputAdornment, Skeleton, useMediaQuery } from '@mui/material';
import DashboardIcon from '../../Common/icons/dashboard';
import UserIcon from '../../Common/icons/userIcon';
import Subscription from '../../Common/icons/subscription';
import Complains from '../../Common/icons/Complains';
import PrivacyPolicy from '../../Common/icons/PrivacyPolicy';
import Reviewsicon from '../../Common/icons/Reviewsicon';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import sessionStoreUtil from '../../../ApiSetup/Localstorage';
import AutoCompleteSearch from '../../Common/commonAutoComplete';
import { bussinessPlanDetails, getAdminList, getMyBusinessWithAdmin, updateAdminDetail, uploadimg } from '../../../Redux/Actions/action';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader } from '../../../Redux/Slices/loaderSlice';
import ErrorHandler from '../../../Utils/handler';
import { useEffect } from 'react';
import { useState } from 'react';
import { setBusiness } from '../../../Redux/Slices/businessId';
import { setAdmin } from '../../../Redux/Slices/adminId';
import CommonModal from '../../Common/CommonModel';
import CommonTextField from '../../Common/Fields/TextField';
import CommonButton from '../../Common/Button/CommonButton';
import { Regex } from '../../../Utils/regex';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import KeyWordsAndTags from '../../Common/icons/KeyWordsAndTags';
import { BucketURL } from '../../../ApiSetup/Api';
import { toast } from 'react-toastify';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#070707 !important",
  backgroundImage: "none !important",
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const useStyles = makeStyles()((theme) => {
  return {
    non_active_tab: {
      color: "#A39E9E",
      fontWeight: "500",
      lineHeight: "19.09px"
    },
    active_tab: {
      background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      lineHeight: "19.09px",
      letterSpacing: "-1%",
      fontWeight: "800 !important",
      "& .MuiTypography-root": {
        fontWeight: "800 !important",
      },
    },

  }
})


function AdminLayout({ children }) {
  // ---------- HOOK --------- 
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation()
  const { classes } = useStyles();
  const selectedBusiness = useSelector((state) => state.business.business);
  const selectedAdmin = useSelector((state) => state.admin.admin)
  const isMyAccount = location.pathname === '/myAccount';
  const isMyBusiness = location.pathname === '/myBusiness';
  const userData = sessionStoreUtil?.getLSItem("userData");
  const loginData = userData ? JSON.parse(userData) : null;
  const isAbove475px = useMediaQuery('(min-width:475px)');

  // --------- STATE -----------
  const [open, setOpen] = React.useState(true);
  const [businessData, setBusinessData] = React.useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [error, setError] = React.useState({});
  const [businesEditdata, setBusinesEditdata] = React.useState({
    firstName: "",
    mobilenumber: "",
    email: "",
  });
  const [adminList, setAdminList] = useState([])
  const [imageData, setImageData] = useState(null);
  const [isShowProfilePic, setIsShowProfilePic] = React.useState(<Skeleton variant="circular" width={40} height={40} />);
  const [isSubscription, setIsSubscription] = useState(true)
  const [isFreeTrial, setIsFreeTrial] = useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);

  const menuList = [
    {
      title: "Dashboard",
      icon: <DashboardIcon active={location?.pathname === "/" ? true : false} />,
      link: "/",
      isShow: loginData?.userType !== 3 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0 ? true : (!isSubscription && !isFreeTrial)
    },
    {
      title: "Admin",
      icon: <UserIcon active={location?.pathname === "/admin" ? true : false} />,
      link: "/admin",
      isShow: loginData?.userType === 0 ? true : false,
    },
    {
      title: "My Business",
      icon: <UserIcon active={location?.pathname === "/myAccount" ? true : false} />,
      link: "/myAccount",
      isShow: loginData?.userType !== 0 ? true : false
    },
    {
      title: "Subscription",
      icon: <Subscription active={location?.pathname === "/subscription" ? true : false} />,
      link: "/subscription",
      isShow: loginData?.userType === 2 || loginData?.userType === 1 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0
    },
    {
      title: "Keywords & Tags",
      icon: <KeyWordsAndTags active={location?.pathname === "/keywords-and-tags" ? true : false} />,
      link: "/keywords-and-tags",
      isShow: loginData?.userType === 2 || loginData?.userType === 1 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0 ? true : (!isSubscription && !isFreeTrial)
    },
    {
      title: "Reviews",
      icon: <Reviewsicon active={location?.pathname === "/reviews" ? true : false} />,
      link: "/reviews",
      isShow: loginData?.userType !== 0 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0 ? true : (!isSubscription && !isFreeTrial)
    },
    {
      title: "All Business",
      icon: <Subscription active={location?.pathname === "/allBusiness" ? true : false} />,
      link: "/allBusiness",
      isShow: loginData?.userType === 0 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0 ? true : (!isSubscription && !isFreeTrial)
    },
    {
      title: "Complains",
      icon: <Complains active={location?.pathname === "/complains" ? true : false} />,
      link: "/complains",
      isShow: loginData?.userType !== 0 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0 ? true : (!isSubscription && !isFreeTrial)
    },
    {
      title: "Terms and Conditions",
      icon: <PrivacyPolicy active={location?.pathname === "/TermsandConditions" ? true : false} />,
      link: "/TermsandConditions",
      isShow: true,
    },
    {
      title: "Privacy Policy",
      icon: <PrivacyPolicy active={location?.pathname === "/privacyPolicy" ? true : false} />,
      link: "/privacyPolicy",
      isShow: true,
    },
    {
      title: "Help Desk",
      icon: <Complains active={location?.pathname === "/helpDesk" ? true : false} />,
      link: "/helpDesk",
      isShow: loginData?.userType !== 3 ? true : false,
      disabled: loginData?.userType === 0 ? false : businessData?.length === 0 ? true : (!isSubscription && !isFreeTrial)
    },

  ];

  const activePage = menuList.find((e) => e.link === location?.pathname)?.title

  // --------- HANDLE CHANGE ----------------
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onchangeBusinesdata = (e) => {
    const { name, value } = e.target
    setBusinesEditdata({ ...businesEditdata, [name]: value });
  }

  const handleBusinessChange = (event, newValue) => {
    if (newValue) {
      dispatch(setBusiness(newValue));
    }
  };

  // const handleAdminChange = (event, newValue) => {
  //   if (newValue) {
  //     dispatch(setAdmin(newValue));
  //   }
  // };
  const handleAdminChange = (event, newValue) => {
    if (newValue) {
      dispatch(setAdmin(newValue));
    } else {
      dispatch(setAdmin(null));  // Reset to null to show the placeholder
    }
  };

  const handleclose = () => {
    setOpenEdit(false);
    setError({});
    // setBusinesEditdata({})
  }

  const handleEditdata = () => {
    setOpenEdit(true);
  }

  const handleEditClick = () => {
    document.getElementById('profilePicInput').click();
  };

  const handleLogout = () => {
    sessionStoreUtil.removeLSItem("auth_token")
    sessionStoreUtil.removeLSItem("userData")
    navigate("/login")
    setAnchorEl(null);
    dispatch(setAdmin(null));
  }

  const validation = () => {
    const error = {};
    let valid = true;
    if (!businesEditdata?.firstName) {
      error.firstName = "please enter your name";
      valid = false;
    }
    // if (!businesEditdata?.mobilenumber) {
    //   error.mobilenumber = "please enter mobile number";
    //   valid = false;
    // }
    if (businesEditdata?.mobilenumber) {
      if (!Regex?.mobileNumberRegex?.test(businesEditdata?.mobilenumber)) {
        error.mobileValid = "Please enter your a valid mobile number";
        valid = false;
      }
    }
    if (!businesEditdata?.email) {
      error.email = "Please enter your email id.";
      valid = false;
    } else if (!Regex?.emailRegex?.test(businesEditdata?.email)) {
      error.emailValid = "Please enter your a valid email id";
      valid = false;
    }
    setError(error);
    return valid;
  }


  //  ----------- API ---------- 
  const handleUploadImage = async (file, key) => {
    const formData = new FormData();
    formData.append("image", file);
    dispatch(showLoader(true))
    try {
      const result = await dispatch(uploadimg(formData));
      const error = ErrorHandler(result);
      if (error) {
        setImageData(result?.payload?.data?.image);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  const _getBusinessList = async () => {
    dispatch(showLoader(true))
    let body = {
      // adminId: loginData?._id
    };
    try {
      const result = await dispatch(getMyBusinessWithAdmin(body));
      const error = ErrorHandler(result);
      if (error) {
        setBusinessData(result?.payload?.data)
        dispatch(setBusiness(result?.payload?.data?.[0]));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  const _getAdminList = async () => {
    dispatch(showLoader(true))
    // let body = { adminId: loginData?._id };
    let body;
    if (loginData?.userType === 0) {
      body = {};
    }
    if (loginData?.userType === 1) {
      body = { adminId: loginData?._id };
    }
    try {
      const result = await dispatch(getAdminList(body));
      const error = ErrorHandler(result);
      if (error) {
        setBusinesEditdata({
          firstName: result?.payload?.data?.response?.[0]?.name,
          mobilenumber: result?.payload?.data?.response?.[0]?.mobileNo,
          email: result?.payload?.data?.response?.[0]?.email,
        })
        setIsShowProfilePic(result?.payload?.data?.response?.[0]?.avtar)
        setImageData(result?.payload?.data?.response?.[0]?.avtar)
        setAdminList(result?.payload?.data?.response)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // ==> get bussiness Plan Details
  const _getBussinessPlanDetails = async () => {
    if (selectedBusiness?._id) {
      dispatch(showLoader(true));
      let body = selectedBusiness?._id;
      try {
        const result = await dispatch(bussinessPlanDetails(body));
        const error = await ErrorHandler(result);
        if (error) {
          setIsSubscription(result?.payload?.data?.isSubscribe);
          setIsFreeTrial(result?.payload?.data?.isActiveTrial);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  const _updateAdmin = async () => {
    if (validation()) {
      dispatch(showLoader(true))
      let body = {
        _id: loginData?._id,
        name: businesEditdata?.firstName,
        email: businesEditdata?.email,
        mobileNo: businesEditdata?.mobilenumber || "",
      };
      if (imageData) {
        body.avtar = imageData
      }
      try {
        const result = await dispatch(updateAdminDetail(body));
        const error = ErrorHandler(result);
        if (error) {
          handleclose()
          _getAdminList()
          toast?.success(result?.payload?.message)
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false))
      }
    }
  };

  // ---------- useEffect --------------
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (loginData?.userType !== 2) {
      _getAdminList();
    }
    _getBusinessList();
  }, []);

  useEffect(() => {
    if (location?.pathname === "/myAccount") {
      _getBusinessList();
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (selectedBusiness?._id && loginData?.userType !== 0) {
      _getBussinessPlanDetails();
    }
  }, [selectedBusiness?._id]);

  useEffect(() => {
    if (!isSubscription && isSubscription !== null && loginData?.userType !== 0) {
      navigate("/subscription")
    }
  }, [isSubscription]);

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      const data = menuList?.filter((e) => e?.link === location?.pathname)?.[0]
      if (loginData?.userType !== 0 && data) {
        if (data?.disabled) {
          if (businessData?.length === 0) {
            navigate('/myAccount')
            return
          }
        } else {
          navigate(location?.pathname)
        }
      }
    }
  }, [location.pathname])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <AppBar position="fixed" open={open}  >
          <Toolbar sx={{ justifyContent: "space-between", marginTop: "5px" }}>
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {isAbove475px && (<Typography noWrap component="div" sx={{ fontSize: "25px", color: '#ffff !important', opacity: 1 }}>
                {activePage}
              </Typography>)}
            </Box>
            <Box
              display={"flex"}
              gap={"20px"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
            >
              {!isMyAccount && !isMyBusiness && loginData?.userType !== 3 && loginData?.userType !== 0 && businessData?.length > 0 &&
                <AutoCompleteSearch
                  options={businessData}
                  placeholder={"Select Business"}
                  name={"business"}
                  width={"200px"}
                  handleChange={handleBusinessChange}
                  startAdornment={<InputAdornment position="start">
                    <UserIcon active={true} />
                  </InputAdornment>}
                  defaultValue={selectedBusiness || {}}
                  getOptionLabel={(option) => option?.name}
                  disabled={loginData?.userType === 2}
                />}

              {activePage !== "Admin" && loginData?.userType === 0 && adminList?.length > 0 &&
                <AutoCompleteSearch
                  options={adminList}
                  placeholder={"Select Admin"}
                  name={"admin"}
                  width={"200px"}
                  handleChange={handleAdminChange}
                  startAdornment={<InputAdornment position="start">
                    <UserIcon active={true} />
                  </InputAdornment>}
                  defaultValue={selectedAdmin || ""}
                  getOptionLabel={(option) => option?.name}
                />
              }

              {/* <AutoCompleteSearch
                options={adminList?.map((val) => val?.name)}
                placeholder={"Admins"}
                name={"business"}
                handleChange={(e, newValue) => {
                  setFilterData({
                    ...filterData,
                    business: newValue,
                  });
                }}
                fullWidth
                defaultValue={filterData?.business}
              /> */}

              <Box display={"flex"}>
                <Box marginRight={"15px"} onClick={handleClick}>
                  {
                    (loginData?.userType !== 2 && loginData?.userType !== 0) &&
                    <Avatar sx={{ width: "40px", height: "40px", objectFit: "cover" }}
                      src={isShowProfilePic ? BucketURL + isShowProfilePic : <Skeleton variant="circular" width={40} height={40} />}
                    />
                  }
                  {
                    (loginData?.userType === 2) &&
                    <Avatar sx={{ width: "40px", height: "40px", objectFit: "cover" }}
                      src={businessData?.[0]?.avtar ? BucketURL + businessData?.[0]?.avtar : <Skeleton variant="circular" width={40} height={40} />}
                    />
                  }
                  {/* "https://ace.jeka.by/assets/images/avatars/profile-pic.jpg" */}
                </Box>
                <Box sx={{ display: { xs: "none", sm: "block" } }}
                  onClick={handleClick}
                >
                  <Typography
                    fontWeight={400}
                    fontSize={"10px"}
                    color={"rgba(109, 109, 109, 1)"}
                    mb={"4px"}
                  >
                    Welcome
                  </Typography>
                  <Typography
                    fontWeight={"600"}
                    fontSize={"16px"}
                    color={"rgba(255, 255, 255, 1)"}
                  >
                    {loginData?.name}
                    <Button
                      id="demo-positioned-button"
                      aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      // onClick={handleClick}
                      sx={{
                        ml: "4px", width: '10px', height: '10px', minWidth: '10px', minHeight: '10px', color: "rgba(173, 179, 204, 1)"
                      }}
                    >
                      <ArrowDropDownIcon />
                    </Button>

                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            zIndex: 10000000
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 6,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              backgroundColor: "#2f2f2f",
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
                bgcolor: "#2f2f2f",
              },
              "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              },
            },
          }}
        >
          {loginData?.userType === 1 && <MenuItem onClick={handleEditdata}>Profile</MenuItem>}
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

        <Drawer variant="permanent" open={open}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#070707',
            }
          }}
        >

          <DrawerHeader >
            <Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" gap={2}>
                <img src="/images/logo.svg" alt="Logo" />
                The AI Reviews
              </Box>
              <Box>
                <IconButton onClick={() => setOpen(!open)}>
                  {!open ? (
                    <MenuIcon sx={{ color: "white" }} />
                  ) : (
                    <ChevronLeftIcon sx={{ color: "white", zIndex: 9 }} />
                  )}
                </IconButton>
              </Box>
            </Box>
          </DrawerHeader>

          {/* <List>
            {menuList
              ?.filter((item) => item?.isShow)
              ?.map((menu, index) => {
                return (
                  <>
                    <ListItem key={index}
                      disablePadding
                      onClick={() => {
                        if (window.innerWidth < 991) {
                          setOpen(false);
                        } else {
                          setOpen(true);
                        }
                      }}
                      sx={{ display: 'block', "& .MuiListItemButton-root:hover": { backgroundColor: activePage === menu.link ? "#0000" : "" } }}
                    >
                      <ListItemButton
                        component={Link}
                        to={menu.link}
                      >
                        {menu?.title === activePage ?
                          (<Box mt={0.5} >
                            <Typography sx={{
                              position: "relative",
                              display: "inline-block",
                              width: "2px",
                              height: "14px",
                              background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                              WebkitBackgroundClip: "border-box",
                              WebkitTextFillColor: "transparent",
                              textAlign: "center",
                              mr: 1,
                              fontWeight: "bold",
                              borderRadius: "0px 5px 5px 0px"
                            }}>
                            </Typography>
                          </Box>) : (<Box><Typography sx={{ mr: 1 }}></Typography></Box>)
                        }
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            color: activePage !== menu.link ? '#fff' : "black"
                          }}
                        >
                          {menu.icon}
                        </ListItemIcon>
                        <ListItemText primary={menu?.title}
                          className={activePage === menu?.title ? classes.active_tab : classes.non_active_tab}
                          sx={{ opacity: open ? 1 : 0, fontWeight: activePage === menu?.title ? "800 !important" : "500" }} />
                      </ListItemButton>
                    </ListItem>
                  </>
                )
              })}
          </List> */}

          <List>
            {menuList
              ?.filter((item) => item?.isShow)
              ?.map((menu, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => {
                    if (window.innerWidth < 991) {
                      setOpen(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  sx={{
                    display: 'block',
                    "& .MuiListItemButton-root:hover": {
                      backgroundColor: menu.disabled ? 'inherit' : (activePage === menu.link ? "#0000" : "")
                    }
                  }}
                >
                  <ListItemButton
                    component={menu.disabled ? 'div' : Link}
                    to={menu.disabled ? '#' : menu.link}
                    disabled={menu.disabled}
                    sx={{
                      opacity: menu.disabled ? 0.5 : 1,
                      pointerEvents: menu.disabled ? 'none' : 'auto',
                      cursor: menu.disabled ? 'default' : 'pointer'
                    }}
                  >
                    {menu?.title === activePage ? (
                      <Box mt={0.5}>
                        <Typography
                          sx={{
                            position: "relative",
                            display: "inline-block",
                            width: "2px",
                            height: "14px",
                            background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                            WebkitBackgroundClip: "border-box",
                            WebkitTextFillColor: "transparent",
                            textAlign: "center",
                            mr: 1,
                            fontWeight: "bold",
                            borderRadius: "0px 5px 5px 0px"
                          }}
                        />
                      </Box>
                    ) : (
                      <Box><Typography sx={{ mr: 1 }} /></Box>
                    )}
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        color: menu.disabled ? 'gray' : (activePage !== menu.link ? '#fff' : "black")
                      }}
                    >
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={menu?.title}
                      className={activePage === menu?.title ? classes.active_tab : classes.non_active_tab}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontWeight: activePage === menu?.title ? "800 !important" : "500",
                        color: menu.disabled ? 'gray' : 'inherit'
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>


        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box >

      {openEdit && (
        <CommonModal
          open={openEdit}
          onClose={handleclose}
          title={"Edit Profile"}
          maxWidth={"md"}
          content={
            <Grid container xs={12} spacing={1}
              padding={{ xs: "12px 16px", sm: "20px 20px" }}
              sx={{ backgroundColor: "#222222" }}
            >

              <Grid item xs={12} sm={3} display={"flex"} justifyContent={"center"} alignItems={"center"} >
                <Box position="relative">
                  <Avatar sx={{ width: 94, height: 94, objectFit: "cover" }} src={imageData ? BucketURL + imageData : <Skeleton variant="circular" width={40} height={40} />} />

                  <IconButton
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      backgroundColor: 'pink',
                      color: 'black',
                      width: 30,
                      height: 30,
                      '&:hover': {
                        backgroundColor: 'pink',
                        opacity: 0.8,
                      },
                    }}
                    onClick={handleEditClick}
                  >
                    <EditIcon />
                  </IconButton>

                  <input
                    type="file"
                    accept="image/*"
                    id="profilePicInput"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      handleUploadImage(e.target.files[0], "image");
                    }}
                  />
                </Box>
              </Grid>

              <Grid container item xs={12} sm={9} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CommonTextField
                    color={"#fff"}
                    text={"Name"}
                    type="text"
                    name={"firstName"}
                    placeholder="Enter your business name"
                    value={businesEditdata?.firstName}
                    onChange={onchangeBusinesdata}
                  />
                  <Typography ml={1} variant="caption" color="error">
                    {!businesEditdata?.firstName ? error.firstName : ""}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CommonTextField
                    color={"#fff"}
                    text={"Email"}
                    type="email"
                    name={"email"}
                    disabled
                    placeholder="Enter your email"
                    value={businesEditdata?.email}
                    onChange={onchangeBusinesdata}
                  />
                  <Typography ml={1} variant="caption" color="error">
                    {!businesEditdata?.email ? error.email : ""}
                  </Typography>
                  <Typography variant="caption" color={"error"}>
                    {businesEditdata?.email ? error?.emailValid : ""}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CommonTextField
                    color={"#fff"}
                    text={"Mobile Number"}
                    type="number"
                    name={"mobilenumber"}
                    placeholder="Enter your lastName"
                    value={businesEditdata?.mobilenumber}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^(?!0{2,})\d*$/.test(value) && value.length <= 10) {
                        setBusinesEditdata((prevValue) => ({
                          ...prevValue,
                          mobilenumber: value,
                        }));
                      }
                    }}
                  />
                  {/* <Typography ml={1} variant="caption" color="error">
                    {!businesEditdata?.mobilenumber ? error.mobilenumber : ""}
                  </Typography> */}
                  <Typography ml={1} variant="caption" color="error">
                    {businesEditdata?.mobilenumber ? error.mobileValid : ""}
                  </Typography>
                </Grid>

              </Grid>

              <Grid item xs={12} mt={{ xs: 1, sm: 4 }}
                display={"flex"}
                justifyContent={"end"}
              >
                <CommonButton
                  maxWidth={"110px"}
                  text={"Submit"}
                  className={"primary"}
                  onClick={_updateAdmin}
                />
              </Grid>

            </Grid>
          }
        />
      )}

    </>
  )
}

export default AdminLayout
