import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import CommonBarChart from './BarChart/CommonBarChart';
import CommonBackgroundContainer from './CommonBackgroundContainer';
import styled from 'styled-components';


const ChartWrapper = styled.div`
  background-color: #151515;
  border-radius: 12px;
  color: white;
  overflow: hidden;
`;

const TitleSection = styled.div`
  padding: 20px 20px ;
  background: #24211B;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
`;


const ReviewByAgeBarChart = (
  {
    data
  }
) => {

  const gradientIds = ['reviewByAgeGradient1'];
  const barNumbers = 1;
  const title = 'Review by Age';

  return (
    <CommonBackgroundContainer
      title={title}
      titleColor='#CCC9C9'
      titleFontSize='16px'
      titleFontWeight='700'
    >
      <CommonBarChart
        data={data}
        gradientIds={gradientIds}
        barNumbers={barNumbers}
        height={230}
        showGrid={false}
        color1={'#A07A93'}
      />
    </CommonBackgroundContainer>
  );
};

export default ReviewByAgeBarChart;
