// journeySlice.js
import { createSlice } from '@reduxjs/toolkit';

const journeySlice = createSlice({
    name: 'journey',
    initialState: {
        type: 'journey1',
    },
    reducers: {
        setJourney: (state, action) => {
            state.type = action.payload;
        },
    },
});

export const { setJourney } = journeySlice.actions;
export default journeySlice.reducer;
