import { Box, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import CommonButton from '../../../Components/Common/Button/CommonButton';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setSelectedKeywords } from '../../../Redux/Slices/keyWordSlice';
import TextLabel from '../../../Components/Common/Fields/TextLabel';
import { showLoader } from '../../../Redux/Slices/loaderSlice';
import ErrorHandler from '../../../Utils/handler';
import { getBusinessDetails } from '../../../Redux/Actions/action';

const useStyles = makeStyles()((theme) => ({
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        marginBottom: "20px",
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    }
}));

const CustomChip = ({ chipTitle, emoji, selected, handleChipClick }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                padding: '1.5px',
                borderRadius: '10px',
                background: selected
                    ? "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)"
                    : 'transparent',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    padding: '9px 14px',
                    color: '#FFF',
                    backgroundColor: '#141414',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Chip
                    label={chipTitle}
                    icon={selected ? <CloseIcon /> : <AddIcon />}
                    onClick={handleChipClick}
                    variant="outlined"
                    sx={{
                        backgroundColor: 'transparent',
                        border: "none",
                        color: '#FFF',
                        width: '100%',
                        fontSize: "14px",
                        lineHeight: "24px",
                        justifyContent: "start",
                        '& .MuiChip-icon': {
                            color: '#FFF',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                        },
                    }}
                    disableRipple
                />
            </Box>
        </Box>
    );
};
const Keywords = () => {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const id = new URLSearchParams(useLocation().search).get('id');


    const [selectedChips, setSelectedChips] = useState([]);
    const [error, setError] = useState({})
    const [businessKeywords, setBusinessKeywords] = useState([])
    const [companyName, setCompanyName] = useState("")

    const handleChipClick = (chipTitle) => {
        setSelectedChips((prev) =>
            prev.includes(chipTitle)
                ? prev.filter((chip) => chip !== chipTitle)
                : [...prev, chipTitle]
        );
    };

    const validation = () => {
        let flag = true;
        let errors = {};

        if (selectedChips?.length === 0) {
            flag = false;
            errors["keyword"] = "please select atleast one keyword";
        }
        setError(errors)
        return flag;
    }

    const onGenerateClick = () => {
        if (validation()) {
            dispatch(setSelectedKeywords(selectedChips));
            navigate(`/user/review?id=${id}`);
        }
    }

    const _getBusinessProfile = async () => {
        dispatch(showLoader(true))
        let body = {
            _id: id,
            pageName: "keyword"
        };
        try {
            const result = await dispatch(getBusinessDetails(body))
            const error = ErrorHandler(result)
            if (error) {
                setBusinessKeywords(result?.payload?.data?.keywords)
                setCompanyName(result?.payload?.data?.name)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false))
        }
    }

    useEffect(() => {
        _getBusinessProfile()
    }, [])

    return (
        <>
            <Grid item container>
                <Grid item xs={12} className={classes?.descriptionContainer}>
                    <Typography fontSize={"26px"} fontWeight={700} color='#FFFFFF' maxWidth={"290px"} letterSpacing={"-1px"} lineHeight={"31.03px"}
                        sx={{
                            background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 70%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}>Select Keywords
                    </Typography>
                </Grid>
                <Grid item xs={12} display={"flex"} flexDirection={"column"} gap={"6px"} marginBottom={"20px"}>
                    <Typography fontSize={"18px"} fontWeight={500} color='#FFFFFF' letterSpacing={-1} lineHeight={"24px"}>{`How was your experience at ${companyName}?`}</Typography>
                    {/* <Typography fontSize={"16px"} fontWeight={400} color='#A3A3A3' letterSpacing={-1} lineHeight={"22px"}>{`Share your experience at ${companyName}, and we'll draft a review for you—feel free to edit or write your own if it doesn't capture your thoughts.`}</Typography> */}
                    <Typography fontSize={"16px"} fontWeight={400} color='#A3A3A3' letterSpacing={-1} lineHeight={"22px"}>{`Share your experience at ${companyName} We’ll create a review draft for you to ensure it captures your feedback effectively.`}</Typography>



                </Grid>
                <Grid item xs={12} marginBottom={"46px"}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        {businessKeywords?.length > 0 ? businessKeywords?.map((businessKeyword, index) => (
                            <CustomChip
                                key={index}
                                chipTitle={businessKeyword?.keyword}
                                emoji={businessKeyword?.emoji}
                                selected={selectedChips.includes(businessKeyword?.keyword)}
                                handleChipClick={() => handleChipClick(businessKeyword?.keyword)}
                            />
                        )) :
                            (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                >
                                    <img src="/images/noDataFound.svg" alt="no data" />
                                </Box>
                            )}
                    </Box>
                    <TextLabel
                        marginTop={1}
                        fontSize={"12px"}
                        color={"red"}
                        title={businessKeywords?.length === 0 ? "" : selectedChips?.length === 0 ? error?.keyword : ""}
                    />
                </Grid>
                <Grid item xs={12} className={classes?.buttonContainer}>
                    <CommonButton variant='contained' className='primary' text={"Generate"} fullWidth onClick={onGenerateClick} />
                </Grid>
            </Grid>
        </>
    )
}

export default Keywords