import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CommonBackgroundContainer = ({ title, subtitle, children, titleColor, titleFontSize, subtitleFontSize, titleFontWeight, subTitleFontWeight, dynamicPadding, ...props }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(217, 217, 217, 0.06)',
        padding: !title && !subtitle ? dynamicPadding : '0',
        borderRadius: '20px',
        overflow: 'hidden',
        ...props.sx,
      }}
    >
      {(title || subtitle) && (
        <Box
          sx={{
            padding: '34px 41px',
            background: '#24211B',
            color: titleColor,
          }}
        >
          {title && (
            <Typography
              variant="h2"
              sx={{
                margin: 0,
                fontSize: titleFontSize || '20px',
                fontWeight: titleFontWeight || 700,
              }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="body1"
              sx={{
                margin: '5px 0 0',
                fontSize: subtitleFontSize || '16px',
                fontWeight: subTitleFontWeight || 400,
                color: '#696969',
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default CommonBackgroundContainer;