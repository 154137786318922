import React from "react";
import { AppBar, Box, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CustomDrawerHeader = ({
    handleClose,
    title,
    subtitle,
    otherText,
    fontSize,
}) => {
    return (
        <Box sx={{ flexGrow: 1, backgroundColor: "#020202" }} >
            <AppBar position="static" >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    minHeight="50px"
                    px={{ xs: 1.5, sm: 4 }}
                    gap={5}
                >
                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
                        <Typography
                            variant="h6"
                            color="#fff"
                            fontWeight="bold"
                            fontSize={fontSize || "1.25rem"}
                            sx={{
                                background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>
                    <IconButton
                        color="default"
                        size="small"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 16,
                            color: '#fff',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </AppBar>
        </Box>
    );
};

export default CustomDrawerHeader;
