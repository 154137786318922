import React from 'react';
import styled from 'styled-components';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    IconButton,
} from '@mui/material';

//--------------- Styled Components ---------

const StyledBox = styled.div`
    background-color: #151515;
    padding: 16px;
    border-radius: 20px;
    height: 100%;
    max-height: 300px;
`;

const HeaderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledTypography = styled(Typography)`
    color: #CCC9C9;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
`;

const StyledIconButton = styled(IconButton)`
`;

const StyledTableCell = styled(TableCell)`
    color: ${props => (props.header ? 'transparent' : '#CCC9C9')};
    font-size: 14px;
    font-weight: 400;
    padding: 7px;
    background-color: 'transparent';
    background: ${props => (props.header ? 'var(--Style, radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%))' : 'none')};
    background-clip: ${props => (props.header ? 'text' : 'none')};
`;

const StyledTableRow = styled(TableRow)`
    &:last-child td {
        border-bottom: none; 
    }
    td {
        border-bottom: none; 
    }
`;

const StyledHeaderRow = styled(TableRow)`

    border-top: 1px solid #CCC9C9;
    border-bottom: 1px solid #CCC9C9;
`;

const StyledTableContainer = styled(TableContainer)`
    color: #fff;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow-x: auto;
    box-shadow: none;
`;

const TabTable = ({ isLike, data }) => {

    return (
        <StyledBox>
            <HeaderBox>
                <StyledTypography>
                    Customer Top 5 {isLike ? 'Keywords' : 'Tags'}
                </StyledTypography>
                <StyledIconButton>
                    {isLike ? <img src='./images/ThumbsUpIcon.svg' /> : <img src='./images/ThumbsDownIcon.svg' />}
                </StyledIconButton>
            </HeaderBox>
            <StyledTableContainer>
                <Table size="small">
                    <TableHead>
                        <StyledHeaderRow>
                            <StyledTableCell header>No.</StyledTableCell>
                            <StyledTableCell header>Keyword</StyledTableCell>
                            <StyledTableCell header>Count&nbsp;↑↓</StyledTableCell>
                        </StyledHeaderRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell>{row.no}</StyledTableCell>
                                <StyledTableCell>{row.keyword}</StyledTableCell>
                                <StyledTableCell>{row.count}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </StyledBox>
    );
};

export default TabTable;
