import React from 'react'

function KeyWordsAndTags({ active }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" opacity={active ? "1" : "0.3"} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="flowbite:tag-solid">
                <path id="Vector" d="M14.7009 3.00545L10.2389 3C10.0363 3.0001 9.83569 3.04037 9.64872 3.1185C9.46176 3.19662 9.29214 3.31105 9.14968 3.45515L3.44672 9.2079C3.16044 9.49895 3 9.89084 3 10.2991C3 10.7073 3.16044 11.0992 3.44672 11.3903L8.56071 16.5494C8.70291 16.6938 8.87248 16.8085 9.0595 16.8866C9.24653 16.9648 9.44726 17.0049 9.64995 17.0045C9.85258 17.0044 10.0532 16.9642 10.2402 16.886C10.4271 16.8079 10.5967 16.6935 10.7392 16.5494L16.5036 10.7321C16.6597 10.5869 16.7843 10.4113 16.8697 10.216C16.955 10.0208 16.9994 9.81003 17 9.59691V5.31775C17.0004 4.70665 16.7588 4.12026 16.3279 3.68691C15.897 3.25356 15.312 3.00853 14.7009 3.00545ZM12.7932 8.0144C12.5868 8.0144 12.389 7.93243 12.243 7.78652C12.0971 7.64061 12.0152 7.44272 12.0152 7.23637C12.0152 7.03003 12.0971 6.83213 12.243 6.68622C12.389 6.54031 12.5868 6.45834 12.7932 6.45834C12.9995 6.45834 13.1974 6.54031 13.3433 6.68622C13.4893 6.83213 13.5712 7.03003 13.5712 7.23637C13.5712 7.44272 13.4893 7.64061 13.3433 7.78652C13.1974 7.93243 12.9995 8.0144 12.7932 8.0144Z" fill="url(#paint0_radial_3635_3084)" />
            </g>
            <defs>
                <radialGradient id="paint0_radial_3635_3084" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.00355 4.48984) rotate(49.982) scale(13.9742 25.8229)">
                    <stop stop-color="white" />
                    <stop offset="0.79" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
            </defs>
        </svg>

    )
}

export default KeyWordsAndTags