import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader } from "../../Redux/Slices/loaderSlice";
import WestIcon from '@mui/icons-material/West';

const PasswordReset = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = () => {
        dispatch(showLoader(true));
        setTimeout(() => {
            navigate("/login");
            dispatch(showLoader(false));
        }, 100);
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                minWidth={"30vh"}
                sx={{
                    background:
                        "radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 100%)",
                    backgroundImage: "url(/images/backgroundLinigImg.svg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#FFFFFF",
                }}
            >
                <Grid
                    container
                    spacing={isMobile ? 1 : 2}
                    maxWidth={isMobile ? "80%" : "620px"}
                    maxHeight={isMobile ? "100%" : "450px"}
                    padding={isMobile ? "25px 20px 25px 20px" : "35px 40px"}
                    border="1px solid #3E3E3E"
                    borderRadius="20px"
                    sx={{
                        backgroundColor: "#121212",
                        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.25)",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        marginBottom={isMobile ? "15px" : "20px"}
                        padding="0px !important"
                    >
                        <Box display="flex" alignItems="center" gap="10px">
                            <img
                                src="/images/logo.svg"
                                alt="logo"
                                height="28px"
                                width="28px"
                            />
                            <Typography fontSize="14px" fontWeight={600} color="#FFFFFF">
                                The AI Reviews
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            fontSize={isMobile ? "28px" : "30px"}
                            fontWeight={700}
                            lineHeight={isMobile ? "30px" : "38.19px"}
                            letterSpacing="-1px"
                            textAlign={"center"}
                            sx={{
                                background:
                                    "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            Password Reset
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        padding={isMobile ? "0px 10px" : "0px 30px !important"}
                    >
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt={isMobile ? "15px" : "4px"}
                        >
                            <Typography
                                fontSize={"14px"}
                                fontWeight={400}
                                lineHeight={"20px"}
                                color={"#696969"}
                                display={"flex"}
                                alignItems={"center"}
                                textAlign={"center"}
                                maxWidth={"250px"}
                                gap={1}
                                onClick={() => {
                                    dispatch(showLoader(true));
                                    setTimeout(() => {
                                        navigate("/login");
                                        dispatch(showLoader(false));
                                    }, 100);
                                }}
                            >
                                Your password has been successfully reset. Click below to log in magically. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mt={isMobile ? "20px" : "38px"}>
                            <CommonButton
                                onClick={onSubmit}
                                text="Continue"
                                className="primary"
                                fullWidth={isMobile}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mt={"25PX"}
                        >
                            <Typography
                                sx={{ cursor: "pointer" }}
                                fontSize={"13px"}
                                fontWeight={400}
                                lineHeight={"20px"}
                                color={"#696969"}
                                display={"flex"}
                                alignItems={"center"}
                                gap={1}
                                onClick={() => onSubmit()}
                            >
                                <WestIcon sx={{ fontSize: "20px" }} />
                                {`Back to login`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box >
        </>
    )
}

export default PasswordReset
