import React from 'react';
import { Button } from '@mui/material';

const CommonButtonDashboard = ({ label, onClick }) => {
    return (
        <Button
            variant="contained"
            onClick={onClick}
            sx={{
                minWidth: '100px',
                maxWidth: 'fit-content',
                height: '32px',
                borderRadius: '36px',
                background: '#242222',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                boxShadow: `
                    2px 2px 6px 0px rgba(0, 0, 0, 0.10),
                    7px 9px 12px 0px rgba(0, 0, 0, 0.09),
                    16px 21px 16px 0px rgba(0, 0, 0, 0.05),
                    28px 37px 18px 0px rgba(0, 0, 0, 0.01),
                    44px 57px 20px 0px rgba(0, 0, 0, 0.00)
                `,
                '& .MuiButton-label': {
                    background: 'radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                },
                typography: {
                    background: 'radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                },
                padding: '0 16px',
            }}
        >
            {label}
        </Button>
    );
};

export default CommonButtonDashboard;