import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider
} from '@mui/material';

// Privacy Policy Data from JSON
const privacyPolicyJson = [
  {
    section: "1) Definitions",
    intro: `In this Privacy Policy, several key terms are used to clarify the rights and responsibilities of all parties involved in the use of our Services. For ease of understanding, here are detailed explanations of these terms:`,
    contentIntoContent: [
      {
        subName: "Customer",
        subContent1: "This refers to an individual or an organization that has entered into a formal agreement with Theaireviews to gain access to our Services. The Customer holds certain rights, such as accessing specific functionalities within our platform, but also certain obligations, including abiding by usage guidelines and policies. The Customer is responsible for any data uploaded to our platform and for designating authorized individuals, referred to as “Users,” who can access and interact with the Services on their behalf."
      },
      {
        subName: "Customer Content",
        subContent1: "Customer Content includes any data, images, text, or other multimedia that the Customer owns and chooses to upload to our Services. This content remains under the ownership of the Customer, although we facilitate its storage, organization, and sharing. Customer Content may be displayed or shared on external platforms, such as social media or review websites, which we refer to as “Networks.” Importantly, when content is shared publicly, this Privacy Policy does not apply to that content, and it may be subject to the privacy policies of those external Networks."
      },
      {
        subName: "Restricted Information",
        subContent1: "Restricted Information encompasses highly sensitive data types, including but not limited to government-issued identifiers (such as social security numbers), Protected Health Information (PHI), and financial account numbers. We typically do not request or collect Restricted Information unless it is strictly necessary for legal or operational reasons. In such cases, we will obtain explicit prior consent from the individual or entity involved, in compliance with applicable privacy regulations."
      },
      {
        subName: "Subscription Services",
        subContent1: " Subscription Services refer to the paid access to our Services, which are offered under an annual subscription model as outlined in a formal Service Agreement between us and the Customer. Subscription Services include exclusive features, access levels, and support tailored to the Customer’s needs as defined in their contractual agreement."
      },
      {
        subName: "User",
        subContent1: "A User is an individual who has been granted access to our Services by the Customer. Each User’s access and usage are governed by the Customer’s contract with us, meaning that the Customer is responsible for ensuring that their Users comply with the terms and policies associated with the use of our Services."
      },
    ],

    contentOne: `These definitions help establish the roles, responsibilities, and expectations for all parties interacting with our Services, ensuring that data handling practices align with the privacy standards and expectations laid out in this Policy.`,

    contentTwo: `This Privacy Policy explains how we collect, use, share, and protect your information when you use our website, mobile app, or related services (‘Services’). By using our Services, you agree to the data practices described in this Policy.If you have questions or requests regarding your privacy rights, please contact us using the information provided at the end of this Policy.`,

    contentThree: `NOTE: It is important that you read this Privacy Policy carefully, as it explains how we collect, use, share, and protect your personal information when you access and use our Services. By choosing to use or access any part of our Services, you are indicating your understanding and acceptance of the data practices described in this Privacy Policy. This means that you consent to the collection, use, and disclosure of your information as outlined within this document. If, however, you do not agree with any aspect of this Privacy Policy or the practices described herein, we ask that you avoid using or accessing our Services. Your continued use of our platform signifies your acceptance of our data handling practices and your acknowledgment of the terms provided in this Privacy Policy.`,
  },
  {
    section: "2) Information We Collect",
    intro: `To operate effectively and provide you with a high-quality experience, we collect various types of information from you, using both direct and indirect methods. This section details the kinds of information we gather and how each type is used to enhance your interactions with our Services.`,
    contentIntoContent: [
      {
        subName: "A. Information You Provide Directly",
        subContent1: " This category covers the data you voluntarily provide while using our Services, which is essential for personalized experiences, account management, and effective",
        subContent2: "Firstly, we collect Contact Information such as your name, address, email, and phone number. This information allows us to communicate with you efficiently, including sending updates, offering customer support, and providing any necessary account-related information. It also enables us to deliver notifications or updates tailored to your engagement with our Services, ensuring you receive relevant and timely communications.",
        subContent3: "For account creation and security, we collect Account Details such as your chosen username, password, and responses to security questions. These details help us verify your identity, secure your account, and allow us to personalize your experience within our Services. To protect your privacy, account credentials are encrypted, ensuring that your information remains safe while we provide the convenience of secure access.",
        subContent4: "In the course of any purchases or payments made via our platform, we gather Transaction Information. This includes billing and shipping addresses, payment details—managed securely through trusted third-party processors—and a record of your purchase history.",
        subContent5: "Transaction information is essential for processing your orders, enabling delivery tracking, and handling requests for refunds or returns. By securely processing this data, we ensure that transactions are completed accurately and promptly.",
        subContent6: "Additionally, when you reach out to us for assistance, we collect Customer Support Inquiries. Any information you provide during these interactions, such as descriptions of the issues you face, your preferences, and feedback, is recorded to enhance our support services. This information enables us to respond quickly and effectively to your needs while also helping us continually improve our support offerings.",
        subContent7: "By collecting and utilizing this information responsibly, we aim to create a streamlined and secure user experience, ensuring that each interaction with our Services is seamless, personalized, and aligned with your needs.",
      },
      {
        subName: "B.  Information Collected Automatically",
        subContent1: "We automatically collect certain information as you interact with our Services to enhance functionality, optimize user experience, and ensure robust security measures are in place. This data, gathered seamlessly in the background, supports the efficient operation and continuous improvement of our platform.",
        subContent2: "One category of automatically collected data is technical details (e.g., IP address, browser type, operating system, and browser type and version).By collecting this information, we can ensure that our Services are compatible with a wide range of devices and operating systems, providing a smooth, optimized experience for users across different platforms. It also allows us to diagnose and troubleshoot compatibility issues, improving the overall reliability and accessibility of our platform.",
        subContent3: "We also collect Usage Information, which records how you interact with our Services. This data encompasses details such as the pages you visit, the links you click, and the time spent on each section of the platform. By analyzing usage information, we gain valuable insights into user behavior and preferences. This understanding helps us assess which features are most valuable to our users, identify areas for improvement, and customize content to match individual interests, creating a more tailored and engaging experience for each user.",
        subContent4: "Additionally, we may gather Location Data, provided you have given consent to access it. This data can be inferred from your IP address or device settings, allowing us to offer location-based content that is relevant to you and to analyze geographic trends across our user base. For instance, location data enables us to recommend nearby services or tailor content based on regional interests. Importantly, we prioritize user control over this feature, allowing you to restrict or adjust location permissions through your device settings if you prefer not to share this information.",
        subContent5: "Together, this automatically collected data enables us to support a secure, personalized, and efficient user experience, continually enhancing the way our Services respond to the needs and preferences of our users."
      },
      {
        subName: "C.  Information Collected Through Cookies and Similar Technologies",
        subContent1: "We utilize cookies, web beacons, and similar tracking technologies to collect information that enhances your experience and optimizes the functionality of our Services. These technologies work in the background, capturing details that allow us to remember your preferences, streamline your interactions, and deliver personalized content across our platform.",
        subContent2: "Types of Cookies used on our platform include both session cookies and persistent cookies. Session cookies are temporary files that remain active only while your browser is open; they expire and are deleted automatically once you close your browser. These session cookies help maintain seamless interactions within a single browsing session. In contrast, persistent cookies are stored on your device for a specified duration, even after you close the browser. Persistent cookies make it easier for you to navigate our Services on subsequent visits, as they retain information about your settings and preferences, providing a smoother and more personalized experience each time you return.",
        subContent3: "Purposes of Cookies include storing user preferences, managing session states, and enhancing security protocols. For instance, cookies allow us to remember your chosen settings, such as language and layout preferences, making each visit more tailored to your needs. They also maintain session continuity, enabling you to navigate between pages without having to re-enter your information or reset your selections. Additionally, cookies support the implementation of security measures, safeguarding your account and data during your interactions with our Services. Beyond personalization and security, cookies are essential for tracking user engagement. By analyzing this data, we gain insights into user behavior, which helps us identify areas for improvement and adapt our site functionality to better meet user needs.",
        subContent4: "Third-Party Cookies may also be employed on our platform, particularly by certain external vendors, such as advertising networks. These third parties use cookies to serve ads that are relevant to your interests, making your online experience more meaningful by showing content that aligns with your preferences. However, we understand the importance of user choice in privacy, and you have control over how these cookies are used. By adjusting your browser settings, you can manage your cookie preferences, including blocking certain types of cookies if desired. Be mindful, though, that blocking cookies may impact site performance and reduce the personalization of certain features.",
        subContent5: "Through the strategic use of cookies and similar technologies, we aim to create a more secure, efficient, and customized user experience while respecting your preferences and maintaining transparency in data usage.",
      },
      {
        subName: "D.  Information from Third-Party Sources",
        subContent1: "We may receive supplementary data (e.g., payment verification from Payment Providers) to enhance service security and functionality. This external information helps us offer a more secure, integrated, and personalized experience within our Services.",
        subContent2: "One key source of third-party information comes from Payment Providers. To process transactions securely, we partner with reputable payment gateways that handle payment details on our behalf. While we do not store sensitive payment information directly, these providers share essential verification data with us to confirm successful transactions and detect potential fraud. This exchange of verification data allows us to complete your transactions efficiently while prioritizing your financial security and ensuring all transactions align with industry-standard protections.",
        subContent3: "We may also receive data from other online sources, such as Google My Business, when you choose to link or engage with these accounts through our Services. This integration enables us to access specific information, such as your name, email address, profile picture, or publicly available business information, depending on the permissions and privacy settings you have selected on these platforms.When you connect your account from platforms like Google My Business, we are able to streamline certain functionalities, such as login processes, data synchronisation, and enhanced user experience, allowing for faster interactions and a more cohesive experience across our services.We are committed to respecting your chosen privacy settings on each platform, ensuring that we access only the information explicitly authorised by you. Any data received through such integrations will be treated in accordance with this Privacy Policy to safeguard your privacy and maintain compliance with data protection regulations",
        subContent4: "Additionally, our Advertising Partners may share demographic and interest-based information to help us deliver more relevant promotional content. This data, which may include general information about your interests or preferences, allows us to tailor our marketing efforts and make our promotional activities more engaging. We only use this data within the boundaries of data protection laws, ensuring that our practices align with regulatory standards and that your privacy is safeguarded. By leveraging these insights, we aim to make our marketing more targeted and beneficial to users, presenting content that resonates with individual preferences.",

        subContent5: "Collectively, the information we receive from these third-party sources supports the enhancement of our Services, making them more secure, user-friendly, and relevant to your needs. We are committed to handling this data responsibly and transparently, ensuring that any third-party information aligns with both your expectations and legal standards.",

        subContent6: "Any information we obtain from third parties will be processed in line with this Privacy Policy to uphold your data rights and ensure that we adhere to the same data protection standards. While we strive to ensure data accuracy, we cannot take responsibility for the accuracy of the information sourced from third-party providers. Additionally, we are not liable for the privacy practices or policies of any third party that may provide us with data. We encourage users to review the policies of third-party websites they interact with.”If you are not comfortable with the privacy practices or policies of third-party services, we recommend that you avoid from using features of our Services that integrate with those providers. By connecting your accounts with third-party platforms, you agree to their terms and privacy practices, which may differ from ours. We encourage you to review their privacy policies",

        subContent7: "carefully before linking your accounts or sharing information with us through these platforms.”",
      }
    ]
  },
  {
    section: "3) Legal Bases and Purposes for Processing Your Information",
    intro: `In compliance with the General Data Protection Regulation (GDPR) and other applicable data protection laws, we process your personal information based on specific legal grounds that include contractual necessity, legitimate interests, legal compliance, and, where applicable, your consent. These legal bases ensure that our data handling practices are aligned with regulatory standards and that your information is processed responsibly and transparently.`,

    contentIntoContent: [
      {
        subName: "A. Contractual Necessity ",
        subContent1: "Contractual Necessity is one of the primary legal grounds under which we collect and process your personal This basis applies to data that is essential for fulfilling the terms of the Services we have agreed to provide you under our contract. For instance, to process your orders and facilitate secure payments, we require relevant data such as billing details and payment information. This information allows us to execute transactions accurately, manage order confirmations, and handle any returns or refunds as necessary.",
        subContent2: "Additionally, we collect data to manage your account and access permissions within our platform. Account-related information, such as login credentials and user permissions, enables us to maintain the security of your account and ensure that only authorized individuals can access specific features or data associated with your account. This is particularly important for preserving the integrity of our Services and ensuring that you receive the full benefits of our offerings as per our contractual agreement.",
        subContent3: "We also need certain information to provide transaction confirmations and support. Once an order or action is completed on our platform, we use your contact details to send confirmations, receipts, and notifications. These updates help keep you informed of the status of your transactions and provide a record for your reference. Additionally, should you require assistance or encounter issues, the data we collect allows us to offer prompt and effective support, tailored to your specific inquiries or account details.",
        subContent4: "In summary, the data we collect under contractual necessity is vital to deliver the Services we have committed to provide. Without this information, we would be unable to fulfill our contractual obligations, making it essential for the continuity and effectiveness of our Services.",
      },
      {
        subName: "B.  Legitimate Interests",
        subContent1: "In some cases, we process your personal information based on our legitimate interests, ensuring that these interests are balanced with your rights and freedoms. Legitimate interests allow us to enhance our Services, create a personalized experience, and maintain a secure environment, all while respecting your privacy.",
        subContent2: "One of our key legitimate interests is Improving Service Functionality. By analyzing usage data, such as which features are most frequently accessed or where users encounter difficulties, we gain valuable insights into how our Services are used. This information enables us to identify areas for enhancement, streamline workflows, and refine the overall user experience. Continuous analysis of this data helps us tailor our platform to better meet the needs of our users, ensuring that our Services evolve to remain efficient and user-friendly.",
        subContent3: "Another area where we rely on legitimate interests is Personalizing Content to create a more relevant experience for each user. By collecting data on your preferences, we can suggest content, features, or products that align with your specific interests. For instance, if our analysis indicates a pattern in your content engagement, we may use this insight to recommend similar content or resources that enhance your experience. This personalization not only makes the platform more engaging but also allows us to offer content that is more aligned with your needs and preferences.",
        subContent4: "Finally, Security Monitoring is a fundamental legitimate interest that supports the safety and integrity of our platform. We carefully monitor user activity to detect and prevent any fraudulent or unauthorized behavior. This includes tracking logins, identifying unusual account activity, and flagging potential security risks. By maintaining vigilant oversight, we are able to protect our users from threats, uphold a secure environment, and respond quickly to potential breaches, ultimately enhancing the safety of our entire user community.",
        subContent5: "These legitimate interests form the basis of several core functions that improve, personalize, and secure our Services. We are committed to ensuring that our pursuit of these interests does not override your privacy rights and that we handle all personal information responsibly, with transparency and care."
      },
      {
        subName: "C.  Compliance with Legal Obligations",
        subContent1: "We are obligated to retain and manage certain data to fulfill various legal and regulatory requirements. This processing is essential for meeting our responsibilities related to tax, accounting, fraud prevention, and compliance with lawful requests from government and law enforcement authorities. By adhering to these obligations, we ensure that our practices align with legal standards while safeguarding our operational integrity.",
        subContent2: "One key aspect of this compliance is Record-Keeping. We maintain data records for purposes such as auditing, tax filings, and regulatory reporting, which are often mandated by law. Retaining accurate and complete records is essential for financial transparency and enables us to provide verified documentation when necessary. For instance, information regarding transactions, payment details, and account history is kept to ensure we can support audits, accurately report our financial activities, and demonstrate compliance with relevant tax regulations. Such record-keeping ensures that our data handling practices are accountable and that we meet our financial and regulatory obligations.",
        subContent3: "Additionally, we respond to Legal Requests in accordance with the law. When required by court orders or during government or law enforcement investigations, we may be legally obligated to disclose certain information. This could include user data that is relevant to a legal case, regulatory examination, or investigation. We carefully review each request to ensure that any disclosure is strictly in line with applicable legal requirements, and we limit the information shared to only what is necessary under the specific circumstances.",
        subContent4: "By retaining and disclosing data solely as required by law, we uphold both regulatory standards and the rights of our users, ensuring that compliance efforts are conducted responsibly and with full respect for legal processes. This approach allows us to balance our legal obligations with our commitment to user privacy and transparency.",
      }
    ]
  },
  {
    section: "4) How We Use Your Information",
    intro: `We use your personal information for a variety of purposes that help us operate, enhance, and secure our Services. This includes managing account functionality, processing transactions, fulfilling contractual obligations, and maintaining clear communication. Each of these purposes contributes to creating a reliable, secure, and personalized experience for our users.`,
    contentIntoContent: [
      {
        subName: "A. Service Provision and Transaction Processing",
        subContent1: "involves using your data to support core functions of the Services, ensuring that your account operates smoothly and that all transactions are processed accurately. For instance, during Order Processing, we use essential information such as your billing and shipping addresses, as well as payment confirmation details, to complete purchases. This data enables us to confirm and fulfill your orders, track shipments, and provide records of transactions. By securely handling this information, we ensure that orders are processed promptly and efficiently.",
        subContent2: "In terms of Account Management, we rely on the information provided at registration, such as your username, password, and security settings, to enable a secure and personalized experience. This data helps us identify authorized users, provide secure login access, and apply any personal settings or preferences that enhance your interaction with our platform. The use of these details is fundamental to safeguarding your account, supporting the unique settings you establish, and allowing you to access the Services confidently.",
        subContent3: "Additionally, we utilize your contact information to facilitate Support and Notifications. If you require assistance or encounter any issues, we use your contact details to provide responsive support, helping to address questions or resolve any problems that may arise. We also use this information to send you essential service notifications, such as updates on your account status, confirmation of actions taken, and alerts about important changes or maintenance activities on our platform. These notifications help keep you informed and ensure that you are aware of any developments affecting your use of the Services.",
        subContent4: "Through these efforts in order processing, account management, and ongoing communication, we use your information to create a seamless, secure, and customized Fexperience, ensuring that our Services align with your expectations and meet our contractual commitments to you",
        subContent5: "User Experience and Personalization:To enhance your experience with our Services, we use collected data to deliver content and interactions that are tailored to your preferences and interests. By personalizing various aspects of the platform, we aim to make your interactions with us more relevant, engaging, and aligned with your specific needs.",
        subContent5: "One way we achieve this personalization is through Content Recommendations. By analyzing your browsing history, interaction patterns, and stated preferences, we are able to suggest products, content, or promotions that are likely to be of interest to you. For example, if your previous activity indicates a preference for certain types of products or services, our platform may highlight similar offerings, making it easier for you to discover relevant content without extensive searching. This approach ensures that the information you see is as relevant as possible, helping you make the most of our Services in a way that suits your unique interests.",
        subContent6: "Additionally, we use data to provide Advertising Customization. With insights into your preferences and browsing behavior, we display ads that are tailored to your interests, both on our platform and on other websites where we may have advertising partnerships. This customized advertising approach enhances your experience by reducing irrelevant ads and presenting content that may genuinely interest you. Our aim is to create a seamless, targeted advertising experience that respects your preferences while aligning with your engagement on our platform.",
        subContent7: "By leveraging data in these ways, we strive to provide a user experience that feels uniquely yours, from personalized content recommendations to interest-based advertising. This personalization adds value to your interactions, ensuring that our Services adapt to your needs and present content that resonates with your interests, while also maintaining transparency and respect for your privacy preferences.",
        subContent8: "Security and Fraud Prevention:Ensuring the security of our platform and protecting users from unauthorized activity is a top priority. We continuously monitor interactions and activity across our Services to detect and prevent potential security breaches, creating a safer environment for all users.",
        subContent9: "One aspect of this security focus is Account Protection. By tracking login activities, we are able to detect unusual access patterns or behaviors that may indicate unauthorized attempts to access an account. For instance, if our system identifies logins from unfamiliar locations or repeated failed login attempts, we can take proactive steps to secure the account. This monitoring helps us safeguard your account from potential threats, ensuring that only authorized users can access their information and account settings.",
        subContent10: "In addition, we perform Threat Monitoring as part of our commitment to overall platform security. By analyzing data on user interactions and system activities, we can identify",
        subContent11: "potential security threats early, whether from external attacks or suspicious behavior within the platform. This includes monitoring for signs of malware, phishing attempts, or other malicious activities that could compromise user data or platform integrity. When such threats are detected, our security measures and protocols are activated to mitigate risks, prevent unauthorized access, and maintain the safety of the platform.",
        subContent12: "Through these ongoing efforts in account protection and threat monitoring, we aim to provide a secure and trustworthy environment, allowing you to use our Services with confidence. Our proactive approach to security ensures that we remain vigilant in identifying and responding to potential risks, prioritizing user safety at every step.",
      },
      {
        subName: "B.  Corporate Affiliates and Subsidiaries",
        subContent1: "To enhance operational efficiency and provide a seamless experience, we may share information with affiliates and subsidiaries within our corporate group. By coordinating data across these entities, we can offer consistent, high-quality service across various regions and product lines, ensuring that users receive a unified experience when interacting with our Services.",

        subContent2: "Integrated Service Delivery is one of the primary benefits of sharing information within our corporate group. By allowing different affiliates and subsidiaries to access relevant data, we can maintain a coherent service model across locations and product offerings. For example, if a user engages with our Services in multiple regions or uses several products, shared data enables us to provide a consistent experience that respects their preferences, streamlines account management, and ensures that each interaction aligns with the user’s history and needs. This integrated approach minimizes redundant data collection and improves overall user satisfaction by making transitions between services and locations seamless",

        subContent3: "Another advantage of intra-group data sharing is Unified Customer Support. By allowing affiliates and subsidiaries access to relevant user data, we can deliver responsive, personalized customer service no matter where or how the user contacts us. This shared data enables support teams to quickly access the information necessary to resolve inquiries, troubleshoot issues, and understand a user’s history with our products and services.",

        subContent4: "Whether the user reaches out to support in a different region or through a subsidiary, the shared access helps ensure that support responses are prompt, efficient, and consistent across the entire corporate group.",

        subContent5: "By sharing information responsibly within our corporate structure, we streamline operations and improve user experiences, ensuring that all affiliates uphold the same standards of privacy and data protection. This coordinated approach allows us to operate more effectively while respecting user privacy and maintaining a cohesive service framework.",

      },
      {
        subName: "C.  Business Transactions",
        subContent1: "In the event of business transactions such as mergers, acquisitions, or asset sales, it may be necessary to disclose or transfer personal information to ensure service continuity and to facilitate a smooth transition. During these transactions, we take precautions to handle personal data responsibly, only sharing information to the extent required and under strict confidentiality terms.",
        subContent2: "Asset Transfers are a common component of business transactions. When a transfer of assets occurs, relevant data—including customer and user information—may need to be shared with the acquiring entity. This transfer of data is restricted to essential business information necessary to continue providing Services without interruption. By transferring only the data critical to operations, we help maintain the quality and continuity of service for users while minimizing unnecessary data exposure. This process ensures that any new ownership or management has the necessary information to meet the ongoing expectations and requirements of our users.",
        subContent1: "In the event of business transactions such as mergers, acquisitions, or asset sales, it may be necessary to disclose or transfer personal information to ensure service continuity and to facilitate a smooth transition. During these transactions, we take precautions to handle personal data responsibly, only sharing information to the extent required and under strict confidentiality terms.",
        subContent3: "To protect the privacy of personal information during such transactions, we implement Confidentiality Agreements with any entities receiving the data. These agreements mandate that all parties adhere to strict data protection and confidentiality standards, ensuring that personal information is not disclosed or used inappropriately. Each receiving entity is obligated to follow data protection laws and maintain the same level of security and privacy for user information as required by our original policies.",
        subContent1: "In the event of business transactions such as mergers, acquisitions, or asset sales, it may be necessary to disclose or transfer personal information to ensure service continuity and to facilitate a smooth transition. During these transactions, we take precautions to handle personal data responsibly, only sharing information to the extent required and under strict confidentiality terms.",
        subContent4: "This approach ensures that personal information is handled with the utmost care and discretion during any business transitions, upholding privacy standards and ensuring that user data remains secure throughout the process. Our commitment to confidentiality and data protection helps provide peace of mind to users during corporate changes, knowing that their information remains protected even in the context of business restructuring or ownership changes.",
      },
      {
        subName: "D.  Legal Compliance",
        subContent1: "To meet our legal and regulatory responsibilities, we may disclose personal information as required by law. This compliance ensures that we uphold our legal obligations while prioritizing the protection of user rights and maintaining transparency in how data is shared with authorities.",
        subContent2: "One scenario in which we may disclose information is in response to Law Enforcement Requests. When we receive a lawful request from authorized governmental or law enforcement agencies—such as a subpoena, court order, or warrant—we are legally obligated to provide the requested data if it falls within the scope of the inquiry. This may involve sharing specific user data relevant to an investigation or legal process, with the purpose of supporting law enforcement in upholding justice and public safety. In each instance, we review the request carefully to confirm its validity and scope, ensuring that only the minimum necessary data is disclosed to fulfill the legal requirement.",
        subContent3: "We may also disclose personal information to Protect Our Rights, Safety, or Property and that of our users or the public. This may involve sharing information if we reasonably believe that it is necessary to enforce our terms and policies, prevent fraud or abuse, or respond to potential threats. For example, if we detect suspicious activity that could compromise the security of our platform or endanger user safety, we may share relevant information with legal authorities or other third parties involved in addressing the threat. This action helps us prevent harm and protect the interests of our organization, our users, and the broader community.",
        subContent4: "Through these practices, we strive to balance our legal responsibilities with our commitment to user privacy, ensuring that data is only shared when strictly necessary and in full compliance with applicable laws. This approach helps protect both the integrity of our platform and the safety of our users while adhering to legal standards and maintaining a transparent relationship with our community.",
      }
    ]
  },
  {
    section: "6) Security Measures",
    intro: `To protect your personal information, we employ a comprehensive set of technical, organizational, and administrative security measures. These safeguards are designed to prevent unauthorized access, misuse, and disclosure, ensuring that your data remains secure throughout its lifecycle with us. Our approach integrates advanced technology, strict access protocols, and continuous monitoring to maintain a high level of security.`,
    contentOne: `Encryption is used to secure sensitive data, such as payment information, both during storage and transmission. By encrypting this data, we convert it into a secure format that is only accessible to those with the appropriate decryption keys. This means that even if data is intercepted or accessed without authorization, it remains unreadable and protected.`,
    contentTwo: `Encryption plays a critical role in securing financial information and other high-sensitivity data, providing peace of mind for our users and reducing risks associated with data handling.`,
    contentThree: `Access Control measures ensure that only authorized personnel can access personal information, based on a need-to-know basis. Each employee or team member with access to personal data is carefully vetted and granted permission solely in line with their specific role and responsibilities. Access permissions are managed and reviewed regularly, with strict protocols to prevent unauthorized access and limit data exposure. This controlled approach ensures that user data is accessible only to individuals who require it for legitimate operational purposes, minimizing the risk of internal breaches or misuse.`,
    contentFour: `We also use Threat Detection tools to monitor for potential security risks in real time. These tools continuously analyze system activity to detect unusual patterns or behaviors that may indicate a security threat, such as unauthorized login attempts or malware activity. Upon identifying a potential threat, our security team can respond swiftly to mitigate risks and protect user data. By maintaining vigilant threat detection and rapid-response protocols, we are able to address security concerns proactively and minimize any potential impact on user information.`,
    contentThree: `Access Control measures ensure that only authorized personnel can access personal information, based on a need-to-know basis. Each employee or team member with access to personal data is carefully vetted and granted permission solely in line with their specific role and responsibilities. Access permissions are managed and reviewed regularly, with strict protocols to prevent unauthorized access and limit data exposure. This controlled approach ensures that user data is accessible only to individuals who require it for legitimate operational purposes, minimizing the risk of internal breaches or misuse.`,
    contentFive: `Through the implementation of encryption, controlled access, and real-time threat detection, we are committed to creating a secure environment for your data. Our layered security approach is designed to protect against evolving threats, ensuring that your information remains safe at all times.`,
  },
  {
    section: "7)   Data transfer",
    intro: `Your personal information may be transferred, stored, or processed outside of your country of residence, including in the United States.To protect your data when transferred internationally, we implement specific safeguards. For individuals residing in the European Economic Area (EEA) or the United Kingdom (UK), we ensure that transfers of personal data to other countries comply with applicable data protection regulations. This is achieved through mechanisms such as the European Commission’s Standard Contractual Clauses (SCCs) or equivalent contracts recognized by UK authorities. These contractual obligations require the recipients of your data to uphold data protection standards that align with GDPR requirements. In cases where your data is transferred to a country that the European Commission has determined provides an adequate level of protection, we may rely on this adequacy decision instead of SCCs.`,
    contentOne: `Additionally, we may share your data with trusted third-party service providers and partners located in countries outside of your own, including jurisdictions that may have less stringent data protection laws. However, we ensure that these providers and partners agree to GDPR-level protection standards through contracts incorporating SCCs or other legally recognized mechanisms. You also have the right to request further information about the transfer of your data outside the EEA or UK, including copies of the relevant transfer mechanisms.`,
  },
  {
    section: "8) Consent and User Rights",
    intro: `We value your privacy and are committed to honoring your rights regarding your personal data. Under the General Data Protection Regulation (GDPR) and other data protection laws, you have specific rights to control and manage the information we hold about you. This section outlines each of these rights and provides guidance on how to exercise them`,
    contentOne: `You have the right to access your personal information, which allows you to obtain a copy of the data we hold about you, as well as information on how we use it and who we share it with. Additionally, if you find that any of your information is inaccurate or incomplete, you have the right to rectification, enabling you to request corrections or updates. This can be done by reaching out to us via email or updating your information directly within your account settings, if applicable.`,
    contentTwo: `You also hold the right to deletion, often referred to as the “right to be forgotten,” which allows you to request that we delete your personal data when it is no longer necessary for the purposes it was collected, or if you withdraw your consent in cases where consent was required. To initiate a deletion request, simply email us, and we will review your request in line with our obligations under data protection laws.`,
    contentThree: `In some circumstances, you may wish to limit the way we process your information, and you have the right to restrict processing. For instance, you may request this restriction if you contest the accuracy of your data or if you object to processing based on legitimate grounds. Additionally, the right to data portability allows you to receive your personal data in a structured, commonly used, and machine-readable format, and, where technically feasible, you can also request that we transfer this data to another provider.`,
    contentFour: `If we process your data based on legitimate interests or for direct marketing purposes, you have the right to object to processing. For instance, you may object to receiving marketing communications at any time, and we provide a straightforward “unsubscribe” option in our emails for this purpose. If our data processing is based on your consent, you retain the right to withdraw consent at any time without affecting the lawfulness of processing conducted prior to the withdrawal. You can withdraw consent by contacting us or adjusting your account preferences where available.`,
    contentFive: `Lastly, if you believe your data protection rights have been violated, you have the right to lodge a complaint with a supervisory authority, To exercise any of these rights, please contact us at Info@theaireviews.com We will respond to your request within 30 days in accordance with legal requirements. To help us process your request efficiently, please include your full name, specify the right you wish to exercise, and provide any relevant details related to your request. Your privacy and data rights are of the utmost importance to us, and we are committed to handling your inquiries transparently and in a timely manner.`,
  },
  {
    section: "9) Privacy of Children",
    intro: `Our Services are intended for a general audience and are not directed at children under the age of 18. We do not knowingly collect or solicit any personal information from children under 18. In accordance with the Children’s Online Privacy Protection Act (COPPA) and other applicable regulations, we take the privacy of minors seriously and actively seek to prevent unauthorized data collection from children.`,
    contentOne: `If we become aware that we have inadvertently collected personal information from a child under the age of 18 without verified parental consent, we will take prompt steps to delete such information from our records. If you believe that a child under 18 may have provided us with personal information, please contact us at Info@theaireviews.com so we can take appropriate action.`,
    contentTwo: `Additionally, parents or legal guardians who believe that we may have inadvertently collected data from a child under 18 can request that such data be reviewed, deleted, or corrected by reaching out to us. We will respond to such requests in compliance with applicable laws to ensure the privacy and safety of minors.`,
  },
  {
    section: "10) Connect with us",
    intro: `If you have any questions about our privacy practices or this Privacy Policy, or if you wish to exercise any of your data rights, please do not hesitate to contact us. You can reach us by emailing Info@Theaireviews.com,where our team is ready to address your inquiries and assist with any privacy-related requests as promptly as possible.`,
  },
]

function PrivacyPolicy() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
        <Grid item container sx={{ padding: { xs: "30px 25px !important", md: "45px 52px !important" }, backgroundColor: "#13131399", borderRadius: "26px" }}>

          {privacyPolicyJson.map((section, index) => (
            <React.Fragment key={index}>
              <Box key={index} sx={{ mb: 4 }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    mb: 2,
                    color: '#FFFFFF'
                  }}
                >
                  {section.section}
                </Typography>

                <Typography
                  paragraph
                  sx={{
                    mb: 2,
                    color: '#6B6767',
                    lineHeight: 1.6
                  }}
                >
                  {section.intro}
                </Typography>

                {section.contentIntoContent && section.contentIntoContent.map((content, contentIndex) => (
                  <Box key={contentIndex} sx={{ mb: 3, pl: 2 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: '500',
                        mb: 1,
                        color: '#FFFFFF'
                      }}
                    >
                      {content.subName}
                    </Typography>
                    {content.subContent1 && (
                      <Typography
                        paragraph
                        sx={{
                          mb: 1.5,
                          color: '#6B6767',
                          lineHeight: 1.6
                        }}
                      >
                        {content.subContent1}
                      </Typography>
                    )}
                    {content.subContent2 && (
                      <Typography
                        paragraph
                        sx={{
                          mb: 1.5,
                          color: '#6B6767',
                          lineHeight: 1.6
                        }}
                      >
                        {content.subContent2}
                      </Typography>
                    )}
                    {content.subContent3 && (
                      <Typography
                        paragraph
                        sx={{
                          mb: 1.5,
                          color: '#6B6767',
                          lineHeight: 1.6
                        }}
                      >
                        {content.subContent3}
                      </Typography>
                    )}
                    {content.subContent4 && (
                      <Typography
                        paragraph
                        sx={{
                          mb: 1.5,
                          color: '#6B6767',
                          lineHeight: 1.6
                        }}
                      >
                        {content.subContent4}
                      </Typography>
                    )}
                    {content.subContent5 && (
                      <Typography
                        paragraph
                        sx={{
                          mb: 1.5,
                          color: '#6B6767',
                          lineHeight: 1.6
                        }}
                      >
                        {content.subContent5}
                      </Typography>
                    )}
                  </Box>
                ))}

                {section.contentOne && (
                  <Typography
                    paragraph
                    sx={{
                      mb: 2,
                      color: '#6B6767',
                      lineHeight: 1.6
                    }}
                  >
                    {section.contentOne}
                  </Typography>
                )}
                {section.contentTwo && (
                  <Typography
                    paragraph
                    sx={{
                      mb: 2,
                      color: '#6B6767',
                      lineHeight: 1.6
                    }}
                  >
                    {section.contentTwo}
                  </Typography>
                )}
                {section.contentThree && (
                  <Typography
                    paragraph
                    sx={{
                      mb: 2,
                      color: '#6B6767',
                      lineHeight: 1.6
                    }}
                  >
                    {section.contentThree}
                  </Typography>
                )}
                {section.contentFour && (
                  <Typography
                    paragraph
                    sx={{
                      mb: 2,
                      color: '#6B6767',
                      lineHeight: 1.6
                    }}
                  >
                    {section.contentFour}
                  </Typography>
                )}
                {section.contentFive && (
                  <Typography
                    paragraph
                    sx={{
                      mb: 2,
                      color: '#6B6767',
                      lineHeight: 1.6
                    }}
                  >
                    {section.contentFive}
                  </Typography>
                )}
              </Box>
              {
                index < privacyPolicyJson.length - 1 && (
                  <Divider
                    variant="middle"
                    sx={{
                      my: 4,
                      ml: -1,
                      mt: -1,
                      borderColor: '#fff',
                      width: '100%',
                      opacity: '0.2',
                    }}
                  />

                )
              }
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Box >
  );
}

export default PrivacyPolicy;