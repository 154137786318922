import React, { useState } from 'react';
import CommonBarChart from '../BarChart/CommonBarChart';

const LocationBarChart = ({ data, options, countries }) => {
    const [selectedValue, setSelectedValue] = useState('Monthly');
    const [selectedCountry, setSelectedCountry] = useState('UK');

    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };


    const gradientIds = ['locationGradient1', 'locationGradient2'];
    // const title = 'User data by location(UK)';
    const title = `User data by location(${selectedCountry})`;
    const height = 250;

    return (
        <CommonBarChart
            data={data}
            gradientIds={gradientIds}
            barNumbers={1}
            title={title}
            selectedValue={selectedValue}
            handleChange={handleValueChange}
            options={options}
            countries={countries}
            showMonthlyOption={true}
            selectedCountry={selectedCountry}
            handleCountryChange={handleCountryChange}
            height={height}
            showCountrySelect={true}
            color1={'#DF7055'}
        />
    );
};

export default LocationBarChart;
