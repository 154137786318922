import React, { useState } from 'react';
import { Box, Typography, styled, LinearProgress, useTheme, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CommonSelect from '../../CommonSelect/CommonSelect';
import { Tags } from 'lucide-react';

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: '#141414',
    borderRadius: '20px',
    padding: '25px',
    color: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: { // Adjust padding for smaller screens
        padding: '15px',
    },
}));

const KeywordItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 0.8,
    '&:not(:last-child)': {
        marginBottom: '6px',
    },
    padding: '2px 0',
}));

const KeywordText = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    color: '#FFFFFF',
    fontWeight: '400',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: { // Reduce font size for mobile
        fontSize: '12px',
    },
}));

const KeywordDetails = styled(Box)(({ theme }) => ({
    // display: 'flex',
    // alignItems: 'center',
    // flexDirection: 'row',
    // flex: 1,
    // marginLeft: '-150px',
    // [theme.breakpoints.down('sm')]: {
    //     marginLeft: '0px', // Reset margin for mobile
    //     justifyContent: 'space-between', // Spread items on mobile
    // },
}));

const KeywordCount = styled(Typography)(({ theme }) => ({
    marginRight: '2px',
    fontSize: '14px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
    width: '100%',
    height: '12px',
    borderRadius: '30px',
    margin: '0 10px',
    backgroundColor: 'transparent',
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#99794D',
        borderRadius: '5px',
    },
    [theme.breakpoints.down('sm')]: { // Hide progress bar on mobile
        display: 'none',
    },
}));

const KeywordRating = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '8px', // Add space on mobile between text and rating
    },
}));

const TopKeywords = ({ keywords, options }) => {
    const [selectedValue, setSelectedValue] = useState('Monthly');
    const handleChange = (event) => {
        setSelectedValue(event?.target?.value);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Ensure keywords is an array and then get top 5
    const keywordsArray = Array.isArray(keywords) ? keywords : [];
    const top5Keywords = keywordsArray?.slice(0, 5) || [];
    const isTagsData = keywordsArray?.[0]?.tag;

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Typography sx={{ color: '#CCC9C9', fontSize: '16px', fontWeight: '700', }}>
                        {isTagsData ? 'Top 5 Tags' : 'Top 5 Keywords'}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <CommonSelect value={selectedValue} onChange={handleChange} options={options} />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '20px 0 0 0',
            }}>
                {top5Keywords?.map((item, index) => (
                    <KeywordItem key={index}>
                        <KeywordText>{isTagsData ? item?.tag : item?.keyword}</KeywordText>
                        <KeywordDetails>
                            <KeywordCount>{item?.count}</KeywordCount>
                            {/* <ProgressBar variant="determinate" value={(keyword?.rating / 5) * 100} />
                            <KeywordRating>
                                {Array.from({ length: 5 }, (_, i) => (
                                    <StarIcon key={i} style={{ color: i < keyword?.rating ? '#FFC107' : '#444' }} fontSize={isMobile ? 'small' : 'default'} />
                                ))}
                            </KeywordRating> */}
                        </KeywordDetails>
                    </KeywordItem>
                ))}
            </Box>
        </Container>
    );
};

export default TopKeywords;


// import React, { useState } from 'react';
// import { Box, Typography, styled, LinearProgress, useTheme, useMediaQuery } from '@mui/material';
// import StarIcon from '@mui/icons-material/Star';
// import CommonSelect from '../../CommonSelect/CommonSelect';

// const Container = styled(Box)(({ theme }) => ({
//     backgroundColor: '#141414',
//     borderRadius: '20px',
//     padding: '25px',
//     color: 'white',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     [theme.breakpoints.down('sm')]: {
//         padding: '15px',
//     },
// }));

// const KeywordItem = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-around',
//     flex: 0.8,
//     '&:not(:last-child)': {
//         marginBottom: '6px',
//     },
//     padding: '2px 0',
// }));

// const KeywordText = styled(Typography)(({ theme }) => ({
//     fontSize: '14px',
//     fontWeight: '500',
//     flex: 1,
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '12px',
//     },
// }));

// const KeywordDetails = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     flexDirection: 'row',
//     flex: 1,
//     position: 'relative', // Ensure relative positioning for absolute elements
//     [theme.breakpoints.down('sm')]: {
//         justifyContent: 'space-between',
//     },
// }));

// const KeywordCount = styled(Typography)(({ theme }) => ({
//     marginRight: '2px',
//     fontSize: '14px',
//     [theme.breakpoints.down('sm')]: {
//         fontSize: '12px',
//     },
// }));

// const ProgressBar = styled(LinearProgress)(({ theme }) => ({
//     width: '100%',
//     height: '12px',
//     borderRadius: '30px',
//     margin: '0 10px',
//     backgroundColor: 'transparent',
//     '& .MuiLinearProgress-bar': {
//         backgroundColor: '#99794D',
//         borderRadius: '5px',
//     },
//     [theme.breakpoints.down('sm')]: {
//         display: 'none',
//     },
// }));

// // Adjust KeywordRating to be positioned according to progress value
// const KeywordRating = styled(Box)(({ theme, progress }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     position: 'absolute',
//     left: `calc(${progress}% - 10px)`, // Position stars close to actual progress
//     transform: 'translateX(-50%)', // Center the stars
//     [theme.breakpoints.down('sm')]: {
//         position: 'static', // Default positioning on mobile
//         marginLeft: '8px',
//     },
// }));

// const TopKeywords = ({ keywords, options }) => {
//     const [selectedValue, setSelectedValue] = useState('Monthly');
//     const handleChange = (event) => {
//         setSelectedValue(event?.target?.value);
//     };

//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     return (
//         <Container>
//             <Box display="flex" justifyContent="space-between" alignItems="center">
//                 <Box display="flex" alignItems="center">
//                     <Typography variant="h6" sx={{ color: '#FFFFFF', fontSize: isMobile ? '16px' : '20px' }}>
//                         Top 5 Keyword
//                     </Typography>
//                 </Box>
//                 <Box display="flex" alignItems="center">
//                     <CommonSelect value={selectedValue} onChange={handleChange} options={options} />
//                 </Box>
//             </Box>
//             <Box sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 flex: 1,
//                 padding: '20px 0 0 0',
//             }}>
//                 {keywords?.map((keyword, index) => {
//                     const progressValue = (keyword?.rating / 5) * 100; // Calculate progress percentage

//                     return (
//                         <KeywordItem key={index}>
//                             <KeywordText>{keyword?.title}</KeywordText>
//                             <KeywordDetails>
//                                 <KeywordCount>{keyword?.count}</KeywordCount>
//                                 <ProgressBar variant="determinate" value={progressValue} />
//                                 <KeywordRating progress={progressValue}>
//                                     {Array.from({ length: 5 }, (_, i) => (
//                                         <StarIcon
//                                             key={i}
//                                             style={{ color: i < keyword?.rating ? '#FFC107' : '#444' }}
//                                             fontSize={isMobile ? 'small' : 'default'}
//                                         />
//                                     ))}
//                                 </KeywordRating>
//                             </KeywordDetails>
//                         </KeywordItem>
//                     );
//                 })}
//             </Box>
//         </Container>
//     );
// };

// export default TopKeywords;
