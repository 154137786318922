import React, { useState } from 'react';
import { colors, InputBase, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: "30px",
    backgroundColor: '#1D2032',
    marginLeft: 0,
    maxWidth: '373px',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    top: "0",
    right: "0",
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "#50556A",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    // color: "#50556A",
    color: "#FFF",
    width: '100%',
    '& .MuiInputBase-input': {
        padding: "15px 16px",
        paddingRight: "40px",
        color: "#ffff",
        backgroundColor: "#131313",
        borderRadius: "30px",
        // transition: theme.transitions.create('width'),
        // [theme.breakpoints.up('sm')]: {
        //     '&:focus': {
        //         width: '20ch',
        //     },
        // },
    },
}));

// const CommonSearch = ({ placeholder, width, onSearch, ...props }) => {

//     const [searchTerm, setSearchTerm] = useState("");
//     const [timer, setTimer] = useState(0);
//     const WAIT_INTERVAL = 500;
//     return (
//         <Search>
//             <StyledInputBase
//                 width={width}
//                 placeholder={placeholder ? placeholder : "Search..."}
//                 inputProps={{ 'aria-label': 'search' }}
//                 // onChange={onSearch ? (e) => onSearch(e) : {}}
//                 onChange={(ev) => {
//                     setSearchTerm(ev.target.value);
//                     let val = (ev.target.value || "").trim();
//                     clearTimeout(timer);
//                     setTimer(
//                         setTimeout(() => {
//                             props.handleSearch && props.handleSearch(val);
//                         }, WAIT_INTERVAL)
//                     );
//                 }}
//             />
//             {/* <SearchIconWrapper>
//                 <SearchIcon />
//             </SearchIconWrapper> */}
//         </Search>
//     )
// }

const CommonSearch = ({ placeholder, width, handleSearch }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [timer, setTimer] = useState(null);
    const WAIT_INTERVAL = 500;

    const handleInputChange = (ev) => {
        const val = ev.target.value.trimStart();
        setSearchTerm(val);
        clearTimeout(timer);
        setTimer(
            setTimeout(() => {
                handleSearch && handleSearch(val); // Call handleSearch prop with updated value
            }, WAIT_INTERVAL)
        );
    };

    const handleClear = () => {
        setSearchTerm("");
        handleSearch && handleSearch("");
    }

    return (
        <Search>
            <StyledInputBase
                width={width}
                placeholder={placeholder || "Search..."}
                inputProps={{ 'aria-label': 'search' }}
                value={searchTerm}
                onChange={handleInputChange}
                endAdornment={
                    searchTerm && (
                        <div
                            onClick={handleClear}
                            style={{
                                cursor: 'pointer',
                                padding: '8px',
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                color: '#50556A',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </div>
                    )
                }
            />
        </Search>
    );
};


export default CommonSearch