import React from 'react';
import CommonChipDash from './CommonChipDash';

const QRCodeScansChip = ({ data }) => {
    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            showChipAndTypography={false}
            useSvgBackground={true}
            icon={<img src="/images/ChipIcon/QRCodeIcon.svg" alt="QR Code" width="24" height="24" />}
        />
    );
};

export default QRCodeScansChip;
