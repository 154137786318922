import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
        display: "none",
    },
});

const StyledTab = styled(Tab)(({ selected }) => ({
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "400",
    color: selected ? "transparent" : "rgba(255, 255, 255, 0.6)",
    position: "relative",
    borderRadius: "25px", // Matches the border radius of the pseudo-element
    padding: "13px 19px",
    minWidth: "auto",
    backgroundColor: selected ? "#1D1919" : "transparent",
    background: selected
        ? "linear-gradient(90deg, #FFF 0%, #F4A606 50%, #FD63C5 100%)"
        : "transparent",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: selected ? "transparent" : "none",
    marginRight: "6px",
    lineHeight: "19.09px",
    transition: "all 0.3s ease", // Smooth transition effect
    zIndex: "1", // Ensure the content is above the pseudo-element
    border: selected
        ? "1px solid transparent"
        : "1px solid rgba(255, 255, 255, 0.2)", // Light gray border for unselected
    // Pseudo-element for gradient border
    "::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "25px",
        padding: "2px", // Adjust the border width
        background: selected
            ? "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)"
            : "transparent",
        WebkitMask:
            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "destination-out",
        maskComposite: "exclude",
        zIndex: "-1", // Send the pseudo-element behind the content
    },
}));





const CustomTabs = ({ labels, selectedTab, onChange, backgroundColor, padding }) => {
    return (
        <Box
            sx={{
                backgroundColor: backgroundColor || "#141414",
                padding: padding || "20px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <StyledTabs value={selectedTab} onChange={onChange}>
                {labels.map((label, index) => (
                    <StyledTab key={index} label={label} selected={selectedTab === index} />
                ))}
            </StyledTabs>
        </Box>
    );
};


export default CustomTabs;
