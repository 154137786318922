import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Divider, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommonButton from '../../Components/Common/Button/CommonButton';
import { bussinessPlanDetails, getSubscription } from '../../Redux/Actions/action';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import ErrorHandler from '../../Utils/handler';
import { useDispatch, useSelector } from 'react-redux';

const subscriptions = [
  {
    id: 1,
    title: 'Monthly',
    price: '$89',
    description: "Perfect for those who want the flexibility of a month-to-month subscription with no commitment.",
    active: false
  },
  {
    id: 2,
    title: 'Quarterly',
    sortDescription: "(equivalent to $80/month)",
    price: '$240',
    description: "Enjoy a 10% discount when you choose our quarterly plan, making it easier to stick with us for the long run!",
    active: true
  },
  {
    id: 3,
    title: 'Yearly',
    sortDescription: "(equivalent to $75/month)",
    price: '$900',
    description: "Our best value! Save 15% with a yearly commitment and unlock maximum savings. This plan is perfect for those who want to maximize their benefits and invest in long-term results.",
    active: false
  },
];

const CardContainer = styled(Box)(({ theme, isActive }) => ({
  maxWidth: "351px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "250px",
  },
  backgroundColor: "#13131399",
  borderRadius: "26px",
  padding: "20px 30px",
  [theme.breakpoints.up("sm")]: {
    padding: "30px 38px",
  },
  textAlign: "center",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "26px",
    padding: "2px",
    background: isActive
      ? "radial-gradient(141.22% 118.61% at 21.45% 10.64%, rgba(255, 255, 255, 0.6) 0%, rgba(244, 166, 6, 0.6) 33.88%, rgba(253, 99, 197, 0.6) 100%)"
      : "#13131399",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    maskComposite: "exclude",
    zIndex: -1,
  },
}));

const ManageSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedBusiness = useSelector((state) => state?.business?.business);
  const [subscriptions, setSubscriptions] = useState([])
  const [subscriptionDetail, setSubscriptionDetail] = useState({})

  const handleSubscriptionClick = (id, plan) => {
    navigate(`/paymentdetails?id=${id?._id}&businessId=${selectedBusiness?._id}&plan=${plan}`);
  };

  const _getSubscriptionPlans = async () => {
    dispatch(showLoader(true))
    let body = {
    };
    try {
      const result = await dispatch(getSubscription(body));
      const error = ErrorHandler(result);
      if (error) {
        setSubscriptions(result?.payload?.data)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // ==> get bussiness Plan Details
  const _getBussinessPlanDetails = async () => {
    if (selectedBusiness?._id) {
      dispatch(showLoader(true));
      let body = selectedBusiness?._id;
      try {
        const result = await dispatch(bussinessPlanDetails(body));
        const error = await ErrorHandler(result);
        if (error) {
          setSubscriptionDetail(result?.payload?.data)
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  useEffect(() => {
    _getSubscriptionPlans()
    _getBussinessPlanDetails()
  }, [selectedBusiness?._id])

  return (
    <Grid container alignItems="stretch" spacing={2} justifyContent={{ xs: "center", lg: "start" }}>
      {subscriptions?.map((subscription) => (
        <Grid key={subscription?.id} item xs={12} sm={6} md={4} lg={4} xl={3} display="flex" alignItems="stretch" justifyContent={"center"}>
          <CardContainer isActive={subscription?.name === subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.name}>
            <Box display="flex" flexDirection="column" height="100%" alignItems={"center"}>
              <Typography
                fontSize={{
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  lg: "14px",
                  xl: "18px",
                }}
                lineHeight="16.71px"
                marginBottom="17px"
                color="#FFFFFF66"
              >
                {subscription?.name}
              </Typography>
              <Divider
                sx={{
                  backgroundColor: '#FFFFFF1A',
                  opacity: 0.3,
                  height: "1px",
                  width: "100%",
                  marginY: "10px"
                }}
              />

              <Box sx={{
                marginTop: {
                  xs: "20px",
                },
                marginBottom: {
                  xs: "20px",
                },
                flexGrow: 1,
              }}>
                <Typography
                  sx={{
                    background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    marginBottom: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "20px",
                      xl: "25px"
                    },
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "16px",
                      lg: "18px",
                      xl: "20px",
                    },
                    fontWeight: "700",
                    lineHeight: "23.87px",
                  }}
                >
                  {subscription?.name}
                </Typography>
                <Typography
                  fontSize="60px"
                  fontWeight={700}
                  lineHeight="20px"
                  sx={{
                    margin: "10px 0",
                    fontSize: {
                      xs: "25px",
                      sm: "25px",
                      md: "35px",
                      lg: "35px",
                      xl: "45px",
                    },
                  }}
                >
                  {`$${subscription?.price}`}
                </Typography>
                {/* <Typography
                  fontSize={{
                    xs: "12px",
                    sm: "12px", 
                    md: "12px",
                    lg: "14px",
                    xl: "14px",
                  }}
                  lineHeight="18px"
                  color="#FFF"
                  marginBottom={{
                    xs: "20px",
                    sm: "25px",
                    md: "30px",
                    lg: "35px",
                    xl: "39px",
                  }}
                  textAlign="center"
                >
                  {subscription?.sortDescription || '-'}
                </Typography> */}
              </Box>
              <Typography
                fontSize={{
                  xs: "12px",
                  sm: "12px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                }}
                lineHeight="18px"
                color="#FFFFFF66"
                marginBottom={{
                  xs: "20px",
                  sm: "25px",
                  md: "30px",
                  lg: "35px",
                  xl: "39px",
                }}
                textAlign="center"
              >
                {subscription?.description || "-"}
              </Typography>
              <CommonButton
                text="Continue"
                className="primary"
                maxWidth="138px"
                padding="12px 16px"
                disabled={subscription?.name === subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.name || subscription?.price <= subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.price}
                disabledWithColor={subscription?.name === subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.name || subscription?.price <= subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.price}
                onClick={() => handleSubscriptionClick(subscription, subscriptionDetail?.isSubscribe)}
              />
            </Box>
          </CardContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default ManageSubscription;
