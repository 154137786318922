import React from 'react'

function Note() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame 1000008403">
                <g id="Layer_3">
                    <path id="Vector" d="M13.4062 5.2207H7.25736C7.02248 5.2207 6.83203 5.44459 6.83203 5.7207C6.83203 5.99681 7.02248 6.2207 7.25736 6.2207H13.4067C13.6416 6.2207 13.832 5.99681 13.832 5.7207C13.832 5.44459 13.6411 5.2207 13.4062 5.2207Z" fill="white" />
                    <path id="Vector_2" d="M13.832 7.7207C13.832 7.44459 13.6416 7.2207 13.4067 7.2207H7.25739C7.0225 7.2207 6.83203 7.44459 6.83203 7.7207C6.83203 7.99681 7.0225 8.2207 7.25739 8.2207H13.4071C13.6416 8.2207 13.832 7.99681 13.832 7.7207Z" fill="white" />
                    <path id="Vector_3" d="M7.26296 10.2207C7.02499 10.2207 6.83203 10.4446 6.83203 10.7207C6.83203 10.9968 7.02499 11.2207 7.26296 11.2207H10.4011C10.6391 11.2207 10.832 10.9968 10.832 10.7207C10.832 10.4446 10.6391 10.2207 10.4011 10.2207H7.26296Z" fill="white" />
                    <path id="Vector_4" d="M7.57503 17.2543H4.56011C4.14433 17.2543 3.80651 16.9187 3.80651 16.5069V3.93453C3.80651 3.52218 4.14487 3.18715 4.56011 3.18715H15.104C15.5192 3.18715 15.8576 3.52272 15.8576 3.93453V8.52245C15.8576 8.78929 16.0757 9.00567 16.3448 9.00567C16.6139 9.00567 16.832 8.78929 16.832 8.52245V3.93453C16.832 2.98956 16.0568 2.2207 15.1045 2.2207H4.56011C3.60728 2.2207 2.83203 2.98956 2.83203 3.93453V16.5069C2.83203 17.4518 3.60728 18.2207 4.56011 18.2207H7.57503C7.8441 18.2207 8.06227 18.0043 8.06227 17.7375C8.06227 17.4706 7.8441 17.2543 7.57503 17.2543Z" fill="white" />
                    <path id="Vector_5" d="M17.3085 10.7447C16.6103 10.046 15.4733 10.046 14.7757 10.7447L10.3521 15.168C10.2121 15.308 10.1201 15.4855 10.087 15.6806L9.84535 17.1147C9.79403 17.4168 9.89292 17.7263 10.1094 17.9427C10.288 18.1218 10.5312 18.2207 10.7803 18.2207C10.8327 18.2207 10.8851 18.2164 10.9375 18.2073L12.3707 17.9652C12.5663 17.9326 12.7443 17.8412 12.8844 17.7006L17.308 13.2773C18.0066 12.5792 18.0066 11.4428 17.3085 10.7447ZM12.2119 17.0164L10.7942 17.2746L11.0321 15.8479L14.2155 12.6647L15.3883 13.8375L12.2119 17.0164ZM16.628 12.5968L16.0683 13.157L14.8954 11.9843L15.4557 11.4241C15.6171 11.2626 15.8299 11.1819 16.0421 11.1819C16.2543 11.1819 16.4665 11.2626 16.6285 11.4241C16.9514 11.748 16.9514 12.2735 16.628 12.5968Z" fill="white" />
                </g>
            </g>
        </svg>
    )
}

export default Note