import { Box } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

export default function ErrorHandler(data) {
  console.log("data?.payload?.status", data?.payload?.status);
  if (data?.payload?.status) {
    if (data?.payload?.status === 500 || data?.payload?.status === 502) {
      toast.error(data?.payload?.message);
      return false;
    } else if (data?.payload?.status === 404) {
      toast.error(data?.payload?.message);
      return false;
    } else if (data?.payload?.status === 400) {
      toast.error(data?.payload?.message);
      return false;
    } else if (data?.payload?.status === 409) {
      toast.error(data?.payload?.message);
      return false;
    } else if (data?.payload?.status === 403) {
      toast.error(data?.payload?.message);
      return false;
    } else if (data?.payload?.status === 401) {
      // toast.error(data?.payload?.message);
      localStorage.clear();
      // window.location.href = "/";
      return false;
    } else {
      return true;
    }
  } else {
    // swal("error", "Something went wrong!", "error");
    toast.error("Something went wrong!")
    return false;
  }
}

export const TruncatedTableCell = ({ content, align, contentLength = 80 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded((prev) => !prev);

  const truncatedContent =
    content?.length > contentLength
      ? content.substring(0, contentLength)
      : content;

  return (
    <Box align={align || "start"}>
      {isExpanded ? content : truncatedContent}
      {content?.length > contentLength && (
        <span
          onClick={toggleExpanded}
          style={{ color: "White", cursor: "pointer", marginLeft: 8 }}
        >
          {isExpanded ? "Less" : "More..."}
        </span>
      )}
    </Box>
  );
};
