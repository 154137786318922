import React from 'react'

function PrivacyPolicy({ active }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" opacity={active ? "1" : "0.3"}  fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_553_1438)">
                <path d="M11.2334 11.086L13.599 9.86326C13.8017 9.75878 14.0291 9.7035 14.2565 9.69735V4.82489C14.2565 3.82336 13.4392 3 12.4315 3H7.01843C6.53915 3 6.06602 3.19662 5.72805 3.53456L3.53448 5.72809C3.19051 6.07217 3 6.533 3 7.01843V14.3425C3 15.344 3.8171 16.1673 4.82485 16.1673H11.6267C10.9608 15.1072 10.5781 13.767 10.4284 12.6343C10.3425 12.0076 10.662 11.3809 11.2334 11.086ZM7.3441 5.45774H9.91854C10.2565 5.45774 10.533 5.73424 10.533 6.07217C10.533 6.41014 10.2565 6.68661 9.91854 6.68661H7.3441C7.00613 6.68661 6.72966 6.41014 6.72966 6.07217C6.72966 5.73424 7.00613 5.45774 7.3441 5.45774ZM7.68191 12.1551H6.07217C5.7342 12.1551 5.45774 11.8786 5.45774 11.5407C5.45774 11.1966 5.7342 10.9262 6.07217 10.9262H7.68191C8.01988 10.9262 8.29635 11.1966 8.29635 11.5407C8.29635 11.8786 8.01988 12.1551 7.68191 12.1551ZM6.07217 9.42085C5.7342 9.42085 5.45774 9.14435 5.45774 8.80641C5.45774 8.46848 5.7342 8.19198 6.07217 8.19198H11.1903C11.5283 8.19198 11.8047 8.46848 11.8047 8.80641C11.8047 9.14435 11.5283 9.42085 11.1903 9.42085H6.07217Z" fill="url(#paint0_radial_553_1438)" />
                <path d="M17.101 12.4747C17.1133 12.3518 17.0518 12.235 16.9412 12.1797L14.422 10.9508C14.3668 10.9263 14.3114 10.9201 14.2562 10.9324C14.2254 10.9324 14.1947 10.9386 14.1639 10.957L11.7985 12.1797C11.6878 12.235 11.6264 12.3456 11.6448 12.4685C11.9035 14.2468 12.6139 16.2107 14.25 16.9723C14.3237 17.0092 14.4158 17.0092 14.4958 16.9723C16.1285 16.2252 16.8412 14.2537 17.101 12.4747Z" fill="url(#paint1_radial_553_1438)" />
            </g>
            <defs>
                <radialGradient id="paint0_radial_553_1438" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.41496 4.40078) rotate(54.3209) scale(12.3874 22.0219)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
                <radialGradient id="paint1_radial_553_1438" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.8132 11.5724) rotate(52.9411) scale(5.81621 10.4958)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
                <clipPath id="clip0_553_1438">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PrivacyPolicy
