import { createTheme } from "@mui/material/styles";

// Create a theme instance.
export const defaultPrimaryColor = "#408CF5";
export const defaultWarningColor = "#FFBE5E";
export const defaultInfoColor = "#2F80ED";
export const defaultBgLightBlue = "#EAF4FE";
export const defaultBgLightBlue2 = "#00AEEF";
export const defaultBgLightGray = "#4D5975";
export const defaultBgLightExtraGray = "#DDD";
export const defaultBgLightWhite = "#FFFFFF";
export const defaultBgRejectColor = "#EF627A";
export const defaultBgSuccessColor = "#2CAE4A";
export const defaultBgBlueColor = "#4E85C5";
export const defaultBgGray = "#777777";
export const defaultBgBlack = "#131A28";
export const defaultBgDarkBlack = "#222222";
export const defaultBgLightBlack = "#B6B6B6";
export const defaultBackgroundColor = "#CCCCCC";
export const defaultBgDarkCyan = "#1ba39c1a";
export const defaultBgPurple = "#646CE1";
export const defaultBgTrendRed = "#FDE8EB";
export const defaultBgTrendOrange = "#FFF5E5";
export const defaultBgLightGreen = "#70CC4A";
export const defaultBgTrendGreen = "#469D22";
export const defaultSkyBlue = "#F1F6F8";
export const defaultdarkSkyBlue = "#EBF4FF";
export const defaultDisableIcon = "#858585";
export const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: defaultPrimaryColor,
        },
        warning: {
            main: defaultWarningColor,
        },
        info: {
            main: defaultInfoColor,
        },
        bgLightBlue: {
            main: defaultBgLightBlue,
        },
        bgLightBlue2: {
            main: defaultBgLightBlue2,
        },
        bgLightGray: {
            main: defaultBgLightGray,
        },
        bgLightExtraGray: {
            main: defaultBgLightExtraGray,
        },
        bgWhite: {
            main: defaultBgLightWhite,
        },
        error: {
            main: defaultBgRejectColor,
        },
        bgSuccess: {
            main: defaultBgSuccessColor,
        },
        bgGray: {
            main: defaultBgGray,
        },
        bgBlue: {
            main: defaultBgBlueColor,
        },
        bgBlack: {
            main: defaultBgBlack,
        },
        bgDarkBlack: {
            main: defaultBgDarkBlack,
        },
        bgLightBlack: {
            main: defaultBgLightBlack,
        },
        bgLightGreen: {
            main: defaultBgLightGreen,
        },
        bgTrendGreen: {
            main: defaultBgTrendGreen,
        },
        bgTrendRed: {
            main: defaultBgTrendRed,
        },
        backgroundDefaultColor: {
            main: defaultBackgroundColor,
        },
        bgCyan: {
            main: defaultBgDarkCyan,
        },
        bgPurple: {
            main: defaultBgPurple,
        },
        bgTrendOrange: {
            main: defaultBgTrendOrange,
        },
        bgSkyBlue: {
            main: defaultSkyBlue,
        },
        bglightBlue: {
            main: defaultdarkSkyBlue,
        },
        disable: {
            main: defaultDisableIcon,
        },
        background: { default: "#EDF4FB" },
    },
    typography: {
        button: {
            textTransform: "none",
        },
    },
    components: {
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                //disableRipple: true, // No more ripple, on the whole application 💣!
                disableTouchRipple: true,
            },
        },
        // MuiAlert: {
        //   styleOverrides: {
        //     standardSuccess: {
        //       backgroundColor: "#ABC9BB",
        //     },
        //   },
        // },
    },
});

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: defaultPrimaryColor,
        },
        warning: {
            main: defaultWarningColor,
        },
        info: {
            main: defaultInfoColor,
        },
        bgLightBlue: {
            main: defaultBgLightBlue,
        },
        bgLightBlue2: {
            main: defaultBgLightBlue2,
        },
        bgLightGray: {
            main: defaultBgLightGray,
        },
        bgLightExtraGray: {
            main: defaultBgLightExtraGray,
        },
        bgWhite: {
            main: defaultBgLightWhite,
        },
        error: {
            main: defaultBgRejectColor,
        },
        bgSuccess: {
            main: defaultBgSuccessColor,
        },
        bgGray: {
            main: defaultBgGray,
        },
        bgBlue: {
            main: defaultBgBlueColor,
        },
        bgBlack: {
            main: defaultBgBlack,
        },
        bgDarkBlack: {
            main: defaultBgDarkBlack,
        },
        bgLightBlack: {
            main: defaultBgLightBlack,
        },
        bgLightGreen: {
            main: defaultBgLightGreen,
        },
        bgTrendGreen: {
            main: defaultBgTrendGreen,
        },
        bgTrendRed: {
            main: defaultBgTrendRed,
        },
        backgroundDefaultColor: {
            main: defaultBackgroundColor,
        },
        bgCyan: {
            main: defaultBgDarkCyan,
        },
        bgPurple: {
            main: defaultBgPurple,
        },
        bgTrendOrange: {
            main: defaultBgTrendOrange,
        },
        bgSkyBlue: {
            main: defaultSkyBlue,
        },
        bglightBlue: {
            main: defaultdarkSkyBlue,
        },
        disable: {
            main: defaultDisableIcon,
        },
    },
    typography: {
        fontFamily:'Wanted Sans' ,
        button: {
            textTransform: "none",
           
        },
    },
    components: {
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                //disableRipple: true, // No more ripple, on the whole application 💣!
                disableTouchRipple: true,
            },
        },
        // MuiAlert: {
        //   styleOverrides: {
        //     standardSuccess: {
        //       backgroundColor: "#ABC9BB",
        //     },
        //   },
        // },
    },
});