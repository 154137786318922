import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import CommonButton from '../../../Components/Common/Button/CommonButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getBusinessDetails } from '../../../Redux/Actions/action';
import { showLoader } from '../../../Redux/Slices/loaderSlice';
import ErrorHandler from '../../../Utils/handler';

const useStyles = makeStyles()((theme) => ({
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "60px",
        gap: "20px",
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    imageContainer: {
        marginBottom: "46px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));
const Feedback = () => {
    const { classes } = useStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const id = new URLSearchParams(useLocation().search).get('id');
    const [googleReviewLink, setGoogleReviewLink] = useState("")
    const [googleReviewButton, setGoogleReviewButton] = useState(false)



    const onClickForOwner = () => {
        navigate(`/user/feedbackform?id=${id}`)
    }

    const onClickForGoogle = () => {
        if (googleReviewLink) {
            setTimeout(() => {
                window.open(googleReviewLink, '_blank');
            }, 1000);
        }
    };

    const _getBusinessProfile = async () => {
        dispatch(showLoader(true))
        let body = {
            _id: id,
        };
        try {
            const result = await dispatch(getBusinessDetails(body))
            const error = ErrorHandler(result)
            if (error) {
                setGoogleReviewLink(result?.payload?.data?.googleReviewsUrl)
                setGoogleReviewButton(result?.payload?.data?.isShowGoogleComplianceBtn)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false))
        }
    }

    useEffect(() => {
        _getBusinessProfile()
    }, [])

    return (
        <>
            <Grid item container>
                <Grid item xs={12} className={classes?.descriptionContainer}>
                    <Typography fontSize={"26px"} fontWeight={700} color='#FFFFFF' maxWidth={"321px"} letterSpacing={"-1px"} lineHeight={"31.03px"}
                        sx={{
                            background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}>How would you like to leave feedback?</Typography>
                    <Typography fontSize={"16px"} fontWeight={400} color='#6B6B6B' lineHeight={"22px"}>We sincerely apologize for any inconvenience you've experienced. Our goal is to make all of our customers happy, we take complaints very seriously and leave it to your discretion how you would like go express it.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes?.imageContainer}>
                    <img src="/images/user/feedbackImage.svg" alt="Company branding" width={"240px"} height={"157px"} />
                </Grid>
                <Grid item xs={12} className={classes?.buttonContainer}>
                    <CommonButton variant='contained' className='primary' text={"Resolve privately with owner"} fullWidth onClick={onClickForOwner} />
                    {googleReviewButton && <CommonButton variant='contained' className='secondary' text={"Express publicly on Google"} fullWidth onClick={onClickForGoogle} />}
                </Grid>
            </Grid>
        </>
    )
}

export default Feedback