import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import CommonBackgroundContainer from './CommonBackgroundContainer';

const TitleSection = styled.div`
  padding: 20px 20px 10px;
  background: #24211B;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`;

const Subtitle = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #888;
`;

const ChartContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column; /* Stack items for smaller screens */
  
  @media (min-width: 768px) {
    flex-direction: row; /* Side by side for larger screens */
  }
`;

const DonutChartContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin-right: 40px;

  @media (max-width: 768px) {
    margin: 0; /* Reset margin for mobile */
    margin-bottom: 20px; /* Add spacing below the chart */
  }
`;

const SvgContainer = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

const Segment = styled.path`
  stroke-width: 30;
  fill: none;
  stroke: ${props => props.fill};
`;

const CenterText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const LegendContainer = styled.div`
  flex-grow: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column; /* Stack legend items vertically */
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Star = styled.span`
  color: #ffc107;
  margin-right: 10px;
  width: 25px;
  font-size: 14px;
`;

const ProgressBar = styled.div`
  flex-grow: 1;
  height: 8px;
  background-color: rgba(255, 144, 109, 0.1);
  margin: 0 10px;
  border-radius: 5px;
  overflow: hidden;
`;

const Progress = styled.div`
  height: 100%;
  background: ${props => {
    const baseColor = 'radial-gradient(184.42% 99.8% at 21.45% 10.64%, rgba(255, 255, 255, 1) 0%, rgba(244, 166, 6, 1) 79%, rgba(253, 99, 197, 1) 100%)';
    if (props?.width === 50) return baseColor;
    if (props?.width === 20) return baseColor;
    if (props?.width === 10) return baseColor;
    return 'rgba(244, 166, 6, 0.2)';
  }};
  width: ${props => props?.width}%;
  border-radius: 5px;
`;

const Percentage = styled.span`
  width: 40px;
  font-size: 14px;
  color: ${props => props?.color};
`;

const Value = styled.span`
  width: 40px;
  text-align: right;
  font-size: 14px;
  background: radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, payload }) => {
  const RADIAN = Math.PI / 180;
  const x = cx + (innerRadius + (outerRadius - innerRadius) / 2) * Math.cos(-midAngle * RADIAN);
  const y = cy + (innerRadius + (outerRadius - innerRadius) / 2) * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="141414" textAnchor="middle" dominantBaseline="middle" fontSize={12}>
      {`${payload?.percentage}% `}
    </text>
  );
};

const calculatePercentages = (data, total) => {
  return data?.map(item => ({
    ...item,
    percentage: Math.round((item?.value / total) * 100)
  }));
};

const RatingReviewPieChart = ({ data }) => {
  const totalReviews = data?.reduce((sum, item) => sum + item?.value, 0); // Calculate total sum of values
  const updatedData = calculatePercentages(data, totalReviews);

  const colors = [
    'url(#paint0_radial_1601_9768)', // Gradient for the top section
    '#D0CCC4', // Light grey for the next section
    '#43413E', // Darker grey for others
    '#676561',
    '#807D7A',
  ];

  return (
    <CommonBackgroundContainer
      title="Rating Review"
      subtitle="Comprehensive Analysis of Customer Ratings and Feedback"
      titleFontSize='20px'
      subtitleFontSize='14px'
      titleFontWeight='700'
      subTitleFontWeight='400'
    >
      <ChartContainer>
        <DonutChartContainer>
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <defs>
                <radialGradient id="paint0_radial_1601_9768" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(43.6778 9.15375) rotate(49.9729) scale(198.596 367.004)">
                  <stop stopColor="white" />
                  <stop offset="0.79" stopColor="#F4A606" />
                  <stop offset="1" stopColor="#FD63C5" />
                </radialGradient>
              </defs>
              <Pie
                data={updatedData}
                cx="50%"
                cy="50%"
                innerRadius={55}
                outerRadius={85}
                paddingAngle={2}
                dataKey="value"
                cornerRadius={8}
                startAngle={300}
                endAngle={-60}
                labelLine={false}
                label={renderLabel}
                strokeWidth={0}
              >
                {updatedData?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <CenterText>
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{updatedData[0]?.percentage}%</div>
            <div style={{ fontSize: '14px' }}>{totalReviews} Review</div>
          </CenterText>
        </DonutChartContainer>
        <LegendContainer>
          {updatedData.map((item, index) => (
            <LegendItem key={index}>
              <Star>{5 - index}★</Star>
              <ProgressBar>
                <Progress color={item?.color} width={item?.percentage} />
              </ProgressBar>
              <Percentage color={item?.color === 'url(#gradient)' ? '#ff5722' : '#ff5722'}>
                {item?.percentage}%
              </Percentage>
              <Value>{item?.value}</Value>
            </LegendItem>
          ))}
        </LegendContainer>
      </ChartContainer>
    </CommonBackgroundContainer>
  );
};

export default RatingReviewPieChart;
