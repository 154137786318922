import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme) => ({
    descriptionContainer: {
        position: 'relative',
        padding: "40px 32px !important",
        color: "#FFF",
        backgroundColor: "#010101",
        borderRadius: "20px",
        zIndex: 1,
        margin: 0,
        width: "100%",
        display:"flex",
        flexDirection:"column",
        gap:"19px"
    },
    gradientBorder: {
        margin: 0,
        position: 'relative',
        padding: '1.5px',
        borderRadius: "22px",
        background: 'radial-gradient(141.22% 118.61% at 21.45% 10.64%, rgba(255, 255, 255, 0.4) 0%, rgba(244, 166, 6, 0.4) 33.88%, rgba(253, 99, 197, 0.4) 100%)',
        zIndex: 2,
    },
    imageContainer: {
        marginBottom: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const Success = () => {
    const { classes } = useStyles()
    return (    
        <>
            <Box className={classes.gradientBorder}>
                <Box className={classes.descriptionContainer}>
                    <Grid item xs={12} className={classes.imageContainer}>
                        <img src="/images/user/successfullImage.svg" alt="Company branding" width={"86px"} height={"86px"} />
                    </Grid>
                    <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography fontSize={"30px"} fontWeight={700} color='#FFFFFF' letterSpacing={"-1px"} lineHeight={"35.8px"} maxWidth={"242px"} textAlign={"center"}
                            sx={{
                                background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}>
                            Successfully Submitted!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} >
                        <Typography fontSize={"16px"} fontWeight={400} color='#6B6B6B' lineHeight={"22px"} maxWidth={"242px"} textAlign={"center"}>
                            Your complaint has been sent to the manager. We will do our best to make things right for you.
                        </Typography>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Success