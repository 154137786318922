import { Box, Checkbox, CircularProgress, Divider, Grid, IconButton, styled, Switch, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CommonChep from '../../Components/Common/CommonChep';
import CommonButton from '../../Components/Common/Button/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { _getgenerateFeedbackAiSuggestion, addComplainsNotes, addNotes, feedbackAutoResponse, getComplain, postReplyToComplain } from '../../Redux/Actions/action';
import ErrorHandler, { TruncatedTableCell } from '../../Utils/handler';
import { showTicketStatus, statusFilter } from '../../Utils/enum';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import CustomPagination from '../../Components/Common/CustomPagination';
import CommonConformationModal from '../../Components/Common/CommonConformationModal';
import AutoCompleteSearch from '../../Components/Common/commonAutoComplete';
import CustomDrawer from '../../Components/Common/CustomDrawer';
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import { makeStyles } from 'tss-react/mui';
import CommonModal from '../../Components/Common/CommonModel';
import Note from '../../Components/Common/icons/note';
import CommonTextEditor from '../../Components/Common/CommonTextEditor';

// Custom Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Wanted Sans",
    whiteSpace: "nowrap",
    padding: "28px 20px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Wanted Sans",
    fontWeight: 400,
    padding: "15px 20px",
    backgroundColor: "#0e0e0e",
    color: "rgba(163, 158, 158, 1)",
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0e0e0e",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomSwitch = styled(Switch)(() => ({
  width: 58,
  height: 32,
  padding: 3,
  '& .MuiSwitch-switchBase': {
    margin: "2px 0px 0px 5px",
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(26px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#000',
      },
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(90deg, #FFDD00 0%, #FF3D88 100%)',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#000',
    width: 20,
    height: 20,
    marginTop: 4
  },
  '& .MuiSwitch-track': {
    borderRadius: "30px",
    background: '#FFFFFF33',
    opacity: 1,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    boxContainer: {
      position: "relative",
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }
});


function Complains() {
  const dispatch = useDispatch()
  const { classes } = useStyles();
  const selectedBusiness = useSelector((state) => state.business.business);

  const [complains, setComplains] = useState([])
  const [filterData, setFilterData] = useState({
    business: "",
    status: "",
  })
  const [conformationModelOpen, setConformationModalOpen] = useState(false)
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedComplains, setSelectedComplains] = useState([]);
  const [checked, setChecked] = useState(false);
  const [conformationModelForAutoResponse, setConformationModelForAutoResponse] = useState(false)
  const [pendingChecked, setPendingChecked] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [updateSuggestions, setUpdateSuggestions] = useState({
    Complain: "",
    Suggestions: ""
  })
  const [editSuggestions, setEditSuggestions] = useState("")
  const [errorSuggestions, setErrorSuggestions] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  // const [modelOpenForNote, setModelOpenForNote] = useState(false)
  const [noteId, setNoteId] = useState(null)
  const [dataNote, setDataNote] = React.useState({ topicDescription: "" });
  const [error, setError] = useState({});

  const isSelected = (id) => selectedComplains?.indexOf(id) !== -1;

  // Content Change in the Note
  const handleContentChange = (content) => {
    setDataNote(prevState => ({
      ...prevState,
      topicDescription: content
    }));
  };

  const removeParagraphTags = (content) => {
    if (content.startsWith('<p>') && content?.endsWith('</p>')) {
      return content?.slice(3, -4);
    }
    return content;
  };

  const handleClick = (event, id, status) => {
    // Prevent row click event from selecting the checkbox
    event.stopPropagation();
    if (status === 3 && status === 4) return;

    const selectedIndex = selectedComplains?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedComplains, id);
    } else {
      newSelected = selectedComplains?.filter((item) => item !== id);
    }
    setSelectedComplains(newSelected);
  };

  const handleCheckboxClick = (event, id) => {
    // Prevent checkbox click event from selecting the row
    event.stopPropagation();
    handleClick(event, id);
  };

  const validation = () => {
    let valid = true

    if (!editSuggestions) {
      valid = false
      setErrorSuggestions("Please enter Suggestion")
    }

    return valid
  }

  // Table pagination onChange
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    _getComplains(newPage)
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };

  const handleConformationModel = () => {
    setConformationModalOpen(false)
    setSelectedComplains([])
  }

  const handleChangeForSwitch = (event) => {
    setConformationModelForAutoResponse(true)
    setPendingChecked(event?.target?.checked);
  };

  const handleClickForAutoResponse = () => {
    setChecked(pendingChecked);
    setConformationModelForAutoResponse(false);
    _allfeedbackAutoResponse()
  }

  // get buisness Filter Data
  const _allfeedbackAutoResponse = async () => {
    dispatch(showLoader(true))
    let body = selectedBusiness?._id
    try {
      const result = await dispatch(feedbackAutoResponse(body));
      const error = ErrorHandler(result);
      if (error) {
        _getComplains(1)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  const _getComplains = async (p) => {
    dispatch(showLoader(true));
    const pageToFetch = p || 1;
    const statusID = statusFilter?.find((val) => val?.name === filterData?.status)?.id
    let body = {
      page: pageToFetch,
      limit: rowsPerPage
    };
    if (selectedBusiness?._id) {
      body.businessId = selectedBusiness?._id
    }
    if (statusID) {
      body.status = statusID
    }
    try {
      const result = await dispatch(getComplain(body))
      const error = ErrorHandler(result)
      if (error) {
        setComplains(result?.payload?.data?.response)
        setCount(result?.payload?.data?.count)
        setChecked(result?.payload?.data?.response?.[0]?.businesseDetail?.[0]?.isAutoReplyUserFeedback)
      }
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(showLoader(false));
    }
  }

  const sendReplyToComplain = async () => {
    dispatch(showLoader(true))
    let body = {
      feedbackId: selectedComplains || []
    };
    try {
      const result = await dispatch(postReplyToComplain(body));
      const error = ErrorHandler(result);
      if (error) {
        setConformationModalOpen(false)
        setSelectedComplains([])
        _getComplains(1)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // _editComplains
  const _editComplains = async (aiGenerate) => {
    dispatch(showLoader(true))
    let body = {
      feedbackId: [updateSuggestions?._id] || [],
      suggestion: aiGenerate ? updateSuggestions?.Suggestions : editSuggestions
    };
    try {
      const result = await dispatch(postReplyToComplain(body));
      const error = ErrorHandler(result);
      if (error) {
        setDrawer(false);
        setEditSuggestions("");
        _getComplains(1);
        setErrorSuggestions("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // get buisness Filter Data
  const _getAiGenerateSuggestions = async () => {
    setIsLoading(true)
    let body = {
      feedbackId: updateSuggestions?._id
    };
    try {
      const result = await dispatch(_getgenerateFeedbackAiSuggestion(body));
      const error = ErrorHandler(result);
      if (error) {
        setUpdateSuggestions({
          ...updateSuggestions,
          Suggestions: result?.payload?.data
        })
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  };

  const _addNote = async () => {
    dispatch(showLoader(true))
    let body = {
      feedbackId: noteId,
      privateNotes: removeParagraphTags(dataNote?.topicDescription)
    };
    try {
      const result = await dispatch(addComplainsNotes(body));
      const error = ErrorHandler(result);
      if (error) {
        _getComplains(1)
        setNoteId(null)
        setDrawer(false)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  useEffect(() => {
    if (filterData?.status) {
      setPage(1);
      _getComplains(page)
    }
  }, [filterData, rowsPerPage]);

  useEffect(() => {
    if (selectedBusiness?._id) {
      setPage(1)
    }
  }, [selectedBusiness?._id])

  useEffect(() => {
    _getComplains(page)
  }, [filterData, selectedBusiness, page, rowsPerPage])

  return (
    <>
      <Grid container xs={12} spacing={0}>

        <Grid container item spacing={1} xs={12} mt={"35px"} alignItems={"center"}>
          <Grid item xs={12} sm={3} md={2}>
            <AutoCompleteSearch
              options={statusFilter?.map((val) => val?.name)}
              placeholder={"Status"}
              name={"status"}
              handleChange={(e, newValue) => {
                setFilterData({
                  ...filterData,
                  status: newValue,
                });
              }}
              fullWidth
              defaultValue={filterData?.status}
            />
          </Grid>
          {selectedComplains?.length > 0 &&
            <Grid item xs={12} sm={3} md={2} mt={{ xs: 1, sm: 0 }}>
              <CommonButton
                maxWidth={"140px"}
                fontSize={"14px"}
                text={"Post"}
                height={"45px"}
                className={"primary"}
                onClick={() => { setConformationModalOpen(true) }}
              />
            </Grid>
          }
          <Grid item xs={12} sm={selectedComplains?.length > 0 ? 6 : 9} md={selectedComplains?.length > 0 ? 8 : 10} display={"flex"}
            justifyContent={"flex-end"}
            mt={{ xs: 1, sm: 0 }}>
            <Tooltip title="Auto Response">
              <CustomSwitch
                checked={checked}
                onChange={handleChangeForSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={"32px"} sx={{ borderRadius: "26px", overflow: "hidden" }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: "#131313", color: "white" }}>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selectedComplains.length > 0 &&
                        selectedComplains.length < complains.filter((item) => item?.status !== 3 && item?.status !== 4).length
                      }
                      checked={
                        complains.filter((item) => item.status !== 3 && item.status !== 4).length > 0 &&
                        selectedComplains.length === complains.filter((item) => item.status !== 3 && item.status !== 4).length
                      }
                      onChange={(event) => {
                        const newSelected = event.target.checked
                          ? complains.filter((item) => item.status !== 3 && item.status !== 4).map((item) => item._id)
                          : [];
                        setSelectedComplains(newSelected);
                      }}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          color: "#666666",
                        },
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Complain</StyledTableCell>
                  <StyledTableCell>Suggestions</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  {/* <StyledTableCell>Note</StyledTableCell> */}
                </TableRow>
              </TableHead>
              {complains?.length > 0 ? (<>
                <TableBody>
                  {complains?.map((item) => {
                    const isItemSelected = isSelected(item?._id);

                    return (
                      <StyledTableRow
                        key={item?._id}
                        hover
                        selected={isItemSelected}
                      >
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={(event) => handleCheckboxClick(event, item?._id)}
                            disabled={item?.status === 3 || item.status === 4}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: "#666666",
                              },
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{item?.name}</StyledTableCell>
                        <StyledTableCell>
                          {/* <Box
                          sx={{
                            width: item?.feedback?.length > 20 ? "200px" : "aut300o",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          >
                          </Box> */}
                          <TruncatedTableCell content={item?.feedback} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box display={"flex"} alignItems={"center"} justifyContent={"start"} gap={1} mb={2}>
                            <TruncatedTableCell content={item?.suggestions} />
                            {item?.status !== 3 && item.status !== 4 && selectedComplains?.length === 0 &&
                              <CommonButton
                                text={"Post"}
                                padding={"12px 16px"}
                                className={"primary"}
                                maxWidth={"48px"}
                                height={"25px"}
                                fontSize={"10px"}
                                // onClick={() => { setConformationModalOpen(true); setSelectedComplains([...selectedComplains, item?._id]) }}
                                onClick={() => {
                                  setDrawer(true);
                                  setNoteId(item?._id);
                                  setDataNote({
                                    topicDescription: item?.privateNotes
                                  });
                                  setUpdateSuggestions({
                                    Complain: item?.feedback,
                                    Suggestions: item?.suggestions,
                                    _id: item?._id
                                  })
                                }}
                              />
                            }
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <CommonChep label={showTicketStatus(item?.status)} />
                        </StyledTableCell>
                        {/* <StyledTableCell align='center'>
                          <IconButton onClick={() => {
                            setModelOpenForNote(true);
                            setNoteId(item?._id);
                            setDataNote({ topicDescription: item?.privateNotes });
                          }}>
                            <Note />
                          </IconButton>
                        </StyledTableCell> */}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
                {/* <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ padding: 0 }}>
                      <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
                        <CustomPagination
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          count={Math.ceil(count / rowsPerPage)}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter> */}
              </>) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <img src="/images/noDataFound.svg" alt="no data" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {complains?.length > 0 && (
            <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
              <CustomPagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={Math.ceil(count / rowsPerPage)}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
          {conformationModelOpen &&
            <CommonConformationModal
              open={conformationModelOpen}
              onClose={() => { handleConformationModel() }}
              maxWidth={"sm"}
              question={"Are you sure you want to post this response?"}
              onSubmit={() => { sendReplyToComplain(); }}
            />
          }
          {conformationModelForAutoResponse &&
            <CommonConformationModal
              open={conformationModelForAutoResponse}
              onClose={() => { setConformationModelForAutoResponse(false) }}
              onSubmit={() => { handleClickForAutoResponse() }}
              maxWidth={"sm"}
              question={!checked ? "Are you sure you want to on this switch? All pending responses will be submitted after this." : "Are you sure you want to off this switch?"}
            />
          }
        </Grid >

        <CustomDrawer isDrawerOpen={drawer}
          handleClose={() => { setDrawer(false); setErrorSuggestions(""); }}
          title={"Update Suggestions"}>

          <Grid container position={"absolute"} mt={6} spacing={1} padding={"25px"} >
            <Grid item xs={12}>
              <TextLabel title={"1. Complain"} color={'#FFFFFF'} fontSize={"18px"} fontWeight={600} lineHeight={"21.48px"} />
              <TextLabel title={updateSuggestions?.Complain} marginTop={"12px"} color={'#6C6C6C'} fontSize={"15px"} fontWeight={400} lineHeight={"22px"} />
            </Grid>
            <Grid item xs={12} mt={2}><Divider /></Grid>
            <Grid item xs={12} mt={2}>
              <TextLabel title={"2. Ai Suggestions"} color={'#FFFFFF'} fontSize={"18px"} fontWeight={600} lineHeight={"21.48px"} />
              <TextLabel title={updateSuggestions?.Suggestions} marginTop={"12px"} color={'#6C6C6C'} fontSize={"15px"} fontWeight={400} lineHeight={"22px"} />
            </Grid>

            <Grid item xs={12} mt={"20px"} display={"flex"} justifyContent={'flex-end'} gap={2} >
              <CommonButton
                className="secondary"
                borderRadius={"30px"}
                text={"Regenerate"}
                maxWidth={"149px"}
                height={"47px"}
                onClick={() => _getAiGenerateSuggestions()}
              />
              <CommonButton
                className="primary"
                borderRadius={"30px"}
                text={"Submit"}
                maxWidth={"118px"}
                height={"47px"}
                onClick={() => { _editComplains(true) }}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <CommonTextField
                text={"Suggestion"}
                type={"text"}
                placeholder={"enter your Suggestion"}
                name={"complain"}
                multiline
                rows={4}
                onChange={(e) => {
                  setEditSuggestions(e.target.value)
                }}
                value={editSuggestions}
              />
            </Grid>
            <Grid item xs={12} mt={9}>
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!editSuggestions ? errorSuggestions : ""}
              />
            </Grid>

            <Grid item xs={12} mt={"20px"} display={"flex"} justifyContent={'flex-end'}>
              <CommonButton
                className="primary"
                borderRadius={"30px"}
                text={"Submit"}
                maxWidth={"118px"}
                height={"47px"}
                onClick={() => {
                  if (validation()) {
                    _editComplains(false)
                  }
                }}
              />
            </Grid>

            {/* Display the Note Form below the Submit Button */}
            <Grid item xs={12}>
              <TextLabel title={"Note"} color={'#FFFFFF'} fontSize={"18px"} fontWeight={600} lineHeight={"21.48px"} />
              <CommonTextEditor
                text="Topic Description"
                handleContentChange={handleContentChange}
                value={dataNote?.topicDescription}
                valid
              />
              <TextLabel
                marginTop={"5px"}
                fontSize={"12px"}
                color={"error"}
                title={!dataNote?.topicDescription ? error?.topicDescription : ""}
              />
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={1}>
              <CommonButton
                maxWidth={"90px"}
                text={"Submit"}
                height={"40px"}
                className={"primary"}
                onClick={() => {
                  if (!dataNote?.topicDescription) {
                    setError({ ...error, topicDescription: "please enter note first" })
                  } else {
                    _addNote()
                  }
                }}
              />
            </Grid>

          </Grid>

          {isLoading &&
            <Box className={classes.boxContainer}>
              <div className="loader-container">
                <div className="spinner-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <h2>Loading</h2>
              </div>
            </Box>}


        </CustomDrawer>
      </Grid >

      {/* {modelOpenForNote && (
        <CommonModal
          open={modelOpenForNote}
          onClose={() => {
            setModelOpenForNote(false);
            setError({});
            setDataNote({ topicDescription: "" });
            setNoteId(null);
          }}
          title={"Notes"}
          maxWidth={"md"}
          content={
            <Grid
              container
              xs={12}
              padding={{ xs: "12px 16px", sm: "20px 20px" }}
              sx={{ backgroundColor: "#222222" }}
            >
              <Grid item xs={12}>
                <CommonTextEditor
                  text="Topic Description"
                  handleContentChange={handleContentChange}
                  value={dataNote?.topicDescription}
                  valid
                />
                <TextLabel
                  marginTop={"5px"}
                  fontSize={"12px"}
                  color={"error"}
                  title={!dataNote?.topicDescription ? error?.topicDescription : ""}
                />
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={1}>
                <CommonButton
                  maxWidth={"90px"}
                  text={"Submit"}
                  height={"40px"}
                  className={"primary"}
                  onClick={() => {
                    if (!dataNote?.topicDescription) {
                      setError({ ...error, topicDescription: "please enter note first" })
                    } else {
                      _addNote()
                    }
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      )} */}
    </>
  )
}

export default Complains
