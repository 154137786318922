import React from 'react'
import { Box, Grid, Typography } from '@mui/material'


const TermsandConditionsData = [
    {
        section: "Acceptance of Terms",
        content: `Welcome to theaireviews.com (the “Website”). By accessing, browsing, or using this Website, or by purchasing any products or services offered by theaireviews (the “Company”), you are agreeing to comply with and be bound by these Terms and Conditions (the “Terms”). This document serves as a legally binding contract (the “Agreement”) between you (“you,” “your,” or the “Client”) and the Company. By continuing to use our Website or any services offered on or through the Website, you acknowledge and accept these Terms.`,
        contentOne: `If you are accessing these Terms on behalf of a company or any other legal entity, you confirm that you have the authority to bind that entity to these Terms. In such cases, “you,” “your,” and “Client” refer to that entity. If you do not have this authority, or if you do not agree with any part of these Terms, you must avoid using the Website and any services provided by the Company.`
    },
    {
        section: "Our Services",
        content: `Theaireviews provides a range of online review management and reputation enhancement services (“Services”) designed to help clients manage and improve their online presence. Detailed descriptions of each Service are available on individual pages accessible through the Website. We reserve the right to update or modify the scope, content, and features of our Services at our discretion. These changes may include enhancements, adjustments to reflect evolving service terms, compliance with regulatory requirements, or other modifications aimed at enhancing the effectiveness of our Services and addressing the needs of our clients.`
    },
    {
        section: "User Accounts and Passwords",
        content: `To access certain features or services on theaireviews.com, you may need to create an account, which includes setting up a unique ID and password. You bear full responsibility for keeping your login credentials confidential and secure. Any activity conducted through your account is your responsibility, especially if it results from a failure to safeguard your login information. If you suspect unauthorized use of your account or a potential security breach, please inform us immediately. While we prioritize protecting user accounts, theaireviews is not liable for any losses or damages that may arise from a failure to adhere to these security measures. We urge you to exercise diligence in keeping your account information safe to ensure a secure and reliable experience.`
    },
    {
        section: "Links to Third-Party Sites",
        content: `Theaireviews.com may feature links to external websites (“Linked Sites”) to provide additional convenience for our users. These Linked Sites operate independently, and we have no control over their content or resources. We neither endorse nor assume responsibility for the information, materials, or content found on these Linked Sites. We encourage you to exercise your own discretion and judgment when accessing or interacting with any Linked Sites, as your engagement with these third-party sites is separate from your use of theaireviews.com.`
    },
    {
        section: "Feedback",
        content: `Any feedback or information you share with us regarding the Site or our Services will be considered non-confidential. We reserve the right to use this information freely and without limitations. Your feedback helps us improve our offerings, and by providing it, you agree that we may use, share, or implement your suggestions without any restrictions.`
    },
    {
        section: "Changes to This Agreement",
        content: `We may revise, update, or discontinue any part of this Agreement at our discretion. These changes become effective as soon as the updated terms are posted on our website. You can check the “last updated” date at the top of this Agreement to see when it was last modified. By continuing to use the site or services after new terms are posted, you agree to the revised terms. Only modifications provided in writing and signed by our authorised representative, or posted directly to the site by an authorised representative, will be considered binding.`
    },
    {
        section: "Billing and Account Information Accuracy",
        content: `We reserve the right to decline any order placed on our site. At our discretion, we may limit or cancel quantities bought per individual, household, or order, which may apply to orders using the same account, credit card, billing, or shipping address. If we make changes to or cancel an order, we will attempt to notify you using the contact information provided during checkout. We may also restrict or refuse orders that seem intended for resale by dealers, resellers, or distributors. By purchasing from our site, you agree to provide accurate and up-to-date billing and account information. This includes keeping your email address, credit card details, and expiration dates current, so we can complete transactions and contact you as needed.`
    },
    {
        section: "Intellectual Property Rights",
        content: `All materials and content available on theaireviews.com, including but not limited to text, graphics, logos, images, icons, software, videos, audio, and design elements, are the property of theaireviews or are licensed by third-party providers. This content is protected by copyright, trademark, and other intellectual property laws, safeguarding the ownership rights of theaireviews and our licensors. This protection ensures that theaireviews maintains exclusive rights over the content, preventing unauthorised copying, distribution, and use.`,
        contentOne: `Your access to the site does not grant you ownership or any rights to the content beyond what is explicitly permitted. The content provided on theaireviews.com is intended for your personal, non-commercial use only, and any other use without prior written authorization is strictly prohibited. You may not copy, reproduce, modify, distribute, display, transmit, sell, lease, or otherwise exploit any content on the site for commercial or public purposes without obtaining explicit written permission from theaireviews. Additionally, creating derivative works based on any content, attempting to reverse-engineer any software or digital content, or using our intellectual property in any way that could mislead the public or harm our brand is strictly forbidden.`,
        contentTwo: `Unauthorised use of any materials, such as copying or distributing content without permission, constitutes a violation of intellectual property rights and may result in legal consequences. Theaireviews reserves the right to pursue legal action for infringement of our intellectual property rights, including seeking civil or criminal penalties where applicable. By using our site, you agree to respect and adhere to these intellectual property protections, recognizing the ownership rights of theaireviews and our content providers.`,
        contentInToContent: [
            {
                subName: "User Responsibilities",
                subContent: `By accessing or using theaireviews.com, you agree to follow all applicable laws, regulations, and the terms outlined in this Agreement. Your use of our site signifies your acceptance of responsibility for any actions or activities performed under your account. This includes safeguarding your account information, such as your username and password, and ensuring that it remains secure and confidential. You are solely accountable for any actions taken under your account, even if they occur without your explicit permission.
        
        To maintain the security and functionality of our site, certain actions are strictly prohibited. These include, but are not limited to:`
            },
            {
                subName: "Interfering with the Site’s Functionality",
                subContent: `You agree not to engage in any activity that disrupts, damages, or impairs the normal operation of the site. This includes, but is not limited to, using bots, scripts, or other automated tools that strain the site’s infrastructure or interfere with other users’ experience.`
            },
            {
                subName: "Introducing Harmful or Malicious Code",
                subContent: `You must not upload, transmit, or distribute viruses, worms, malware, spyware, or any other malicious software designed to harm the site, its users, or its systems. Such activities are not only harmful but also constitute serious breaches of cybersecurity laws.`
            },
            {
                subName: "Unauthorised Collection of Information",
                subContent: `Harvesting or collecting any information about other users, including but not limited to their personal information or contact details, without their explicit consent, is strictly forbidden. You must refrain from scraping data, accessing areas of the site that are not publicly accessible, or using information gathered on the site for purposes unrelated to your use of our services.`
            },
            {
                subName: "Attempting Unauthorised Access",
                subContent: `You may not attempt to gain unauthorised access to any part of the site, including restricted areas, theaireviews’ systems, servers, or databases. Hacking, password mining, or any other methods used to breach the security of the site’s infrastructure are prohibited and may be subject to criminal prosecution.`,
                subContentTwo: `Your responsibility extends to ensuring your actions do not compromise the security and functionality of our site for other users. Any violations of these responsibilities may result in immediate suspension or termination of your account and access to theaireviews.com, and we reserve the right to take legal action if necessary.`
            },
        ]
    },
    {
        section: "Termination",
        content: `Theaireviews reserves the right to terminate or suspend your access to our site and services at any time, without prior notice, if we determine that you have violated any of the terms outlined in this Agreement. This includes, but is not limited to, engaging in activities that are deemed harmful, disruptive, or detrimental to theaireviews, its users, or its operations.`,
        contentOne: `Termination may be enforced at our sole discretion, and theaireviews is not obligated to provide you with any justification or explanation for such actions.In the event of termination, all rights, permissions, and access granted to you under this Agreement will immediately cease. This means that you will no longer be able to access the site, use any of the services, or benefit from any features associated with theaireviews. Furthermore, any data or content you have uploaded or stored on our platform may no longer be accessible.You acknowledge that theaireviews is not liable for any damages or losses, financial or otherwise, resulting from the termination or suspension of your access to the site or services. By agreeing to these terms, you understand and accept that termination is a necessary measure to protect the integrity, security, and proper functioning of theaireviews.com, as well as the safety of its users and systems. Additionally, termination may occur without any liability or obligation on the part of theaireviews to compensate or provide redress to you for any interruption of access or use of the site and services.`
    },
    {
        section: "Privacy Policy",
        content: `At theaireviews, we prioritize the privacy and security of our users. Our Privacy Policy outlines in detail how we collect, use, store, and protect your personal information when you interact with theaireviews.com. This information may include data collected during account creation, such as your name, email address, and payment information, as well as information gathered from your usage patterns and preferences on the site.`,
        contentOne: `Our Privacy Policy is designed to provide transparency about the types of information we collect and how we use it to enhance your experience on our platform. We use this information to improve our services, personalise your experience, communicate with you,and for any other purpose as outlined in the policy. Additionally, we have implemented security measures to protect your data from unauthorised access, alteration, or disclosure.`,
        contentTwo: `For your convenience, the full Privacy Policy is available on our website, where you can review it to gain a complete understanding of our practices, your rights, and our obligations regarding your data. We encourage you to read the Privacy Policy thoroughly and to check for updates periodically, as continued use of our site constitutes acceptance of the terms outlined therein.`,
    },
    {
        section: "Content Submitted by Users",
        content: `If you provide us with comments, suggestions, ideas, proposals, or other submissions—whether in response to a specific request from us or of your own accord, and whether submitted online, by email, or through other means—you agree that we have the unrestricted right to use, modify, publish, distribute, and translate these submissions in any medium. We are not obligated to keep any submissions confidential, compensate you for them, or respond to them.We reserve the right, though not the obligation, to review, edit, or remove content that we deem inappropriate, offensive, unlawful, or in violation of these Terms, including content that infringes on someone else’s intellectual property rights. This includes, but is not limited to, material we find threatening, defamatory, obscene, or otherwise objectionable.When you submit comments, you agree that they will not violate the rights of any third party, such as copyright, trademark, privacy, or any personal or proprietary rights. Additionally, you agree that your comments will not contain harmful or unlawful content, such as abusive language, obscene material, or malware that could affect the site’s functionality. You are also prohibited from using a false email address, impersonating someone else, or misleading us or others regarding the origin of your comments.`
    },
    {
        section: "Information Updates and Corrections",
        content: `At times, there may be unintentional errors, inaccuracies, or missing information on our site or in our services. These issues might relate to product details, pricing, promotions, offers, shipping costs, delivery estimates, or availability. We reserve the right to make corrections to any such errors, update or change information, or even cancel orders if we discover inaccuracies on our site or affiliated websites. This can occur at any time without prior notice, including after an order has been submitted.We are not obligated to routinely revise or clarify information on our site or affiliated sites, such as pricing information. Additionally, any dates noted for updates or changes should not be taken to mean that all information in our services or on related sites has been fully updated.`
    },
    {
        section: "Disclaimer of Guarantees and Liability",
        content: `We make no promises that your experience with our service will be seamless, on-time, secure, or free from errors. We also do not guarantee that any outcomes or results from using our service will be accurate or reliable.`,
        contentOne: `From time to time, we may need to temporarily remove or permanently discontinue the service without notice, and you agree that we have the right to do so.`,
        contentTwo: `Your use of our service is entirely at your own risk. All services and products provided through our platform are delivered “as is” and “as available” without any warranties, guarantees, or conditions of any kind, unless explicitly stated. This includes, but is not limited to, implied warranties of merchantability, quality, suitability for a particular purpose, durability, title, or non-infringement.Certain regions or states do not allow limitations on incidental or consequential damages, so in those jurisdictions, our liability will be limited to the maximum extent permitted by law.In no event shall theaireviews, its team members, affiliates, agents, contractors, suppliers, or service providers be liable for any injury, loss, or damage, including but not limited to direct, indirect, incidental, punitive, special, or consequential damages of any kind. This includes lost profits, revenue, savings, data, or replacement costs, even if we have been advised of the possibility of such damages. These limitations apply to any claims arising from your use of the service or any products obtained through it, as well as any other issues related to your use of the site, such as errors or omissions in content or any damage resulting from content made available through the service.`
    },
    {
        section: "Legal Framework",
        content: `These Terms of Service, along with any additional agreements under which we provide our services, are governed by and interpreted according to the laws of the country in which our business is registered. This means that any legal matters or disputes related to these terms or our services will be handled under the legal framework and regulations of that jurisdiction.`
    },
    {
        section: "Preservation of Agreement Terms",
        content: `If any part of these Terms of Service is found to be illegal, invalid, or unenforceable under applicable law, that portion will still be enforceable to the greatest extent allowed. The specific section deemed unenforceable will be treated as if it has been removed from these Terms, without impacting the overall validity or enforceability of the remaining provisions.`,
        contentOne: `This means that even if a particular clause is considered void, the rest of the Agreement remains intact and binding for both parties. This approach ensures that the essential terms and conditions continue to govern the relationship, preserving the integrity of the Agreement as a whole.`
    },
    {
        section: "Connect with us",
        content: `If you have any questions, comments, or concerns about these Terms and Conditions, or if you need assistance with any aspect of your use of theaireviews, please don’t hesitate to get in touch. You can reach our team by emailing info@theaireviews.com. We are here to support you and address any inquiries to ensure you have a seamless experience with our services.`
    },
];


const TermsandConditions = () => {
    return (
        <>
            <Grid container >
                <Grid item container sx={{ padding: { xs: "30px 25px !important", md: "45px 52px !important" }, backgroundColor: "#13131399", borderRadius: "26px" }}>
                    {TermsandConditionsData?.map((data, index) => (
                        <Grid item xs={12} key={index} sx={{ paddingBottom: index !== TermsandConditionsData.length - 1 ? "26px" : "0px", paddingTop: index === 0 ? "0px" : "30px", borderBottom: index !== TermsandConditionsData.length - 1 ? "1px solid #FFFFFF1A" : "none" }}>
                            <Box display={"flex"} flexDirection={"column"} gap={"11px"}>
                                <Typography fontSize={"16px"} fontWeight={500} lineHeight={"19.09px"}>{`${index + 1}. ${data?.section} `}</Typography>
                                <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"}>{data?.content}</Typography>
                                {data?.contentOne && <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"} mt={1}>{data?.contentOne}</Typography>}
                                {data?.contentTwo && <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"} mt={1}>{data?.contentTwo}</Typography>}
                                {data?.contentThree && <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"} mt={1}>{data?.contentThree}</Typography>}
                                {data?.contentFour && <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"} mt={1}>{data?.contentFour}</Typography>}
                                <Box display={"flex"} flexDirection={"column"} gap={"11px"} mt={2}>
                                    {data?.contentInToContent?.map((val, i) => (
                                        <Box display={"flex"} flexDirection={"column"} gap={"11px"} key={i} mt={1}>
                                            <Typography fontSize={"16px"} fontWeight={700} lineHeight={"19.09px"} color={"#7A7A7A"}>
                                                {`${i + 1}. ${val?.subName} :`}
                                            </Typography>
                                            <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"} mt={0}>
                                                {val?.subContent}
                                            </Typography>
                                            <Typography fontSize={"16px"} fontWeight={500} lineHeight={"32px"} color={"#6B6767"} mt={0}>
                                                {val?.subContentTwo}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>

                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}

export default TermsandConditions
