export const showTicketStatus = (order) => {
    return order === 1
        ? "Pending"
        : order === 2
            ? "In Progress"
            : order === 3
                ? "Resolve"
                : "Cancelled"
};

export const statusFilter = [
    { name: "Pending", id: 1 },
    { name: "In progress", id: 2 },
    { name: "Resolved", id: 3 },
    { name: "Cancelled", id: 4 },
]

export const ratings = [
    { label: '05 /', stars: 5 },
    { label: '04 /', stars: 4 },
    { label: '03 /', stars: 3 },
    { label: '02 /', stars: 2 },
    { label: '01 /', stars: 1 }
];