import React from 'react'

function Reviewsicon({ active }) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" opacity={active ? "1" : "0.3"}  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5293 7.97932L13.574 8.36519C13.4511 8.41506 13.3218 8.44024 13.1901 8.44024C12.9078 8.44024 12.6348 8.32113 12.4416 8.11343C12.2494 7.90718 12.1501 7.62685 12.17 7.34458L12.2421 6.3172L11.5793 5.52899C11.4321 5.35372 11.3585 5.13924 11.3483 4.91943H5.06736C3.92958 4.91943 3 5.84902 3 6.99163V12.7531C3 13.8957 3.92958 14.8205 5.06736 14.8205H6.64087L6.60698 15.784C6.5973 16.1761 6.80549 16.5344 7.15408 16.7184C7.30417 16.7958 7.46394 16.8346 7.62856 16.8346C7.84159 16.8346 8.05462 16.762 8.23376 16.6264L10.6352 14.8205H13.4046C14.5423 14.8205 15.4719 13.8957 15.4719 12.7531V8.36035L14.5293 7.97932ZM9.47659 11.4072H6.93524C6.69462 11.4072 6.4995 11.212 6.4995 10.9714C6.4995 10.7308 6.69462 10.5357 6.93524 10.5357H9.47659C9.71722 10.5357 9.91233 10.7308 9.91233 10.9714C9.91233 11.212 9.71722 11.4072 9.47659 11.4072ZM11.7294 9.20618H6.93621C6.69558 9.20618 6.50047 9.01106 6.50047 8.77043C6.50047 8.52981 6.69558 8.33469 6.93621 8.33469H11.7294C11.97 8.33469 12.1651 8.52981 12.1651 8.77043C12.1651 9.01106 11.97 9.20618 11.7294 9.20618Z" fill="url(#paint0_radial_553_667)" />
            <path d="M14.7811 3.1398L15.4827 4.26256L16.7671 4.58259C16.9802 4.63585 17.0639 4.89342 16.923 5.06191L16.0719 6.07574L16.1644 7.39604C16.1799 7.61488 15.9605 7.77465 15.7567 7.69235L14.5294 7.19608L13.302 7.69235C13.0987 7.77465 12.8789 7.61536 12.8944 7.39604L12.9868 6.07574L12.1357 5.06191C11.9943 4.89391 12.0786 4.63585 12.2916 4.58259L13.5761 4.26256L14.2776 3.1398C14.3933 2.9534 14.6649 2.9534 14.7811 3.1398Z" fill="url(#paint1_radial_553_667)" />
            <defs>
                <radialGradient id="paint0_radial_553_667" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.67572 6.187) rotate(48.6795) scale(12.1239 22.5594)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
                <radialGradient id="paint1_radial_553_667" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.1229 3.50153) rotate(48.7256) scale(4.79357 8.91772)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default Reviewsicon
