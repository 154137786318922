import { Box, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CommonChep from '../../Components/Common/CommonChep';
import CommonButton from '../../Components/Common/Button/CommonButton';
import CommonModal from '../../Components/Common/CommonModel';
import CommonTextField from '../../Components/Common/Fields/TextField'
import ErrorHandler, { TruncatedTableCell } from '../../Utils/handler';
import { addBusinessComplains, addNotes, getBusinessComplains, getMyBusinessWithAdmin, updateComplainStatus } from '../../Redux/Actions/action';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { showTicketStatus, statusFilter } from '../../Utils/enum';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import CustomPagination from '../../Components/Common/CustomPagination';
import sessionStoreUtil from '../../ApiSetup/Localstorage';
import AutoCompleteSearch from '../../Components/Common/commonAutoComplete';
import SelectDropDown from '../../Components/Common/SelectDropDown';
import CommonConformationModal from '../../Components/Common/CommonConformationModal';
import CommonCircularProgress from '../../Components/Common/Charts/CommonCircularProgress';
import Note from '../../Components/Common/icons/note';
import CommonTextEditor from '../../Components/Common/CommonTextEditor';
import TextLabel from '../../Components/Common/Fields/TextLabel';
// import { setBusiness } from '../../Redux/Slices/businessId';

// Custom Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 400,
        fontSize: 16,
        color: "rgba(255, 255, 255, 1)",
        fontFamily: "Wanted Sans",
        whiteSpace: "nowrap",
        padding: "28px 20px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Wanted Sans",
        fontWeight: 400,
        padding: "18px 20px",
        backgroundColor: "#0e0e0e",
        color: "rgba(163, 158, 158, 1)",
        // borderBottom: "none",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#0e0e0e",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


function HelpDesk() {

    const dispatch = useDispatch()
    const userData = sessionStoreUtil?.getLSItem("userData");
    const loginData = userData ? JSON.parse(userData) : null;
    const selectedBusiness = useSelector((state) => state?.business?.business);
    const selectedAdmin = useSelector((state) => state.admin.admin)


    const [data, setData] = useState({
        description: ""
    });
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({});
    const [businessComplains, setBusinessComplains] = useState([])

    // Table pagination states
    const [count, setCount] = useState(0)  // set count direct in get data
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [filterData, setFilterData] = useState({
        business: "",
        status: "",
    });
    const [manageStatus, setManageStatus] = useState(null)
    const [manageStatusId, setManageStatusId] = useState(null)
    const [modelOpenForManageStatus, setModelOpenForManageStatus] = useState(false)
    const [chart, setChart] = useState()
    const [businessData, setBusinessData] = React.useState([]);


    const chartData = [
        {
            value: chart?.pending || 0,
            color: "rgba(244, 166, 6, 1)",
            label: chart?.total || 0,
            subLabel: "Pending"
        },
        {
            value: chart?.inProgresss || 0,
            color: "rgba(255, 98, 0, 1)",
            label: chart?.total || 0,
            subLabel: "In progress"
        },
        {
            value: chart?.resolve || 0,
            color: "rgba(5, 239, 99, 1)",
            label: chart?.total || 0,
            subLabel: "Resolved"
        },
        {
            value: chart?.canceled || 0,
            color: "rgba(164, 6, 5, 1)",
            label: chart?.total || 0,
            subLabel: "Cancelled"
        }
    ]

    // Table pagination onChange
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        _getBusinessComplains(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(1); 
        // _getBusinessComplains(1);
    };

    //  handlers
    const onChangeData = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value?.trimStart() });
    }

    const validation = () => {
        const errors = {};
        let valid = true;

        if (!data.description) {
            errors.description = "Please enter description";
            valid = false;
        }

        setError(errors);
        return valid;
    };

    const handleSave = () => {
        if (validation()) {
            _addBusinessComplains()
        }
    }

    const handleClear = () => {
        setOpen(false);
        setData({})
        setError({})
    }

    const handleChangeForManageSubscription = (event) => {
        setModelOpenForManageStatus(true)
        setManageStatus(event.target.value);
    };

    const handleClickForManageStatus = () => {
        setModelOpenForManageStatus(false)
        setManageStatus(null);
        setManageStatusId(null)
        _updateComplainStatus()
    }

    // get buisness Filter Data
    const _getBusiness = async () => {
        dispatch(showLoader(true))
        let body = {
        };
        if (selectedAdmin?._id) {
            body.adminId = selectedAdmin?._id
        }
        try {
            const result = await dispatch(getMyBusinessWithAdmin(body));
            const error = ErrorHandler(result);
            if (error) {
                setBusinessData(result?.payload?.data)
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(showLoader(false))
        }
    };

    const _getBusinessComplains = async (currentPage = 1) => {
        dispatch(showLoader(true));
        const statusID = statusFilter?.find((val) => val?.name === filterData?.status)?.id
        const businessId = businessData?.find((val) => val?.name === filterData?.business)?._id
        let body = {
            page: currentPage,
            limit: rowsPerPage
        };
        if (loginData?.userType !== 0 && selectedBusiness?._id) {
            body.businessId = selectedBusiness?._id
        }
        if (loginData?.userType === 0 && businessId) {
            body.businessId = businessId
        }
        if (loginData?.userType === 0 && selectedAdmin?._id) {
            body.adminId = selectedAdmin?._id
        }
        if (statusID) {
            body.status = statusID
        }
        try {
            const result = await dispatch(getBusinessComplains(body))
            const error = ErrorHandler(result)
            if (error) {
                setBusinessComplains(result?.payload?.data?.response)
                setCount(result?.payload?.data?.count)
                setChart(result?.payload?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }

    const _addBusinessComplains = async () => {
        dispatch(showLoader(true));
        // const businessID = business?.find((val) => val?.name === filterData?.business)?._id
        let body = {
            businessId: selectedBusiness?._id,
            complains: data?.description
        };
        try {
            const result = await dispatch(addBusinessComplains(body))
            const error = ErrorHandler(result)
            if (error) {
                _getBusinessComplains(1)
                handleClear()
            }
        } catch (error) {
            console.log(error)
        } finally {
            dispatch(showLoader(false));
        }
    }

    const _updateComplainStatus = async () => {
        if (manageStatus && manageStatusId) {
            const statusID = statusFilter?.find((val) => val?.name === manageStatus)?.id
            dispatch(showLoader(true));
            let body = {
                _id: manageStatusId,
                status: statusID
            };
            try {
                const result = await dispatch(updateComplainStatus(body))
                const error = ErrorHandler(result)
                if (error) {
                    _getBusinessComplains(1)
                }
            } catch (error) {
                console.log(error)
            } finally {
                dispatch(showLoader(false));
            }
        }
    }

    // useEffect(() => {
    //     if (selectedAdmin?._id) {
    //         setPage(1);
    //         _getBusinessComplains(1);
    //     }
    // }, [selectedAdmin?._id, rowsPerPage]);

    // useEffect(() => {
    //     if (!selectedAdmin?._id || !filterData?.business) {
    //         _getBusinessComplains()
    //     }
    // }, [filterData, selectedBusiness, page, rowsPerPage])


    useEffect(() => {
        if (loginData?.userType === 0) {
            _getBusiness();
            setFilterData({
                business: "",
                status: "",
            })
        }
    }, [selectedAdmin]); 

    // -------- Business ----------- 
    useEffect(() => {
        if (loginData?.userType === 2) {
            if (filterData?.status) {
                setPage(1);
                _getBusinessComplains(1);
            }
        }
    }, [filterData, rowsPerPage]);

    useEffect(() => {
        if (loginData?.userType === 2) {
            if (!filterData?.status) {
                _getBusinessComplains(page);
            }
        }
    }, [filterData, rowsPerPage]);

    // ------ Admin --------
    useEffect(() => {
        if (loginData?.userType === 1) {
            if (selectedBusiness?._id || filterData?.status) {
                setPage(1);
                _getBusinessComplains(1);
            }
        }
    }, [filterData, selectedBusiness?._id, rowsPerPage]);

    useEffect(() => {
        if (loginData?.userType === 1) {
            if (!filterData?.status) {
                _getBusinessComplains(page);
            }
        }
    }, [filterData, selectedBusiness?._id, page, rowsPerPage]);

    // --------- Super Admin --------
    useEffect(() => {
        if (loginData?.userType === 0) {
            if (selectedAdmin?._id || filterData?.business) {
                setPage(1);
                _getBusinessComplains(1);
            }
        }
    }, [selectedAdmin?._id, filterData?.business, rowsPerPage]);

    useEffect(() => {
        if (loginData?.userType === 0) {
            if (!selectedAdmin?._id && !filterData?.business) {
                _getBusinessComplains(page);
            }
        }
    }, [filterData, selectedAdmin, page, rowsPerPage]);

    return (
        <>
            <Grid container>

                <Grid item xs={12} marginBottom={"30px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"}>
                    <Grid item xs={12} sx={{ borderRadius: "26px", overflow: "hidden", backgroundColor: "#13131399", borderColor: "#FFFFFF1A" }}>
                        <Box sx={{
                            p: { xs: 2, sm: 3 },
                            backgroundColor: 'transparent',
                            width: '100%'
                        }}>
                            <Typography
                                variant="h5"
                                component="h2"
                                sx={{
                                    mb: { xs: 2, sm: 3 },
                                    color: 'white',
                                    fontSize: '1.5rem'
                                }}
                            >
                                Support request
                            </Typography>
                            <Grid container spacing={2}>
                                {chartData?.map((dataItem, index) => (

                                    <Grid item xs={12} sm={6} lg={3} key={index} >
                                        <CommonCircularProgress
                                            value={dataItem?.value}
                                            color={dataItem?.color}
                                            label={dataItem?.label}
                                            subLabel={dataItem?.subLabel}
                                        />
                                    </Grid>
                                ))
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Grid >

                <Grid item xs={12} marginBottom={"30px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexWrap={"wrap"}>
                    <Typography fontSize={"22px"} fontWeight={600} lineHeight={"26.25px"} letterSpacing={"-2%"}>Support Request Details</Typography>
                    <Box display={"flex"} alignItems={"center"} gap={2} mt={{ xs: 2, md: 2, lg: 0 }} justifyContent={"space-between"} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
                        {
                            loginData?.userType === 0 &&
                            <Box width={"200px"}>
                                <AutoCompleteSearch
                                    options={businessData?.map((val) => val?.name)}
                                    placeholder={"Select Business"}
                                    name={"business"}
                                    handleChange={(e, newValue) => {
                                        setFilterData({
                                            ...filterData,
                                            business: newValue,
                                        });
                                    }}
                                    fullWidth
                                    defaultValue={filterData?.business}
                                // getOptionLabel={(option) => option?.name}
                                />
                            </Box>
                        }
                        <Box width={"200px"}>
                            <AutoCompleteSearch
                                options={statusFilter?.map((val) => val?.name)}
                                placeholder={"Status"}
                                name={"status"}
                                handleChange={(e, newValue) => {
                                    setFilterData({
                                        ...filterData,
                                        status: newValue,
                                    });
                                }}
                                fullWidth
                                defaultValue={filterData?.status}
                            />
                        </Box>
                        {loginData?.userType !== 0 &&
                            <CommonButton
                                onClick={() => setOpen(true)}
                                text={"+Add Complain"}
                                maxWidth={"144px"}
                                className={"primary"}
                                padding={"12px 16px"}
                            />}

                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ borderRadius: "26px", overflow: "hidden", backgroundColor: "#13131399", borderColor: "#FFFFFF1A" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead
                                sx={{
                                    background: "#131313",
                                    color: "white",
                                }}
                            >
                                <TableRow>
                                    <StyledTableCell align="center">ID</StyledTableCell>
                                    <StyledTableCell align="center">Issue Description</StyledTableCell>
                                    {loginData?.userType === 0 && <StyledTableCell align="center">Submitted By</StyledTableCell>}
                                    <StyledTableCell align="center">Date Submitted</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    {loginData?.userType === 0 && <StyledTableCell align="center">Manage status</StyledTableCell>}
                                </TableRow>
                            </TableHead>
                            {businessComplains?.length > 0 ? (
                                <>
                                    <TableBody>
                                        {businessComplains?.map((item, i) => (
                                            <StyledTableRow
                                                key={item?.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <StyledTableCell align="center">
                                                    {i + 1 + (page - 1) * rowsPerPage}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {<TruncatedTableCell content={item?.complains} align={"center"} />}
                                                </StyledTableCell>
                                                {loginData?.userType === 0 && <StyledTableCell align="center">{item?.businesseDetail?.[0]?.name || "-"}</StyledTableCell>}
                                                <StyledTableCell align="center">{moment(item?.createdAt).format("MMMM Do, YYYY")}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <CommonChep label={showTicketStatus(item?.status)} />
                                                </StyledTableCell>
                                                {loginData?.userType === 0 && (
                                                    <StyledTableCell align="center">
                                                        <SelectDropDown
                                                            disabled={item?.status === 3}
                                                            values={statusFilter?.map((val) => val?.name)}
                                                            value={manageStatus || showTicketStatus(item?.status)}
                                                            onChange={(e) => { handleChangeForManageSubscription(e); setManageStatusId(item?._id); }}
                                                        />
                                                    </StyledTableCell>
                                                )}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    {/* <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ padding: 0 }}>
                                                <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
                                                    <CustomPagination
                                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                                        count={Math.ceil(count / rowsPerPage)}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter> */}
                                </>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={loginData?.userType === 0 ? 6 : 5}>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                width="100%"
                                            >
                                                <img src="/images/noDataFound.svg" alt="no data" />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    {businessComplains?.length > 0 && (
                        <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
                            <CustomPagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={Math.ceil(count / rowsPerPage)}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                    )}
                </Grid>



                <CommonModal
                    open={open}
                    onClose={() => handleClear()}
                    title={"Complains"}
                    maxWidth={'sm'}
                    content={
                        <Grid container xs={12} spacing={2} padding={{ xs: "12px 16px", sm: "20px 20px" }}
                            sx={{ backgroundColor: "#222222" }}>

                            <Grid item xs={12} mb={14}>
                                <CommonTextField
                                    text={"Issues "}
                                    color={"rgba(171, 171, 171, 1)"}
                                    type="text"
                                    placeholder="Add any additional Comments/Remarks... "
                                    name={"description"}
                                    onChange={onChangeData}
                                    value={data?.description}
                                    multiline
                                    rows={5}
                                />
                            </Grid>
                            <Typography ml={3} variant="caption" color="error">
                                {!data?.description ? error?.description : ""}
                            </Typography>
                            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                                <CommonButton
                                    onClick={handleSave}
                                    text={"Submit"}
                                    maxWidth={"100px"}
                                    className={"primary"}
                                    padding={"12px 16px"} />
                            </Grid>
                        </Grid>
                    }
                />
                {
                    modelOpenForManageStatus &&
                    <CommonConformationModal
                        open={modelOpenForManageStatus}
                        onClose={() => { setModelOpenForManageStatus(false); setManageStatus(null); setManageStatusId(null) }}
                        onSubmit={() => { handleClickForManageStatus() }}
                        maxWidth={"sm"}
                        question={`Are you sure you want to change the status to ${manageStatus}?`}
                    />
                }

            </Grid >
        </>
    )
}

export default HelpDesk
