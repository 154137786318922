import React from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { Box, Stack, Typography, Paper } from '@mui/material';
import CommonButtonDashboard from '../ButtonDashboard/CommonButtonDashboard';
import CommonBackgroundContainer from './CommonBackgroundContainer';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: '#242222',
                padding: '10px',
                borderRadius: '8px',
                color: '#fff',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)'
            }}>
                <p style={{ color: '#F4A606' }}>{label}</p> {/* Updated label color */}
                <p style={{ color: '#FFCC00' }}>Review: {payload[0].value}</p> {/* Updated color */}
                <p style={{ color: '#FF6F91' }}>Complain: {payload[1].value}</p> {/* Updated color */}
            </div>
        );
    }
    return null;
};

const AreaChartComponent = ({ data }) => {
    return (
        <CommonBackgroundContainer
            dynamicPadding='30px'
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography style={{ color: '#CCC9C9', fontSize: '16px', fontWeight: '700' }}>
                    Monthly Review Number
                </Typography>
                <CommonButtonDashboard
                    label="Cycle View"
                    onClick={() => console.log('Cycle view clicked')}
                />
            </Stack>
            <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                    data={data}
                    margin={{ top: 20, right: 20, left: -20, bottom: 0 }}
                >
                    <defs>
                        <linearGradient id="colorReview" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="1%" stopColor="#FF6F91" stopOpacity={0.5} />
                            <stop offset="55%" stopColor="#FF6F91" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorComplain" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="1%" stopColor="#FFCC00" stopOpacity={0.5} />
                            <stop offset="100%" stopColor="#FFCC00" stopOpacity={0} />
                        </linearGradient>
                        <radialGradient id="backgroundGradient" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(259.509 18.0851) rotate(148.508) scale(248.689 415.677)">
                            <stop stopColor="white" />
                            <stop offset="0.79" stopColor="#F4A606" />
                            <stop offset="1" stopColor="#FD63C5" />
                        </radialGradient>
                    </defs>
                    <XAxis dataKey="name" stroke="#ccc" tick={{ fontSize: 12, fontWeight:500 }} />
                    <YAxis stroke="#ccc" tick={{ fontSize: 12, fontWeight:500 }} />
                    <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="review"
                        stroke="#FF1493"
                        fillOpacity={1}
                        fill="url(#colorReview)"
                    />
                    <Area
                        type="monotone"
                        dataKey="complain"
                        stroke="#FFD700"
                        fillOpacity={1}
                        fill="url(#colorComplain)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </CommonBackgroundContainer>
    );
};

export default AreaChartComponent;
