import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CommonSelect from '../../CommonSelect/CommonSelect';
import CommonBarChart from '../BarChart/CommonBarChart';

// Sample static data


const CustomBarChart = ({ data, options }) => {
    const [selectedValue, setSelectedValue] = useState('Monthly');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (

        <CommonBarChart
            data={data}
            gradientIds={['Data1', 'Data2']}
            barNumbers={2}
            title="All Business"
            showMonthlyOption={true}
            selectedValue={selectedValue}
            handleChange={handleChange}
            options={options}
            height={250}
            showLabels={true}
            labels={{ label1: 'Subscription', label2: 'No Subscription' }}
            color1={'#F4A606'}
            color2={'#FD63C5'}
        />
    );
};

export default CustomBarChart;
