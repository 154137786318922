import React from 'react';
import CommonChipDash from './CommonChipDash';

const FiveStarReviewChip = ({ data }) => {

    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            showChipAndTypography={false}
            useSvgBackground={true}
            icon={<img src="/images/ChipIcon/StarIcon.svg" alt="Star" width="24" height="24" />}
        />
    )
};

export default FiveStarReviewChip;
