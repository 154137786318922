import React from 'react'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import UserLayout from './UserLayout'
import AdminLayout from './AdminLayout'
 
const Layouts = ({ children }) => {
    const location = useLocation()
    console.log(location?.pathname);
    const userRoutes = ["/user/experience", "/user/keyword", "/user/review", "/user/feedback", "/user/feedbackform", "/user/success"];
    const authRoutes = ["/signup","/login","/forgot-password","/check-email","/otp-verification","/setnew-password","/password-reset", "/paymentdetails","/payment-page"]
    return (
        <Box>
            {(userRoutes?.includes(location?.pathname)) ? <UserLayout children={children} /> : authRoutes?.includes(location?.pathname) ? children : <AdminLayout children={children} />}
        </Box>
    )
}

export default Layouts