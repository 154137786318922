import React from 'react'
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme) => {
    return {
        dialog_Main: {
            '& .MuiDialog-paper.MuiPaper-rounded': {
                borderRadius: '26px',
                overflow: 'hidden',
                // zIndex: 9999
            },
            "& .MuiDialogTitle-root": {
                fontSize: "20px",
                backgroundColor: "#131313",
                padding: "18px 20px !important",
                [theme.breakpoints.down('sm')]: {
                    padding: "12px 16px !important"
                }
            }
        }

    };
});

const CommonModal = ({ onClose, open, title, content, maxWidth }) => {
    const { classes } = useStyles();
    return (
        <Dialog
            fullWidth
            onClose={false}
            open={open}
            className={classes.dialog_Main}
            maxWidth={maxWidth}
            sx={{ padding: "10px" }}
        // style={{ zIndex: 2222 }}
        //sx={{ borderBottom: "1px solid #CDCDCD" }}
        >
            <DialogTitle fontWeight={700} fontSize={16} display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={1}>
                {title}
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={20} height={20} borderRadius={1} onClick={() => onClose()} sx={{ backgroundColor: "transparent", cursor: "pointer" }}>
                    <CloseOutlinedIcon sx={{ color: "#ffff", fontSize: "18px" }} />
                </Box>
            </DialogTitle>
            <Box sx={{ overflow: "scroll", "::-webkit-scrollbar": { width: "0.5px" }, "::-webkit-scrollbar-thumb": { backgroundColor: "black" }, backgroundColor: '#222222', }}>
                {content}
            </Box>
        </Dialog>
    )
}
export default CommonModal