// TotalReviewsChip.js
import React from 'react';
import CommonChipDash from './CommonChipDash';
import StarIcon from '@mui/icons-material/Star';

const TotalReviewsChip = ({data}) => {

    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            change={data?.change}
            isPositive={false}
            icon={<img src="/images/ChipIcon/StarChipIcon.svg" alt="Star" width="24" height="24" />}
        />
    );
};

export default TotalReviewsChip;
