import { Box, Drawer } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import CustomDrawerHeader from "./CustomDrawerHeader";

const useStyles = makeStyles()((theme) => ({
    drawerPaper: {
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 500,
        },
        [theme.breakpoints.up("lg")]: {
            width: 600,
        },
        [theme.breakpoints.up("xl")]: {
            width: 700,
        },
    },
    smallPaper: {
        width: 300,
        position: "absolute",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 300,
        },
        [theme.breakpoints.up("lg")]: {
            width: 300,
        },
        [theme.breakpoints.up("xl")]: {
            width: 300,
        },
    },
    bigPaper: {
        width: 800,
        position: "absolute",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: 600,
        },
        [theme.breakpoints.up("lg")]: {
            width: 700,
        },
        [theme.breakpoints.up("xl")]: {
            width: 800,
        },
    },
}));

const CustomDrawer = ({
    isDrawerOpen,
    handleClose,
    children,
    anchor,
    small,
    title,
    subtitle,
    otherText,
    big,
    fontSize,
}) => {
    const { classes } = useStyles();
    return (
        <Drawer
            sx={{
                zIndex: 10000000,
                "& .MuiPaper-root": {
                    backgroundImage: "none",
                    backgroundColor: "#121212",
                },
            }}
            open={isDrawerOpen}
            anchor={anchor || "right"}
            variant="temporary"
            classes={
                small
                    ? { paper: classes.smallPaper }
                    : big
                        ? { paper: classes.bigPaper }
                        : { paper: classes.drawerPaper }
            }
            transitionDuration={{ enter: 500, exit: 500 }}
        >
            {title && (
                <Box m={0}>
                    <CustomDrawerHeader
                        handleClose={handleClose}
                        title={title}
                        subtitle={subtitle}
                        otherText={otherText}
                        fontSize={fontSize}
                    />
                </Box>
            )}
            {children}
        </Drawer>
    );
};

export default CustomDrawer;
