import {
  Box,
  Grid,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextLabel from "../../Components/Common/Fields/TextLabel";
import ErrorHandler, { TruncatedTableCell } from "../../Utils/handler";
import { useDispatch } from "react-redux";
import { deleteSingleBusiness, getMyAccount } from "../../Redux/Actions/action";
import { showLoader } from "../../Redux/Slices/loaderSlice";
import { BucketURL } from "../../ApiSetup/Api";
import sessionStoreUtil from "../../ApiSetup/Localstorage";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { toast } from "react-toastify";
import CommonConformationModal from "../../Components/Common/CommonConformationModal";
import { setBusiness } from "../../Redux/Slices/businessId";

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStoreUtil?.getLSItem("userData"));
  const loginData = userData ? userData : null;

  const [data, setdata] = useState([]);
  const [deleteBusiness, setDeleteBusiness] = useState("");
  // const [firstPaperHeight, setFirstPaperHeight] = useState(0);

  // const firstPaperRef = useRef(null);

  const handleDeleteCancel = () => {
    setDeleteBusiness(null)
  }

  const handleDeleteButton = () => {
    _deleteBusiness(deleteBusiness);
    handleDeleteCancel()
  }

  const _getMyAccount = async () => {
    dispatch(showLoader(true))
    let body = {
      // adminId: loginData?._id
    };
    // if (loginData?.userType !== 2) {
    //   body.adminId = loginData?._id
    // }
    // if (loginData?.userType === 2) {
    //   body.budinessID = loginData?._id
    // }
    try {
      const result = await dispatch(getMyAccount(body));
      const error = ErrorHandler(result);
      if (error) {
        setdata(result?.payload?.data?.response)
        dispatch(setBusiness(result?.payload?.data?.response?.[0]));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // delete business
  const _deleteBusiness = async (id) => {
    if (id) {
      dispatch(showLoader(true));
      try {
        const result = await dispatch(deleteSingleBusiness(id));
        const error = ErrorHandler(result);
        if (error) {
          toast?.success(result?.payload?.message)
          _getMyAccount();
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false));
      }
    }
  }

  useEffect(() => {
    _getMyAccount();
  }, []);

  // useEffect(() => {
  //   if (firstPaperRef.current) {
  //     setFirstPaperHeight(firstPaperRef.current.clientHeight);
  //   }
  // }, [data]);

  return (
    <>
      {/* <CommonSearch /> */}
      <Paper sx={{ padding: { xs: "15px", sm: "40px", md: "50px" }, backgroundColor: "#0E0E0E !important", borderRadius: "27.44px", backgroundImage: "none" }}>
        <Grid container xs={12} spacing={0}>
          <Grid item xs={12}>
            <TextLabel title={"All Businesses"} fontWeight={700} fontSize={{ xs: "25px", sm: "30px" }} color={'rgba(255, 255, 255, 1)'} />
          </Grid>
          <Grid container alignItems="stretch" spacing={1.7} item xs={12} mt={{ xs: "20px", sm: "26px" }}>
            {data?.length > 0 &&
              data?.map((val, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}
                    display={"flex"}
                    alignItems="stretch"
                    justifyContent={"center"}>
                    <Paper
                      sx={{
                        backgroundColor: "#1F1F1F99",
                        borderRadius: "10px",
                        width: "259px",
                        height: "100%",
                        backgroundImage: "none",
                        display: "flex", // Ensures content alignment
                        flexDirection: "column", // Stacks items vertically
                      }}
                    >
                      <Box sx={{ padding: { xs: "12px", sm: "18.5px 18px" }, flex: 1 }}>
                        <img
                          src={
                            val?.avtar
                              ? BucketURL + val?.avtar
                              : "https://images.unsplash.com/photo-1514933651103-005eec06c04b?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          }
                          alt=""
                          style={{
                            width: "100%",
                            height: "142px",
                            borderRadius: "6px",
                            objectFit: "cover",
                          }}
                        />

                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          gap={{ xs: 1, sm: 0 }}
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          mt="20px"
                        >
                          <Rating
                            name="half-rating"
                            defaultValue={val?.rating || 5}
                            readOnly
                            precision={0.5}
                            size="small"
                          />
                        </Box>

                        <Typography
                          sx={{ ml: { xs: "5px", sm: "2px" }, mt: "10px", mb: "8px" }}
                          color="rgba(255, 255, 255, 1)"
                          fontWeight={700}
                          fontSize="12.58px"
                          lineHeight="15.01px"
                        >
                          {val?.name}
                        </Typography>

                        <TextLabel
                          title={
                            <TruncatedTableCell
                              content={
                                val?.description ||
                                "The pasta dishes stand out for their flavor, boasting a blend that appeals to true aficionados. Each dish showcases a careful attention to detail."
                              }
                              contentLength={160}
                            />
                          }
                          color="#FFFFFF80"
                          fontWeight={400}
                          fontSize="11.01px"
                          lineHeight="15.72px"
                        />
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap="6px"
                        alignItems="center"
                        mt="auto" // Pushes this element to the bottom
                        mb={2}
                        mr={1}
                      >
                        <CommonButton
                          maxWidth="78px"
                          height="35px"
                          text="Edit"
                          className="secondary"
                          onClick={() => {
                            navigate(`/myBusiness?param1=${val?._id}`);
                          }}
                        />
                        {loginData?.userType !== 2 && (
                          <CommonButton
                            maxWidth="78px"
                            height="35px"
                            text="Delete"
                            className="primary"
                            onClick={() => setDeleteBusiness(val?._id)}
                          />
                        )}
                      </Box>
                    </Paper>

                  </Grid>
                );
              })}
            {(loginData?.userType === 0 || loginData?.userType === 1) && <Grid item xs={12} sm={6} md={4} lg={3} xl={2}
              display={"flex"}
              justifyContent={"center"}
            >
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#1F1F1F99",
                  backgroundImage: "none",
                  borderRadius: "10px",
                  width: "259px",
                  height: "100%",
                  // height: firstPaperHeight,
                  minHeight: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    border: "2px dashed rgba(255, 255, 255, 0.3)",
                    borderRadius: "50%",
                    padding: "10px",
                    marginBottom: "9px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/myBusiness`);
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "42px",
                      width: "30px",
                      height: "30px", // 141.22% 118.61% at 21.45% 10.64%
                      background:
                        "radial-gradient( 121.22% 95.61% at 11.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                      WebkitMaskImage:
                        "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 43 42%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M19.4823 29.0202V23.0327H12.4924C9.82312 23.0327 9.82312 18.9705 12.4924 18.9705H19.4823V11.9807C19.4823 9.31137 23.5445 9.31137 23.5445 11.9807V18.9705H30.5319C33.2012 18.9705 33.2012 23.0327 30.5319 23.0327H23.5445V29.0202C23.5445 31.6895 19.4823 31.6895 19.4823 29.0202ZM21.5126 42C33.111 42 42.5126 32.5992 42.5126 21C42.5126 9.4016 33.111 0 21.5126 0C9.91418 0 0.512573 9.4016 0.512573 21C0.512573 32.5984 9.91418 42 21.5126 42Z%22 fill=%22url(%23paint0_radial_929_3241)%22/%3E%3Cdefs%3E%3CradialGradient id=%22paint0_radial_929_3241%22 cx=%220%22 cy=%220%22 r=%221%22 gradientUnits=%22userSpaceOnUse%22 gradientTransform=%22translate(9.52322 4.46808) rotate(49.9729) scale(41.9147 77.4582)%22%3E%3Cstop stop-color=%22white%22/%3E%3Cstop offset=%220.79%22 stop-color=%22%23F4A606%22/%3E%3Cstop offset=%221%22 stop-color=%22%23FD63C5%22/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E')",
                      maskImage:
                        "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 43 42%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M19.4823 29.0202V23.0327H12.4924C9.82312 23.0327 9.82312 18.9705 12.4924 18.9705H19.4823V11.9807C19.4823 9.31137 23.5445 9.31137 23.5445 11.9807V18.9705H30.5319C33.2012 18.9705 33.2012 23.0327 30.5319 23.0327H23.5445V29.0202C23.5445 31.6895 19.4823 31.6895 19.4823 29.0202ZM21.5126 42C33.111 42 42.5126 32.5992 42.5126 21C42.5126 9.4016 33.111 0 21.5126 0C9.91418 0 0.512573 9.4016 0.512573 21C0.512573 32.5984 9.91418 42 21.5126 42Z%22 fill=%22url(%23paint0_radial_929_3241)%22/%3E%3Cdefs%3E%3CradialGradient id=%22paint0_radial_929_3241%22 cx=%220%22 cy=%220%22 r=%221%22 gradientUnits=%22userSpaceOnUse%22 gradientTransform=%22translate(9.52322 4.46808) rotate(49.9729) scale(41.9147 77.4582)%22%3E%3Cstop stop-color=%22white%22/%3E%3Cstop offset=%220.79%22 stop-color=%22%23F4A606%22/%3E%3Cstop offset=%221%22 stop-color=%22%23FD63C5%22/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E')",
                      WebkitMaskSize: "100% 100%",
                      maskSize: "100% 100%",
                      WebkitMaskRepeat: "no-repeat",
                      maskRepeat: "no-repeat",
                    }}
                  />
                </Box>
                <TextLabel title={"Add your business"} fontSize={"12px"} color={"#ffff"} />
              </Paper>


            </Grid>}
          </Grid>
        </Grid>
      </Paper>

      {deleteBusiness &&
        <CommonConformationModal
          open={deleteBusiness}
          onClose={() => { handleDeleteCancel() }}
          onSubmit={handleDeleteButton}
          maxWidth={"sm"}
          question={"Are you sure you want to delete this Business ?"}
          isDelete
        />
      }
    </>
  );
};

export default MyAccount;
