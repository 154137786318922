import React from 'react'

const DashboardIcon = ({ active }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 17 17" fill="none" opacity={active ? "1" : "0.3"} xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7297 0.166992H14.5513C15.7199 0.166992 16.6668 1.12187 16.6668 2.30029V5.14576C16.6668 6.32419 15.7199 7.27907 14.5513 7.27907H11.7297C10.5611 7.27907 9.61426 6.32419 9.61426 5.14576V2.30029C9.61426 1.12187 10.5611 0.166992 11.7297 0.166992Z" fill="url(#paint0_radial_553_311)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.11544 0.166992H4.93708C6.10563 0.166992 7.05251 1.12187 7.05251 2.30029V5.14576C7.05251 6.32419 6.10563 7.27907 4.93708 7.27907H2.11544C0.94688 7.27907 0 6.32419 0 5.14576V2.30029C0 1.12187 0.94688 0.166992 2.11544 0.166992ZM2.11544 9.72159H4.93708C6.10563 9.72159 7.05251 10.6765 7.05251 11.8549V14.7004C7.05251 15.878 6.10563 16.8337 4.93708 16.8337H2.11544C0.94688 16.8337 0 15.878 0 14.7004V11.8549C0 10.6765 0.94688 9.72159 2.11544 9.72159ZM14.5512 9.72159H11.7296C10.561 9.72159 9.61415 10.6765 9.61415 11.8549V14.7004C9.61415 15.878 10.561 16.8337 11.7296 16.8337H14.5512C15.7198 16.8337 16.6667 15.878 16.6667 14.7004V11.8549C16.6667 10.6765 15.7198 9.72159 14.5512 9.72159Z" fill="url(#paint1_radial_553_311)" />
            <defs>
                <radialGradient id="paint0_radial_553_311" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.1273 0.923595) rotate(50.21) scale(7.0731 13.0516)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
                <radialGradient id="paint1_radial_553_311" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.57565 1.94004) rotate(49.9729) scale(16.6328 30.7374)">
                    <stop stop-color="white" />
                    <stop offset="0.338848" stop-color="#F4A606" />
                    <stop offset="1" stop-color="#FD63C5" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default DashboardIcon
