import React from 'react'

function FreeTrial({ stroke }) {
    return (
        <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.7057 9.82011C24.871 10.7705 26.5899 10.7026 27.676 9.61644C28.8346 8.45783 28.8346 6.57938 27.6761 5.4208C26.5175 4.26222 24.639 4.26222 23.4804 5.42084C22.9011 6.00011 22.6115 6.75932 22.6115 7.51854" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.2272 5.47613L4.56684 5.47613V9.5727" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.56702 7.48535H6.09544" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.03783 7.6172L10.7587 9.57241" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.54389 5.47613V9.5727" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.7588 6.54051C10.7588 7.12847 10.2432 7.60516 9.63386 7.60516C9.33171 7.60516 8.55287 7.60988 8.55287 7.60988C8.55287 7.60988 8.54801 6.83691 8.54798 6.54051C8.54801 6.29708 8.54529 5.47583 8.54529 5.47583C8.54529 5.47583 9.27681 5.47587 9.63386 5.47587C10.2432 5.47587 10.7588 5.95255 10.7588 6.54051Z" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.7149 5.47613H16.9888L16.9888 9.5727L18.7149 9.5727" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.5877 7.52441H16.9889" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.7149 5.47613H12.9888L12.9888 9.5727L14.7149 9.5727" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.5877 7.52441H12.9889" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.9003 14.0481L18.5959 14.0481C19.1132 14.0481 19.6094 13.8426 19.9752 13.4767L24.5465 8.90543C25.3083 8.14363 25.3084 6.90849 24.5465 6.14666L19.973 1.57317C19.6072 1.20739 19.1112 1.00186 18.5938 1.00183L3.17704 1C2.0996 0.999862 1.22604 1.87329 1.22607 2.95076L1.22607 12.0973C1.22607 13.1747 2.09947 14.0481 3.17684 14.0481H8.51113" stroke={stroke} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default FreeTrial
