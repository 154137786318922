import React from 'react';
import CommonChipDash from './CommonChipDash';

const PositiveResponsesChip = ({data}) => {

    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            icon={<img src="/images/ChipIcon/ThumbsUpIcon.svg" alt="Thumbs Up" width="24" height="24" />}
            showChipAndTypography={false}
            useSvgBackground={true}
        />
    );
};

export default PositiveResponsesChip;
