import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { forgetPassword, otpVerification } from "../../Redux/Actions/action";
import ErrorHandler from "../../Utils/handler";
import { showLoader } from "../../Redux/Slices/loaderSlice";
import WestIcon from '@mui/icons-material/West';
import OTPInput from "react-otp-input";


const OtpVerification = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { email } = location.state || {}

    const isXs = useMediaQuery('(max-width:600px)');
    const isSm = useMediaQuery('(max-width:960px) and (min-width:600px)');

    let inputWidth = "50px";
    if (isXs) {
        inputWidth = "40px";
    } else if (isSm) {
        inputWidth = "45px";
    }

    const [otp, setOtp] = React.useState('');
    const [error, setError] = useState({});

    const handleValidation = () => {
        let isValid = true
        let errors = {}
        if (!otp) {
            toast.error("Please enter otp");
            isValid = false
        }
        setError(errors)
        return isValid
    }

    useEffect(() => {
        if (!email) {
            navigate("/forgot-password")
        }
    }, [])

    const onSubmit = async () => {
        if (handleValidation()) {
            dispatch(showLoader(true));
            let body = {
                email: email,
                otp: +otp,
            };
            try {
                const result = await dispatch(otpVerification(body));
                const error = ErrorHandler(result);
                if (error) {
                    toast.success(result?.payload?.message);
                    navigate("/setnew-password", { state: { otp: otp } });
                }
            } catch (error) {
                toast.error(error?.message || "An unknown error occurred");
            } finally {
                dispatch(showLoader(false));
            }
        }
    };

    const handleResendOtp = async () => {
        dispatch(showLoader(true));
        let body = {
            email: email,
        };
        try {
            const result = await dispatch(forgetPassword(body));
            const error = ErrorHandler(result);
            if (error) {
                toast.success(result?.payload?.message);
                setOtp("")
            }
        } catch (error) {
            toast.error(error?.message || "An unknown error occurred");
        } finally {
            dispatch(showLoader(false));
        }
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                minWidth={"30vh"}
                sx={{
                    background:
                        "radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 100%)",
                    backgroundImage: "url(/images/backgroundLinigImg.svg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#FFFFFF",
                }}
            >
                <Grid
                    container
                    spacing={isMobile ? 1 : 2}
                    maxWidth={isMobile ? "80%" : "620px"}
                    maxHeight={isMobile ? "100%" : "450px"}
                    padding={isMobile ? "25px 20px 25px 20px" : "35px 40px"}
                    border="1px solid #3E3E3E"
                    borderRadius="20px"
                    sx={{
                        backgroundColor: "#121212",
                        boxShadow: "0px 20px 50px rgba(0, 0, 0, 0.25)",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        marginBottom={isMobile ? "15px" : "20px"}
                        padding="0px !important"
                    >
                        <Box display="flex" alignItems="center" gap="10px">
                            <img
                                src="/images/logo.svg"
                                alt="logo"
                                height="28px"
                                width="28px"
                            />
                            <Typography fontSize="14px" fontWeight={600} color="#FFFFFF">
                                The AI Reviews
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mb={isMobile ? "25px" : "20px"}
                    >
                        <Typography
                            fontSize={isMobile ? "28px" : "30px"}
                            fontWeight={700}
                            lineHeight={isMobile ? "30px" : "38.19px"}
                            letterSpacing="-1px"
                            sx={{
                                background:
                                    "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                            }}
                        >
                            OTP Verification
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid xs={12} >
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{
                                    width: inputWidth,
                                    height: inputWidth,
                                    border: "1px solid rgba(255, 255, 255, 0.2)",
                                    backgroundColor: "transparent",
                                    borderRadius: "15px",
                                    fontSize: "20px",
                                    color: "#fff",
                                    textAlign: "center",
                                    outline: "none",
                                    transition: "border-color 0.3s ease",
                                }}
                                containerStyle={{
                                    justifyContent: "center",
                                    gap: "10px",
                                    padding: isMobile ? "10px" : "18px",
                                    flexWrap: "wrap",
                                }}
                                inputType="number"
                            />
                            <Typography variant="caption" color={"error"}>
                                {otp ? "" : error?.otp}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} textAlign={"center"} my={{ xs: 1, sm: 0 }}>
                        <CommonButton
                            padding={"12px 10px"}
                            className="primary"
                            borderRadius={"30px"}
                            text={"Submit"}
                            maxWidth={"144px"}
                            onClick={() => onSubmit()}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexWrap={"wrap"}
                        gap={1}
                        mt={"0px"}
                    >
                        <Typography
                            fontSize={"14px"}
                            fontWeight={400}
                            lineHeight={"20px"}
                            color={"#696969"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            marginRight={"-4px"}
                            onClick={() => {
                                dispatch(showLoader(true));
                                setTimeout(() => {
                                    navigate("/login");
                                    dispatch(showLoader(false));
                                }, 100);
                            }}
                        >
                            {`Didn't receive the email?`}
                        </Typography>
                        <Typography
                            fontSize={"14px"}
                            fontWeight={400}
                            lineHeight={"20px"}
                            color={"#FFFFFF"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleResendOtp()}
                        >
                            {`Click to resend`}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={isMobile ? "30px" : "30px"}
                    >
                        <Typography
                            sx={{ cursor: "pointer" }}
                            fontSize={"13px"}
                            fontWeight={400}
                            lineHeight={"20px"}
                            color={"#696969"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            onClick={() => {
                                dispatch(showLoader(true));
                                setTimeout(() => {
                                    navigate("/forgot-password");
                                    dispatch(showLoader(false));
                                }, 100);
                            }}
                        >
                            <WestIcon sx={{ fontSize: "20px" }} />
                            {` Forgot password`}
                        </Typography>
                    </Grid>
                </Grid >
            </Box >
        </>
    )
}

export default OtpVerification
