import { Box, Grid, IconButton, Link, Modal, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import CommonButton from '../../../Components/Common/Button/CommonButton';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { generateReview, getBusinessDetails } from '../../../Redux/Actions/action';
import { showLoader } from '../../../Redux/Slices/loaderSlice';
import ErrorHandler from '../../../Utils/handler';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import TextLabel from '../../../Components/Common/Fields/TextLabel';

const useStyles = makeStyles()((theme) => ({
    gradientBorder: {
        position: 'relative',
        padding: '1.5px',
        borderRadius: "22px",
        background: 'radial-gradient(141.22% 118.61% at 21.45% 10.64%, rgba(255, 255, 255, 0.4) 0%, rgba(244, 166, 6, 0.4) 33.88%, rgba(253, 99, 197, 0.4) 100%)',
        zIndex: 1,
        marginBottom: "20px"
    },
    descriptionContainer: {
        position: 'relative',
        padding: "23px 30px",
        color: "#FFF",
        backgroundColor: "#141414",
        borderRadius: "22px",
        zIndex: 2,
        boxSizing: 'border-box',
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    }
}));

const Review = () => {
    const { classes } = useStyles();
    const selectedKeywords = useSelector((state) => state?.keywords?.selectedKeywords);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = new URLSearchParams(useLocation().search).get('id');

    const [fullText, setFullText] = useState("");
    const [textToCopy, setTextToCopy] = useState("");
    const [copied, setCopied] = useState(false);
    const [googleReviewLink, setGoogleReviewLink] = useState("");
    const [open, setOpen] = useState(false);
    const wordIndex = useRef(0);
    let timer; // Declare timer variable for cleanup

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onCopyClick = () => {
        setCopied(true);
        if (googleReviewLink) {
            setTimeout((e) => {
                // e.preventDefault()
                window.open(googleReviewLink, '_self', 'noopener,noreferrer');
            }, 1000);
        }
    };



    const _generateReview = async () => {
        dispatch(showLoader(true));
        if (selectedKeywords?.length !== 0) {
            let body = {
                businessId: id,
                keywords: selectedKeywords
            };
            try {
                const result = await dispatch(generateReview(body));
                const error = ErrorHandler(result);
                if (error) {
                    setFullText(result?.payload?.data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(showLoader(false));
            }
        } else {
            navigate(`/user/keyword?id=${id}`);
        }
    };

    const _getBusinessProfile = async () => {
        dispatch(showLoader(true));
        let body = {
            _id: id,
            pageName: "review"
        };
        try {
            const result = await dispatch(getBusinessDetails(body));
            const error = ErrorHandler(result);
            if (error) {
                _generateReview();
                setGoogleReviewLink(result?.payload?.data?.googleReviewsUrl);
            } else {
                dispatch(showLoader(false));
            }
        } catch (error) {
            console.error(error);
            dispatch(showLoader(false));
        }
    };

    useEffect(() => {
        _getBusinessProfile();
    }, []);

    useEffect(() => {
        const displayWords = () => {
            if (fullText) {
                const words = fullText.split(" ");
                if (wordIndex.current < words.length) {
                    const nextWord = words[wordIndex.current];
                    setTextToCopy((prevText) => prevText + (wordIndex.current > 0 ? " " : "") + nextWord);
                    wordIndex.current += 1;
                    timer = setTimeout(displayWords, 50);
                }
            }
        };
        displayWords();
        return () => {
            clearTimeout(timer);
        };
    }, [fullText]);

    return (
        <>
            <Grid item container>
                <Grid item xs={12} marginBottom={"25px"}>
                    <Typography fontSize={"26px"} fontWeight={700} color='#FFFFFF' letterSpacing={"-1px"} lineHeight={"31.03px"}
                        sx={{
                            background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 70%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}>Let Us See Your Review
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.gradientBorder}>
                        <Box className={classes.descriptionContainer}>
                            <Box sx={{ position: "absolute", top: { xs: "-15%", sm: "-30%" }, left: { xs: "-35%", sm: "-25%" } }}>
                                <img src={"/images/shadow.svg"} alt="" />
                            </Box>
                            <Typography fontSize={"16px"} fontWeight={500} color='#FFFFFF' lineHeight={"22px"} fontFamily={"Satoshi"}>
                                {textToCopy}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} marginBottom={"46px"}>
                    <Typography fontSize={"12px"} fontWeight={400} color='#636462' lineHeight={"18px"}>
                        If you agree with our generated sentiments, continue. For more information,
                        {/* <Link rel="noopener noreferrer" color="#EFF1EC">
                            click here
                        </Link>. */}
                        <a
                            // href="#"
                            className={classes.highlightLink}
                            style={{
                                marginLeft: "5px",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                color: "#939393",
                                cursor: "pointer",
                                "&:hover": {
                                    color: "darkgray",
                                },
                            }}
                            onClick={handleOpen}
                        >
                            <strong> click here</strong>
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes?.buttonContainer}>
                    {!copied ? (
                        <>
                            <CopyToClipboard text={textToCopy}>
                                <CommonButton variant='contained' className='primary' text={"Copy Review & Continue"} onClick={onCopyClick} fullWidth disabled={!fullText} />
                            </CopyToClipboard>
                            <CommonButton variant='contained' className='secondary' text={"Regenerate review"} fullWidth onClick={() => { _generateReview(); setTextToCopy(""); wordIndex.current = 0; }} />
                        </>
                    ) : (
                        <CommonButton variant='contained' className='primary' text={"Copied"} fullWidth />
                    )}
                </Grid>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="hover-modal-title"
                    aria-describedby="hover-modal-description"
                    disablePortal
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: '#181816',
                            boxShadow: 24,
                            p: "20px",
                            borderRadius: '8px',
                        }}
                    >
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <TextLabel
                                fontSize="25px"
                                title={"Our Terms"}
                                sx={{
                                    background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, rgb(255, 255, 255) 0%, rgb(244, 166, 6) 33.88%, rgb(253, 99, 197) 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    display: "inline-block",
                                }}
                            />
                            <IconButton onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography sx={{ mt: 2, fontSize: "13px" }}>
                            By submitting your review, you understand that our AI-generated content is designed to streamline the review process while accurately reflecting your thoughts and feelings about the establishment. Our goal is to be as authentic as possible. If the review doesn't quite capture your perspective, feel free to edit it before submitting. Please note that we cannot guarantee the accuracy or completeness of AI-generated reviews, so use your own judgment when relying on this information. We appreciate your understanding and support in our mission to create genuine reviews!
                        </Typography>
                    </Box>
                </Modal>

            </Grid>
        </>
    );
};

export default Review;
