// import * as React from "react";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import { Box, InputLabel, Paper, Typography, useTheme } from "@mui/material";
// import { makeStyles } from "tss-react/mui";

// const useStyles = makeStyles()((theme) => {
//     return {
//         icon: {
//             fill: '#eee',
//         },
//         root: {
//             // color: 'white',
//         },
//         main: {
//             backgroundColor: "transparent",
//             borderRadius: '30px',
//             minWidth: "50px",
//             ".MuiInputBase-input": {
//                 height: "10px",
//                 fontSize: '14px',
//             },
//             ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
//                 borderColor: `#2D2D2D !important`,
//             },
//             ".MuiInputBase-formControl.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
//                 borderColor: `#2D2D2D !important`,
//             },
//             ".MuiOutlinedInput-notchedOutline": {
//                 borderColor: `#2D2D2D !important`,
//                 borderRadius: '30px',
//             },
//             ".Mui-focused .MuiOutlinedInput-notchedOutline": {
//                 borderColor: `#2D2D2D !important`,
//                 borderWidth: "1px !important",
//             },
//             "& .MuiInputBase-formControl.Mui-disabled": {
//                 backgroundColor: "transparent",
//                 borderRadius: '30px',
//                 opacity: 0.8
//             }
//         },
//         error: {
//             border: "1px solid red",
//         },
//     };
// });

// export default function AutoCompleteSearch({
//     width,
//     height,
//     text,
//     valid,
//     options,
//     placeholder,
//     handleChange,
//     name,
//     handleChangeInput,
//     fontWeight,
//     labelSize,
//     disabled = false,
//     defaultValue,
//     freeSolo = true,
//     clearOnSelect,
//     blurOnSelect,
//     loadData,
//     onBlur,
//     searchValue,
//     handleSearch,
//     multiple = false,
//     fullWidth,
//     className,
//     fontSize,
//     color,
//     renderOption,
//     getOptionLabel,
//     startAdornment,
//     isBusiness
// }) {
//     const { classes, cx } = useStyles();
//     const theme = useTheme()

//     return (
//         <>
//             {/* {text && (
//                 <Box
//                     mb={"8px"}
//                     display="flex"
//                     fontSize="12px"
//                     flexDirection={"row"}
//                 >
//                     <InputLabel
//                         sx={{
//                             marginRight: "3px",
//                             fontSize: "14px",
//                             color: "#FFFFFF",
//                             padding: '3px',
//                         }}
//                     >
//                         {text}
//                     </InputLabel>
//                     {valid && (
//                         <Typography color="#EF627A" component={"caption"} variant={"body2"}>
//                             *
//                         </Typography>
//                     )}
//                 </Box>
//             )} */}
//             {text && (
//                 <Box
//                     mb={0.5}
//                     display="flex"
//                     fontSize="12px"
//                     flexDirection={"row"}
//                     marginBottom={"8px"}
//                 >
//                     <InputLabel
//                         sx={{
//                             letterSpacing: "0.03em",
//                             marginRight: "2px",
//                             fontWeight: fontWeight,
//                             fontSize: fontSize || "14px",
//                             color: color || "rgba(255, 255, 255, 1)",
//                         }}
//                     >
//                         {text}
//                     </InputLabel>
//                     {valid && (
//                         <Typography color="#cf2525" component={"caption"} variant={"body2"} >
//                             *
//                         </Typography>
//                     )}
//                 </Box>
//             )}
//             <Box display="flex">
//                 <Autocomplete
//                     fullWidth={fullWidth}
//                     multiple={multiple}
//                     className={cx(classes.main, className)}
//                     disabled={disabled}
//                     // autoHighlight={false}
//                     // disablePortal
//                     renderOption={renderOption}
//                     blurOnSelect={blurOnSelect}
//                     options={options || []}
//                     onChange={handleChange}
//                     isOptionEqualToValue={(option, value) => {
//                         if (value === "" || value === option) {
//                             return true;
//                         } else {
//                             return false;
//                         }
//                     }}
//                     value={defaultValue || ""}
//                     defaultValue={defaultValue || ""}
//                     sx={{ width: width, backgroundColor: "white", }}
//                     // freeSolo={freeSolo}
//                     renderInput={(params) => (
//                         <TextField
//                             className="searchInput"
//                             {...params}
//                             onBlur={onBlur}
//                             placeholder={placeholder}
//                             onChange={handleSearch}
//                             name={name}
//                             disabled={disabled ? disabled : false}
//                             value={searchValue}
//                             InputProps={{
//                                 ...params.InputProps,
//                                 startAdornment: startAdornment,
//                             }}

//                         />
//                     )}
//                     getOptionLabel={getOptionLabel}

//                 />
//             </Box>
//         </>
//     );
// }


import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, InputLabel, Typography, useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    icon: {
        fill: '#eee',
    },
    main: {
        backgroundColor: "transparent",
        borderRadius: '30px',
        minWidth: "50px",
        ".MuiInputBase-input": {
            height: "10px",
            fontSize: '14px',
        },
        ".MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `#2D2D2D !important`,
        },
        ".MuiOutlinedInput-notchedOutline": {
            borderColor: `#2D2D2D !important`,
            borderRadius: '30px',
        },
        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `#2D2D2D !important`,
            borderWidth: "1px !important",
        },
        "& .MuiInputBase-formControl.Mui-disabled": {
            backgroundColor: "transparent",
            borderRadius: '30px',
            opacity: 0.8,
        }
    },
    error: {
        border: "1px solid red",
    },
}));

export default function AutoCompleteSearch({
    width,
    height,
    text,
    valid,
    options,
    placeholder,
    handleChange,
    name,
    handleChangeInput,
    fontWeight,
    labelSize,
    disabled = false,
    defaultValue,
    freeSolo = true,
    clearOnSelect,
    blurOnSelect,
    loadData,
    onBlur,
    searchValue,
    handleSearch,
    multiple = false,
    fullWidth,
    className,
    fontSize,
    color,
    renderOption,
    getOptionLabel,
    startAdornment,
    isBusiness
}) {
    const { classes, cx } = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

    return (
        <>
            {text && (
                <Box mb={0.5} display="flex" fontSize="12px" flexDirection={"row"} marginBottom={"8px"}>
                    <InputLabel
                        sx={{
                            letterSpacing: "0.03em",
                            marginRight: "2px",
                            fontWeight: fontWeight,
                            fontSize: fontSize || "14px",
                            color: color || "rgba(255, 255, 255, 1)",
                        }}
                    >
                        {text}
                    </InputLabel>
                    {valid && (
                        <Typography color="#cf2525" component={"caption"} variant={"body2"}>
                            *
                        </Typography>
                    )}
                </Box>
            )}
            <Box display="flex">
                <Autocomplete
                    fullWidth={fullWidth}
                    multiple={multiple}
                    className={cx(classes.main, className)}
                    disabled={disabled}
                    renderOption={renderOption}
                    blurOnSelect={blurOnSelect}
                    options={options || []}
                    onChange={handleChange}
                    isOptionEqualToValue={(option, value) => value === "" || value === option}
                    value={defaultValue || null}
                    defaultValue={defaultValue || ""}
                    sx={{
                        width: isMobile ? '100%' : width, // Adjust width for mobile
                        backgroundColor: "white",
                        marginLeft: isMobile ? '10px' : 'auto', // Center on mobile
                    }}
                    renderInput={(params) => (
                        <TextField
                            className="searchInput"
                            {...params}
                            onBlur={onBlur}
                            placeholder={placeholder}
                            onChange={handleSearch}
                            name={name}
                            disabled={disabled}
                            value={searchValue}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: isMobile ? null : startAdornment, // Hide adornment on mobile
                            }}
                        />
                    )}
                    getOptionLabel={getOptionLabel}
                />
            </Box>
        </>
    );
}

