import React from 'react';
import CommonChipDash from './CommonChipDash';

const MostDislikedCategoryChip = ({ data }) => {

    return (
        <CommonChipDash
            title={data?.title}
            value={data?.value}
            icon={<img src="/images/ChipIcon/ThumbsDownIcon.svg" alt="Thumbs Down" width="24" height="24" />}
            showChipAndTypography={false}
            useSvgBackground={true}
        />);
};

export default MostDislikedCategoryChip;
