import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { Typography } from '@mui/material';
import CommonBackgroundContainer from './CommonBackgroundContainer'; // Import the container

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: '#222',
                padding: '10px',
                borderRadius: '8px',
                color: '#fff',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)'
            }}>
                <p>{label}</p>
                <p>Rating: {payload[0]?.value}</p>
                <p>Average: {payload[1]?.value}</p>
            </div>
        );
    }
    return null;
};

const MonthlyReportsLineChart = ({ data }) => {
    return (
        <CommonBackgroundContainer
            dynamicPadding='30px'
        >
            <Typography style={{ color: '#CCC9C9', fontSize: '16px', fontWeight: '700' }}>Monthly Review Rating</Typography>
            <ResponsiveContainer width="100%" height={290}>
                <LineChart
                    data={data}
                    margin={{ top: 25, right: 20, left: -20, bottom: -10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                    <XAxis dataKey="name" stroke="#ccc" fontSize={12} fontWeight={500} />
                    <YAxis stroke="#ccc" fontSize={12} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                        type="monotone"
                        dataKey="rating"
                        stroke="#FFA500"
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 3 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="average"
                        stroke="#FFC0CB"
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 3 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </CommonBackgroundContainer>
    );
};

export default MonthlyReportsLineChart;
