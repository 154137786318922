import React from 'react';
import PaymentStatus from '../../Components/Common/PaymentStatus';
import { useSearchParams } from 'react-router-dom'; // Importing useSearchParams to get URL parameters

const PaymentPage = () => {
    const [searchParams] = useSearchParams();
    const isSuccess = searchParams.get('isSuccess') === 'true';

    return (
        <PaymentStatus
            isSuccess={isSuccess} 
        />
    );
};

export default PaymentPage;
