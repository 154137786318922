import React from "react";
import { Button, styled } from "@mui/material";

const CustomButton = styled(Button)(({ theme, className, maxWidth, padding, borderRadius, height, fontSize, fontWeight, opacity, disabled, disabledWithColor }) => ({
    maxWidth: maxWidth ? maxWidth : "100%",
    width: "100%",
    padding: padding ? padding : "17px",
    background: className === "primary"
        ? "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)"
        : "transparent", // fixed typo ("transperent" to "transparent")
    color: className === "primary" ? "#010101" : "#908F8F",
    fontSize: fontSize || "14px",
    fontWeight: fontWeight || "500",
    borderRadius: borderRadius || "30px",
    border: className === "secondary" ? "1px solid #2D2D2D;" : "none",
    boxSizing: "border-box",
    height: height ? height : "51px",
    minWidth: "48px",
    opacity: disabled ? 0.6 : opacity || 1,
    zIndex: 5,
    "&.Mui-disabled": {
        color: disabledWithColor ? "#070707 !important" : "#ffffff !important",
    },
    "&:hover": {
        background: className === "primary"
            ? "radial-gradient(163.94% 119.35% at 80.6% 100%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)"
            : "transparent",
    },
    overflow: "hidden",
    "&:before": {
        color: className === "primary" ? "#010101" : "#908F8F",
        content: '""', // Fix for empty content
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "110%",
        height: "400%",
        background: className === "primary" ? "linear-gradient(135deg, #FFFFFF 20%, #F4A606 50%, #FD63C5 80%)" : "transparent", // Fixed syntax here
        transform: "translate3d(-50%, -50%, 0)",
        transition: "0.8s",
        zIndex: -1000,
    },
    "&:hover:before": {
        transform: "translate3d(-50%, -50%, 0) rotate(-180deg)", // Fixed hover syntax
    }
}));



const CommonButton = ({
    text,
    endIcon,
    startIcon,
    onClick,
    disabled,
    type,
    href,
    fullWidth = false,
    className,
    maxWidth,
    padding,
    borderRadius,
    height,
    fontSize,
    fontWeight,
    opacity,
    disabledWithColor
}) => {
    return (
        <CustomButton
            fullWidth={fullWidth}
            type={type}
            disableElevation
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onClick}
            disabled={disabled}
            href={href}
            className={className} // className can be 'primary' or 'secondary'
            maxWidth={maxWidth}
            padding={padding}
            borderRadius={borderRadius}
            height={height}
            fontSize={fontSize}
            fontWeight={fontWeight}
            opacity={opacity}
            disabledWithColor={disabledWithColor}
        >
            {text}
        </CustomButton>
    );
};

export default CommonButton;
