import React from 'react';
import { Card, CardContent, Typography, Chip, Box } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const CommonChipDash = ({ title, value, change, isPositive, icon, useSvgBackground = false, showChipAndTypography = true, showIconVectorBG = true }) => {
    return (
        <Card
            sx={{
                width: '100%',
                height: showChipAndTypography ? '176px' : '138px',
                color: '#fff',
                borderRadius: '20px',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: useSvgBackground ? 'transparent' : 'rgba(249, 216, 147, 0.06)',
                backgroundImage: useSvgBackground ? `url('images/gradientImage.png')` : 'url(#sunsetGradient)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
        >
            {useSvgBackground && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        zIndex: 1,
                    }}
                />
            )}

            <CardContent sx={{
                padding: showChipAndTypography ? '18px' : '16px 16px 8px',
                height: '100%',
                position: 'relative',
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: showChipAndTypography ? 'space-between' : 'flex-start',
            }}>
                <Typography variant="subtitle2" sx={{ color: '#696969', fontWeight: 400, fontSize: '14px', lineHeight: "16.71px" }}>
                    {title}
                </Typography>
                <Typography variant="h4" sx={{
                    fontWeight: 800,
                    my: showChipAndTypography ? 1 : 2,
                    fontSize: '36px',
                    lineHeight: "42.96px"
                }}>
                    {value}
                </Typography>
                {showChipAndTypography && (
                    <Box display="flex" alignItems="center">
                        <Chip
                            icon={isPositive ?
                                <TrendingUpIcon sx={{ color: '#4CAF50', fontSize: '0.60rem' }} />
                                :
                                <TrendingDownIcon sx={{ color: '#F44336', fontSize: '0.60rem' }}
                                />
                            }
                            label={`${change}`}
                            size="small"
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                color: isPositive ? '#4CAF50' : '#F44336',
                                fontWeight: 'bold',
                                height: '20px',
                                '& .MuiChip-label': { fontSize: '10px' },
                            }}
                        />
                        <Typography variant="body2" sx={{ ml: 1, color: '#696969', fontSize: '14px' }}>
                            {isPositive ? `+2.60 Since The AI reviews usage` : `Than the last month`}
                        </Typography>
                    </Box>
                )}
            </CardContent>

            {/* Background Vector - Conditionally Rendered */}
            {showIconVectorBG && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '76px',
                        height: '72px',
                        backgroundImage: 'url("/images/ChipIcon/IconBGVector.svg")',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top right',
                    }}
                />
            )}

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '76px',
                    height: '72px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '-8px',
                    zIndex: 3,
                }}
            >
                {icon}
            </Box>

            {!useSvgBackground && (
                <svg width="0" height="0">
                    <defs>
                        <linearGradient id="sunsetGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" style={{ stopColor: 'rgb(255, 255, 0)', stopOpacity: 1 }} />
                            <stop offset="50%" style={{ stopColor: 'rgb(255, 165, 0)', stopOpacity: 1 }} />
                            <stop offset="100%" style={{ stopColor: 'rgb(255, 0, 255)', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                </svg>
            )}
        </Card>
    );
};

export default CommonChipDash;
