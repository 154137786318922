import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui';
import CommonButton from '../../../Components/Common/Button/CommonButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { showLoader } from '../../../Redux/Slices/loaderSlice';
import { useDispatch } from 'react-redux';
import { getBusinessDetails } from '../../../Redux/Actions/action';
import ErrorHandler from '../../../Utils/handler';
import { setJourney } from '../../../Redux/Slices/journeySlice';
import { BucketURL } from '../../../ApiSetup/Api';

const useStyles = makeStyles()((theme) => ({
    descriptionContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        marginBottom: "25px",
        alignItems: "center"
    },
    descriptionText: {
        fontSize: "35px",
        fontWeight: 300,
        lineHeight: "48px",
        letterSpacing: "-0.84px",
    },
    imageContainer: {
        marginBottom: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "34px"
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    }
}));
const User = () => {
    const { classes } = useStyles()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = new URLSearchParams(useLocation().search).get('id');

    const [companyName, setCompanyName] = useState()



    const onClickOfExperience = () => {
        navigate(`/user/keyword?id=${id}`);
        dispatch(setJourney("journey1"));
    }

    const onClickForConcern = () => {
        navigate(`/user/feedback?id=${id}`);
        dispatch(setJourney("journey2"));

    }
    console.log("id😂", id)
    const _getBusinessProfile = async () => {
        dispatch(showLoader(true))
        if (id) {

            let body = {
                _id: id,
                pageName: "landing"
            };
            try {
                const result = await dispatch(getBusinessDetails(body))
                const error = ErrorHandler(result)
                if (error) {
                    setCompanyName(result?.payload?.data)
                }
            } catch (error) {
                console.log(error)
            } finally {
                dispatch(showLoader(false))
            }
        }
    }

    useEffect(() => {
        _getBusinessProfile()
    }, [])

    return (
        <>
            <Grid item container>
                <Grid item xs={12} className={classes?.imageContainer} >
                    <img src={companyName?.avtar ? `${BucketURL}${companyName?.avtar}` : `/images/user/companyImage.svg`} alt="Company branding" width={"200px"} height={"200px"} />
                </Grid>
                <Grid item xs={12} className={classes?.descriptionContainer}>
                    <Typography fontSize={"42px"} fontWeight={300} color='#FFFFFF' maxWidth={"298px"} letterSpacing={"-2%"} lineHeight={"48px"} textAlign={"center"} fontFamily={"Satoshi"}
                        sx={{
                            background: "radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 33.88%, #FD63C5 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}><span className={classes.descriptionText}>Tell us</span>
                        <span style={{ display: "block", fontSize: "35px", fontWeight: "500" }}>about your experience</span>
                    </Typography>
                    <Typography fontSize={"18px"} fontWeight={400} color='#757575' textAlign={"center"} lineHeight={"24px"}>{`How was your experience at ${companyName?.name}?`}</Typography>
                </Grid>
                <Grid item xs={12} className={classes?.buttonContainer}>
                    <CommonButton variant='contained' className='primary' text={"Great Experience"} fullWidth onClick={onClickOfExperience} />
                    <CommonButton variant='contained' className='secondary' text={"I have a concern"} fullWidth onClick={onClickForConcern} />
                </Grid>
            </Grid>
        </>
    )
}

export default User