import React from 'react'

const UserIcon = ({ active }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" opacity={active ? "1" : "0.3"} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.1562 18C15.7225 18 17 16.7022 17 15.1111C17 12.2933 14.7425 10 11.9688 10H8.03125C5.2575 10 3 12.2933 3 15.1111C3 16.7022 4.2775 18 5.84375 18H14.1562Z" fill="url(#paint0_radial_553_1605)" />
      <path d="M6 5.5C6 7.43375 7.56625 9 9.5 9C11.4338 9 13 7.43375 13 5.5C13 3.56625 11.4338 2 9.5 2C7.56625 2 6 3.56625 6 5.5Z" fill="url(#paint1_radial_553_1605)" />
      <defs>
        <radialGradient id="paint0_radial_553_1605" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.00355 10.8511) rotate(34.2293) scale(10.8683 18.9667)">
          <stop stop-color="white" />
          <stop offset="0.338848" stop-color="#F4A606" />
          <stop offset="1" stop-color="#FD63C5" />
        </radialGradient>
        <radialGradient id="paint1_radial_553_1605" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.50177 2.74468) rotate(49.9729) scale(6.98578 12.9097)">
          <stop stop-color="white" />
          <stop offset="0.338848" stop-color="#F4A606" />
          <stop offset="1" stop-color="#FD63C5" />
        </radialGradient>
      </defs>
    </svg>

  )
}

export default UserIcon
