import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Divider,
    Button,
    Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Styled components
const StyledPaper = styled(Box)(({ theme }) => ({
    backgroundColor: '#0F0F0F',
    color: 'white',
    // padding: theme.spacing(4),
    padding: "40px 50px 50px 50px",
    borderRadius: theme.spacing(2),
    maxWidth: 500,
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    position: 'relative',
    [theme.breakpoints.down('sm')]: { // Mobile view
        padding: theme.spacing(2),
        margin: '20px auto',
        maxWidth: '90%',
    },
}));

const StatusBackgroundCircle = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: -36,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 80,
    height: 40,
    backgroundColor: '#0F0F0F',
    borderRadius: '40px 40px 0 0',
    zIndex: 1,
}));

const StatusIconWrapper = styled(Box)(({ success }) => ({
    position: 'absolute',
    top: -24,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    '& svg': {
        fontSize: 32,
        color: 'white',
    },
}));

const InfoBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#0F0F0F',
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    border: '1.665px solid #202020',
}));

const DownloadButton = styled(Button)(({ theme }) => ({
    color: 'rgba(255, 255, 255, 0.72)',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(3),
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    border: 'none',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
}));

const GradientButton = styled(Button)(({ theme }) => ({
    borderRadius: '30px',
    background: 'radial-gradient(184.42% 99.8% at 21.45% 10.64%, #FFF 0%, #F4A606 79%, #FD63C5 100%)',
    color: 'black',
    padding: theme.spacing(1.5),
    textTransform: 'none',
    fontSize: '15px',
    fontWeight: 500,
    marginTop: "2px",
    '&:hover': {
        opacity: 0.9,
    },
}));

const PaymentStatus = ({
    isSuccess,
    // amount,
    // refNumber, 
    // paymentTime,
    // paymentMethod, 
    // senderName ,
    // onUpdatePayment = () => { },
}) => {

    const navigate = useNavigate();

console.log(isSuccess,"isSuccess")
    const handleButtonClick = () => {
        if (isSuccess) {
            navigate('/keywords-and-tags');
        } else {
            navigate('/mangesubscription');
        }
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000000',
            padding: 2
        }}>
            <StyledPaper elevation={3}>
                <StatusBackgroundCircle />
                <StatusIconWrapper success={isSuccess}>
                    {isSuccess ? (
                        <img src="images/PaymentSuccessIcon.svg" alt="Success Icon" style={{ width: '100%', height: '100%' }} />
                    ) : (
                        <img src="images/PaymentFailIcon.svg" alt="Failure Icon" style={{ width: '100%', height: '100%' }} />
                    )}
                </StatusIconWrapper>

                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Typography sx={{ fontWeight: 500, mb: 1, fontSize: "32px" }}>
                        {isSuccess ? 'Payment Success!' : 'Payment Failed'}
                    </Typography>
                    <Typography sx={{ color: '#888', fontSize: "20px", marginTop: "10px" }}>
                        {isSuccess ? 'Thank you for your payment! Your subscription Plan is now active, and we’re excited to have you on board!' : 'We tried to charge your card, but something went wrong. Please update your payment method below to continue.'}
                    </Typography>
                </Box>

                {isSuccess ? (
                    <>
                        {/* <Divider sx={{ bgcolor: '#404040' }} /> */}

                        {/* <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="body2" sx={{ color: '#888', mb: 1 }}>
                                Total Payment
                            </Typography>
                            <Typography variant="h4" component="p" sx={{ fontWeight: 500, fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                                ${amount}
                            </Typography>
                        </Box>

                        <Grid container spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid item xs={12} sm={6}>
                                <InfoBox>
                                    <Typography variant="caption" sx={{ color: '#888' }}>
                                        Ref Number
                                    </Typography>
                                    <Typography variant="body2">
                                        {refNumber}
                                    </Typography>
                                </InfoBox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InfoBox>
                                    <Typography variant="caption" sx={{ color: '#888' }}>
                                        Payment Time
                                    </Typography>
                                    <Typography variant="body2">
                                        {paymentTime}
                                    </Typography>
                                </InfoBox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InfoBox>
                                    <Typography variant="caption" sx={{ color: '#888' }}>
                                        Payment Method
                                    </Typography>
                                    <Typography variant="body2">
                                        {paymentMethod}
                                    </Typography>
                                </InfoBox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InfoBox>
                                    <Typography variant="caption" sx={{ color: '#888' }}>
                                        Sender Name
                                    </Typography>
                                    <Typography variant="body2">
                                        {senderName}
                                    </Typography>
                                </InfoBox>
                            </Grid>
                        </Grid> */}

                        {/* <DownloadButton
                            variant="outlined"
                            startIcon={<img src="images/PaymentDownloadIcon.svg" alt="Download Icon" style={{ width: '32px', height: '32px' }} />}
                            fullWidth
                        >
                            Get PDF Receipt
                        </DownloadButton> */}
                        <GradientButton
                            fullWidth
                            onClick={handleButtonClick}
                        >
                            Payment Confirmed
                        </GradientButton>
                    </>
                ) : (
                    <GradientButton
                        fullWidth
                        onClick={handleButtonClick}
                    >
                        Update Payment Method
                    </GradientButton>
                )}
            </StyledPaper>
        </Box>
    );
};

export default PaymentStatus;
