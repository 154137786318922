import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()(() => ({
    mainBox: {
        maxWidth: "500px",
        margin: "0 auto",
        background: "#010101",
    },
    container: {
        padding: "40px !important"
    },
    titleContainer: {
        marginBottom: "50px"
    },
    stepper_main: {
        "& .Mui-disabled": {
            "& .MuiStepConnector-line": {
                borderColor: "#FFFFFF33",
            },
        },
        "& .MuiStepConnector-line": {
            borderColor: "#FFFFFF33",
        },
        "& .Mui-active.MuiSvgIcon-root": {
            color: "transparent",
            background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
            height: "24px",
            width: "24px",
            borderRadius: "50%"
        },
        "& .Mui-completed.MuiSvgIcon-root": {
            color: "transparent",
            background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
            height: "24px",
            opacity: "20%",
            width: "24px",
            borderRadius: "50%"
        },
        "& .MuiStepLabel-root.Mui-disabled": {
            color: "transparent",
            background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 33.88%, #FD63C5 100%)",
            opacity: "20%",
            height: "24px",
            width: "24px",
            borderRadius: "50%"
        },
        "& .MuiStepIcon-text": {
            fill: "#010101",
            fontSize: "12px",
            lineHeight: "24px",
            fontWeight: "700"
        }
    },
}));

const UserLayout = ({ children }) => {
    const { classes } = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const journeyType = useSelector((state) => state.journey.type);
    const id = new URLSearchParams(useLocation().search).get('id');
    const [activeStep, setActiveStep] = useState(0);

    const handleLogoClick = () => {
        navigate(`/user/experience?id=${id}`);
    };

    const handleStepClick = (index) => {
        if (index <= activeStep) {
            setActiveStep(index);

            if (journeyType === "journey1") {
                switch (index) {
                    case 0:
                        navigate(`/user/experience?id=${id}`);
                        break;
                    case 1:
                        navigate(`/user/keyword?id=${id}`);
                        break;
                    case 2:
                        navigate(`/user/review?id=${id}`);
                        break;
                    default:
                        break;
                }
            } else if (journeyType === "journey2") {
                switch (index) {
                    case 0:
                        navigate(`/user/experience?id=${id}`);
                        break;
                    case 1:
                        navigate(`/user/feedback?id=${id}`);
                        break;
                    case 2:
                        navigate(`/user/feedbackform?id=${id}`);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    useEffect(() => {
        switch (location?.pathname) {
            case "/user/experience":
                setActiveStep(0);
                break;
            case "/user/keyword":
                setActiveStep(1);
                break;
            case "/user/review":
                setActiveStep(2);
                break;
            case "/user/feedback":
                setActiveStep(1);
                break;
            case "/user/feedbackform":
                setActiveStep(2);
                break;
            default:
                setActiveStep(0);
                break;
        }
    }, [location?.pathname]);

    return (
        <>
            <Grid container className={classes?.mainBox}>
                <Grid item container className={classes?.container}>
                    <Grid item xs={12} className={classes?.titleContainer}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Box display={"flex"} alignItems={"center"} gap={"10px"} onClick={handleLogoClick} sx={{ cursor: "pointer" }}>
                                <img src="/images/logo.svg" alt="logo" height={"28px"} width={"28px"} />
                                <Typography fontSize={"14px"} fontWeight={600} color='#FFFFFF'>
                                    The AI Reviews
                                </Typography>
                            </Box>
                            {/* <div id="google_translate_element" className="dropdown_head2" ></div> */}
                        </Box>
                    </Grid>
                    {location?.pathname !== "/user/success" && (
                        <Grid item xs={12} marginBottom={"59px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Stepper
                                activeStep={activeStep}
                                className={classes.stepper_main}
                                sx={{ width: "85%" }}
                            >
                                {[1, 2, 3].map((label, index) => (
                                    <Step
                                        key={label}
                                        onClick={() => handleStepClick(index)}
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <StepLabel>{""}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default UserLayout;
