import {
  Box,
  Divider,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonButton from "../../Components/Common/Button/CommonButton";
import { useNavigate } from "react-router-dom";
import { businessPaymentHistory, bussinessPlanDetails } from "../../Redux/Actions/action";
import { showLoader } from "../../Redux/Slices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandler from "../../Utils/handler";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.09px",
    color: "#FFFFFF",
    fontFamily: "Wanted Sans",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "Wanted Sans",
    lineHeight: "16.71px",
    fontWeight: 400,
    color: "#7A7373",
    borderBottom: "none",
  },
}));

const Subscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedBusiness = useSelector((state) => state?.business?.business);
  const [paymentHistoryData, setPaymentHistoryData] = useState([])
  const [isSubscription, setIsSubscription] = useState(false)
  const [subscriptionDetail, setSubscriptionDetail] = useState({})

  const handleDownloadInvoice = (pdfUrl) => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Invoice.pdf"; // Name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the element after click
  };

  const ValidAmount = (amount) => {
    return amount / 100
  }

  const _getPaymentHistory = async () => {
    if (selectedBusiness?._id) {
      dispatch(showLoader(true))
      let body = {
        businessId: selectedBusiness?._id
      };
      try {
        const result = await dispatch(businessPaymentHistory(body));
        const error = ErrorHandler(result);
        console.log(result, "result")
        if (error) {
          setPaymentHistoryData(result?.payload?.data?.response)
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false))
      }
    }
  };

  // ==> get bussiness Plan Details
  const _getBussinessPlanDetails = async () => {
    if (selectedBusiness?._id) {
      dispatch(showLoader(true));
      let body = selectedBusiness?._id;
      try {
        const result = await dispatch(bussinessPlanDetails(body));
        const error = await ErrorHandler(result);
        if (error) {
          setIsSubscription(result?.payload?.data?.isSubscribe);
          setSubscriptionDetail(result?.payload?.data)
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  useEffect(() => {
    _getPaymentHistory()
    _getBussinessPlanDetails()
  }, [selectedBusiness])

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ padding: "0px 10px", color: "#fff" }}
      >
        {/* Left Section */}
        <Grid item xs={12} md={6} container direction="column" spacing={3}>
          {/* Subscription Section */}
          <Grid item pl={{ xs: "10px !important", sm: "24px !important" }}>
            <Grid
              item
              xs={12}
              container
              sx={{
                backgroundColor: "#13131399",
                borderRadius: "26px",
                padding: { xs: "20px", sm: "36px" },
              }}
            >
              <Grid item xs={12} mb={"20px"}>
                <Box display={"flex"} gap={3}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={700}
                    lineHeight={"19.09px"}
                  >
                    {"Current Plan :-"}
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    fontWeight={700}
                    lineHeight={"19.09px"}
                  >
                    {isSubscription ? `$${(subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.price)?.toFixed()}/${subscriptionDetail?.subscrptionPlanDetails?.subscrptionplansList?.name}` : "No currenct plan"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} mb={"14px"}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={"400"}
                  lineHeight={"16.71px"}
                  color={"#7A7373"}
                  mb={"4px"}
                >
                  Plan Start Date:   {isSubscription ? dayjs(subscriptionDetail?.subscrptionPlanDetails?.planStartDate)?.format("DD/MM/YYYY") : "No details found"}
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontWeight={"400"}
                  lineHeight={"16.71px"}
                  color={"#7A7373"}
                >
                  Plan End Date:   {isSubscription ? dayjs(subscriptionDetail?.subscrptionPlanDetails?.planEndDate)?.format("DD/MM/YYYY") : "No details found"}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={"39px"}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={"400"}
                  lineHeight={"16.71px"}
                  color={"#7A7373"}
                >
                  Unlock the full potential of your card designs with our
                  Premium plan! Gain access to exclusive templates and advanced
                  customization.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CommonButton
                  height={"45px"}
                  text={isSubscription ? "Manage Subscription" : "Subscribe"}
                  className={"primary"}
                  maxWidth={"188px"}
                  onClick={() => {
                    navigate("/mangesubscription")
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Trial Section */}
          {/* <Grid item pl={{ xs: "10px !important", sm: "24px !important" }}>
            <Grid
              item
              container
              xs={12}
              sx={{
                backgroundColor: "#13131399",
                borderRadius: "26px",
                padding: { xs: "20px", sm: "36px" },
              }}
              spacing={1}
            >
              <Grid item xs={12} md={7}>
                <Grid item xs={12} mb={"8px"}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={"400"}
                    lineHeight={"16.71px"}
                    color={"#7A7373"}
                  >
                    Unlimited access across all device
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={"23px"}>
                  <Typography
                    fontSize={"22px"}
                    fontWeight={"700"}
                    lineHeight={"26.25px"}
                    color={"#FFFFFF"}
                  >
                    Refer a Friend 1 month free subscription at last
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={"20px"}>
                  <Typography
                    fontSize="14px"
                    fontWeight="700"
                    lineHeight="16.71px"
                    sx={{
                      background: "radial-gradient(141.22% 118.61% at 21.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Start free trial for one month
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={"400"}
                    lineHeight={"20px"}
                    color={"#7A7373"}
                  >
                    Enjoy one month of free card design with no hidden costs or
                    commitments! Create beautiful business cards, invitations,
                    and more with our easy-to-use design tools.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                md={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Divider orientation="vertical" variant="middle" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid item xs={12} mb={"30px"}>
                  <Typography
                    fontSize={"16px"}
                    lineHeight={"19.09px"}
                    fontWeight={700}
                  >
                    What's included
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box display={"flex"} flexDirection={"column"} gap={"18px"}>
                    <Box display={"flex"} gap={"7px"}>
                      <img src="/images/rightIcon.svg" alt="Checked icon"/>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"#7A7373"}
                      >
                        High-Resolution Downloads
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"7px"}>
                      <img src="/images/rightIcon.svg" alt="Checked icon"/>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"#7A7373"}
                      >
                        Unlimited Access
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"7px"}>
                      <img src="/images/rightIcon.svg" alt="Checked icon"/>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"#7A7373"}
                      >
                        High-Quality Exports
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"7px"}>
                      <img src="/images/rightIcon.svg" alt="Checked icon"/>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"#7A7373"}
                      >
                        Collaboration Tools
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"7px"}>
                      <img src="/images/rightIcon.svg" alt="Checked icon"/>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        color={"#7A7373"}
                      >
                        Advanced Customization
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={6}
          pl={{ xs: "10px !important", sm: "24px !important" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              background: "#201B14",
              padding: { xs: "20px 25px", sm: "30px 43px" },
              borderRadius: "26px 26px 0px 0px",
            }}
          >
            <Typography variant="h6">Payment History</Typography>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{
              padding: { xs: "12px 10px", sm: "17px 28px" },
              backgroundColor: "#13131399 !important",
              backgroundImage: "none",
              borderRadius: "0px 0px 26px 26px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentHistoryData?.length > 0 ? paymentHistoryData?.map((paymenHistory, index) => (
                  <TableRow key={index}>
                    <StyledTableCell><Box minWidth={"80px"}> {dayjs(paymenHistory?.created).format("DD-MM-YYYY")} </Box></StyledTableCell>
                    <StyledTableCell>{`$ ${ValidAmount(paymenHistory?.amount)}.00`}</StyledTableCell>
                    <StyledTableCell>{paymenHistory?.isActive ? "Active" : "Inactive"}</StyledTableCell>
                    <StyledTableCell>
                      <CommonButton
                        text={"Invoice"}
                        className={"primary"}
                        padding={"12px 16px"}
                        height={"25px"}
                        maxWidth={"62px"}
                        fontSize={"10px"}
                        onClick={() => handleDownloadInvoice(paymenHistory?.paymentPayslipUrl)}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))
                  :
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      // width="100%"
                      >
                        <img src="/images/noDataFound.svg" alt="no data" />
                      </Box>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </>
  );
};

export default Subscription;
