import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Checkbox, CircularProgress, Divider, Grid, IconButton, Rating, Switch, Tooltip, Typography } from '@mui/material';
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CustomPagination from '../../Components/Common/CustomPagination';
import ErrorHandler, { TruncatedTableCell } from '../../Utils/handler';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { _getgenerateFeedbackAiSuggestion, addNotes, getGoogleReview, postReplyToComplain } from '../../Redux/Actions/action';
import { showLoader } from '../../Redux/Slices/loaderSlice';
import sessionStoreUtil from '../../ApiSetup/Localstorage';
import CommonChep from '../../Components/Common/CommonChep';
import { ratings, showTicketStatus, statusFilter } from '../../Utils/enum';
import CommonButton from '../../Components/Common/Button/CommonButton';
import CommonConformationModal from '../../Components/Common/CommonConformationModal';
import AutoCompleteSearch from '../../Components/Common/commonAutoComplete';
import CustomDrawer from '../../Components/Common/CustomDrawer';
import TextLabel from '../../Components/Common/Fields/TextLabel';
import CommonTextField from '../../Components/Common/Fields/TextField';
import { makeStyles } from 'tss-react/mui';
import { Note } from '@material-ui/icons';
import CommonModal from '../../Components/Common/CommonModel';
import CommonTextEditor from '../../Components/Common/CommonTextEditor';

// Custom Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Wanted Sans",
    whiteSpace: "nowrap",
    padding: "22px 18px", // 28 20
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Wanted Sans",
    fontWeight: 400,
    padding: "15px 20px",
    backgroundColor: "#0e0e0e",
    color: "rgba(163, 158, 158, 1)",
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0e0e0e",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomSwitch = styled(Switch)(() => ({
  width: 58,
  height: 32,
  padding: 3,
  '& .MuiSwitch-switchBase': {
    margin: "2px 0px 0px 5px",
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(26px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#000',
      },
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(90deg, #FFDD00 0%, #FF3D88 100%)',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#000',
    width: 20,
    height: 20,
    marginTop: 4
  },
  '& .MuiSwitch-track': {
    borderRadius: "30px",
    background: '#FFFFFF33',
    opacity: 1,
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    boxContainer: {
      position: "relative",
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }
});

const rows = [
  {
    _id: 1,
    businessName: 'Frozen yogurt',
    reviewContent: "reviewCondasds adasdtentre viewContdasda dasdasdasdasdas sdasdasentreviewContent reviewContent reviewContent reviewContent reviewContent",
    createdAi: "safdsghdjh dasihdasdhs dhag dgad asfdgasfd agsfd gasfdg asdgxc fasgdfasgdfasgxcvxcvvcvxcvdf g",
    rating: 4,
    dateOfResponce: "09-10-2015",
    status: 4
  },
  {
    _id: 2,
    businessName: 'Frozen yogurt',
    reviewContent: "reviewContesd reviewContesd reviewContesd reviewContesd reviewConte ",
    createdAi: "safdsghdjh dasihdasdhs dhag dgad asfdgad dfsdffsdfsdfsdfsfdd agsfd gasfdg asdgfasgdfasgdfasgdf g",
    rating: 3,
    dateOfResponce: "09-10-2015",
    status: 1
  },
  {
    _id: 3,
    businessName: 'Frozen yogurt',
    reviewContent: "reviewContentre viewContentreviewContent reviewContent reviewContent reviewContent reviewContent",
    createdAi: "safdsghdjh dasihdasdhs dhag dgad asfdgasfd fsdfsdf fsddf fsf sd agsfd gasfdg asdgfasgdfasgdfasgdf g",
    rating: 3,
    dateOfResponce: "09-10-2015",
    status: 2
  },
]

const Reviews = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const userData = sessionStoreUtil?.getLSItem("userData");
  const loginUser = userData ? JSON.parse(userData) : null;


  // ---------------------------- STATE --------------------------------
  const [count, setCount] = useState(0)  // set count direct in get data
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState({
    business: "",
    response: "",
    rating: "",
  });
  const [reviewData, setReviewData] = useState([])
  const [conformationModelOpen, setConformationModalOpen] = useState(false)
  const [conformationModelForAutoResponse, setConformationModelForAutoResponse] = useState(false)
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [checked, setChecked] = useState(false);
  const [pendingChecked, setPendingChecked] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [updateSuggestions, setUpdateSuggestions] = useState({
    Complain: "",
    Suggestions: ""
  })
  const [editSuggestions, setEditSuggestions] = useState("")
  const [errorSuggestions, setErrorSuggestions] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  // const [modelOpenForNote, setModelOpenForNote] = useState(false)
  const [noteId, setNoteId] = useState(null)
  const [dataNote, setDataNote] = useState({ topicDescription: "" });
  const [error, setError] = useState({});


  // Content Change in the Note
  const handleContentChange = (content) => {
    setDataNote(prevState => ({
      ...prevState,
      topicDescription: content
    }));
  };

  const isSelected = (id) => selectedReviews?.indexOf(id) !== -1;

  // Handle selecting or deselecting a single row
  const handleClick = (event, id, status) => {
    if (status === 3 && status === 4) return;

    const selectedIndex = selectedReviews?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReviews, id);
    } else {
      newSelected = selectedReviews?.filter((item) => item !== id);
    }
    setSelectedReviews(newSelected);
  };
  console.log(selectedReviews, "selected");

  // Handle selecting or deselecting all rows
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows
        .filter((item) => item.status !== 3 && item?.status !== 4)
        .map((item) => item._id);

      setSelectedReviews(newSelected);
    } else {
      setSelectedReviews([]);
    }
  };

  const removeParagraphTags = (content) => {
    if (content.startsWith('<p>') && content?.endsWith('</p>')) {
      return content?.slice(3, -4);
    }
    return content;
  };


  // ------------------------ HANDLE CHANGE ----------------------------
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };

  const handleConformationModel = () => {
    setConformationModalOpen(false)
    setSelectedReviews([])
  }

  const handleChange = (event) => {
    setConformationModelForAutoResponse(true)
    setPendingChecked(event.target.checked);
  };

  const handleClickForAutoResponse = () => {
    setChecked(pendingChecked);
    setConformationModelForAutoResponse(false)
  }

  const validation = () => {
    let valid = true

    if (!editSuggestions) {
      valid = false
      setErrorSuggestions("Please enter Suggestion")
    }

    return valid
  }

  // ==>> API CALL
  const _getReview = async () => {
    const status = statusFilter?.find((val) => val?.name === filterData?.status)?.id
    let body = {
      // status: status || "",
      // rating: filterData?.rating?.stars ||
      businessId: loginUser?._id,
      keywords: []
    };
    dispatch(showLoader(true));
    try {
      const result = await dispatch(getGoogleReview(body));
      const error = ErrorHandler(result);
      if (error) {
        console.log(result, "result😀F")
        // setReviewData(result?.payload?.data?.response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false));
    }
  };


  // _editComplains
  const _editComplains = async (aiGenerate) => {
    dispatch(showLoader(true))
    let body = {
      feedbackId: [updateSuggestions?._id] || [],
      suggestion: aiGenerate ? updateSuggestions?.Suggestions : editSuggestions
    };
    try {
      const result = await dispatch(postReplyToComplain(body));
      const error = ErrorHandler(result);
      if (error) {
        setDrawer(false);
        setEditSuggestions("");
        setErrorSuggestions("");
        _getReview();
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };

  // get buisness Filter Data
  const _getAiGenerateSuggestions = async () => {
    setIsLoading(true)
    let body = {
      feedbackId: updateSuggestions?._id
    };
    try {
      const result = await dispatch(_getgenerateFeedbackAiSuggestion(body));
      const error = ErrorHandler(result);
      if (error) {

        // setUpdateSuggestions({
        //   Suggestions:
        // })
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false)
    }
  };


  const _addNote = async () => {
    dispatch(showLoader(true))
    let body = {
      businessId: noteId,
      privateNotes: removeParagraphTags(dataNote?.topicDescription)
    };
    try {
      const result = await dispatch(addNotes(body));
      const error = ErrorHandler(result);
      if (error) {
        // _getComplains(1)
        setNoteId(null)
        // setModelOpenForNote(false)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false))
    }
  };


  // React.useEffect(() => {
  //   _getReview();
  // }, [filterData]);


  return (
    <>
      <Grid container xs={12} spacing={0}>
        {/* <Grid item xs={12}>
          <CommonSearch />
        </Grid> */}

        <Grid container item spacing={1} xs={12} mt={"35px"} alignItems={"center"}>
          {/* {(loginUser?.userType === 0 || loginUser?.userType === 1) &&
            <Grid item xs={12} sm={3} md={2}>
              <AutoCompleteSearch
                options={business?.map((val) => val?.name)}
                placeholder={"Business"}
                name={"business"}
                handleChange={(e, newValue) => {
                  setFilterData({
                    ...filterData,
                    business: newValue,
                  });
                }}
                fullWidth
                defaultValue={filterData?.business}
              />
            </Grid>} */}
          <Grid item xs={12} sm={3} md={2}>
            <AutoCompleteSearch
              options={statusFilter?.map((val) => val?.name)}
              placeholder={"Status"}
              name={"response"}
              handleChange={(e, newValue) => {
                setFilterData({
                  ...filterData,
                  response: newValue,
                });
              }}
              fullWidth
              defaultValue={filterData?.response}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <AutoCompleteSearch
              options={ratings}
              renderOption={(props, option) => {
                return (
                  <Box {...props} display="flex" alignItems="center" sx={{ backgroundColor: "#070707" }}>
                    <Typography variant="body2" style={{ marginRight: '8px', color: "#6a6a6a" }}>
                      {option?.label}
                    </Typography>
                    <Rating
                      name="read-only"
                      value={option?.stars}
                      readOnly
                      size="small"
                    />
                  </Box>
                );
              }}
              placeholder={"Rating"}
              name={"rating"}
              handleChange={(e, newValue) => {
                setFilterData({
                  ...filterData,
                  rating: newValue,
                });
              }}
              fullWidth
              defaultValue={filterData?.rating}
              getOptionLabel={(option) => option?.stars || ""}
            />
          </Grid>
          {selectedReviews?.length > 0 &&
            <Grid item xs={6} sm={3} md={4} mt={{ xs: 1, sm: 0 }}>
              <CommonButton
                height={"45px"}
                maxWidth={"140px"}
                fontSize={"14px"}
                text={"Post"}
                className={"primary"}
                onClick={() => { setConformationModalOpen(true) }}
              />
            </Grid>
          }
          {/* {loginUser?.userType === 2 ?
            (<Grid item xs={selectedReviews?.length > 0 ? 6 : 12} sm={selectedReviews?.length > 0 ? 3 : 6} md={selectedReviews?.length > 0 ? 4 : 8} display={"flex"} justifyContent={{ xs: selectedReviews?.length > 0 ? "flex-end" : "start", sm: selectedReviews?.length > 0 ? "flex-end" : "flex-end", md: "flex-end" }} mt={{ xs: 1, sm: 0 }}>
              <Tooltip arrow title="Auto Response">
                <CustomSwitch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Tooltip>
            </Grid>)
            : */}
          <Grid item xs={selectedReviews?.length > 0 ? 6 : 12} sm={selectedReviews?.length > 0 ? 3 : 6} md={selectedReviews?.length > 0 ? 4 : 8} display={"flex"} justifyContent={"flex-end"} mt={{ xs: 1, sm: 0 }}>
            <Tooltip arrow title="Auto Response">
              <CustomSwitch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Tooltip>
          </Grid>
          {/* } */}
        </Grid>

        <Grid item xs={12} mt={"24px"} sx={{ borderRadius: "26px", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="selectable table">
              <TableHead sx={{ background: "#131313", color: "white" }}>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedReviews.length > 0 && selectedReviews.length < rows.filter((item) => item.status !== 3 && item.status !== 4).length}
                      checked={rows?.filter((item) => item.status !== 3 && item.status !== 4).length > 0 && selectedReviews.length === rows.filter((item) => item.status !== 3 && item.status !== 4).length}
                      onChange={handleSelectAllClick}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          color: "#666666",
                        },
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="start">Business name</StyledTableCell>
                  <StyledTableCell align="start">Review content</StyledTableCell>
                  <StyledTableCell align="start">AI Suggestion</StyledTableCell>
                  <StyledTableCell align="start">Star Rating</StyledTableCell>
                  <StyledTableCell align="start">Date of Response</StyledTableCell>
                  <StyledTableCell align="start">Status</StyledTableCell>
                  {/* <StyledTableCell>Note</StyledTableCell> */}
                </TableRow>
              </TableHead>
              {rows?.length > 0 ? (<> <TableBody>
                {rows?.map((item) => {
                  const isItemSelected = isSelected(item._id);

                  return (
                    <StyledTableRow
                      key={item?.businessName}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        backgroundColor: '#13131399!important',
                        backgroundImage: "none",
                        '&:hover': {
                          backgroundColor: 'inherit',
                          backgroundImage: "none"
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#13131399 !important',
                          backgroundImage: "none"

                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#13131399!important',
                          backgroundImage: "none"
                        },
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          disabled={item?.status === 3 || item.status === 4}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: "#333333",
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClick(event, item?._id, item?.status);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{item?.businessName}</StyledTableCell>
                      <StyledTableCell align="start">
                        <TruncatedTableCell content={item?.reviewContent} />
                      </StyledTableCell>
                      <StyledTableCell align="start">
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                          <TruncatedTableCell content={item?.createdAi} />
                          {item?.status !== 3 && item?.status !== 4 && selectedReviews?.length === 0 &&
                            <CommonButton
                              text={"Post"}
                              padding={"12px 16px"}
                              className={"primary"}
                              maxWidth={"48px"}
                              height={"25px"}
                              fontSize={"10px"}
                              fontWeight={500}
                              // onClick={() => { setConformationModalOpen(true); setSelectedReviews([...selectedReviews, item?._id]) }}
                              onClick={() => {
                                setDrawer(true);
                                setUpdateSuggestions({
                                  Complain: item?.reviewContent,
                                  Suggestions: item?.createdAi,
                                  _id: item?._id
                                })
                              }}
                            />}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="start">
                        <Box display={"flex"} gap={1}>
                          {`${item?.rating}/`}
                          <Rating name="read" defaultValue={item.rating} readOnly size="small" />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="start">{item?.dateOfResponce}</StyledTableCell>
                      <StyledTableCell align="start">
                        <CommonChep label={showTicketStatus(item?.status)} />
                      </StyledTableCell>
                      {/* <StyledTableCell align='center'>
                        <IconButton onClick={() => {
                          setModelOpenForNote(true);
                          setNoteId(item?._id);
                          setDataNote({ topicDescription: item?.privateNotes });
                        }}>
                          <Note />
                        </IconButton>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
                {/* <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ padding: 0 }}>
                      <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
                        <CustomPagination
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          count={Math.ceil(count / rowsPerPage)}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter> */}
              </>) :
                (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                        >
                          <img src="/images/noDataFound.svg" alt="no data" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
            </Table>
          </TableContainer>
          {rows?.length > 0 && (
            <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
              <CustomPagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={Math.ceil(count / rowsPerPage)}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </Grid >

        {conformationModelOpen &&
          <CommonConformationModal
            open={conformationModelOpen}
            onClose={() => { handleConformationModel() }}
            onSubmit={() => { handleConformationModel() }}
            title={"Type Your Business"}
            maxWidth={"sm"}
            question={"Are you sure you want to post this response?"}
          />
        }

        {conformationModelForAutoResponse &&
          <CommonConformationModal
            open={conformationModelForAutoResponse}
            onClose={() => { setConformationModelForAutoResponse(false) }}
            onSubmit={() => { handleClickForAutoResponse() }}
            maxWidth={"sm"}
            question={!checked ? "Are you sure you want to on this switch? All pending responses will be submitted after this." : "Are you sure you want to off this switch?"}
          />
        }


        <CustomDrawer
          isDrawerOpen={drawer}
          handleClose={() => {
            setDrawer(false);
            setErrorSuggestions("");
          }}
          title={"Update Suggestions"}
        >
          <Grid position={"absolute"} container mt={6} spacing={1} padding={{ xs: "20px", sm: "30px" }} >
            <Grid item xs={12}>
              <TextLabel title={"1. Review content"} color={'#FFFFFF'} fontSize={"18px"} fontWeight={600} lineHeight={"21.48px"} />
              <TextLabel marginTop={"12px"} title={updateSuggestions?.Complain} color={'#6C6C6C'} fontSize={"15px"} fontWeight={400} lineHeight={"22px"} />
            </Grid>
            <Grid item xs={12} mt={2}><Divider /></Grid>
            <Grid item xs={12} mt={2}>
              <TextLabel title={"2. Ai Suggestions"} color={'#FFFFFF'} fontSize={"18px"} fontWeight={600} lineHeight={"21.48px"} />
              <TextLabel marginTop={"12px"} title={updateSuggestions?.Suggestions} color={'#6C6C6C'} fontSize={"15px"} fontWeight={400} lineHeight={"22px"} />
            </Grid>

            <Grid item xs={12} mt={"20px"} display={"flex"} justifyContent={'flex-end'} gap={2} >
              <CommonButton
                className="secondary"
                borderRadius={"30px"}
                text={"Regenerate"}
                maxWidth={"149px"}
                height={"47px"}
                onClick={() => _getAiGenerateSuggestions()}
              />
              <CommonButton
                className="primary"
                borderRadius={"30px"}
                text={"Submit"}
                maxWidth={"118px"}
                height={"47px"}
                onClick={() => { _editComplains(true) }}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <CommonTextField
                text={"Suggestion"}
                type={"text"}
                placeholder={"enter your Suggestion..."}
                name={"complain"}
                multiline
                rows={4}
                onChange={(e) => {
                  setEditSuggestions(e.target.value)
                }}
                value={editSuggestions}
              />
            </Grid>
            <Grid item xs={12} mt={9}>
              <TextLabel
                marginTop={1}
                fontSize={"12px"}
                color={"error"}
                title={!editSuggestions ? errorSuggestions : ""}
              />
            </Grid>

            <Grid item xs={12} mt={"20px"} display={"flex"} justifyContent={'flex-end'}>
              <CommonButton
                className="primary"
                borderRadius={"30px"}
                text={"Submit"}
                maxWidth={"118px"}
                height={"47px"}
                onClick={() => {
                  if (validation()) {
                    _editComplains(false)
                  }
                }}
              />
            </Grid>

            {/* Display the Note Form below the Submit Button */}
            <Grid item xs={12}>
              <TextLabel title={"Note"} color={'#FFFFFF'} fontSize={"18px"} fontWeight={600} lineHeight={"21.48px"} />
              <CommonTextEditor
                text="Topic Description"
                handleContentChange={handleContentChange}
                value={dataNote?.topicDescription}
                valid
              />
              <TextLabel
                marginTop={"5px"}
                fontSize={"12px"}
                color={"error"}
                title={!dataNote?.topicDescription ? error?.topicDescription : ""}
              />
            </Grid>

            {/* <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={1}>
              <CommonButton
                maxWidth={"90px"}
                text={"Submit"}
                height={"40px"}
                className={"primary"}
                onClick={() => {
                  if (!dataNote?.topicDescription) {
                    setError({ ...error, topicDescription: "please enter note first" })
                  } else {
                    _addNote()
                  }
                }}
              />
            </Grid> */}
          </Grid>

          {isLoading && (
            <Box className={classes.boxContainer}>
              <div className="loader-container">
                <div className="spinner-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <h2>Loading</h2>
              </div>
            </Box>
          )}
        </CustomDrawer>




      </Grid >

      {/* {modelOpenForNote && (
        <CommonModal
          open={modelOpenForNote}
          onClose={() => {
            setModelOpenForNote(false);
            setError({});
            setDataNote({ topicDescription: "" });
            setNoteId(null);
          }}
          title={"Notes"}
          maxWidth={"md"}
          content={
            <Grid
              container
              xs={12}
              padding={{ xs: "12px 16px", sm: "20px 20px" }}
              sx={{ backgroundColor: "#222222" }}
            >
              <Grid item xs={12}>
                <CommonTextEditor
                  text="Topic Description"
                  handleContentChange={handleContentChange}
                  value={dataNote?.topicDescription}
                  valid
                />
                <TextLabel
                  marginTop={"5px"}
                  fontSize={"12px"}
                  color={"error"}
                  title={!dataNote?.topicDescription ? error?.topicDescription : ""}
                />
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={1}>
                <CommonButton
                  maxWidth={"90px"}
                  text={"Submit"}
                  height={"40px"}
                  className={"primary"}
                  onClick={() => {
                    if (!dataNote?.topicDescription) {
                      setError({ ...error, topicDescription: "please enter note first" })
                    } else {
                      _addNote()
                    }
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      )} */}
    </>
  )
}

export default Reviews
