import { Typography, Box, InputLabel } from "@mui/material";
// import { Image_BASE_URL } from "../../APiSetUp/axios";
import { styled } from '@mui/material/styles';
import { makeStyles } from "tss-react/mui";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const useStyles = makeStyles()((theme) => {
    return {
        clearBox: {
            borderRadius: "2px",
            padding: "4px",
            color: "#5F616C",
            backgroundColor: "red",
            cursor: "pointer",
        },
    };
});

const Image_BASE_URL = "";
const DocumentsUpload = ({
    handleFileChange,
    selectedFile,
    OnDelate,
    text,
    //to only accept filetype as images and it's type
    acceptFile = "image/*",
    multiple = false,
    title,
    titleHead,
    fontWeight,
    labelSize,
    labelColor,
    valid,
    whiteSpace,
}) => {
    const { classes } = useStyles();
    const fileExtension = selectedFile?.split('.')?.pop()?.toLowerCase();

    const getThumbnail = () => {
        let thumb;
        if (fileExtension === 'pdf') {
            thumb = <PictureAsPdfIcon fontSize="large" color="error" style={{ height: '130px', maxWidth: '100%' }} />
        } else if (fileExtension === 'mp3') {
            thumb = <audio class='audioPlayer' controls style={{ height: '44px' }}>  <source src={`${Image_BASE_URL}${selectedFile}`} type="audio/mpeg" /></audio>
        } else if (fileExtension === 'jpeg' || fileExtension === 'gif' || fileExtension === 'jpg' || fileExtension === 'png') {
            thumb = <img src={`${Image_BASE_URL}${selectedFile}`} alt="Selected" style={{ height: '130px', maxWidth: '100%' }} />
        } else {
            thumb = <NoteAddIcon fontSize="large" color="primary" style={{ height: '130px', maxWidth: '100%' }} />
        }
        return thumb
    }

    return (
        <>
            {titleHead && (
                <Box
                    mb={0.5}
                    display="flex"
                    fontSize="12px"
                    flexDirection={"row"}
                    marginBottom={"8px"}
                >
                    <InputLabel
                        sx={{
                            letterSpacing: "0.03em",
                            marginRight: "2px",
                            fontWeight: fontWeight,
                            fontSize: labelSize || "14px",
                            color: labelColor || "rgba(255, 255, 255, 1)",
                            whiteSpace: whiteSpace ? "wrap" : "nowrap"
                        }}
                    >
                        {titleHead}
                    </InputLabel>
                    {valid && (
                        <Typography color="#cf2525" component={"caption"} variant={"body2"} >
                            *
                        </Typography>
                    )}
                </Box>
            )}
            <Box component="label" p={1}
                sx={{
                    border: "1px dashed #FFFFFF",
                    borderRadius: "27.5px",
                    cursor: "pointer",
                    backgroundColor: "#222222",
                    color: "#5F616C"
                }}
                display={'flex'}
                width={'100%'}
                flexDirection={'column'}
                flexWrap={'wrap'}
                padding={"19px 21px"}
            >
                {!selectedFile &&
                    <>
                        <Typography variant="body2" display={"flex"} alignItems={"center"}>  <img src={"/images/imageUpload.svg"} alt="img" style={{ marginRight: "8px" }} /> {title}</Typography>
                        <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                            onClick={(event) => { event.target.value = null }}
                            accept={acceptFile}
                            multiple={multiple} />
                    </>
                }
                {selectedFile &&
                    <>
                        <Box display={'flex'} flexDirection={'column'} gap={3} width={'100%'}>
                            <Box display={'flex'} justifyContent={'space-between'} gap={5} alignItems={'center'}>
                                <Typography variant="body2" color={'primary'}>
                                    {title}
                                </Typography>
                                <Box display={"flex"} justifyContent={"flex-end"} gap={1} onClick={OnDelate}>
                                    <CloseOutlinedIcon sx={{ color: "red", fontSize: "23px" }} className={classes.clearBox} />
                                </Box>
                            </Box>
                            <Box display={'flex'} justifyContent={'center'}>
                                {getThumbnail()}
                            </Box>
                        </Box>
                    </>
                }
            </Box >
        </>
    );
};


export default DocumentsUpload