import {
  Box, Button, Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTabs from "../../Components/Common/CommonTabs";
import CommonTextField from "../../Components/Common/Fields/TextField";
import CommonButton from "../../Components/Common/Button/CommonButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomPagination from "../../Components/Common/CustomPagination";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CommonModal from "../../Components/Common/CommonModel";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandler from "../../Utils/handler";
import { addBusinessKeywords, addGenerate, deleteBusinessKeywords, deleteGenerate, editBusinessKeywords, getBusinessKeywords, getGenerate, getSingleData, keywordGenerate, tagGenerate } from "../../Redux/Actions/action";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { showLoader } from "../../Redux/Slices/loaderSlice";
import CommonConformationModal from "../../Components/Common/CommonConformationModal";
import { useSearchParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

// Custom Table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Wanted Sans",
    whiteSpace: "nowrap",
    padding: "28px 40px", // 28 20
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Wanted Sans",
    fontWeight: 400,
    padding: "15px 20px",
    backgroundColor: "#0e0e0e",
    color: "rgba(163, 158, 158, 1)",
    borderBottom: "none",
  },
}));

const useStyles = makeStyles()((theme) => {
  return {
    customButton: {
      background: "#161616",
      width: "auto",
      height: "41px",
      borderRadius: "30px",
      fontWeight: 500,
      padding: "16px 20px",
      color: "rgba(154, 117, 91, 1)",
      border: "1px solid #242222",
      "&:focus": {
        border: "1px solid rgba(97, 97, 97, 1)",
        color: "rgba(154, 117, 91, 1)",
        background: "transparent",
      },
      "&:hover": {
        color: "rgba(154, 117, 91, 1)",
        border: "1px solid rgba(97, 97, 97, 1)",
        background: "rgba(19, 19, 19, 0.6)",
      },
    },
    icon: {
      fontSize: "15px !important",
    },
    generateKeywordButton: {
      fontSize: "16px",
      lineHeight: "19.09px",
      background: "transparent",
      width: "auto",
      height: "41px",
      borderRadius: "30px",
      fontWeight: 500,
      padding: "17px 27px 15px 20px",
      color: "rgba(156, 155, 119, 1)",
      border: "1px solid rgba(61, 57, 56, 1)",
      "&:focus": {
        border: "1px solid rgba(61, 57, 56, 1)",
        color: "rgba(156, 155, 119, 1)",
        background: "transparent",
      },
      "&:hover": {
        color: "rgba(156, 155, 119, 1)",
        border: "1px solid rgba(61, 57, 56, 1)",
        background: "rgba(19, 19, 19, 0.6)",
      },
    },
    addIconbtn: {
      fontSize: "15px !important",
      marginRight: "0px !important",
    },
  };
});

const KeywordsAndTags = () => {

  // ---------------------------- Hooks --------------------------------;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const selectedBusiness = useSelector((state) => state?.business?.business);

  const [searchParams] = useSearchParams();
  const businessUpdateID = searchParams.get('param1');
  // ---------------------------- STATE --------------------------------
  const [selectedTab, setSelectedTab] = useState(0);
  const [filterData, setFilterData] = useState({
    // business: "",
    // businessTag: "",
  })

  //  key word tab
  const [count, setCount] = useState(10); // set count direct in get data
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [error, setError] = useState({});
  const [typeBusiness, setTypeBusiness] = useState("");
  const [generateData, setGenerateData] = useState([]);
  const [keyWordData, setKeyWordData] = useState([]);  // show keyWord data 
  const [keywordEdit, setKeywordEdit] = useState(false)
  const [conformationModelOpenKeyword, setConformationModalOpenKeyword] = useState(false)
  const [deleteKeywordId, setDeleteKeywordId] = useState(null);
  // tag tab
  const [openTagModal, setOpenTagModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [tagError, setTagError] = useState({});
  const [myBusinessTag, setMyBusinessTag] = useState();
  const [tagData, setTagData] = useState([]);  // show tag data 
  const [typeBusinessTag, setTypeBusinessTag] = useState(""); // Modal Field
  const [tagGeneratedata, setTagGeneratedata] = useState([]);  // modal generate 
  const [selectedData, setSelectedData] = useState([]);  // modal selected
  const [deleteTagId, setDeleteTagId] = useState("")

  // ------------ HANDLE CHANGE tab Genral ----------------
  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  // handleChange Pagination
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  // handle Change Rows PerPage
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };

  // Handle change open accordion
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // --------------------------------- keyWords HandelChange --------------------------------

  // Add search key word staore in business array
  const handleAddGenerateKeyword = (language) => {
    if (!businessTypes.some((item) => item.value === language)) {
      setBusinessTypes(prevBusinessTypes => {
        if (prevBusinessTypes.length > 0) {
          return prevBusinessTypes.map(businessType => ({
            ...businessType, keywords: [...businessType.keywords, { _id: uuidv4(), keyword: language }]
          }));
        } else {
          return [{ _id: uuidv4(), keywords: [{ _id: uuidv4(), keyword: language }] }];
        }
      });
    }
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setError(""); // Clear error when the user starts typing
  };

  const handleAddKeyword = () => {
    if (keyword.trim() === "") {
      setError({ tag: "please enter your keyword" });
      return;
    }

    const isDuplicate = businessTypes?.[0]?.keywords.some((item) => item?.keyword === keyword);
    if (isDuplicate) {
      setError({ tag: "Keyword already exists" });
      return;
    }
    setBusinessTypes(prevBusinessTypes => {
      if (prevBusinessTypes.length > 0) {
        return prevBusinessTypes.map(businessType => ({
          ...businessType, keywords: [...businessType.keywords, { _id: uuidv4(), keyword }]
        }));
      } else {
        return [{ _id: uuidv4(), keywords: [{ _id: uuidv4(), keyword }] }];
      }
    });
    setKeyword("");
    setError("");
  };

  const handleRemoveKeyword = (businessTypeId, keywordId) => {
    setBusinessTypes((prevBusinessTypes) =>
      prevBusinessTypes.map((businessType) => {
        if (businessType._id === businessTypeId) {
          return {
            ...businessType,
            keywords: businessType.keywords.filter((keyword) => keyword._id !== keywordId),
          };
        }
        return businessType;
      })
    );
  };

  const handlecloseKeyword = () => {
    setOpen(false);
    setError({});
    setBusinessTypes([]);
    setTypeBusiness("");
    setKeyword("");
    setGenerateData([]);
    setKeywordEdit(false)
  }


  // -------------------------------------- TAG tab handle --------------------------------------
  // Handle input change
  const handleChangeTag = (e) => {
    setTypeBusinessTag(e.target.value);
    if (tagError.typeBusinessTag) {
      setTagError({});
    }
  };

  // Handle input change
  const handleChangemyBusinessTag = (e) => {
    setMyBusinessTag({ _id: uuidv4(), tag: e.target.value });
    if (tagError.myBusinessTag) {
      setTagError({});
    }
  };

  // Handle removing a keyword from the array
  const handleRemove = (index) => {
    setSelectedData((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  // Add search key word staore in tag selected array
  const handleAddSSelectTag = (tag) => {
    if (!selectedData.some((item) => item.value === tag)) {
      setSelectedData((prev) => [
        ...prev,
        { _id: uuidv4(), tag: tag },
      ]);
    }
  };

  // Handle Close Tag
  const handleCloseTag = () => {
    setTagError({});
    setError({});
    setOpenTagModal(false);
    setTypeBusinessTag("")
    setMyBusinessTag("");
    setTagGeneratedata([]);
    setSelectedData([]);
  }

  const handleConformationModelForKeyword = () => {
    setConformationModalOpenKeyword(false);
    setDeleteKeywordId(null)
  }

  const handleConformationModelForTags = () => {
    setDeleteTagId(null)
  }

  const validationKeyWord = () => {
    const temp = {}
    let valid = true
    if (!businessTypes?.length) {
      temp.typeBusinessKeyWords = "please select atleast one bussiness type keyword"
      valid = false
    }
    if (!businessTypes?.[0]?.keywords?.length) {
      temp.typeBusinessKeyWord = "please select atleast one bussiness type keyword"
      valid = false
    }
    setError(temp)
    return valid
  }

  // ===> validation tag
  const validationTag = () => {
    const temp = {}
    let valid = true
    if (!selectedData?.length) {
      temp.typeBusinessTag = "Please select atleast one bussiness type tag"
      valid = false
    }
    setError(temp)
    return valid
  }


  // handel generate keyword
  const handleAdd = async () => {
    const trimmedTypeBusiness = typeBusiness.trim();
    if (!trimmedTypeBusiness) {
      setError({ typeBusiness: "Please enter your Business type" });
      return;
    }
    if (trimmedTypeBusiness) {
      dispatch(showLoader(true))
      let body = {
        description: typeBusiness,
      };
      try {
        const result = await dispatch(keywordGenerate(body));
        const error = ErrorHandler(result);
        if (error) {
          setGenerateData(result?.payload?.data);
          setError({});
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.payload?.message);
      } finally {
        dispatch(showLoader(false))
      }
    }
  };

  // ==> get Keyword data 
  const _getKeyWordData = async () => {
    if (selectedBusiness?._id) {
      dispatch(showLoader(true));
      let body = {
        // "businessId": businessID ? businessID : loginUser?._id
        "businessId": selectedBusiness?._id,
      };
      try {
        const result = await dispatch(getBusinessKeywords(body));
        const error = await ErrorHandler(result);
        if (error) {
          setKeyWordData(result?.payload?.data?.keywordsData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false));
      }
    }
  };
  // ===> keyword Add & generate Api modal
  const _handleAddKeyWords = async () => {
    if (validationKeyWord()) {

      let body = {
        "businessId": selectedBusiness?._id, //changed here business_id
        "keywordsData": businessTypes
      };

      dispatch(showLoader(true));
      try {
        const result = await dispatch(keywordEdit ? editBusinessKeywords(body) : addBusinessKeywords(body));
        const error = ErrorHandler(result);
        if (error) {
          // setGenerateData([]); 
          handlecloseKeyword()
          _getKeyWordData()
          toast?.success(result?.payload?.message)
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.payload?.message);
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  // ===> delete keyword data
  const _deleteKeyWord = async (id) => {
    // const businessID = filterBusiness?.find((business) => business.name === filterData?.business)?._id;
    let body = {
      "businessId": selectedBusiness?._id,
      "objectId": id
    }
    dispatch(showLoader(true));
    try {
      const result = await dispatch(deleteBusinessKeywords(body));
      const error = ErrorHandler(result);
      if (error) {
        _getKeyWordData()
        toast?.success(result?.payload?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false));
    }
  }

  // ===> API calling Tag
  // ===> generate tag
  const handleAddTag = async () => {
    // Trim whitespace and validate if keyword is not empty or a duplicate
    if (!typeBusinessTag) {
      setTagError({ businessTag: "Please enter your Business type" });
      return;
    }
    if (typeBusinessTag) {
      dispatch(showLoader(true));
      let body = {
        description: typeBusinessTag,
      };
      try {
        const result = await dispatch(tagGenerate(body));
        const error = ErrorHandler(result);
        if (error) {
          setTagGeneratedata(result?.payload?.data) // store tag geerated data 
          // setTypeBusinessTag("");
          setTagError({});
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.payload?.message);
      } finally {
        dispatch(showLoader(false));
      }
    };
  };

  // ===> get tag data 
  const _getTagData = async () => {
    dispatch(showLoader(true));
    // const businessID = filterBusiness?.find((business) => business.name === filterData?.businessTag)?._id;
    let body = {
      // "businessId": businessID ? businessID : loginUser?._id
      "businessId": selectedBusiness?._id,
    };
    try {
      const result = await dispatch(getGenerate(body));
      const error = ErrorHandler(result);
      if (error) {
        setTagData(result?.payload?.data?.tagsData);
        // setSelectedData(result?.payload?.data?.tags);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false));
    }
  };
  // ===> Tag Add & generate Api modal
  const _handleAddGenerateData = async () => {
    if (validationTag()) {
      // const businessID = filterBusiness?.find((business) => business.name === filterData?.businessTag)?._id;
      let body = {
        // "businessId": businessID ? businessID : loginUser?._id,
        "businessId": selectedBusiness?._id,
        "tagsData": selectedData
      };
      dispatch(showLoader(true));
      try {
        const result = await dispatch(addGenerate(body));
        const error = ErrorHandler(result);
        if (error) {
          handleCloseTag()
          _getTagData()
          toast?.success(result?.payload?.message)
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.payload?.message);
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  // ===> Tag Add & generate Api
  const handleAddmyBusinessTag = () => {
    const trimmedTag = myBusinessTag?.tag?.trimStart();

    if (!trimmedTag) {
      setTagError({ myBusinessTag: "please enter your tag" });
      return;
    }

    setTagError({ myBusinessTag: "" });
    const isDuplicate = selectedData?.some((item) => item?.tag === trimmedTag);
    const isDuplicateOut = tagData?.some((item) => item?.tag === trimmedTag);
    if (isDuplicate) {
      setTagError({ myBusinessTag: "This tag is already added" });
      return;
    }
    if (isDuplicateOut) {
      setTagError({ myBusinessTag: "This tag is already generated" });
      return;
    }

    setSelectedData((prev) => [
      ...prev,
      { _id: uuidv4(), tag: myBusinessTag.tag },
    ]);

    setTagError({ myBusinessTag: "" });
    setMyBusinessTag({ _id: "", tag: "" });

    // if (isDuplicate) {
    //   setSelectedData((prev) => [
    //     ...prev,
    //     { _id: uuidv4(), tag: myBusinessTag.tag },
    //   ]);
    // }
    // // Reset the input field
    // setMyBusinessTag({ _id: "", tag: "" });
  };

  const _deletetag = async (id) => {
    // const businessID = filterBusiness?.find((business) => business.name === filterData?.businessTag)?._id;
    let body = {
      // "businessId": businessID ? businessID : loginUser?._id,
      "businessId": selectedBusiness?._id,
      "tagId": id
    }
    dispatch(showLoader(true));
    try {
      const result = await dispatch(deleteGenerate(body));
      const error = ErrorHandler(result);
      if (error) {
        handleCloseTag()
        _getTagData()
        toast?.success(result?.payload?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(showLoader(false));
    }
  }


  // --------------------------------------- useEffect ----------------------------------

  useEffect(() => {
    if (!open) {
      setBusinessTypes([]);
    }
  }, [open]);

  useEffect(() => {
    if (selectedTab === 1) {
      _getTagData()
    }
  }, [selectedTab, selectedBusiness]);

  useEffect(() => {
    if (selectedTab === 0) {
      _getKeyWordData()
    }
  }, [selectedTab, selectedBusiness]);

  // ------------------------ >>>>>>>>>>>>>>>>>>>>>>  KeyWord Tab
  const keyWord = () => {
    return (
      <Grid container item xs={12} mt={3} sx={{ borderRadius: "26px", overflow: "hidden" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead sx={{ color: "white", }} >
              <TableRow>
                <StyledTableCell align="start">Keywords</StyledTableCell>
                <StyledTableCell align="right">
                  <Typography mr={1}> Action </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {keyWordData?.length > 0 ? (
              <>
                <TableBody sx={{ background: "#13131399" }}>
                  {keyWordData?.map((item, i) => (
                    <TableRow key={i} sx={{ borderBottom: "1px solid #ffffff0D" }}>
                      <TableCell
                        colSpan={12}
                        sx={{ padding: 0, borderBottom: "none" }}
                      >
                        <Box mb={expanded === i ? 1 : 0}>
                          <Accordion
                            elevation={0}
                            key={i}
                            expanded={expanded === i}
                            onChange={handleAccordionChange(i)}
                          >
                            <AccordionSummary
                              sx={{
                                backgroundColor: "rgba(19, 19, 19, 0.6)",
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                gap: 1,
                                padding: "20px 40px !important",
                                "& .MuiAccordionSummary-content.Mui-expanded": {
                                  margin: 0,
                                },
                                "& .MuiAccordionSummary-content": {
                                  margin: 0,
                                },
                              }}
                              aria-controls={`panel-content-${i}`}
                              id={`panel-header-${i}`}
                            >
                              <Typography sx={{ flexGrow: 1 }}>
                                {item?.keywords?.[0]?.keyword}
                              </Typography>
                              {/* Actions (Edit and Delete) */}
                              <Box sx={{ display: "flex", gap: 4 }}>
                                <IconButton
                                  sx={{ padding: 0, color: "#f9cf77" }}
                                  onClick={(e) => { setOpen(true); setBusinessTypes([item]); setKeywordEdit(true) }}
                                >
                                  <img src="/images/editicon.svg" alt="edit icon" style={{ width: 16, height: 16 }} />
                                </IconButton>
                                <IconButton
                                  sx={{ padding: 0, color: "#f9cf77" }}
                                  onClick={(e) => { setConformationModalOpenKeyword(true); setDeleteKeywordId(item?._id); }}
                                >
                                  <img src="/images/deleteicon.svg" alt="delete icon" style={{ width: 16, height: 16 }} />
                                </IconButton>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 50px" }}>
                              {item?.keywords?.map((keyword, index) => (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={1}
                                  mb={"16px"}
                                >
                                  <Typography
                                    fontSize={"14px"}
                                    lineHeight={"16.71px"}
                                    color={"#716b6b"}
                                  >
                                    {index + 1}.
                                  </Typography>
                                  <Typography
                                    fontSize={"14px"}
                                    lineHeight={"16.71px"}
                                    color={"#716B6B"}
                                  >
                                    {keyword?.keyword}
                                  </Typography>
                                </Box>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* <TableFooter>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ padding: 0 }}>
                      <Box sx={{ width: "100%" }}>
                        <CustomPagination
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          count={Math.ceil(count / rowsPerPage)}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter> */}
              </>) :
              (
                <TableBody sx={{ background: "#13131399" }}>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <img src="/images/noDataFound.svg" alt="no data" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )
            }
          </Table>
        </TableContainer>
        {/* {keyWordData?.length > 0 && (
          <Box sx={{ width: '100%', backgroundColor: "#131313" }}>
            <CustomPagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              count={Math.ceil(count / rowsPerPage)}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )} */}
      </Grid>
    );
  };

  // ------------------------- >>>>>>>>>>>>>>>>>>>>>>  tag
  const tag = () => {
    return (
      <Grid
        item
        container
        sx={{
          padding: { xs: "20px !important", sm: "30px !important" },
          backgroundColor: "#13131399",
          borderRadius: "26px",
        }}
        mt={3}
      >
        {/* show get tag data */}
        <Grid item xs={12} mt={3}>
          <Box display={"flex"} flexWrap={"wrap"} gap={1}>
            {tagData?.length > 0 ? tagData?.map((item) => (  // tagData
              <Button
                key={item.id}
                variant="outlined"
                className={classes.customButton}
                endIcon={
                  <CloseOutlinedIcon className={classes.icon} />
                }
                onClick={() => setDeleteTagId(item?._id)}
              >
                {item?.tag}
              </Button>
            )) :
              (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  <img src="/images/noDataFound.svg" alt="no data" />
                </Box>
              )}
          </Box>
        </Grid>
      </Grid >
    );
  };

  // Content for each tabs
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return keyWord();
      case 1:
        return tag();
      default:
        return keyWord();
    }
  };

  return (
    <Grid container xs={12} spacing={0}>
      <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} >

        <CustomTabs
          labels={["Keywords", "Tags"]}
          selectedTab={selectedTab}
          onChange={handleTabChange}
          backgroundColor={"#070707"}
          padding={"20px 20px 20px 0px"}
        />

        {selectedTab === 0 &&
          <Box display={"flex"} alignItems={"center"} gap={2} justifyContent={"space-between"} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
            <CommonButton
              className={"primary"}
              fontWeight={500}
              padding={"10px 20px"}
              maxWidth={"144px"}
              text={"Add Keyword +"}
              onClick={() => { setOpen(true); setKeywordEdit(false) }}
            />
          </Box>}
        {selectedTab === 1 &&
          <Box display={"flex"} alignItems={"center"} gap={2} justifyContent={"space-between"} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
            <CommonButton
              text={"Add tags +"}
              className={"primary"}
              maxWidth={"109px"}
              height={"51px"}
              onClick={() => { setOpenTagModal(true); setTagError({}) }}
            />
          </Box>}

      </Grid>

      <Grid item xs={12}>
        {selectedTab === 0 ? (

          renderTabContent()

        ) : (
          renderTabContent()
        )}
      </Grid>

      <CommonModal
        open={open}
        onClose={() => handlecloseKeyword()}
        title={"Add Keyword"}
        maxWidth={"md"}
        content={
          <Grid
            container
            xs={12}
            spacing={2}
            padding={{ xs: "20px 25px", md: "40px 60px" }}
            sx={{ backgroundColor: "#222222" }}
          >

            {/* Generate search textField */}
            <Grid item xs={12} sm={8} md={9}>
              <CommonTextField
                color={"rgba(171, 171, 171, 1)"}
                text={"Business Description"}
                type="text"
                placeholder="Type your business description"
                value={typeBusiness}
                onChange={(e) => {
                  setTypeBusiness(e.target.value);
                }}
              />
              <Typography ml={1} variant="caption" color="error">
                {!typeBusiness ? error.typeBusiness : ""}
              </Typography>
            </Grid>

            {/* Generate Button */}
            <Grid item xs={12} sm={4} md={3} mt={{ xs: 1, sm: 3.5 }}>
              <CommonButton
                className={"primary"}
                fontWeight={500}
                padding={"10px 20px"}
                maxWidth={"150px"}
                height={"41px"}
                text={"Generate by Ai"}
                onClick={handleAdd}
              />
            </Grid>

            {/* generate data */}
            <Grid item xs={12}>
              <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                {generateData
                  ?.filter((key) =>
                    !businessTypes.some((item) =>
                      item.keywords.some((k) => k.keyword === key?.keyword)
                    )
                  )
                  ?.map((key) => (
                    <Button
                      key={key?.id}
                      variant="outlined"
                      className={classes.generateKeywordButton}
                      onClick={() => handleAddGenerateKeyword(key?.keyword)}
                      startIcon={<AddIcon className={classes.addIconbtn} />}
                    >
                      {key?.keyword}
                    </Button>
                  ))}
              </Box>
            </Grid>

            {/* Keywords textField */}
            <Grid Grid item xs={12} sm={11} mt={{ xs: 1, sm: 2 }}>
              <CommonTextField
                fontSize={"16px"}
                text={"Keywords"}
                color={"rgba(171, 171, 171, 1)"}
                type={"text"}
                placeholder={"Add your keywords"}
                name={"keyword"}
                onChange={handleKeywordChange}
                value={keyword}
              />
              <Typography ml={1} variant="caption" color="error">
                {error.tag || ""}
              </Typography>
            </Grid>

            {/* Add button */}
            <Grid item xs={12} sm={1} mt={{ xs: 0, sm: 5.7 }}>
              <Box
                sx={{
                  fontSize: "20px",
                  width: "46px",
                  height: "46px", // Increased from 42px to 50px
                  background:
                    "radial-gradient(121.22% 95.61% at 11.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                  WebkitMaskImage:
                    "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 43 42%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M19.4823 29.0202V23.0327H12.4924C9.82312 23.0327 9.82312 18.9705 12.4924 18.9705H19.4823V11.9807C19.4823 9.31137 23.5445 9.31137 23.5445 11.9807V18.9705H30.5319C33.2012 18.9705 33.2012 23.0327 30.5319 23.0327H23.5445V29.0202C23.5445 31.6895 19.4823 31.6895 19.4823 29.0202ZM21.5126 42C33.111 42 42.5126 32.5992 42.5126 21C42.5126 9.4016 33.111 0 21.5126 0C9.91418 0 0.512573 9.4016 0.512573 21C0.512573 32.5984 9.91418 42 21.5126 42Z%22 fill=%22url(%23paint0_radial_929_3241)%22/%3E%3Cdefs%3E%3CradialGradient id=%22paint0_radial_929_3241%22 cx=%220%22 cy=%220%22 r=%221%22 gradientUnits=%22userSpaceOnUse%22 gradientTransform=%22translate(9.52322 4.46808) rotate(49.9729) scale(41.9147 77.4582)%22%3E%3Cstop stop-color=%22white%22/%3E%3Cstop offset=%220.79%22 stop-color=%22%23F4A606%22/%3E%3Cstop offset=%221%22 stop-color=%22%23FD63C5%22/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E')",
                  maskImage:
                    "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 43 42%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M19.4823 29.0202V23.0327H12.4924C9.82312 23.0327 9.82312 18.9705 12.4924 18.9705H19.4823V11.9807C19.4823 9.31137 23.5445 9.31137 23.5445 11.9807V18.9705H30.5319C33.2012 18.9705 33.2012 23.0327 30.5319 23.0327H23.5445V29.0202C23.5445 31.6895 19.4823 31.6895 19.4823 29.0202ZM21.5126 42C33.111 42 42.5126 32.5992 42.5126 21C42.5126 9.4016 33.111 0 21.5126 0C9.91418 0 0.512573 9.4016 0.512573 21C0.512573 32.5984 9.91418 42 21.5126 42Z%22 fill=%22url(%23paint0_radial_929_3241)%22/%3E%3Cdefs%3E%3CradialGradient id=%22paint0_radial_929_3241%22 cx=%220%22 cy=%220%22 r=%221%22 gradientUnits=%22userSpaceOnUse%22 gradientTransform=%22translate(9.52322 4.46808) rotate(49.9729) scale(41.9147 77.4582)%22%3E%3Cstop stop-color=%22white%22/%3E%3Cstop offset=%220.79%22 stop-color=%22%23F4A606%22/%3E%3Cstop offset=%221%22 stop-color=%22%23FD63C5%22/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E')",
                  WebkitMaskSize: "100% 100%",
                  maskSize: "100% 100%",
                  WebkitMaskRepeat: "no-repeat",
                  maskRepeat: "no-repeat",
                }}
                onClick={handleAddKeyword}
              />

            </Grid>

            {/* Show & Delete chep data */}
            <Grid item xs={12} mb={3} sx={{ paddingTop: !businessTypes?.length || !businessTypes?.[0]?.keywords?.length ? "3px !important" : "16px !important" }}>
              <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                {businessTypes?.length > 0
                  ? businessTypes?.map((item) => (
                    item?.keywords?.map((value) => (
                      <Button
                        key={value?._id}
                        variant="outlined"
                        sx={{
                          fontSize: "16px",
                          background: "transparent",
                          lineHeight: "19.09px",
                          width: "auto",
                          height: "41px",
                          borderRadius: "30px",
                          fontWeight: 500,
                          padding: "16px 16px 16px 16px",
                          color: "rgba(154, 117, 91, 1)",
                          border: "1px solid rgba(97, 97, 97, 1)",
                          ":focus": {
                            border: "1px solid rgba(97, 97, 97, 1)",
                            color: "rgba(154, 117, 91, 1)",
                            background: "transparent",
                          },
                          ":hover": {
                            color: "rgba(154, 117, 91, 1)",
                            border: "1px solid rgba(97, 97, 97, 1)",
                            background: "rgba(19, 19, 19, 0.6)",
                          },
                        }}
                        startIcon={
                          <CloseOutlinedIcon
                            sx={{ fontSize: "15px !important", marginRight: "0px !important" }}
                          />
                        }
                        onClick={() => handleRemoveKeyword(item._id, value._id)}
                      >
                        {value.keyword}
                      </Button>
                    ))
                  ))
                  : ""}
                <Typography ml={1} variant="caption" color="error">
                  {!businessTypes?.length ? error?.typeBusinessKeyWords : ""}
                </Typography>
                <Typography ml={0} variant="caption" color="error">
                  {businessTypes?.length && !businessTypes?.[0]?.keywords?.length ? error?.typeBusinessKeyWord : ""}
                </Typography>
              </Box>
            </Grid>

            {/* save btn */}
            <Grid
              item
              xs={12}
              mt={{ xs: 1, sm: 4 }}
              display={"flex"}
              justifyContent={"end"}
            >
              <CommonButton
                className={"primary"}
                fontWeight={500}
                padding={"10px 20px"}
                maxWidth={"75px"}
                height={"45px"}
                text={"Save"}
                onClick={() => _handleAddKeyWords()}
              />
            </Grid>

          </Grid>
        }
      />

      <CommonModal
        open={openTagModal}
        onClose={() => { handleCloseTag() }}
        title={"Add Tag"}
        maxWidth={"md"}
        content={
          <Grid
            container
            xs={12}
            spacing={2}
            padding={{ xs: "25px 40px", md: "40px 60px" }}
            sx={{ backgroundColor: "#222222" }}
          >
            <Grid item xs={12} sm={8} md={9}>
              <CommonTextField
                // fontSize={"16px"}
                text={"Business Description"}
                color={"rgba(171, 171, 171, 1)"}
                type="text"
                placeholder="Enter your business description"
                name="typeBusinessTag"
                onChange={handleChangeTag}
                value={typeBusinessTag}
              />
              <Typography ml={1} variant="caption" color="error">
                {!typeBusinessTag ? tagError.businessTag : ""}
              </Typography>
            </Grid>

            {/* serach generate button */}
            <Grid item xs={12} sm={4} md={3} mt={{ xs: 1, sm: 3 }}>
              <CommonButton
                className={"primary"}
                fontWeight={500}
                padding={"10px 20px"}
                maxWidth={"150px"}
                height={"51px"}
                text={"Generate by Ai"}
                onClick={handleAddTag}
              />
            </Grid>

            {/* generate data */}
            <Grid item xs={12}>
              <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                {tagGeneratedata // tagGeneratedata
                  ?.filter(
                    (keyword) =>
                      !selectedData.some((item) => item.tag === keyword?.keyword) &&
                      !tagData.some((item) => item.tag === keyword?.keyword)
                  )
                  ?.map((keyword) => (
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "16px",
                        background: "transparent",
                        width: "auto",
                        height: "41px",
                        borderRadius: "30px",
                        fontWeight: 500,
                        padding: "10px 20px",
                        color: "rgba(156, 155, 119, 1)",
                        border: "1px solid rgba(61, 57, 56, 1)",
                        ":focus": {
                          border: "1px solid rgba(61, 57, 56, 1)",
                          color: "rgba(156, 155, 119, 1)",
                          background: "transparent",
                        },
                        ":hover": {
                          color: "rgba(156, 155, 119, 1)",
                          border: "1px solid rgba(61, 57, 56, 1)",
                          background: "rgba(19, 19, 19, 0.6)",
                        },
                      }}
                      onClick={() => handleAddSSelectTag(keyword?.keyword)}
                      startIcon={<AddIcon sx={{ fontSize: "15px !important", marginRight: "0px !important" }} />}
                    >
                      {keyword?.keyword}
                    </Button>
                  ))}
              </Box>
            </Grid>

            {/* <Grid item xs={12}>
            <Divider />
            <TextLabel title={"Selected Data"} color="rgba(171, 171, 171, 1)" fontSize="16px" marginTop={"10px"} />
          </Grid> */}

            <Grid item xs={10} sm={10} md={11} mt={2} >
              <CommonTextField
                fontSize={"16px"}
                text={"Tag"}
                color={"rgba(171, 171, 171, 1)"}
                type="text"
                placeholder="Add your tag"
                name="myBusinessTag"
                onChange={handleChangemyBusinessTag}
                value={myBusinessTag?.tag}
              />
              <Typography ml={1} variant="caption" color="error">
                {!myBusinessTag?.tag ? tagError?.myBusinessTag : ""}
              </Typography>
              <Typography ml={1} variant="caption" color="error">
                {myBusinessTag?.tag ? tagError?.myBusinessTag : ""}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={1} mt={{ xs: 0, sm: 6 }}>
              {/* <Tooltip arrow title={!filterData?.businessTag ? "Please select at least one tag" : ""}> */}
              <span>
                <Box
                  sx={{
                    fontSize: "20px",
                    width: "42px",
                    height: "42px", // 141.22% 118.61% at 21.45% 10.64%
                    background:
                      "radial-gradient( 121.22% 95.61% at 11.45% 10.64%, #FFFFFF 0%, #F4A606 79%, #FD63C5 100%)",
                    WebkitMaskImage:
                      "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 43 42%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M19.4823 29.0202V23.0327H12.4924C9.82312 23.0327 9.82312 18.9705 12.4924 18.9705H19.4823V11.9807C19.4823 9.31137 23.5445 9.31137 23.5445 11.9807V18.9705H30.5319C33.2012 18.9705 33.2012 23.0327 30.5319 23.0327H23.5445V29.0202C23.5445 31.6895 19.4823 31.6895 19.4823 29.0202ZM21.5126 42C33.111 42 42.5126 32.5992 42.5126 21C42.5126 9.4016 33.111 0 21.5126 0C9.91418 0 0.512573 9.4016 0.512573 21C0.512573 32.5984 9.91418 42 21.5126 42Z%22 fill=%22url(%23paint0_radial_929_3241)%22/%3E%3Cdefs%3E%3CradialGradient id=%22paint0_radial_929_3241%22 cx=%220%22 cy=%220%22 r=%221%22 gradientUnits=%22userSpaceOnUse%22 gradientTransform=%22translate(9.52322 4.46808) rotate(49.9729) scale(41.9147 77.4582)%22%3E%3Cstop stop-color=%22white%22/%3E%3Cstop offset=%220.79%22 stop-color=%22%23F4A606%22/%3E%3Cstop offset=%221%22 stop-color=%22%23FD63C5%22/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E')",
                    maskImage:
                      "url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 43 42%22%3E%3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 d=%22M19.4823 29.0202V23.0327H12.4924C9.82312 23.0327 9.82312 18.9705 12.4924 18.9705H19.4823V11.9807C19.4823 9.31137 23.5445 9.31137 23.5445 11.9807V18.9705H30.5319C33.2012 18.9705 33.2012 23.0327 30.5319 23.0327H23.5445V29.0202C23.5445 31.6895 19.4823 31.6895 19.4823 29.0202ZM21.5126 42C33.111 42 42.5126 32.5992 42.5126 21C42.5126 9.4016 33.111 0 21.5126 0C9.91418 0 0.512573 9.4016 0.512573 21C0.512573 32.5984 9.91418 42 21.5126 42Z%22 fill=%22url(%23paint0_radial_929_3241)%22/%3E%3Cdefs%3E%3CradialGradient id=%22paint0_radial_929_3241%22 cx=%220%22 cy=%220%22 r=%221%22 gradientUnits=%22userSpaceOnUse%22 gradientTransform=%22translate(9.52322 4.46808) rotate(49.9729) scale(41.9147 77.4582)%22%3E%3Cstop stop-color=%22white%22/%3E%3Cstop offset=%220.79%22 stop-color=%22%23F4A606%22/%3E%3Cstop offset=%221%22 stop-color=%22%23FD63C5%22/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E')",
                    WebkitMaskSize: "100% 100%",
                    maskSize: "100% 100%",
                    WebkitMaskRepeat: "no-repeat",
                    maskRepeat: "no-repeat",
                  }}
                  onClick={handleAddmyBusinessTag}
                />
              </span>
              {/* </Tooltip> */}
            </Grid>

            {/* show selected data */}
            <Grid item xs={12} mt={"5px"} sx={{ paddingTop: !selectedData?.length ? "3px !important" : "16px !important" }} >
              <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                {selectedData?.map((item, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    sx={{
                      background: "rgba(22, 22, 22, 1)",
                      lineHeight: "19.09px",
                      width: "auto",
                      height: "41px",
                      borderRadius: "30px",
                      fontSize: "16px",
                      fontWeight: 500,
                      padding: "10px 20px",
                      color: "rgba(154, 117, 91, 1)",
                      border: "1px solid rgba(22, 22, 22, 1)",
                      ":focus": {
                        border: "1px solid rgba(22, 22, 22, 1)",
                        color: "rgba(154, 117, 91, 1)",
                        background: "rgba(19, 19, 19, 0.6)",
                      },
                      ":hover": {
                        color: "rgba(154, 117, 91, 1)",
                        border: "1px solid rgba(22, 22, 22, 1)",
                        background: "rgba(19, 19, 19, 0.6)",
                      },
                    }}
                    endIcon={
                      <CloseOutlinedIcon sx={{ fontSize: "15px !important", marginRight: "0px !important" }} />
                    }
                    onClick={() => handleRemove(index)}
                  >
                    {item.tag}
                  </Button>
                ))}
                <Typography ml={1} variant="caption" color="error">
                  {!selectedData?.length ? error?.typeBusinessTag : ""}
                </Typography>
              </Box>
            </Grid>

            {/* Save button */}
            <Grid
              item
              xs={12}
              mt={{ xs: 1, sm: 4 }}
              display={"flex"}
              justifyContent={"end"}
            >
              <CommonButton
                className={"primary"}
                fontWeight={500}
                padding={"10px 20px"}
                maxWidth={"75px"}
                height={"45px"}
                text={"Save"}
                onClick={() => _handleAddGenerateData()}
              />
            </Grid>
          </Grid>
        }
      />

      {/* Model for delete keyword */}
      {conformationModelOpenKeyword &&
        <CommonConformationModal
          open={conformationModelOpenKeyword}
          onClose={() => { handleConformationModelForKeyword() }}
          onSubmit={() => { _deleteKeyWord(deleteKeywordId); handleConformationModelForKeyword(); }}
          maxWidth={"sm"}
          question={"Are you sure you want to delete this keyword?"}
          isDelete
        />}
      {deleteTagId &&
        <CommonConformationModal
          open={deleteTagId}
          onClose={() => { handleConformationModelForTags() }}
          onSubmit={() => { _deletetag(deleteTagId); handleConformationModelForTags() }}
          maxWidth={"sm"}
          question={"Are you sure you want to delete this tag?"}
          isDelete
        />}
    </Grid >
  )
}

export default KeywordsAndTags